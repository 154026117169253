import * as React from 'react'
import * as mdl from '../model'
import {
	cancelClick, Component, computed, Context, css, Icon, observer
} from './common'

interface Props {
	item: mdl.Item
	tags: mdl.Item[]
	allTags: mdl.Item[]
	context: Context
}

@observer
export class ItemTags extends Component<Props> {

	@computed get isExpanded() {
		return this.props.context.ui.activeComponent === this ||
			this.props.context.ui.activeComponent === 'test'
	}

	render() {
		const { item, tags, allTags } = this.props
		if (!item.isReady)
			return null
		return <div className={css('tags', this.isExpanded && 'expanded')}
			onClick={this.toggleExpand}>
			<div>
				{tags.map(t =>
					<Tag key={t.id} item={t} />)}
				{tags.length === 0 && this.isExpanded &&
					<span className="tag">{' '}</span>}
			</div>
			{this.isExpanded && <div className={'options'} onClick={cancelClick}>
				{allTags.map(t =>
					<TagOption key={t.id} item={item} tags={tags} tag={t} />)}
				<div title="TODO: implement" style={{ color: '#d7d7d7' }}>new...</div>
			</div>}
		</div>
	}

	toggleExpand = (evn: React.MouseEvent<HTMLElement>) => {
		if (!this.isExpanded) {
			this.props.context.ui.activeComponent = this
			evn.stopPropagation()
		}
	}

}

const Tag = observer(({ item }: { item: mdl.Item }) => {
	const style = {
		backgroundColor: item.props.get('backColor')?.value ?? 'gray',
		color: item.props.get('color')?.value ?? 'white',
	}
	return <span className="tag" style={style}>
		{item.props.get('mark')?.value ?? '?'}</span>
})

@observer
export class TagOption
	extends Component<{ item: mdl.Item, tags: mdl.Item[], tag: mdl.Item }> {

	render() {
		const { tags, tag } = this.props
		return <div onClick={this.toggle}>
			<Icon uri={tags.includes(tag) ? 'checked' : 'unchecked'} />
			<Tag item={tag} />
		</div>
	}

	toggle = () => {
		const { item, tag } = this.props
		if (!tag.links.remove(item.id))
			tag.links.add(item)
	}
}
