import { observable } from 'mobx'
import { Credentials, CredentialsProvider } from './Auth'
import { computed, signal } from './common'
import { Item } from './Item'
import { AccountValue } from './PropertyValue'

export class Account {
	@observable provider: string
	@observable url: string
	constructor(public readonly item: Item, data?: AccountValue) {
		this.item = item
		this.provider = data?.account
		this.url = data?.url
	}
	@computed get id() { return this.item.id }
	@computed get isActive() {
		return Account.isLocal(this.provider) || !!this.credentials
	}
	static isLocal(provider: string) {
		return provider === 'local'
	}
	@computed get isLocal() { return Account.isLocal(this.provider) }
	static isToken(provider: string) {
		return provider.endsWith('-token')
	}
	@computed get isToken() { return Account.isToken(this.provider) }
	static isOAuth(provider: string) {
		return provider.includes('-oauth')
	}
	@computed get isOAuth() { return Account.isOAuth(this.provider) }
	/** The URL is fixed defined in config (google,...) */
	static hasFixedUrl(provider: string) {
		return provider.includes('google-')
	}
	@computed get hasFixedUrl() { return Account.hasFixedUrl(this.provider) }
	static canLogin(provider: string, url: string) {
		return (!!url || Account.hasFixedUrl(provider)) && !Account.isLocal(provider)
	}
	@computed get canLogin() { return Account.canLogin(this.provider, this.url) }
	static getCredentialsType(provider: string) {
		return Account.isLocal(provider) ? null : Account.isToken(provider) ?
			'token' : Account.isOAuth(provider) ? 'oauth' : 'password'
	}
	@computed get credentialsType() {
		return Account.getCredentialsType(this.provider)
	}
	get $debug() {
		return this.id + ': ' + this.url
	}
	credentialsProvider: CredentialsProvider
	/** Authentication credentials to access this server. */
	@computed get credentials() {
		return this.credentialsKey &&
			this.credentialsProvider?.getCredentials(this.credentialsKey)
	}
	@computed get credentialsKey() {
		return this.hasFixedUrl ?
			this.provider + (this.url ? ':' + this.url : '') : this.url
	}
	login = signal<(id: string, password?: string) => Promise<void>>()
	readUser = signal<(credentials: Credentials) => Promise<void>>()
	logout = signal()
	changePassword = {
		start: signal<
			(oldPw: string, newPw1: string, newPw2: string) => Promise<void>>(),
		end: signal<(newPw: string) => Promise<void>>()
	}
}

