import { signal } from '../common'
import { Account } from './Account'
import { Credentials } from './Auth'
import { Box, BoxPermissions } from './Box'
import { action, computed, observable, reaction } from './common'
import { ItemData } from './data'
import { Item } from './Item'
import { StatusReports } from './Log'
import { BoxStorageValue } from './PropertyValue'

/** Storage for Boxes
 * Localstorage, indexedDB, CouchDB servers so far.
 * Peer-to-peer remote installation storages (indexedDB,...) should follow.
 */

export class BoxStorage {
	@observable protocol: string
	@observable url: string
	@observable searchUrl: string
	@observable blobUrl: string
	constructor(public readonly item: Item, data: BoxStorageValue) {
		this.item = item
		this.url = data.url
		this.searchUrl = data.searchUrl
		this.blobUrl = data.blobUrl
		this.protocol = data.storage
		// clear data access, when data has changed
		reaction(() => ({ u: this.url, p: this.protocol, c: this.credentials }),
			d => {
				if (this._access)
					this._access.close()
				this._access = null
			})
	}
	@observable status = ''
	@computed get id() { return this.item.id }
	@computed get label() { return this.item.labelText }
	@computed get account(): Account {
		return this.item.findRelatedPropertyValue('account')
	}
	@computed get credentials() {
		return this.account?.credentials
	}
	@computed get allBoxes(): Box[] {
		return this.item.findRelatedPropertyValues('box')
	}
	@computed get activeBoxes(): Box[] {
		return this.allBoxes.filter(b => b.isActive)
	}
	@computed get isAvailable() {
		return this.isActive && !!this.access
			&& (!this.access.isAvailable || this.access.isAvailable())
	}
	// Local storage generally provide faster access and don't need 
	// an account with credential
	static isLocal(protocol: string) {
		return protocol === 'local' || protocol === 'indexed' || protocol === 'test'
	}
	@computed get isLocal() { return BoxStorage.isLocal(this.protocol) }
	/** Passive box storages are "normal" items only */
	@observable private _isActive = false
	@computed get isActive() { return this._isActive }
	set isActive(val) {
		if (!val && this._access) {
			this._access.close()
			this._access = null
		}
		this._isActive = val
	}
	toggleActive = action(() => {
		this.isActive = !this.isActive
	})
	static setActive(storageItem: Item, val = true) {
		const storage = BoxStorage.getStorage(storageItem)
		if (!storage)
			throw new Error(`Item ${storageItem.id} does not contain a box storage!`)
		storage.isActive = val
	}
	static getStorage(storageItem: Item) {
		return storageItem?.props?.findByType('storage')?.value as BoxStorage
	}
	get $debug() {
		return {
			id: this.id, url: this.url, protocol: this.protocol,
			credentials: this.credentials
		}
	}
	/** Box access per protocol. */
	static protocols:
		{ [protocol: string]: (props: BoxStorageAccessArgs) => BoxStorageAccess }
		= {}
	/** Data access for this box. */
	private _access: BoxStorageAccess
	get access() {
		if (!this.isActive || !this.protocol)
			return null
		if (!this._access) {
			if (!(this.protocol in BoxStorage.protocols))
				throw new Error(`Invalid protocol '${this.protocol
					}' in storage ${this.id} at ${this.url}`)
			this._access = BoxStorage.protocols[this.protocol](this)
			this._access?.open()
		}
		return this._access
	}

	/** Status reports. */
	reports = new StatusReports()

	createAccount = {
		start: signal<() => Promise<void>>(),
		end: signal<(id: string) => void>()
	}

	addBoxes = signal<() => Promise<void>>()
}

export type DataFilter = 'admin' | 'boxes'

export interface SearchTextData {
	id: string
	rev: string
	text: string
}

export interface BoxStorageAccessArgs {
	url: string
	searchUrl?: string
	blobUrl?: string
	credentials: Credentials
	reports?: StatusReports
}

export interface BoxStorageAccess {
	/** Only for storages providing access control. */
	readPermissions?(fromBoxId: string): Promise<BoxPermissions>
	readData(fromBoxId: string, filter?: DataFilter): Promise<ItemData[]>
	readIds(fromBoxId: string): Promise<{ id: string, rev: string }[]>
	readItem(id: string, fromBoxIds: string[], completely: boolean)
		: Promise<ItemData | null>
	readItems(ids: string[], fromBoxIds: string[], completely: boolean)
		: Promise<ItemData[]>
	/** IDs of items linking to the item with the given ID.
	 * Items are searched in (but not limited to) the given Boxes (IDs of).
	 */
	readFromLinks(id: string, fromBoxIds: string[]): Promise<string[]>
	/** Search texts for all items in (but not limited to) the given 
	 * Boxes (IDs of).*/
	readSearchTexts?(fromBoxIds: string[]): Promise<SearchTextData[]>
	search?(query: string, fromBoxIds: string[]): Promise<string[]>
	writeItem(data: ItemData, intoBoxId: string): Promise<void>
	removeItem(id: string, fromBoxId: string): Promise<void>
	getBoxes(knownBoxIds: string[])
		: Promise<{ id: string, permissions: BoxPermissions }[]>
	addBox(box: Box): Promise<void>
	removeBox(boxId: string): Promise<void>
	open(boxId?: string): Promise<void>
	close(boxId?: string): Promise<void>
	isAvailable?(): boolean
}
