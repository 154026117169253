
export interface StorageEvent {
	readonly key: string
	readonly newValue: string | null
	readonly oldValue: string | null
}

export interface SyncStorage {
	getKeys(): string[]
	getItem(key: string): string | null
	setItem(key: string, data: string): void
	removeItem(key: string): void
	onChange(hdl: (evn: StorageEvent) => void): () => void
}

export class LocalStorage implements SyncStorage {
	getKeys(): string[] {
		const keys = []
		for (let i = 0, len = localStorage.length; i < len; ++i)
			keys.push(localStorage.key(i))
		return keys
	}
	getItem(key: string): string {
		return localStorage.getItem(key)
	}
	setItem(key: string, data: string): void {
		localStorage.setItem(key, data)
	}
	removeItem(key: string): void {
		localStorage.removeItem(key)
	}
	onChange(hdl: (evn: StorageEvent) => void): () => void {
		window.addEventListener('storage', hdl)
		return () => { window.removeEventListener('storage', hdl) }
	}

}

export const local_storage = new LocalStorage()

export interface AsyncStorage {
	getVal(key: string): Promise<any | null>
	setVal(key: string, data: any): Promise<void>
	removeVal(key: string): Promise<void>
}
