import * as React from 'react'
import { computed, observable } from '../../common'
import { observer } from './base'
import { Component } from './Component'
import { Icon } from './icons'

interface Props extends React.PropsWithChildren {
	label: string
	openByDefault?: boolean
	if?: boolean
}

@observer
export class Section extends Component<Props> {

	@observable _isOpen: boolean
	@computed get isOpen() { return this._isOpen ?? this.props.openByDefault }

	render() {
		const { label, children } = this.props
		return this.props.if === false ? null : <>
			<div className="section"
				onClick={this.toggle}>
				<Icon uri={this.isOpen ? 'expand' : 'chevron_right'}
				/> {label}</div>
			{this.isOpen && children}
		</>
	}

	toggle = () => {
		this._isOpen = !this.isOpen
	}
}
