import { action, reaction } from '../common'
import * as mdl from '../model'

const LOCALSTORAGE_KEY_CLIPBOARD = 'clipboard'

export const setup = {
	read: async ({ items, ui }:
		{ items: mdl.ItemManager, ui: mdl.UserInterface }) => {
		const str = localStorage.getItem(LOCALSTORAGE_KEY_CLIPBOARD)
		if (str) ui.clipboard = parse(items, str)
	},

	update: ({ ui }: { ui: mdl.UserInterface }) => {
		reaction(
			() => stringify(ui.clipboard),
			d => {
				if (d)
					localStorage.setItem(LOCALSTORAGE_KEY_CLIPBOARD, d)
				else
					localStorage.removeItem(LOCALSTORAGE_KEY_CLIPBOARD)
			})
	},

	external: ({ items, ui }:
		{ items: mdl.ItemManager, ui: mdl.UserInterface }) => {
		addEventListener('storage', evn => {
			if (evn.key !== LOCALSTORAGE_KEY_CLIPBOARD) return
			action(() => { ui.clipboard = parse(items, evn.newValue) })()
		})
	},

}

function stringify(clipboard: mdl.Clipboard) {
	if (!clipboard)
		return null
	const data: { content?: string[], links?: string[] } = {}
	if ('content' in clipboard)
		data.content = clipboard.content.map(ln => mdl.Link.toItem(ln)?.id)
	if ('links' in clipboard)
		data.links = clipboard.links.map(ln => mdl.Link.toItem(ln)?.id)
	return JSON.stringify(data)
}

function parse(items: mdl.ItemManager, clipboard: string): mdl.Clipboard {
	if (!clipboard)
		return null
	const cb = JSON.parse(clipboard)
	if ('content' in cb)
		cb.content = cb.content.map((id: string) => items.requestItem(id))
	if ('links' in cb)
		cb.links = cb.links.map((id: string) => items.requestItem(id))
	return cb
}
