import * as React from 'react'
import { observable, U } from '../common'
import * as mdl from '../model'
import { Component, Context, css, Icon, observer } from './common'
import { FooterDetails } from './FooterDetails'
import { ItemLabel, ItemLink } from './LayoutViews'

interface Props {
	view: mdl.PlaceItemView
	context: Context
}

@observer
export class PlaceItem extends Component<Props> {

	render() {
		const { context, context: { nav }, view, view: { item, items, allBoxes } }
			= this.props
		return <div className="view">
			<header>
				<button onClick={nav.back}><Icon uri="navBack" /></button>
				<div>
					<div>
						<span>
							<i>boxes for </i>
							{items ? `${items.length} items` :
								<ItemLabel item={item} context={context} />}
						</span>
					</div>
				</div>
			</header>
			<section>
				<div className="boxes items">
					{allBoxes.sort(U.str.compare(b => b.item.labelText))
						.sort(activeBoxesFirst)
						.map(box =>
							<PlaceLink key={box.id} box={box} view={view} context={context} />
						)}
				</div>
			</section>
			<footer>
				<FooterDetails item={item} context={context} />
			</footer>
		</div>
	}

	onDocumentKey = (evn: KeyboardEvent) => {
		const { nav } = this.props.context
		switch (evn.key) {
			case 'Escape': nav.back(); break
		}
	}

}

function activeBoxesFirst(box1: mdl.Box, box2: mdl.Box) {
	const a = box1.isActive && box1.hasAvailableStorages
	const b = box2.isActive && box2.hasAvailableStorages
	return a === b ? 0 : a ? -1 : 1
}

interface LinkProps {
	box: mdl.Box
	view: mdl.PlaceItemView
	context: Context
}

@observer
class PlaceLink extends Component<LinkProps> {

	@observable isToggleing = false

	render() {
		const { box, view, context } = this.props
		const itemsInBox = view.itemsInBox(box)
		const complete = itemsInBox === view.itemsToPlace.length
		const passive = !box.isActive
		const iconUri = this.isToggleing ? 'data_usage'
			: itemsInBox > 0 ? 'checked' : 'unchecked'
		return <div>
			<div className={css('entry', { complete, passive })}
				onClick={this.onToggle}>
				<Icon className={css('state', this.isToggleing && 'spin')}
					uri={iconUri}
					title={complete ? 'All items are in this box.' : itemsInBox > 0 ?
						'Some items are in this box.' : 'No items are in this box.'} />
				<ItemLink item={box.item} level="normal" context={context} />
				<button className="expand" onClick={this.onPlaceMode}
					title="Place multiple items into this box.">
					<Icon uri="cases" />
				</button>
				<button className="expand" onClick={this.onNav}>
					<Icon uri="navForward" />
				</button>
			</div>
		</div>
	}

	onToggle = () => {
		const { view, box } = this.props
		if (!this.isToggleing) {
			this.isToggleing = true
			view.toggleBox(box).finally(() => { this.isToggleing = false })
		}
	}

	onPlaceMode = (evn: React.MouseEvent<HTMLButtonElement>) => {
		evn.preventDefault()
		evn.stopPropagation()
		const { context: { nav }, view: { item } } = this.props
		nav.go({ key: 'view', id: item.id, params: { place: this.props.box.id } })
	}

	onNav = (evn: React.MouseEvent<HTMLButtonElement>) => {
		evn.preventDefault()
		evn.stopPropagation()
		this.props.context.nav.go('view', this.props.box.id)
	}

}
