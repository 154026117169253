
export interface Constructor<T> extends Function { new(...args: any): T }

export class ErrorX extends Error {
	cause?: Error
	data?: any
	constructor(message: string, data?: any)
	constructor(message: string, cause: Error, data?: any)
	constructor(message: string, causeOrData?: Error | any, data?: any) {
		super(message +
			(causeOrData && causeOrData instanceof Error
				? ' - ' + causeOrData.message : ''))
		if (causeOrData && causeOrData instanceof Error)
			this.cause = causeOrData
		else if (causeOrData !== void 0)
			this.data = causeOrData
		if (data)
			this.data = data
	}
}

declare const __DEBUG__: boolean

export function doDebug() {
	return typeof location !== 'undefined' &&
		(location.hostname in { 'localhost': 1, 'dev.server': 1 })
		&& !navigator.webdriver
}

// detect nodejs
declare var process: NodeJS.Process
export class Env {
	static isNodeJs = typeof process !== 'undefined' && !('browser' in process)
}

// TODO: unit tests
export class ObjectType {
	// TODO: Date and RegExp in JSON
	private static valueTypes = ['Boolean', 'Number', 'String', 'Date', 'RegExp']
	private static objTypes = ['Function', 'Array', 'Object', 'Error']
	private static typeMap: Record<string, string> = {}
	private static valueTypeMap: Record<string, boolean> = {}
	private static toString = {}.toString

	private static init = (() => {
		for (var i = 0, t: string; (t = ObjectType.valueTypes[i]); ++i)
			ObjectType.typeMap['[object ' + t + ']'] = t
		for (var i = 0, t: string; (t = ObjectType.objTypes[i]); ++i)
			ObjectType.typeMap['[object ' + t + ']'] = t
		for (var i = 0, t: string; (t = ObjectType.valueTypes[i]); ++i)
			ObjectType.valueTypeMap['[object ' + t + ']'] = true
	})()

	static get(obj: any) {
		return ObjectType.typeMap[ObjectType.toString.call(obj)]
	}

	static isValue(obj: any) {
		// TODO: experiment with typeof... to potentially improve speed?
		return ObjectType.valueTypeMap[ObjectType.toString.call(obj)] === true
	}
}

export const IGNORE_ERROR = (err: Error) => { }
export const IGNORE_ERROR_NULL = (err: Error) => null
