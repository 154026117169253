import { U } from '../../../common'
import * as mdl from '../../../model'
import * as type from './types'

export interface Query<D = any> {
	toUrl: (idParts: string[]) => string
	transform: (key: string, data: D, idParts?: string[]) => mdl.ItemData
}

export const idPrefix = 'google.com_'
export const idPeopleBox = idPrefix + 'people.box'

const api = {
	people: 'https://people.googleapis.com/v1',
	calendar: 'https://www.googleapis.com/calendar/v3',
	gmail: 'https://gmail.googleapis.com/gmail/v1',
	drive: 'https://www.googleapis.com/drive/v3',
}

const contactUrl = 'https://contacts.google.com/person'
const gmailUrl =
	'https://mail.google.com/mail/mu/mp/943/#tl/search/rfc822msgid%3A'

export const lists = {

	'people.box': {
		toUrl: () => null,
		transform: id => ({
			id, props: {
				title: 'Your Personal Information at Google.com',
				box: {
					box: 'google', mark: 'G',
					color: 'darkgreen', backColor: 'white'
				}
			},
			links: [idPrefix + 'contactGroups', idPrefix + 'calendars',
			idPrefix + 'labels', idPrefix + 'files'],
			tmpls: ['box.tmpl'],
			boxes: [idPeopleBox]
		})
	} as Query,

	'contactGroups': {
		toUrl: parts => toUrl(api.people, parts.join('/'), {
			pageSize: 100,
		}),
		transform: (id, d) => ({
			id, props: {
				title: 'Contact Groups',
				description: d.totalItems >= 100 ? { text: 'The first 100 groups.' } : void 0,
				icon: { icon: 'text:🤼‍♂️' },
			},
			content: d.contactGroups.map(d => resourceNameToId(d.resourceName)),
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.ContactGroups>,

	'calendars': {
		toUrl: () => toUrl(api.calendar, 'users/me/calendarList', {
			maxResults: 100,
		}),
		transform: (id, d) => ({
			id, props: {
				title: 'Calendars',
				description: d.items.length >= 100 ? { text: 'The first 100 calendars.' } : void 0,
				icon: { icon: 'text:📅' },
			},
			content: d.items.map(d => (
				{ url: id + '_' + calendarIdToId(d.id), preview: 'compact' })),
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.Calendars>,

	'events': {
		toUrl: parts => toUrl(api.calendar,
			'calendars/' + calendarIdToUrl(parts[1]) + '/events', {
			maxResults: 100,
			singleEvents: true,
			orderBy: 'startTime',
			timeMin: '2021-06-01T00:00:00Z',
			timeMax: '2021-07-01T00:00:00Z',
		}),
		transform: (id, d) => ({
			id, props: {
				title: 'Events',
				description: d.items.length >= 100 ? { text: 'The first 100 events.' } : void 0,
				icon: { icon: 'event' },
			},
			content: d.items.map(d => id + '_' + eventIdToId(d.id)),
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.CalendarEvents>,

	'labels': {
		toUrl: () => toUrl(api.gmail, 'users/me/labels'),
		transform: (id, d) => ({
			id, props: {
				title: 'EMail Labels',
				icon: { icon: 'label' },
			},
			content: d.labels.sort((a, b) => a.name.localeCompare(b.name))
				.map(d => id + '_' + labelIdToId(d.id)),
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.Labels>,

	'messages': {
		toUrl: parts => toUrl(api.gmail, 'users/me/messages', {
			maxResults: 100, labelIds: labelIdToUrl(parts[1]),
		}),
		transform: (id, d) => ({
			id, props: {
				title: 'Messages',
				description: d.messages.length >= 100 ? { text: 'The first 100 EMails.' } : void 0,
				icon: { icon: 'email' },
			},
			content: d.messages.map(d => id + '_' + d.id),
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.Messages>,

	'files': {
		toUrl: parts => toUrl(api.drive, 'files', {
			pageSize: 100,
			orderBy: 'folder,name',
			q: `'${parts.length > 2 ? fileIdToUrl(parts[1]) : 'root'}' in parents`,
			supportsAllDrives: true,
			includeItemsFromAllDrives: true,
		}),
		transform: (id, d, parts) => ({
			id, props: {
				title: 'Files',
				description: d.files.length >= 100 ? { text: 'The first 100 files.' } : void 0,
				icon: { icon: 'insert_drive_file' },
			},
			containerId: parts.length > 2 ? idPrefix + 'files_' + parts[1] : void 0,
			links: d.files.map(d => idPrefix + 'files_' + fileIdToId(d.id)),
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.Files>,

}

export const singles = {

	'people': {
		toUrl: parts => toUrl(api.people, parts.join('/'), {
			personFields: 'names,photos',
		}),
		transform: (id, d, parts) => ({
			id, props: {
				title: d.names?.[0]?.displayName ?? '?',
				icon: { icon: d.photos?.[0]?.url ?? 'text:🧑' },
				url: { url: contactUrl + '/' + parts[1] },
			},
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.Person>,

	'contactGroups': {
		toUrl: parts => toUrl(api.people, parts.join('/'), {
			maxMembers: 5,
		}),
		transform: (id, d) => ({
			id, props: {
				title: d.name,
				icon: { icon: 'text:👨‍👩‍👧‍👦' },
			},
			links: d.memberResourceNames?.map(resourceNameToId),
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.ContactGroup>,

	'calendars': {
		toUrl: parts => toUrl(api.calendar,
			'users/me/calendarList/' + calendarIdToUrl(parts[1])),
		transform: (id, d) => ({
			id, props: {
				title: d.summary,
				description: d.description && { text: d.description },
				icon: { icon: 'text:📅' },
				color: { color: d.foregroundColor },
				backColor: { color: d.backgroundColor },
			},
			content: [id + '_events'],
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.Calendar>,

	'events': {
		toUrl: parts => toUrl(api.calendar,
			`calendars/${calendarIdToUrl(parts[1])}/events/${eventIdToUrl(parts[3])}`),
		transform: (id, d) => ({
			id, props: {
				title: d.summary,
				description: d.description && { text: d.description },
				icon: { icon: 'event' },
				start: toDate(d.start),
				end: toDate(d.end),
				link: { url: d.htmlLink },
			},
			create: { date: d.created },
			update: { date: d.updated },
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.CalendarEvent>,

	'labels': {
		toUrl: parts => toUrl(api.gmail,
			`users/me/labels/${labelIdToUrl(parts[1])}`),
		transform: (id, d) => ({
			id, props: {
				title: d.name,
				icon: { icon: 'label' },
				messages: d.messagesTotal,
				unread: d.messagesUnread,
			},
			content: d.messagesTotal > 0 && [id + '_messages'],
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.Label>,

	'messages': {
		toUrl: parts => toUrl(api.gmail, `users/me/messages/${parts[
			parts[0] === 'messages' ? 1 : 3]}`, {
			format: 'metadata',
		}),
		transform: (id, d) => ({
			id, props: {
				title: header(d, 'subject'),
				icon: { icon: 'mail' },
				from: header(d, 'from'),
				to: header(d, 'to', 'envelope-to'),
				cc: header(d, 'cc'),
				date: { date: new Date(header(d, 'date')) },
				snippet: { text: d.snippet },
				size: d.sizeEstimate,
				gmail: { url: gmailUrl + encodeURIComponent(header(d, 'message-id')) }
			},
			links: d.labelIds?.map(l => idPrefix + 'labels_' + labelIdToId(l)),
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.Message>,

	'files': {
		toUrl: parts => toUrl(api.drive, `files/${fileIdToUrl(parts[1])}`, {
			fields: '*', supportsAllDrives: true,
		}),
		transform: (id, d) => ({
			id, props: {
				title: d.name,
				icon: { icon: d.iconLink },
				type: d.mimeType,
				version: d.version,
				link: { url: d.webViewLink }
			},
			create: { date: d.createdTime },
			update: { date: d.modifiedTime },
			content: d.mimeType === 'application/vnd.google-apps.folder' &&
				[id + '_files'],
			tmpls: ['list.tmpl'],
			boxes: [idPeopleBox],
		})
	} as Query<type.File>,

}

export interface Search<R = any> {
	toUrl: (query: string) => string
	toIds: (resp: R) => string[]
}

export const search = {

	people: {
		toUrl: query => toUrl(api.people, 'people:searchContacts', {
			pageSize: 10, query, readMask: 'metadata'
		}),
		toIds: ({ results }) => results?.map(d => resourceNameToId(d.person.resourceName)),
	} as Search<{ results: { person: type.Person }[] }>,

	calendar: {
		toUrl: q => toUrl(api.calendar, 'calendars/primary/events', {
			maxResults: 10, singleEvents: true, orderBy: 'startTime', q,
		}),
		toIds: ({ items }) => items?.map(e =>
			`${idPrefix}calendars_${U.base64.encode('primary')
			}_events_${eventIdToId(e.id)}`)
	} as Search<type.CalendarEvents>,

	gmail: {
		toUrl: q => toUrl(api.gmail, 'users/me/messages', {
			maxResults: 20, includeSpamTrash: false, q,
		}),
		toIds: d => d.messages?.map(m => `${idPrefix}messages_${m.id}`)
	} as Search<type.Messages>,

	drive: {
		toUrl: q => toUrl(api.drive, 'files', {
			pageSize: 100, q: `fullText contains '${q}'`,
			supportsAllDrives: true, includeItemsFromAllDrives: true,
		}),
		toIds: d => d.files?.map(f => `${idPrefix}files_${fileIdToId(f.id)}`)
	} as Search<type.Files>,

}

function toUrl(base: string, path: string, params?: any) {
	return U.url.addParams(base + '/' + path, params)
}

function toDate(d: type.DateTime) {
	return 'dateTime' in d ? { dateTime: new Date(d.dateTime) } :
		{ date: new Date(d.date) }
}

// people API resource names to full IDs
function resourceNameToId(res: string) {
	return idPrefix + res.replace('/', '_')
}

// some event IDs seem to start with an '_' 🤔
function eventIdToId(eventId: string) {
	return eventId.replace('_', '$')
}
function eventIdToUrl(id: string) {
	return id.replace('$', '_')
}

// calendar IDs might contain '#' or other characters
function calendarIdToId(calendarId: string) {
	return U.base64.encode(calendarId)
}
function calendarIdToUrl(id: string) {
	return encodeURIComponent(U.base64.decode(id))
}

// extract message header
function header(m: type.Message, ...names: string[]) {
	if (!('_headerCache' in m))
		m._headerCache =
			U.array.toObject(m.payload.headers,
				h => h.name.toLowerCase(), h => h.value)
	for (const n of names)
		if (n in m._headerCache)
			return m._headerCache[n]
}

// label IDs contain an '_'
function labelIdToId(labelId: string) {
	return labelId.replace('_', '$')
}
function labelIdToUrl(id: string) {
	return id.replace('$', '_')
}

// file IDs might contain multiple '_'
function fileIdToId(fileId: string) {
	return fileId.replaceAll('_', '$')
}
function fileIdToUrl(id: string) {
	return id.replaceAll('$', '_')
}
