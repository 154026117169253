import { httpClient } from '../../../common'
import { boxIdToDbName } from './data'

export function couchHttpClient(url: string,
	creds: { id: string; password: string },
	init?: (req: RequestInit) => void) {

	const http = httpClient(creds, init)

	return {
		direct: http,
		get: <R = any>(boxId: string, path: string, params?: {}) =>
			http.get<R>(url + '/' + boxIdToDbName(boxId) + '/' + path, params)
				.catch(err => {
					if (err.status === 404)
						return null
					throw err
				}) as Promise<R>,
		put: <R = any>(boxId: string, path: string, content: any) =>
			http.put<R>(url + '/' + boxIdToDbName(boxId) + '/' + path, content),
		post: <R = any>(boxId: string, path: string, content: any) =>
			http.post<R>(url + '/' + boxIdToDbName(boxId) + '/' + path, content),
		delete: <R = any>(boxId: string) =>
			http.delete<R>(url + '/' + boxIdToDbName(boxId)),
	}
}
