import * as str from "./str"

export const isoDatePattern =
	/^["']\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)["']$/

export function isDate(val: any): val is Date {
	return val instanceof Date && !isNaN(val.getTime())
}

export function isDateLike(val: any): val is Date {
	switch (typeof val) {
		case 'number': return Number.isInteger(val)
		case 'string': return !isNaN(Date.parse(val))
		case 'object': return isDate(val)
		default: return false
	}
}

export function daysInMonth(date: Date) {
	if (date instanceof Date)
		return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

export function sameDate(a: Date, b: Date) {
	return a instanceof Date && b instanceof Date &&
		a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() &&
		a.getDate() === b.getDate()
}

export function toIsoDateString(d: Date) {
	return d ? d.toLocaleDateString('sv') : null
}

export function parseIsoDate(str: string) {
	return str ? new Date(str + 'T00:00:00.00' +
		toIsoTzString(new Date(str))) : null
}

export function toIsoTimeString(d: Date) {
	return toIsoDateTimeString(d).substring(11)
}

export function parseIsoTime(str: string) {
	return str ? new Date('2020-02-02T' + str) : null
}

export function toIsoDateTimeString(d: Date) {
	const ms = d.getMilliseconds()
	return d.toLocaleString('sv').replace(' ', 'T') + (ms > 0 ? '.' + ms : '') +
		toIsoTzString(d)
}

export function parseIsoDateTime(str: string) {
	return str ? new Date(str) : null
}

export function toIsoTzString(d: Date) {
	const tzo = -d.getTimezoneOffset()
	const s = tzo === 0 ? 'Z' : tzo > 0 ? '+' : '-'
	return s + str.padAbsOne(tzo / 60) + ':' + str.padAbsOne(tzo % 60)
}

/** Weekday starting with Monday (1) to Sunday (7). */
export function weekday(date: Date) {
	if (date instanceof Date) {
		const d = date.getDay()
		return d === 0 ? 7 : d
	}
}

