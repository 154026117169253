import * as mdl from '../model'

export const setup = {

	sys: ({ items, inst }:
		{ items: mdl.ItemManager, inst: mdl.Installation }) => {
		items.itemIdAliases['installation'] =
			() => inst.item?.id ?? 'installation.sys'
		items.itemIdAliases['installation.sys'] =
			() => inst.item?.id ?? 'installation.sys'
		items.itemIdAliases['boxes'] = () => 'boxes.sys'
		items.itemIdAliases['session'] = () => 'session.sys'
	},

}
