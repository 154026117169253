import { textImporters } from './common'

textImporters.push(newItemsForList)

function newItemsForList(txt: string) {
	const lines = txt.split('\n')
	// test first line to detect
	if (lines.length <= 0 || !lines[0].startsWith('- '))
		return null
	const matches = lines.filter(ln => ln.startsWith('- '))
	return matches.map(ln => (
		{ props: { title: ln.substring(2).trim() }, tmpls: [{ ref: 'list.tmpl' }] }
	))
}
