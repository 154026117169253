import { httpClient } from '../common'
import * as mdl from '../model'

export async function generateInstallationId(config: { auth: string },
	auth: mdl.Auth) {
	const cds = auth.getCredentials(config.auth)
	const http = httpClient(cds as mdl.PasswordCredentials)
	return await http.put(config.auth +
		'/allsbe_id/_design/utils/_update/genId/installation_id', null)
}
