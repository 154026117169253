import { U } from '../../../common'
import * as mdl from '../../../model'
import * as type from './types'

export interface ListQuery<D = any> {
	toId?: (data: D, parentId?: string, parentIdParts?: string[]) => string
	toPath?: (idParts: string[]) => string
	transform: (key: string, data: D[], idParts?: string[]) => mdl.ItemData
}

export interface SingleQuery<D = any> {
	toId?: (data: D, parentId?: string, parentIdParts?: string[]) => string
	toPath?: (idParts: string[]) => string
	transform: (key: string, data: D, idParts?: string[]) => mdl.ItemData
}

export type Query = ListQuery | SingleQuery

export const idPrefix = 'gitlab.com'
export const idRootBox = idPrefix + '_root.box'

export const lists: { [type: string]: Query } = {

	'groups': {
		toId: (d, id) => id + '_' + d.id,
		toPath: parts => parts.join('/') + '?per_page=100',
		transform: (id, d) => ({
			id, props: {
				title: 'Groups',
				description: d.length > 100 ? { text: 'The first 100 groups.' } : void 0,
				icon: { icon: 'text:📚' },
			},
			content: d.map(d => lists.groups.toId(d, id)),
			tmpls: ['list.tmpl'],
			boxes: [idRootBox],
		})
	} as ListQuery<type.Group>,

	'projects': {
		toId: d => idPrefix + '_projects_' + d.id,
		toPath: parts => parts.join('/') + '?per_page=100',
		transform: (id, d) => ({
			id, props: {
				title: 'Projects',
				description: d.length > 100 ? { text: 'The first 100 projects.' } : void 0
			},
			containerId: d.length > 0 ?
				`${idPrefix}_${d[0].namespace.kind}s_${d[0].namespace.id}` : void 0,
			content: d.map(d => lists.projects.toId(d, id)),
			tmpls: ['list.tmpl'],
			boxes: [idRootBox],
		})
	} as ListQuery<type.Project>,

	'issues': {
		toId: (d, id) => id + '_' + d.iid,
		toPath: parts => parts.join('/') + '?scope=all&per_page=100',
		transform: (id, d, parts) => ({
			id, props: {
				title: 'Issues',
				description: d.length > 100 ? { text: 'The first 100 issues.' } : void 0,
				icon: { icon: 'text:📑' },
			},
			containerId: parts.slice(0, -1).join('_'),
			content: d.map(d => lists.issues.toId(d, id, parts)),
			tmpls: ['list.tmpl'],
			boxes: [idRootBox],
		})
	} as ListQuery<type.Issue>,

	'open-issues': {
		toId: (d, id, parts) => parts.slice(0, -1).join('_') + '_issues_' + d.iid,
		toPath: parts => parts.slice(0, -1).join('/') +
			'/issues?state=opened&scope=assigned_to_me&per_page=100',
		transform: (id, d, parts) => ({
			id, props: {
				title: 'Open Issues',
				description: d.length > 100
					? { text: 'The first 100 open issues assigned to me.' } : void 0,
				icon: { icon: 'text:📑' },
			},
			containerId: parts.slice(0, -1).join('_'),
			links: d.map(d => lists['open-issues'].toId(d, id, parts)),
			tmpls: ['list.tmpl'],
			boxes: [idRootBox],
		})
	} as ListQuery<type.Issue>,

	'notes': {
		toId: (d, id) => id + '_' + d.id,
		toPath: parts => parts.join('/') + '?per_page=100',
		transform: (id, d, parts) => ({
			id, props: {
				title: 'Comments',
				description:
					d.length > 100 ? { text: 'The first 100 comments.' } : void 0,
				icon: { icon: 'comment' },
			},
			containerId: parts.slice(0, -1).join('_'),
			content: d.map(d => lists.notes.toId(d, id, parts)),
			tmpls: ['list.tmpl'],
			boxes: [idRootBox],
		})
	} as ListQuery<type.Note>,

	'branches': {
		toId: (d, id) => id + '_' + U.base64.encode(d.name),
		toPath: parts => parts.slice(0, -1).join('/') +
			'/repository/branches?per_page=100',
		transform: (id, d, parts) => ({
			id, props: { title: 'Branches', icon: { icon: 'alt_route' }, },
			containerId: parts.slice(0, -1).join('_'),
			content: d.map(d => lists.branches.toId(d, id, parts)),
			tmpls: ['list.tmpl'],
			boxes: [idRootBox],
		})
	} as ListQuery<type.Branch>,

	'root.box': {
		toPath: parts => null,
		transform: (id, d) => ({
			id, props: {
				title: 'GitLab.com',
				box: {
					box: 'gitlab', mark: 'GitLab',
					color: 'darkred', backColor: 'orange'
				}
			},
			links: [idPrefix + '_groups'],
			tmpls: ['box.tmpl'],
			boxes: [idRootBox]
		})
	} as SingleQuery,

}

export const singles = {

	'groups': {
		transform: (id, d) => ({
			id, props: {
				title: d.name, description: { text: d.description },
				icon: { icon: d.avatar_url ?? 'text:📘' },
				url: { url: d.web_url },
			},
			create: { date: d.created_at },
			content: [id + '_projects'],
			tmpls: ['list.tmpl'],
			boxes: [idRootBox],
		})
	} as SingleQuery<type.Group>,

	'projects': {
		transform: (id, d) => ({
			id, props: {
				title: d.name, description: { text: d.description },
				icon: { icon: d.avatar_url ?? 'text:🗄' },
				url: { url: d.web_url },
			},
			create: { date: d.created_at },
			containerId: `${idPrefix}_${d.namespace.kind}s_${d.namespace.id}_projects`,
			content: [id + '_issues', id + '_branches'],
			links: [id + '_open-issues'],
			tmpls: ['list.tmpl'],
			boxes: [idRootBox],
		})
	} as SingleQuery<type.Project>,

	'issues': {
		transform: (id, d, parts) => ({
			id, props: {
				title: d.title, description: { text: d.description },
				url: { url: d.web_url }, state: d.state, labels: d.labels.join(', '),
				icon: { icon: 'text:📑' },
			},
			create: { date: d.created_at },
			containerId: parts.slice(0, -1).join('_'),
			content: [id + '_notes'],
			tmpls: ['list.tmpl'],
			boxes: [idRootBox],
		})
	} as SingleQuery<type.Issue>,

	'notes': {
		transform: (id, d, parts) => ({
			id, props: {
				text: { text: d.body }, author: d.author.name,
			},
			create: { date: d.created_at },
			update: { date: d.updated_at },
			containerId: parts.slice(0, -1).join('_'),
			tmpls: ['comment.tmpl'],
			boxes: [idRootBox],
		})
	} as SingleQuery<type.Note>,

	'branches': {
		toPath: parts => parts.slice(0, -2).join('/') + '/repository/branches/' +
			encodeURIComponent(U.base64.decode(parts[parts.length - 1])),
		transform: (id, d, parts) => ({
			id, props: {
				title: d.name,
				url: { url: d.web_url },
				icon: { icon: 'alt_route' },
			},
			containerId: parts.slice(0, -1).join('_'),
			tmpls: ['list.tmpl'],
			boxes: [idRootBox],
		})
	} as SingleQuery<type.Branch>,
}
