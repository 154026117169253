import { U } from '../../common'
import { AsyncStorage } from '../Storage'

export type StoreSpec = string | {
	name: string
	indexes?: (string |
	{ name: string, keyPath: string, options?: IDBIndexParameters })[]
}

export class IndexedDb {

	static readonly deleteValue = {}

	static create(name: string, storesPerVersion: StoreSpec[][],
		indexedDb = indexedDB) {
		return new IndexedDb(
			new Promise<IDBDatabase>((resolve, reject) => {
				const version = storesPerVersion.length
				const req = indexedDb.open(name, version)
				req.onupgradeneeded = evn => {
					for (let i = 0; i < version; ++i) {
						if (evn.oldVersion < i + 1) {
							const db = req.result
							for (const store of storesPerVersion[i]) {
								const name = typeof store === 'string' ? store : store.name
								const rm = name.startsWith(' - ')
								if (rm) {
									db.deleteObjectStore(name.substring(' - '.length))
								} else {
									const s = db.createObjectStore(name)
									if (typeof store === 'object' && 'indexes' in store) {
										for (const i of store.indexes) {
											const iName = typeof i === 'string' ? i : i.name
											const keyPath = typeof i === 'string' ? i : i.keyPath
											s.createIndex(iName, keyPath, i['options'])
										}
									}
								}
							}
						}
					}
				}
				req.onsuccess = evn => { resolve(req.result) }
				req.onerror = evn => { reject(req.error) }
			})
		)
	}

	private constructor(private dbPromise: Promise<IDBDatabase>) { }

	async getInfo() {
		const db = await this.dbPromise
		return {
			name: db.name, version: db.version,
		}
	}

	getVal<T = any>(store: string, key: string) {
		return new Promise<T>((resolve, reject) => {
			if (!key)
				return resolve(null)
			this.dbPromise.then(db => {
				const tr = db.transaction([store])
				const req = tr.objectStore(store).get(key)
				req.onsuccess = evn => {
					resolve(U.obj.isEmptyObject(req.result) ? void 0 : req.result)
				}
				tr.onerror = evn => { reject(tr.error) }
			}, reject)
		})
	}

	getVals<T = any>(store: string, keys: string[]) {
		return new Promise<T[]>((resolve, reject) => {
			this.dbPromise.then(db => {
				const tr = db.transaction([store])
				const kys = U.array.toObject(keys)
				const vals = []
				collect(tr.objectStore(store).openCursor(),
					(k, v) => { if (k in kys && !U.obj.isEmptyObject(v)) vals.push(v) },
					() => { resolve(vals) })
				tr.onerror = () => { reject(tr.error) }
			}, reject)
		})
	}

	collectVals(store: string, vals: { [key: string]: any }) {
		return new Promise<{ [key: string]: any }>((resolve, reject) => {
			this.dbPromise.then(db => {
				const tr = db.transaction([store])
				collect(tr.objectStore(store).openCursor(),
					(k, v) => { if (k in vals) vals[k] = v }, () => { resolve(vals) })
				tr.onerror = () => { reject(tr.error) }
			}, reject)
		})
	}

	getIndexVal<T = any>(store: string, index: string, key: string) {
		return new Promise<T>((resolve, reject) => {
			this.dbPromise.then(db => {
				const tr = db.transaction([store])
				const req = tr.objectStore(store).index(index).get(key)
				req.onsuccess = () => {
					resolve(U.obj.isEmptyObject(req.result) ? void 0 : req.result)
				}
				tr.onerror = () => { reject(tr.error) }
			}, reject)
		})
	}

	getIndexPrimaryKeys<T = any>(store: string, index: string, key: string) {
		return new Promise<T[]>((resolve, reject) => {
			this.dbPromise.then(db => {
				const tr = db.transaction([store])
				const vals = []
				collectKeys(tr.objectStore(store).index(index).openKeyCursor(key),
					(k, pk) => { if (!U.obj.isEmptyObject(pk)) vals.push(pk) },
					() => { resolve(vals) })
				tr.onerror = () => { reject(tr.error) }
			}, reject)
		})
	}

	getIndexKeys<T = any>(store: string, index: string, key: string = null) {
		return new Promise<T[]>((resolve, reject) => {
			this.dbPromise.then(db => {
				const tr = db.transaction([store])
				const vals = []
				collectKeys(tr.objectStore(store).index(index)
					.openKeyCursor(key, 'nextunique'),
					(k) => { if (!U.obj.isEmptyObject(k)) vals.push(k) },
					() => { resolve(vals) })
				tr.onerror = () => { reject(tr.error) }
			}, reject)
		})
	}

	getAll<T = any>(store: string) {
		return new Promise<T[]>((resolve, reject) => {
			this.dbPromise.then(db => {
				const tr = db.transaction([store])
				const req = tr.objectStore(store).getAll()
				req.onsuccess = evn => {
					resolve(U.obj.isEmptyObject(req.result) ? void 0 : req.result)
				}
				tr.onerror = evn => { reject(tr.error) }
			}, reject)
		})
	}

	putVal(store: string, key: string, value: any) {
		return new Promise<void>((resolve, reject) => {
			this.dbPromise.then(db => {
				const tr = db.transaction([store], 'readwrite')
				if (value === IndexedDb.deleteValue) tr.objectStore(store).delete(key)
				else tr.objectStore(store).put(value, key)
				tr.oncomplete = evn => { resolve() }
				tr.onerror = evn => { reject(tr.error) }
			}, reject)
		})
	}

	delVal(store: string, key: string) {
		return new Promise<void>((resolve, reject) => {
			this.dbPromise.then(db => {
				const tr = db.transaction([store], 'readwrite')
				const req = tr.objectStore(store).delete(key)
				tr.oncomplete = evn => { resolve() }
				tr.onerror = evn => { reject(tr.error) }
			}, reject)
		})
	}

	putVals(...keys: { store: string, key: string, value: any }[]) {
		return new Promise<void>((resolve, reject) => {
			const stores = keys.map(k => k.store).sort().filter(U.array.distinct)
			this.dbPromise.then(db => {
				const tr = db.transaction(stores, 'readwrite')
				for (const k of keys)
					if (k.value === IndexedDb.deleteValue)
						tr.objectStore(k.store).put(k.key)
					else
						tr.objectStore(k.store).put(k.value, k.key)
				tr.oncomplete = evn => { resolve() }
				tr.onerror = evn => { reject(tr.error) }
			}, reject)
		})
	}

	bulk(...specs: BulkSpec[]): Promise<any>
	bulk(opts: { stores?: string[], mode?: IDBTransactionMode },
		...specs: BulkSpec[]): Promise<any>
	bulk(arg0: BulkSpec | { stores?: string[], mode?: IDBTransactionMode },
		...specs: BulkSpec[]) {
		return new Promise<any>((resolve, reject) => {
			if ('store' in arg0)
				specs.splice(0, 0, arg0)
			const stores = 'stores' in arg0 ? arg0.stores :
				specs.map(k => k.store).sort().filter(U.array.distinct)
			this.dbPromise.then(db => {
				const tr = db.transaction(stores,
					'mode' in arg0 ? arg0.mode : 'readwrite')
				const result = { last: void 0 }
				processBulk(tr, specs, result)
				tr.oncomplete = () => { resolve(result.last) }
				tr.onerror = () => { reject(tr.error) }
			}, reject)
		})
	}

	async close() {
		const db = await this.dbPromise
		db.close()
	}

}

function nextBulk(tr: IDBTransaction, spec: BulkSpec, val?: any,
	result?: { last: any }) {
	if (spec.next) processBulk(tr, spec.next(val), result)
	else if (val !== void 0 && result) result.last = val
}

function processBulk(tr: IDBTransaction, spec: BulkSpec | BulkSpec[] | void,
	result: { last: any }) {
	if (Array.isArray(spec)) {
		for (const s of spec) processBulk(tr, s, result)
	} else if (spec) {
		try {
			const store = 'index' in spec ?
				tr.objectStore(spec.store).index(spec.index) :
				tr.objectStore(spec.store)
			if (Array.isArray(spec['get'])) {
				const keys = U.array.toObject(spec['get'])
				const vals = []
				collect(store.openCursor(),
					(k, v) => { if (k in keys && !U.obj.isEmptyObject(v)) vals.push(v) },
					() => { nextBulk(tr, spec, vals, result) })
			} else if (typeof spec['get'] === 'object') {
				const vals = { ...spec['get'] }
				collect(store.openCursor(), (k, v) => { if (k in vals) vals[k] = v },
					() => { nextBulk(tr, spec, vals, result) })
			} else if ('get' in spec) {
				const req = store.get(spec.get)
				req.onsuccess = () => { nextBulk(tr, spec, req.result, result) }
			} else if ('primaryKeyFor' in spec) {
				const req = store.getKey(spec.primaryKeyFor)
				req.onsuccess = () => { nextBulk(tr, spec, req.result, result) }
			} else if ('keysFor' in spec) {
				const vals = []
				collectKeys(store.openKeyCursor(spec.keysFor),
					(k) => { if (!U.obj.isEmptyObject(k)) vals.push(k) },
					() => { nextBulk(tr, spec, vals, result) })
			} else if ('primaryKeysFor' in spec) {
				const vals = []
				collectKeys(store.openKeyCursor(spec.primaryKeysFor),
					(k, pk) => { if (!U.obj.isEmptyObject(pk)) vals.push(pk) },
					() => { nextBulk(tr, spec, vals, result) })
			} else if ('put' in spec) {
				(store as IDBObjectStore).put(spec['val'], spec.put)
					.onsuccess = () => { nextBulk(tr, spec) }
			} else if ('del' in spec) {
				(store as IDBObjectStore).delete(spec.del)
					.onsuccess = () => { nextBulk(tr, spec) }
			} else {
				throw new Error('No known action found in spec!')
			}
		} catch (err) {
			throw new Error(`Invalid IndexedDB bulk action spec!\n${err.message
				}\n${U.any.stringify(U.obj.filterMembers(spec, k => k !== 'next'))
				}\n${tr.objectStoreNames}`)
		}
	}
}

function collect(req: IDBRequest<IDBCursorWithValue>,
	collect: (key: string, value: any) => void,
	done: () => void) {
	req.onsuccess = () => {
		const cur = req.result
		if (cur) {
			collect(cur.key as string, cur.value)
			cur.continue()
		} else {
			done()
		}
	}
}

function collectKeys(req: IDBRequest<IDBCursor>,
	collect: (key: string, value: any) => void,
	done: () => void) {
	req.onsuccess = () => {
		const cur = req.result
		if (cur) {
			collect(cur.key as string, cur.primaryKey)
			cur.continue()
		} else {
			done()
		}
	}
}

type BulkSpec = {
	get: string
	store: string
	index?: string
	next?: (value: any) => BulkSpec | BulkSpec[] | void
} | {
	get: string[]
	store: string
	index?: string
	next?: (values: any[]) => BulkSpec | BulkSpec[] | void
} | {
	get: { [key: string]: any }
	store: string
	index?: string
	next?: (keyValues: { [key: string]: any }) => BulkSpec | BulkSpec[] | void
} | {
	primaryKeyFor: string
	store: string
	index?: string
	next?: (value: any) => BulkSpec | BulkSpec[] | void
} | {
	keysFor: string
	store: string
	index?: string
	next?: (values: any[]) => BulkSpec | BulkSpec[] | void
} | {
	primaryKeysFor: string
	store: string
	index?: string
	next?: (values: any[]) => BulkSpec | BulkSpec[] | void
} | {
	put: string
	store: string
	val: any
	next?: () => BulkSpec | BulkSpec[] | void
} | {
	del: string
	store: string
	next?: () => BulkSpec | BulkSpec[] | void
}

export class IndexedDbStore implements AsyncStorage {

	db: IndexedDb

	constructor(dbName: string, public storeName: string) {
		this.db = IndexedDb.create(dbName, [[storeName]])
	}

	getVal(key: string): Promise<any> {
		return this.db.getVal(this.storeName, key)
	}
	setVal(key: string, data: any): Promise<void> {
		return this.db.putVal(this.storeName, key, data)
	}
	removeVal(key: string): Promise<void> {
		return this.db.delVal(this.storeName, key)
	}

}
