import * as React from 'react'
import * as mdl from '../../model'
import { Image } from '../common/ImageEditor/Image'
import { ImageEditor } from '../common/ImageEditor/ImageEditor'
import { PropertyView } from './common'

export const image: PropertyView<mdl.ImageValue> = {
	label: () => <>image</>,
	icon: ({ value }) => <Image value={value} className="icon" />,
	content: ({ value }) => mdl.ImageValue.isValid(value) ?
		<Image value={value} /> :
		value ? <pre>{JSON.stringify(value, null, '  ')}</pre> : null,
	editor: (prop, form, { config }) =>
		<ImageEditor label={prop.label} value={form.values[prop.name]}
			query={prop.item.props.label?.value} config={config} />
}

