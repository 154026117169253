import { O } from '../common'
import * as mdl from '../model'
import { fixIds } from '../model/common'

export const setup = {
	sys: {
		installation: ({ boxes, inst }:
			{ boxes: mdl.BoxManager, inst: mdl.Installation }) => {
			boxes.generatedItemsFactory[fixIds.sys.installation] = (item: mdl.Item) => {
				item.props.set('title', 'This provisional Installation')
				item.props.set('installation', inst)
			}
		},

		boxes: ({ boxes }: { boxes: mdl.BoxManager }) => {
			boxes.generatedItemsFactory[fixIds.sys.boxes] = (item: mdl.Item) => {
				item.props.set('title', 'Boxes')
				item.props.set('icon', 'box', 'icon')
				item.links.setComputed(() => boxes.allBoxItems
					.sort((a, b) => a.labelText.localeCompare(b.labelText))
					.map(b => O.new(mdl.Link, b.id, b)))
			}
		},

		storages: ({ boxes }: { boxes: mdl.BoxManager }) => {
			boxes.generatedItemsFactory[fixIds.sys.storages] = (item: mdl.Item) => {
				item.props.set('title', 'Box Storages')
				item.props.set('icon', 'storage', 'icon')
				item.links.setComputed(() => boxes.storageItems
					.sort((a, b) => a.labelText.localeCompare(b.labelText))
					.map(b => O.new(mdl.Link, b.id, b)))
			}
		},

		tags: ({ boxes, items }:
			{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
			boxes.generatedItemsFactory[fixIds.sys.tags] = (item: mdl.Item) => {
				item.props.set('title', 'Tags')
				item.props.set('icon', 'tag', 'icon')
				item.links.setComputed(() => items.tagItems
					.sort((a, b) => a.labelText.localeCompare(b.labelText))
					.map(itm => O.new(mdl.Link, itm.id, itm)))
			}
		},

		session: ({ boxes, session }:
			{ boxes: mdl.BoxManager, session: mdl.Session }) => {
			boxes.generatedItemsFactory[fixIds.sys.session] = (item: mdl.Item) => {
				item.props.set('title', 'Session')
				item.props.set('icon', 'session', 'icon')
				item.links.add(session.user)
				item.links.add(session.userBoxItem)
			}
		},

		system: ({ boxes, items }:
			{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
			boxes.generatedItemsFactory[fixIds.sys.system] = (item: mdl.Item) => {
				item.props.set('title', 'System')
				item.props.set('icon', 'system', 'icon')
				item.links.add(items.getItem(fixIds.sys.installation))
				item.links.add(items.getItem(fixIds.sys.session))
				item.links.add(items.getItem(fixIds.sys.storages))
				item.links.add(items.getItem(fixIds.sys.boxes))
				item.links.add(items.getItem(fixIds.sys.tags))
			}
		},

		templates: ({ boxes, session }:
			{ boxes: mdl.BoxManager, session: mdl.Session }) => {
			boxes.generatedItemsFactory['list.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default List Template')
				item.props.set('icon', null, 'icon')
			}
			boxes.generatedItemsFactory['user.tmpl'] = (item: mdl.Item) => {
				item.props.set('name', 'User Name')
				item.props.set('icon', null, 'icon')
			}
			boxes.generatedItemsFactory['text.tmpl'] = (item: mdl.Item) => {
				item.props.set('text', 'Default text template.', 'text')
				item.layoutId = 'text.layout'
			}
			boxes.generatedItemsFactory['url.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default URL Template')
				item.props.set('url', 'https://alls.be', 'url')
				item.props.set('icon', null, 'icon')
				item.layoutId = 'url.layout'
			}
			boxes.generatedItemsFactory['location.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default Location Template')
				item.props.set('location', {}, 'location')
			}
			boxes.generatedItemsFactory['query.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default Query Template')
				item.props.set('query', null, 'query')
			}
			boxes.generatedItemsFactory['todo.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default Todo Template')
				item.props.set('completed', false, 'boolean')
				item.props.set('toggle', O.new(mdl.ToggleAction, item))
				item.layoutId = 'todo.layout'
			}
			boxes.generatedItemsFactory['comment.tmpl'] = (item: mdl.Item) => {
				item.props.set('text', 'Default comment template.', 'text')
				item.props.set('author', session.user.labelText)
				item.props.set('time', new Date(Date.now()), 'dateTime')
				item.layoutId = 'comment.layout'
			}
			boxes.generatedItemsFactory['number.tmpl'] = (item: mdl.Item) => {
				item.props.set('value', { number: 0, unit: 'kg' }, 'number')
			}
			boxes.generatedItemsFactory['date.tmpl'] = (item: mdl.Item) => {
				item.props.set('date', new Date(Date.now()))
			}
			boxes.generatedItemsFactory['calendar.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default Calendar Template')
				item.props.set('date', new Date(Date.now()))
				item.props.set('select', O.new(mdl.SelectDateAction, item))
				item.props.set('icon', null, 'icon')
				item.layoutId = 'calendar.layout'
			}
			boxes.generatedItemsFactory['image.tmpl'] = (item: mdl.Item) => {
				item.props.set('image', '', 'image')
				item.props.set('caption', 'Default image template.')
				item.props.set('icon', null, 'icon')
				item.layoutId = 'image.layout'
			}
			boxes.generatedItemsFactory['tag.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default Tag Template')
				item.props.set('icon', 'tag', 'icon')
				item.props.set('mark', 'template')
				item.props.set('color', '#ffffff', 'color')
				item.props.set('backColor', '#777777', 'color')
			}
			boxes.generatedItemsFactory['box.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default Box Template')
				item.props.set('box', O.new(mdl.Box, item,
					{ box: 'default', mark: 'D', color: 'white', backColor: 'gray' }))
			}
			boxes.generatedItemsFactory['storage.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default Box Storage Template')
				item.props.set('storage', O.new(mdl.BoxStorage, item,
					{ storage: 'couch', url: 'https://' }))
			}
			boxes.generatedItemsFactory['installation.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default Installation Template')
			}
			boxes.generatedItemsFactory['account.tmpl'] = (item: mdl.Item) => {
				item.props.set('title', 'Default Account Template')
				item.props.set('account', O.new(mdl.Account, item,
					{ account: 'couch', url: 'https://couch.alls.be' }))
				item.props.set('avatar', null, 'icon')
				item.props.set('email', '')
			}
			boxes.generatedItemsFactory['question.tmpl'] = (item: mdl.Item) => {
				item.props.set('text', 'Question Template', 'text')
				item.props.set('answered', false, 'boolean', true)
				item.props.set('toggle', O.new(mdl.ToggleAction, item))
				item.layoutId = 'question.layout'
			}
		},

	},

}
