import { couchInitDocs } from '../../../../../common/src/CouchInitDocs'
import { IGNORE_ERROR, signal, U } from '../../../common'
import * as mdl from '../../../model'
import { bulk } from './bulk'
import { couchHttpClient } from './couchHttpClient'
import {
	boxIdToDbName, dbNameToBoxId, docToItemData, docToItemId, isItemDoc,
	itemDataToDoc, itemToDocId, queryToId, queryToSearchText, queryUri
} from './data'
import { ItemDocBase, ViewResponse } from './types'

// don't log errors when long-poll connections get brocken on page unload
// TODO: factor out
let pageUnloading = false
window.addEventListener('unload', () => { pageUnloading = true })

export class CouchDataAccess implements mdl.BoxStorageAccess {

	private http: ReturnType<typeof couchHttpClient>
	private dbNames: string[]
	private idRevs: {
		[boxId: string]:
		{ req: Promise<void>, idRevs?: { [id: string]: string } }
	} = {}

	constructor(private props: mdl.BoxStorageAccessArgs,
		private log: mdl.Logger, public network: mdl.Network) {
		this.http = couchHttpClient(this.props.url,
			this.props.credentials as mdl.PasswordCredentials)
	}

	isAvailable() {
		return this.network.isOnline
	}

	async readPermissions(fromBoxId: string) {
		if (!navigator.onLine)
			return null
		let p: mdl.BoxPermissions = 'no'
		try {
			// test read
			const info = await this.http.get(fromBoxId, '')
			p = info ? 'ro' : 'no'
			if (info) {
				// test write
				const tmpDoc = await this.http.put(fromBoxId, '0_tmp',
					{ _id: '0_tmp', comment: 'to be deleted' })
				if (tmpDoc?.rev)
					this.http.put(fromBoxId, '0_tmp',
						{ _id: '0_tmp', _rev: tmpDoc.rev, _deleted: true })
				p = tmpDoc?.rev ? 'rw' : 'ro'
			}
		}
		catch (err) {
			if (err.status !== 403)
				throw err
		}
		return p
	}

	async readData(fromBoxId: string, filter?: 'boxes') {
		if (!navigator.onLine)
			return []
		const p = filter ? queryUri('filter', filter) : '_all_docs'
		const res = await this.http.get<ViewResponse>(fromBoxId, p,
			{ include_docs: true, attachments: true, conflicts: true })
		return await Promise.all(
			res.rows.filter(isItemDoc).map(r => r.doc).map(docToItemData))
	}

	// TODO: streaming items
	async readPages(fromBoxId: string,
		onPage: (data: mdl.ItemData[], count: number) => void,
		pageSize = 100, filter?: 'boxes') {
		if (!navigator.onLine)
			return
		const p = filter ? queryUri('filter', filter) : '_all_docs'
		let startKey: string = void 0, count = 0
		do {
			const res = await this.http.get<ViewResponse>(fromBoxId, p, {
				include_docs: true, attachments: true, conflicts: true,
				limit: pageSize + 1, startKey
			})
			count += res.rows.length - 1
			startKey = res.rows.length > pageSize ? res.rows.pop().id : null
			onPage(await Promise.all(
				res.rows.filter(isItemDoc).map(r => r.doc).map(docToItemData)),
				res.total_rows)
		} while (startKey)
	}

	async readIds(fromBoxId: string) {
		if (!navigator.onLine)
			return []
		const res = await this.http.get<ViewResponse>(fromBoxId,
			queryUri('map', 'idRevs'))
		return res.rows.map(r => ({ id: docToItemId(r.id), rev: r.value }))
	}

	async isItemInBox(itemId: string, boxId: string) {
		if (!(boxId in this.idRevs)) {
			const req = this.http.get<ViewResponse>(boxId, queryUri('map', 'idRevs'))
			this.idRevs[boxId] = {
				req: req.then(res => {
					this.idRevs[boxId].idRevs =
						U.array.toObject(res?.rows, r => docToItemId(r.id), r => r.value)
					this.idRevs[boxId].req = null
					setTimeout(() => { delete this.idRevs[boxId] }, 5000)
				}, err => {
					delete (this.idRevs[boxId])
					throw err
				})
			}
		}
		if (this.idRevs[boxId].req)
			await this.idRevs[boxId].req
		return itemId in this.idRevs[boxId].idRevs
	}

	onChange = signal<(boxId: string, itemId: string) =>
		Promise<void>>()
	listeners: { [boxId: string]: AbortController } = {}

	async listenToBox(boxId: string) {
		if (boxId in this.listeners)
			return
		const abortCtrl = new AbortController()
		const http = couchHttpClient(this.props.url,
			this.props.credentials as mdl.PasswordCredentials,
			req => { req.signal = abortCtrl.signal })
		this.listeners[boxId] = abortCtrl
		const res = await this.http.get(boxId, '')
		let lastSeq = res?.update_seq
		let errorCount = 0
		// TODO: factor out a DB listener
		const whenOnline = () => new Promise<void>((resolve) => {
			const fn = () => {
				window.removeEventListener('online', fn)
				window.removeEventListener('focus', fn)
				setTimeout(() => { resolve() }, 3000)
			}
			window.addEventListener('online', fn)
			window.addEventListener('focus', fn)
		})
		const getChanges = async () => {
			if (!navigator.onLine) {
				whenOnline().then(getChanges)
			} else {
				try {
					const res = await http.get(boxId, '_changes',
						{ since: lastSeq ?? 'now', heartbeat: 6000, feed: 'longpoll' })
					lastSeq = res?.last_seq
					if (res?.results?.length) {
						for (const r of res.results)
							this.onChange(boxId, docToItemId(r.id))
					}
					errorCount = 0
					setTimeout(() => { getChanges() }, 3000)
				}
				catch (err) {
					if (pageUnloading)
						// ignore when page is unloading
						return
					if (err.name === 'AbortError') {
						delete this.listeners[boxId]
					} else if (navigator.onLine) {
						if (err.name === 'TypeError') {
							// eg. for net::ERR_NETWORK_IO_SUSPENDED errors 
							// navigator.onLine still seem to be true :( 
							// try again later
							if (errorCount < 1) {
								errorCount++
								setTimeout(() => { getChanges() }, 1000)
							} else {
								errorCount = 0
								whenOnline().then(getChanges)
							}
						} else if (err.name === 'SyntaxError') {
							// connection got interrupted
							whenOnline().then(getChanges)
						} else {
							this.log.error(err)
							delete this.listeners[boxId]
						}
					} else {
						whenOnline().then(getChanges)
					}
				}
			}
		}
		getChanges()
	}

	private bulkReads = {
		plain: {} as { [boxId: string]: (id: string) => Promise<any>; },
		completely: {} as { [boxId: string]: (id: string) => Promise<any>; },
	}

	async readItem(id: string, fromBoxIds: string[], completely: boolean) {
		if (!navigator.onLine)
			return null
		// OPT: explicitly check for revision and only read newer ones.
		let itemData: mdl.ItemData = null
		await Promise.all(fromBoxIds.map(async (boxId) => {
			if (!await this.isItemInBox(id, boxId))
				return
			this.listenToBox(boxId)
			const bulkReads = this.bulkReads[completely ? 'completely' : 'plain']
			if (!(boxId in bulkReads))
				bulkReads[boxId] = bulk(keys => this.readAll(boxId, keys, completely)
					.then(({ rows }) => U.array.toObject(rows, U.obj.toId)))
			const docId = itemToDocId(id)
			const res = await bulkReads[boxId](docId)
			if (res && docId in res) {
				const d = await docToItemData(res[docId].doc)
				if (d) {
					if (!itemData || itemData.rev < d.rev)
						itemData = d
					if (itemData.boxes)
						itemData.boxes.push(boxId)

					else
						itemData.boxes = [boxId]
				}
			}
		}))
		return itemData
	}

	async readItems(ids: string[], fromBoxIds: string[], completely: boolean) {
		if (!navigator.onLine)
			return []
		const itemsData: { [id: string]: mdl.ItemData; } = {}
		for (const boxId of fromBoxIds) {
			const res = await this.readAll(boxId, ids.map(itemToDocId), completely)
			if (res?.rows && res.rows.length > 0) {
				this.listenToBox(boxId)
				for (const r of res.rows) {
					const inData = await docToItemData(r.doc)
					let itemData = itemsData[inData.id]
					if (!itemData || itemData.rev < inData.rev)
						itemData = itemsData[inData.id] = inData
					if (itemData.boxes)
						itemData.boxes.push(boxId)

					else
						itemData.boxes = [boxId]
				}
			}
		}
		return Object.values(itemsData)
	}

	async readFromLinks(id: string, fromBoxIds: string[]) {
		if (!navigator.onLine)
			return []
		const rows = await this.query(fromBoxIds, 'index', 'fromLinks', id)
		return rows.map(queryToId)
	}

	async readSearchTexts(fromBoxIds: string[]) {
		if (!navigator.onLine)
			return []
		const rows = await this.query(fromBoxIds, 'map', 'searchText')
		return rows.map(queryToSearchText)
	}

	async writeItem(data: mdl.ItemData, intoBoxId: string) {
		if (!navigator.onLine)
			return
		const d = await itemDataToDoc(data)
		if (await this.isItemInBox(data.id, intoBoxId)) {
			const oldDoc = await this.http.get<ItemDocBase>(intoBoxId,
				itemToDocId(data.id))
				.catch(() => null)
			if (mdl.Item.compareRev(data, await docToItemData(oldDoc)) <= 0)
				// only write newer data
				return
			if (oldDoc?._rev)
				d._rev = oldDoc._rev
		}
		await this.http.put(intoBoxId, d._id, d)
		if (intoBoxId in this.idRevs) {
			const e = this.idRevs[intoBoxId]
			if (e.req)
				await e.req
			e.idRevs[data.id] = mdl.Item.toFullRev(data)
		}
	}

	async removeItem(id: string, fromBoxId: string) {
		if (!navigator.onLine)
			return
		if (!await this.isItemInBox(id, fromBoxId))
			return
		const docId = itemToDocId(id)
		const doc = await this.http.get<ItemDocBase>(fromBoxId, docId)
			.catch(() => null)
		if (doc) {
			doc._deleted = true
			await this.http.put(fromBoxId, docId, doc)
		}
		if (fromBoxId in this.idRevs) {
			const e = this.idRevs[fromBoxId]
			if (e.req)
				await e.req
			delete e.idRevs[id]
		}
	}

	async getBoxes(knownBoxIds: string[]) {
		const excludeBoxIds = U.array.toObject(knownBoxIds)
		const dbNames = await this.getDbNames()
		return await Promise.all(dbNames.map(n => dbNameToBoxId(n))
			.filter(id => id && !(id in excludeBoxIds))
			.map(async boxId => (
				{ id: boxId, permissions: await this.readPermissions(boxId) })))
	}

	async addBox(box: mdl.Box) {
		if (!navigator.onLine)
			return
		if (!await this.hasDb(box.id)) {
			const data = box.allStorages.length === 1 &&
				box.allStorages[0].access === this ? null : await box.readData()
			try {
				// create db
				await this.http.put(box.id, '', null)
				// initialize new db
				await this.http.post(box.id, '_bulk_docs', { docs: couchInitDocs })
				if (data)
					await this.http.post(box.id, '_bulk_docs',
						{ docs: await Promise.all(data.map(itemDataToDoc)) })
				this.dbNames.push(boxIdToDbName(box.id))
			} catch (err) {
				// try to cleanup failed db 
				await this.http.delete(box.id).catch(IGNORE_ERROR)
				throw err
			}
		}
	}

	async removeBox(boxId: string) {
		if (!navigator.onLine)
			return
		// don't actually delete remote, potentially shared boxes
		// if (await this.hasDb(boxId)) {
		// 	const idx = this.dbNames.indexOf(boxIdToDbName(boxId))
		// 	if (idx >= 0)
		// 		this.dbNames.splice(idx, 1)
		// 	await this.http.delete(boxId)
		// }
		delete this.listeners[boxId]
	}

	async open(boxId?: string) {
	}

	async close(boxId?: string) {
		if (boxId) {
			if (boxId in this.listeners) {
				const l = this.listeners[boxId]
				delete this.listeners[boxId]
				l.abort()
			}
		} else {
			const ls = Object.values(this.listeners)
			this.listeners = {}
			for (const l of ls)
				l.abort()
		}
	}

	private readAll = (boxId: string, keys: string[], completely: boolean) =>
		this.http.post<ViewResponse>(boxId, '_all_docs',
			{
				keys, include_docs: true,
				attachments: completely, conflicts: completely
			})

	private dbNamePromise: Promise<string[]>
	private async getDbNames() {
		if (!this.dbNames) {
			if (!this.dbNamePromise)
				this.dbNamePromise = this.http.direct.get(this.props.url + '/_all_dbs')
			this.dbNames = await this.dbNamePromise
			this.dbNamePromise = null
			// cache only valid for 3s
			// TODO: allow to configure
			setTimeout(() => { this.dbNames = null }, 3000)
		}
		return this.dbNames
	}

	private async hasDb(boxId: string) {
		const dbNames = await this.getDbNames()
		return dbNames.includes(boxIdToDbName(boxId))
	}

	private async query(fromBoxIds: string[], queryDocId: string,
		view: string, key?: string) {
		const rows: { [id: string]: any; } = {}
		await Promise.all(fromBoxIds.map(async (boxId) => {
			const resp = await this.http.get<ViewResponse>(boxId,
				queryUri(queryDocId, view), key ? { key: JSON.stringify(key) } : null)
			if (resp)
				for (const r of resp.rows)
					// TODO: check rev,...
					rows[r.id] = r
		}))
		return Object.values(rows)
	}
}
