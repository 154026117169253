
export interface Alphabet {
	encode: string[]
	decode: { [char: string]: number }
}

export const alphabet: { [name: string]: Alphabet } = {
	// cspell:disable
	// https://www.crockford.com/base32.html
	crockford: {
		encode: '0123456789ABCDEFGHJKMNPQRSTVWXYZ'.split(''),
		decode: { 'O': 0, 'L': 1, 'I': 1, 'o': 0, 'l': 1, 'i': 1 }
	},
	// similar to Crockford. optimized for lower case. u instead of s.
	// https://github.com/agnoster/base32-js
	crockford2: {
		encode: '0123456789abcdefghjkmnpqrtuvwxyz'.split(''),
		decode: { 'O': 0, 'L': 1, 'I': 1, 'S': 5, 'o': 0, 'l': 1, 'i': 1, 's': 5 }
	},
	// cspell:enable
}
alphabet.crockford.encode.forEach((c, i) => {
	alphabet.crockford.decode[c] = i
	alphabet.crockford.decode[c.toLowerCase()] = i
})
alphabet.crockford2.encode.forEach((c, i) => {
	alphabet.crockford2.decode[c] = i
	alphabet.crockford2.decode[c.toLowerCase()] = i
})

/** 
 * Encode positive integer (0..2147483647) to base32 string
 * (eg. '0'..'1zzzzzz').
 * Integer numbers < 0 or > 2147483647 return an empty string.
*/
export function encode(data: number | ArrayBuffer,
	alphabetSpec: Alphabet | Alphabet['encode'] = alphabet.crockford2) {
	const alpha = 'encode' in alphabetSpec ? alphabetSpec.encode : alphabetSpec
	let str = ''
	if (typeof data === 'number') {
		if (data == 0)
			return '0'
		data = data >> 0
		while (data > 0) {
			str = alpha[data & 0x1f] + str
			data = data >> 5
		}
	} else {
		const view = new DataView(data)
		let bits = 0, v = 0
		for (let i = 0, len = view.byteLength; i < len; ++i) {
			v = (v << 8) | view.getUint8(i)
			bits += 8
			while (bits >= 5) {
				str += alpha[(v >>> (bits - 5)) & 0x1f]
				bits -= 5
			}
		}
		if (bits > 0)
			str += alpha[(v << (5 - bits)) & 0x1f]
	}
	return str
}

export function decode(str: string,
	alphabetSpec: Alphabet | Alphabet['decode'] = alphabet.crockford2) {
	const alpha = 'decode' in alphabetSpec ? alphabetSpec.decode : alphabetSpec
	let num = 0
	for (const c of str) {
		num = num << 5
		num += (alpha as any)[c]
	}
	return num
}

