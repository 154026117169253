import { httpClient, O, U } from '../../common'
import * as mdl from '../../model'
import { fontIcons } from './materialIcons.json'

export const setup = {

	open: () => {
		O.onInit(mdl.ImageCapture, editor => {
			// TODO: load on demand
			editor.fontIcons = fontIcons
		})
	},

}

export async function loadImage(url: string, asIcon: boolean,
	config: mdl.Config['api']): Promise<mdl.ImageValue> {
	if (!url)
		return
	if (url.startsWith('blob:')) {
		const b = await mdl.HashedBlob.create(await httpClient().getBlob(url))
		return asIcon ? { icon: b } : { image: b }
	}
	if (url.startsWith('http://') || url.startsWith('https://')) {
		const b = await mdl.HashedBlob.create(await httpClient().getBlob(
			U.url.addParams(config.services.proxy, { url })))
		return asIcon ? { icon: b, source: url } : { image: b, source: url }
	}
	return asIcon ? { icon: url } : { image: url }
}
