import { O } from '../common'
import * as mdl from '../model'

export const setup = {

	addContact: ({ ui }: { ui: mdl.UserInterface }) => {
		O.onInit(mdl.AddItemView, view => {
			view.addContact.react(() => {
				ui.dialogs.push(O.new(mdl.GoogleContactsDialog, view.item))
			})
		})
	},

	closeContacts: ({ ui }: { ui: mdl.UserInterface }) => {
		O.onInit(mdl.GoogleContactsDialog, dlg => {
			dlg.select.react(() => {
				dlg.close()
				ui.nav.back()
			})
		})
	},

}
