import { action, O, reaction, U } from '../common'
import * as mdl from '../model'
import { ImageValue } from '../model'
import { proxy } from '../view/common/services'

const iconSize = 64

export const setup = {

	imageChange: () => {
		O.onInit(mdl.Item, item => {
			item.request3.react(() => {
				if (item.isReadOnly)
					return
				// TODO: more than one image property per item?
				reaction(() => item.props.findByType('image'), p => {
					if (!p)
						return
					reaction(() => p.value, async v => {
						if (!v) return
						const url0 = ImageValue.url(v)
						const blob0 = ImageValue.blob(v)
						const url = blob0 ? URL.createObjectURL(blob0) : proxy(url0)
						if (!url) return
						const img = await createImage(url)
						const w = img.naturalWidth, h = img.naturalHeight
						const icon = await imageToBlob(img, iconSize, iconSize)
						const blob = blob0 ?? await imageToBlob(img)
						action(() => {
							if (!blob0 || v.width !== w || v.height !== h)
								p.value = { image: blob, width: w, height: h }
							item.props.set('icon', icon, 'icon')
						})()
						if (blob0)
							URL.revokeObjectURL(url)
					})
				}, { fireImmediately: true })
			})
		})
	},

	imageIcon: () => {
		O.onInit(mdl.Item, item => {
			item.request3.react(async () => {
				if (item.isReadOnly || item.props.has('icon'))
					return
				await generateImageIcon(item)
			})
		})
	},

	research: () => {
		O.onInit(mdl.Item, item => {
			item.research.react(() => generateImageIcon(item))
		})
	},

	iconUrlsAsBlobs: () => {
		// icons as Blobs
		O.onInit(mdl.Item, item => {
			item.request3.react(() => {
				if (item.isReadOnly)
					return
				reaction(() => item.props.findByType('icon'), p => {
					if (!p) return
					reaction(() => p.value, async v => {
						if (typeof v === 'string' && (
							// keep data URLs smaller than 64 chars.
							(v.length > 64 && v.startsWith('data:')) ||
							v.startsWith('http://') || v.startsWith('https://'))) {
							const blob = await imgUrlToBlob(proxy(v), iconSize, iconSize)
							action(() => { p.value = blob })()
						}
					}, { fireImmediately: true })
				}, { fireImmediately: true })
			})
		})
	},

}

async function generateImageIcon(item: mdl.Item) {
	if (item.isReadOnly)
		return
	const p = item.props.findByType('image')
	if (!p?.value)
		return
	const url0 = ImageValue.url(p.value)
	const blob0 = ImageValue.blob(p.value)
	const url = blob0 ? URL.createObjectURL(blob0) : proxy(url0)
	if (!url)
		return
	const img = await createImage(url)
	const icon = await imageToBlob(img, iconSize, iconSize)
	action(() => {
		item.props.set('icon', icon, 'icon')
	})()
	if (blob0)
		URL.revokeObjectURL(url)
}

function createImage(url: string) {
	return new Promise<HTMLImageElement>((resolve, reject) => {
		const img = new Image()
		img.onload = () => { resolve(img) }
		img.src = url
	})
}

function imageToCanvas(img: HTMLImageElement,
	width = img.width, height = img.height) {
	const canvas = document.createElement('canvas')
	canvas.width = width
	canvas.height = height
	const [x, y, w, h] = zoomAndCrop(img.width, img.height, width, height)
	const ctx = canvas.getContext('2d')
	ctx.drawImage(img, x, y, w, h, 0, 0, width, height)
	return canvas
}

export function zoomAndCrop(imgWidth: number, imgHeight: number,
	width: number, height: number) {
	const isCropX = imgWidth / imgHeight > width / height
	const w = isCropX ? imgHeight / height * width : imgWidth
	const h = isCropX ? imgHeight : imgWidth / width * height
	const x = isCropX ? (imgWidth - w) / 2 : 0
	const y = isCropX ? 0 : (imgHeight - h) / 2
	return [x, y, w, h]
}

async function imageToBlob(img: HTMLImageElement,
	width?: number, height?: number) {
	const canvas = imageToCanvas(img, width, height)
	return await mdl.HashedBlob.create(
		await new Promise<Blob>((resolve, reject) => {
			canvas.toBlob(resolve, U.file.typeToFileExt(new URL(img.src).pathname))
		}))
}

async function imgUrlToBlob(url: string, width?: number, height?: number) {
	const img = await createImage(url)
	return await imageToBlob(img, width, height)
}

