import * as mdl from '../../model'
import { BoxStorage } from './common'

export async function updateBoxInStorages(boxId: string,
	storages: BoxStorage[], statusReporter?: { status: string }) {
	if (statusReporter)
		statusReporter.status =
			`Storage IDs ${storages.map(s => s.id).join(', ')}: start updating items...`
	const writes = []
	const newestRevs: { [id: string]: string } = {}
	const newestPerSrc: string[][] = []
	for (const storage of storages) {
		const idRevs = await storage.access.readIds(boxId)
		if (statusReporter)
			statusReporter.status =
				`${idRevs.length} idRevs read from ${storage.label}...`
		const itemIdMap = { ...newestRevs }
		const newest = []
		for (const idRev of idRevs) {
			if (!containsNewerOrEqualRev(newestRevs, idRev)) {
				for (const w of writes)
					w[idRev.id] = 1
				newestRevs[idRev.id] = idRev.rev
				for (const n of newestPerSrc)
					delete n[idRev.id]
				newest.push(idRev.id)
				delete itemIdMap[idRev.id]
			} else if (containsNewerRev(newestRevs, idRev)) {
				itemIdMap[idRev.id] = '1'
			} else {
				delete itemIdMap[idRev.id]
			}
		}
		newestPerSrc.push(newest)
		writes.push(itemIdMap)
	}
	let count = 0
	const statistics: number[] = []
	for (let i = 0, len = storages.length; i < len; ++i) {
		const c = Object.keys(writes[i]).length
		statistics.push(c)
		count += c
	}
	if (count > 0) {
		const isNeeded = (id: string) => {
			for (const w of writes)
				if (id in w)
					return true
			return false
		}
		const errors = []
		for (let i = 0, len = storages.length; i < len; ++i) {
			const ids = newestPerSrc[i].filter(isNeeded)
			// TODO: allow to configure
			const batchSize = 100
			for (let b = 0, bLen = ids.length / batchSize; b < bLen; ++b) {
				const count = Math.min((b + 1) * batchSize, ids.length)
				// get newest data
				if (statusReporter)
					statusReporter.status = `${count} of ${ids.length
						} items from ${storages[i].label}. Reading...`
				const data = await storages[i].access.readItems(
					ids.slice(b * batchSize, (b + 1) * batchSize), [boxId], true)
				// write newest data to all needed
				if (statusReporter)
					statusReporter.status = `${count} of ${ids.length
						} items from ${storages[i].label}. Writing...`
				await Promise.all(storages.map(async (s, j) => {
					const w = writes[j]
					for (const d of data.filter(d => d.id in w))
						await s.access.writeItem(d, boxId)
							.catch(err => { errors.push(err) })
				}))
			}
		}
		if (errors.length > 0)
			throw errors
	}
	if (statusReporter)
		statusReporter.status = ''
	return statistics
}

function containsNewerRev(revs: { [id: string]: string },
	idRev: { id: string; rev: string }) {
	return idRev.id in revs && mdl.Item.compareRev(revs[idRev.id], idRev.rev) > 0
}

function containsNewerOrEqualRev(revs: { [id: string]: string },
	idRev: { id: string; rev: string }) {
	return idRev.id in revs && mdl.Item.compareRev(revs[idRev.id], idRev.rev) >= 0
}

