import { Account } from '../Account'
import { observable, signal } from '../common'
import { ViewBase } from '../views/common'

export class AccountsView extends ViewBase {
	readonly key = 'accounts'
	get isReady() { return true }
	get label() { return 'Accounts' }
	@observable accounts: Account[] = []
	addAccount = signal<(provider: string) => void>()
}
