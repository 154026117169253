import * as React from 'react'
import * as mdl from '../model'
import { Component, Context, f, Icon, observer, Observer } from './common'

interface Props {
	view: mdl.TimelineView
	context: Context
}

@observer
export class Timeline extends Component<Props> {

	render() {
		const { context, context: { nav }, view } = this.props
		return <div className="view">
			<header>
				<button onClick={nav.back}><Icon uri="navBack" /></button>
				<div><span><i>Timeline</i></span></div>
			</header>
			<section>
				<div className="timeline">
					<Observer>{() => <>
						{view.actions.map((act, idx) =>
							<div key={idx} title={`Item: ${act.itemId}, box: ${act.boxId}`}>
								{f.dateTime(new Date(act.start))} - {act.end ?
									f.time(new Date(act.end)) :
									<Icon uri="settings" className="spin small" />}
								<br />
								{`${act.type} '${act.itemLabel ?? act.itemId
									}' in '${act.boxLabel ?? act.boxId}'`}
							</div>)}
						{view.actions.length <= 0 && '-'}
					</>}</Observer>
				</div>
			</section>
			<footer />
		</div>
	}

}
