import * as React from 'react'
import {
	Component, FileUploadButton, FormValue, hasCapture, Icon, observer
} from '..'
import { action, O } from '../../../common'
import { loadImage } from '../../../data/components/ImageCapture'
import * as mdl from '../../../model'
import placeholder from '../../../style/placeholder.svg'
import { IconList } from './IconList'
import { Image } from './Image'
import { ImageSearch } from './ImageSearch'

interface Props {
	label?: string
	value: FormValue
	query: FormValue | string
	asIcon?: boolean
	config: mdl.Config
	onClose: () => void
}

@observer
export class ImageCapture extends Component<Props> {

	// extendable, property independent state
	state = O.new(mdl.ImageCapture, placeholder)

	// object URL cache to revoke
	objectUrlCache: string[] = []

	render() {
		const { label, asIcon, config, query } = this.props
		return <div className="dialog" onKeyUp={this.onAnyKey} tabIndex={0}>
			<div className="wide">
				<header>{label}</header>
				<section className="content">
					<ImageSearch query={query} asIcon={asIcon} onSelect={this.onSelect}
						config={config} autoFocus={true} />
					{asIcon && <IconList title="favorit icons" expand={true}
						source={this.state} member="favoritIcons" onSelect={this.onSelect}>
						{(url, idx) =>
							<Icon key={idx} uri={url} />}
					</IconList>}
					{asIcon && <IconList title="recent icons"
						source={this.state} member="recentIcons" onSelect={this.onSelect}>
						{(url, idx) =>
							<Icon key={idx} uri={mdl.ImageValue.urlOrBlob(url)} />}
					</IconList>}
					<IconList title="recent images"
						source={this.state} member="recentImages" onSelect={this.onSelect}>
						{(v, idx) => <Image key={idx} value={v}
							objectUrlCache={this.objectUrlCache} />}
					</IconList>
					<IconList title="font icons"
						source={this.state} member="fontIcons" onSelect={this.onSelect}>
						{group => <div key={group.title}>
							<label>{group.title}</label>
							<div className="icons">
								{group.icons.map(n => <Icon key={n} uri={n} />)}
							</div>
						</div>}
					</IconList>
				</section>
				<footer>
					<div className="actions">
						<FileUploadButton onChange={this.onUpload} accept="image/*"
							title="Select image file.">
							<Icon uri="insert_drive_file" />
						</FileUploadButton>
						{hasCapture() &&
							<FileUploadButton onChange={this.onUpload} accept="image/*"
								capture title="Capture image from camera.">
								<Icon uri="camera_alt" />
							</FileUploadButton>}
						<button onClick={this.onPaste} title="Paste from clipboard.">
							<Icon uri="paste" /></button>
						<button onClick={this.onClose}
							title="Close this image selector. [ESC]">
							<Icon uri="close" /></button>
					</div>
				</footer>
			</div>
			<div className="curtain"></div>
		</div>
	}

	onUpload = (evn: React.FormEvent<HTMLInputElement>) => {
		const file = evn.currentTarget.files[0]
		mdl.HashedBlob.create(file).then(b => {
			this.props.value.val =
				{ image: b, source: file.name, info: { size: file.size } }
		})
	}

	onPaste = async () => {
		// TODO: factor out to ui/DataTransfer
		// TODO: improve data item interpretation
		try {
			if ('read' in navigator.clipboard) {
				const dataItems = await navigator.clipboard.read()
				const dataItem = dataItems[0]
				const blob = await dataItem.getType(dataItem.types[0])
				if (blob.type.startsWith('image/')) {
					this.props.value.val = { image: await mdl.HashedBlob.create(blob) }
					this.onClose()
				}
			}
		}
		catch (err) {
			// TODO: logger
			console.error(err)
		}
	}

	onSelect = (url: string) => {
		if (url) {
			// TODO: refactor to remove data/...-dependency
			loadImage(url, this.props.asIcon, this.props.config.api)
				.then(action(v => {
					this.props.value.val = v
					this.onClose()
				})).catch(err => {
					// TODO: logger
					console.error(err, { ...err })
				})
		}
	}

	onClose = () => {
		this.props.onClose()
	}

	onAnyKey = (evn: React.KeyboardEvent) => {
		if (evn.key === 'Escape') {
			this.onClose()
			evn.stopPropagation()
			evn.nativeEvent.stopImmediatePropagation()
		}
	}

	componentWillUnmount() {
		this.objectUrlCache.forEach(URL.revokeObjectURL)
		this.objectUrlCache = []
	}

}
