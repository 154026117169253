import { Config } from './Config'

export class ApiManager {

	constructor(public conf: Config['api']) { }

	private googleApi: any

	async getGoogleContactsApi<T>(
		initFn: (conf: Config['api']['google']) => Promise<T>) {
		if (!this.googleApi)
			this.googleApi = await initFn(this.conf['google'])
		return this.googleApi as T
	}
}
