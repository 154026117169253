import { U } from '../../../common'
import * as mdl from '../../../model'

export const statements = {

	getItems: (ids: string[]) => ({
		statement: `MATCH (i:Item) WHERE i.id IN $ids AND keys(i) <> ["id"]
		OPTIONAL MATCH (i)-[l]->(t)
		RETURN i, collect({type: type(l),link: l, trg: t.id})`,
		parameters: { ids }
	}),

	getBoxItems: {
		statement: `MATCH (i:Item) WHERE keys(i) <> ["id"] AND
			any(k in keys(i) WHERE k ENDS WITH '_type' AND i[k] = 'box')
		OPTIONAL MATCH (i)-[l]->(t)
		RETURN i, collect({type: type(l),link: l, trg: t.id})`,
	},

	getAdminItems: {
		statement: `MATCH (i:Item) WHERE keys(i) <> ["id"] AND
			any(k in keys(i) WHERE k ENDS WITH '_type' AND i[k] IN 
				['box', 'storage', 'account', 'installation'])
		OPTIONAL MATCH (i)-[l]->(t)
		RETURN i, collect({type: type(l),link: l, trg: t.id})`,
	},

	getItemIds: (box: string) => ({
		statement: `MATCH (i:Item)-[:BOX]->(:Item {id:$box}) 
		OPTIONAL MATCH (i)-[:UPDATED_ON]->(updater)
		OPTIONAL MATCH (i)-[:CREATED_ON]->(creator)
		RETURN { id:i.id,
			rev:coalesce(i.rev,0)+'_'+coalesce(updater.id, creator.id, 'ukn') }`,
		parameters: { box }
	}),

	mergeItem: (data: mdl.ItemData) => ({
		// add system template names as labels
		statement: `MERGE (i:Item {id: $id}) SET i = ${toStr(itemToMap(data))}
		${data.tmpls?.filter(t => t.endsWith('.tmpl'))
				.map(t => `SET i:${U.str.substringBefore(t, '.tmpl')}`).join(' ') ?? ''}
				REMOVE i:Placeholder`,
		parameters: { id: data.id }
	}),

	createLink: (id: string, trg: string, type: string,
		data?: mdl.ItemLinkData) => trg && {
			statement: `
MATCH (s:Item {id: $id})
MERGE (t:Item {id: $trg})
	ON CREATE SET t:Placeholder
MERGE (s)-[:${type}${typeof data === 'object'
					? ' ' + toStr(U.obj.excludeMembers(data, 'url'), true) : ''}]->(t)
`.trim(),
			parameters: { id, trg }
		},

	deleteLinks: (id: string) => ({
		statement: 'MATCH (:Item {id: $id})-[r:LINK|CONTENT|TMPL]->() DELETE r',
		parameters: { id }
	}),

	deleteUpdatedLinks: (id: string) => ({
		statement: 'MATCH (:Item {id: $id})-[r:UPDATED_ON]->() DELETE r',
		parameters: { id }
	}),

	deleteBoxLink: (id: string, trg: string) => ({
		statement: 'MATCH (:Item {id: $id})-[r:BOX]->(:Item {id: $trg}) DELETE r',
		parameters: { id, trg }
	}),

	deleteBox: (id: string) => ({
		statement: 'MATCH (:Item {id: $id})<-[r:BOX]-() DELETE r',
		parameters: { id }
	}),

}

export function itemToStatements(data: mdl.ItemData, boxId: string) {
	return [
		statements.mergeItem(data),
		statements.deleteLinks(data.id),
		...linksToStatements(data.id, data.content, 'CONTENT'),
		...linksToStatements(data.id, data.links, 'LINK'),
		...linksToStatements(data.id, data.tmpls, 'TMPL'),
		statements.createLink(data.id, boxId, 'BOX'),
		statements.createLink(data.id, data.create?.installationId, 'CREATED_ON'),
		statements.deleteUpdatedLinks(data.id),
		statements.createLink(data.id, data.update?.installationId, 'UPDATED_ON'),
	]
}

export function linksToStatements(itemId: string,
	links: mdl.ItemLinkData[], type: string) {
	return links?.map(ln =>
		statements.createLink(itemId, mdl.ItemLinkData.url(ln), type, ln)) ?? []
}

/** Extracts the item data for every row of the first result. */
export function responseToItems({ results }:
	{ results: { data: { row: any[] }[] }[] }) {
	return results[0].data.map(d => rowToItem(d.row))
}

/** Extracts the values of the first cell in every row of the first result. */
export function responseToValues({ results }:
	{ results: { data: { row: any[] }[] }[] }) {
	return results[0].data.map(d => d.row[0])
}

function toLinkData(trg: string, d: {}) {
	return d && Object.keys(d).length > 0 ?
		{ ...d, url: trg } : trg
}
const linkHandlers = {
	LINK: (item, trg, d) => { U.array.addTo(item, 'links', toLinkData(trg, d)) },
	CONTENT: (item, trg, d) => { U.array.addTo(item, 'content', toLinkData(trg, d)) },
	BOX: (item, trg) => { U.array.addTo(item, 'boxes', trg) },
	TMPL: (item, trg) => { U.array.addTo(item, 'tmpls', trg) },
	CREATED_ON: (item, trg) => {
		U.obj.memberObject(item, 'create').installationId = trg
	},
	UPDATED_ON: (item, trg) => {
		U.obj.memberObject(item, 'update').installationId = trg
	},
} as { [type: string]: (item: mdl.ItemData, trg: string, d: {}) => void }

export function rowToItem(row: any[]) {
	const item = cellToItem(row[0])
	const links: { link: any, type: string, trg: string }[] = row[1]
	if (links) {
		for (const ln of links) {
			if (ln.type in linkHandlers)
				linkHandlers[ln.type](item, ln.trg, ln.link)
		}
	}
	return item
}

export function cellToItem(row: any) {
	const item: mdl.ItemData = { id: row.id, rev: row.rev ?? 0, props: {} }
	if (row.isDeleted)
		item.isDeleted = true
	// set props
	const { props } = item
	// collect values (in props), types and sub-properties
	const subs = {}, types = {}
	for (const k of Object.keys(row)) {
		if (k in item)
			continue
		if (k === 'create_date') {
			item.create = { date: row[k] }
			continue
		}
		if (k === 'update_date') {
			item.update = { date: row[k] }
			continue
		}
		const s = k.indexOf('_')
		if (s > 0) {
			const name = k.substring(0, s)
			const sub = k.substring(s + 1)
			if (sub === 'type')
				types[name] = row[k]
			else if (name in subs)
				subs[name][sub] = row[k]
			else
				subs[name] = { [sub]: row[k] }
		} else {
			props[k] = row[k]
		}
	}
	// replace prop values with typed objects
	for (const name of Object.keys(types)) {
		props[name] = { [types[name]]: props[name] }
		if (name in subs)
			Object.assign(props[name], subs[name])
	}
	return item
}

function toStr(map: { [n: string]: string }, stringify = false) {
	return `{${Object.keys(map)
		.filter(k => map[k] !== void 0)
		.map(k => k + ':' + (stringify ? JSON.stringify(map[k]) : map[k]))
		.join(',')}}`
}

function itemToMap(item: mdl.ItemData) {
	const map: { [n: string]: string } = { id: JSON.stringify(item.id) }
	if (item.rev)
		map.rev = '' + item.rev
	if (item.isDeleted)
		map.isDeleted = 'true'
	for (const name of Object.keys(item.props))
		// TODO: encode reserved names (id, rev, create, update)
		setProp(map, name, item.props[name])
	if (item.create?.date)
		map.create_date = `datetime("${item.create.date}")`
	if (item.update?.date)
		map.update_date = `datetime("${item.update.date}")`
	return map
}

function setProp(map: { [n: string]: string }, name: string, value: any) {
	if (typeof value === 'object') {
		for (const t of Object.keys(typedVals)) {
			if (t in value) {
				const vals = typedVals[t](value)
				for (const k of Object.keys(vals)) {
					const v = vals[k]
					if (v !== void 0 && v !== null)
						map[k === '_' ? name : name + '_' + k] = JSON.stringify(v)
				}
				return
			}
		}
		for (const t of Object.keys(dateVals)) {
			if (t in value) {
				const v = dateVals[t](value[t])
				if (v) {
					map[name] = `${t.toLowerCase()}("${v}")`
					map[name + '_type'] = JSON.stringify(t)
				}
				return
			}
		}
		for (const t of Object.keys(simpleTypedVals)) {
			if (t in value) {
				const v = value[t]
				if (v !== void 0 && v !== null) {
					map[name] = JSON.stringify(v)
					map[name + '_type'] = JSON.stringify(t)
				}
				return
			}
		}
		if (value.hidden)
			map[name + '_hidden'] = 'true'
	} else {
		map[name] = JSON.stringify(value)
	}
}

const dateVals = {
	date: (d: string) => d.substring(0, 10),
	time: (d: string) => d.charAt(10) === 'T' ? d.substring(11) : d,
	dateTime: (d: string) => d,
}
const simpleTypedVals = {
	text: 1, color: 1, url: 1, object: 1, enum: 1,
	query: 1, action: 1
}
const typedVals = {
	number: (v: { number: number, unit?: string }) =>
		({ _: v.number, unit: v.unit }),
	object: (v: { object: {} }) =>
		({ _: JSON.stringify(v.object) }),
	image: (v: mdl.ImageValueObject) => ({
		_: v.image, type: 'image', source: v.source, info: JSON.stringify(v.info),
		width: v.width, height: v.height, class: v.class
	}),
	icon: (v: mdl.IconValueObject) => ({
		_: v.icon, type: 'icon', source: v.source, info: JSON.stringify(v.info),
		width: v.width, height: v.height, class: v.class
	}),
	box: (v: mdl.BoxValue) => ({
		_: v.box, type: 'box',
		mark: v.mark, color: v.color, backColor: v.backColor
	}),
	storage: (v: mdl.BoxStorageValue) => (
		{ _: v.storage, type: 'storage', url: v.url, searchUrl: v.searchUrl }),
	account: (v: mdl.AccountValue) => (
		{ _: v.account, type: 'account', url: v.url }),
}

