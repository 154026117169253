import * as React from 'react'
import { NavItemLink } from '../NavItemLink'
import { PropertyView } from './common'

export const query: PropertyView = {
	content: ({ value }, context) => <div>
		<div className="field">
			<label>Query:</label><code>{value.select}</code>
		</div>
		{value.select && <div className="result items">
			{value.results.map(itm =>
				<NavItemLink key={itm.id} item={itm} context={context} />)}
			<div>{value.results.length} results</div>
		</div>}
	</div>
}
