import * as React from 'react'
import * as mdl from '../../model'
import { cancelClick, Icon, observer } from '../common'
import { ItemContent, ItemLabel, ItemLink } from '../LayoutViews'
import { PropContent, PropLabel } from '../PropertyViews'
import { Layout } from './common'

// TODO: merge with todo.layout

export const question: Layout = {
	icon: item =>
		<QuestionIcon item={item} />,
	links: {
		small: (item, context) => <>
			<QuestionIcon item={item} />
			<div>
				{item.props.title && <h6>{item.props.title.value}</h6>}
				{mdl.QuestionLayout.getContentProps(item).map(p =>
					<PropContent key={p.name} prop={p} context={context} />)}
			</div>
		</>,
		normal: (item, context) => <>
			<QuestionIcon item={item} />
			<div>
				{item.props.title && <h6>{item.props.title.value}</h6>}
				{mdl.QuestionLayout.getContentProps(item).map(p =>
					<PropContent key={p.name} prop={p} context={context} />)}
				<ItemLink item={mdl.QuestionLayout.getFirstComment(item)}
					context={context} level="compact" />
			</div>
		</>,
	},
}

const QuestionIcon = observer(({ item }: { item: mdl.Item }) =>
	<Icon uri={mdl.QuestionLayout.getIconUri(item)}
		onClick={(evn) => {
			cancelClick(evn)
			mdl.ToggleAction.getActionProp(item)?.()
		}} />
)
