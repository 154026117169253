import { O } from '../../common'
import * as mdl from '../../model'

// interpret links list with optional separators

function compensateSeparators(idx: number, cLen: number, lLen: number) {
	// at separator labels
	if ((cLen > 0 && (idx === cLen || idx === cLen + 1 + lLen)) ||
		(cLen <= 0 && idx === lLen))
		return -1
	// compensate for separator labels
	if (cLen > 0 && idx > cLen)
		idx--
	if (idx > cLen + lLen)
		idx--
	return idx
}

// TODO: separate getLink, getItem, moveLink into base/src/env or .../model
// keep only compensateSeparators

export const setup = {

	moveLink: ({ log }: { log: mdl.SystemLogger }) => {
		O.onInit(mdl.ViewItemView, view => {
			view.moveLink.react(log.try((fromIdx, toIdx) => {
				moveLink(view.item, fromIdx, toIdx, view.fromLinks)
			}))
		})
	},

}

export function getLink(idx: number, content: mdl.Link[], links: mdl.Link[]) {
	const cLen = content.length
	const lLen = links.length
	idx = compensateSeparators(idx, cLen, lLen)
	// ignore when at separator labels
	if (idx < 0)
		return null
	return idx < cLen ? content[idx] :
		idx < cLen + lLen ? links[idx - cLen] : null
}

export function getItem(idx: number, content: mdl.Link[], links: mdl.Link[],
	fromItems: mdl.Item[]) {
	const cLen = content.length
	const lLen = links.length
	idx = compensateSeparators(idx, cLen, lLen)
	// ignore when at separator labels
	if (idx < 0)
		return null
	return idx < cLen ? content[idx].item :
		idx < cLen + lLen ? links[idx - cLen].item :
			fromItems[idx - cLen - lLen]
}

export function moveLink(item: mdl.Item, startIdx: number, endIdx: number,
	fromItems: mdl.Item[]) {
	const cLen = item.content.available.length
	const lLen = item.links.available.length
	if (cLen > 0) {
		// with content
		if (startIdx < cLen) {
			// content to...
			if (endIdx < cLen) {
				// ...content
				item.content.move(startIdx, endIdx)
			} else if (endIdx < cLen + 1 + lLen) {
				// ...links
				const ln = item.content.available[startIdx]
				item.addAsRelated(ln, endIdx - cLen)
			}
		} else if (startIdx > cLen && startIdx < cLen + 1 + lLen) {
			// link to...
			if (endIdx > cLen && endIdx <= cLen + 1 + lLen) {
				// ...link
				item.links.move(startIdx - cLen - 1, endIdx - cLen - 1)
			} else if (endIdx <= cLen) {
				// ...content
				const ln = item.links.available[startIdx - cLen - 1]
				item.addAsContent(ln, endIdx)
			}
		} else {
			// from to...
			if (endIdx > cLen && endIdx <= cLen + 1 + lLen) {
				// ...link
				const itm = fromItems[startIdx - cLen - 2 - lLen]
				item.addAsRelated(itm, endIdx - cLen)
			} else if (endIdx <= cLen) {
				// ...content
				const itm = fromItems[startIdx - cLen - (lLen > 0 ? 2 : 1) - lLen]
				item.addAsContent(itm, endIdx)
			}
		}
	} else {
		// no content
		if (startIdx < lLen) {
			// link to...
			if (endIdx < lLen) {
				// ...link
				item.links.move(startIdx, endIdx)
			}
		} else {
			// from to...
			if (endIdx < lLen) {
				// ...link
				const itm = fromItems[startIdx - lLen - 1]
				item.addAsRelated(itm, endIdx)
			}
		}
	}
}

