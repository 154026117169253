import * as React from 'react'
import * as mdl from '../../model'
import {
	action, Block, css, EnumInput, f, Icon,
	observable, observer, TextInput
} from '../common'
import { PropertyView } from './common'

const providers = [
	{ label: 'Local', value: 'local' },
	{ label: 'CouchDB', value: 'couch' },
	{ label: 'Google', value: 'google-oauth-implicit' },
	{ label: 'Gitlab Token', value: 'gitlab-token' },
	{ label: 'NextCloud', value: 'nextcloud' },
	{ label: 'ArangoDB', value: 'arango' },
]

export const account: PropertyView<mdl.Account> = {
	label: ({ value }) => <span>
		{value.item.props.get('username')?.value ?? value.credentials?.id ??
			'Account'} at {value.url}</span>,
	icon: ({ value }) =>
		<span className={css('icon', !value.isActive && 'passive')}
			title={`${value.credentials?.id ?? 'Account'} at ${value.url}`}>
			<Icon uri={'account'} />
		</span>,
	content: ({ value, label }) => <Block label={label}>
		<Block label="Provider">{value.provider}</Block>
		{!value.isLocal && <Block label="URL">{value.url}</Block>}
		{value.canLogin && <Block label="Login"><Login account={value} /></Block>}
	</Block>,
	editor: ({ name, label }, { values }, ctx, autoFocus) =>
		<Block label={label}>
			<EnumInput label="Provider" value={values[name]['provider']}
				autoFocus={autoFocus} options={providers} />
			{!mdl.Account.isLocal(values[name]['provider'].val) &&
				<TextInput label="URL" value={values[name]['url']} />}
		</Block>,
}

@observer
class Login extends React.Component<{ account: mdl.Account }> {

	@observable isOpen = false
	@observable credential = {
		id: this.props.account.credentials?.id || '',
		password: this.props.account.credentials?.password || '',
	}
	@observable isChanging = false
	@observable password = { old: '', new1: '', new2: '' }

	render() {
		const { account } = this.props
		return account.login.isActive ?
			<Icon uri="settings" className="spin" /> :
			this.isOpen ? <>
				<div>
					<TextInput label="Name" placeholder="Enter login name"
						target={this.credential} member="id" />
					<TextInput label="Password" type="password"
						placeholder="Enter password"
						target={this.credential} member="password" />
				</div>
				<button onClick={this.onClick}>Login</button>
			</> : account.credentials ? <>
				<div>{account.credentials.id}</div>
				{'expires' in account.credentials &&
					<div>expires at {f.dateTime(account.credentials.expires)}</div>}
				{this.isChanging ? <div>
					<TextInput label="Old Password" type="password"
						title="Please enter your old password!"
						target={this.password} member="old" autoFocus={true} />
					<TextInput label="New Password" type="password"
						title="Please enter the new password!"
						target={this.password} member="new1" />
					<TextInput label="Repeat new Password" type="password"
						title="Please repeat the new password!"
						target={this.password} member="new2" />
				</div> :
					<button onClick={this.onClick}>Logout</button>}
				{!account.isOAuth && !account.isToken &&
					<button onClick={this.onPassword}>Change Password</button>}
				{this.isChanging && <button onClick={this.onCancel}>Cancel</button>}
			</> : <button onClick={this.onClick}>Login</button>
	}

	onClick = action(() => {
		const { account } = this.props
		if (!this.isOpen && account.credentials?.id) {
			account.logout()
			this.credential.id = ''
			this.credential.password = ''
		} else if (this.isOpen || account.isOAuth) {
			account.login(this.credential.id, this.credential.password)
			this.isOpen = false
		} else {
			this.isOpen = !this.isOpen
		}
	})

	onPassword = action(() => {
		const { account } = this.props
		if (this.isChanging) {
			account.changePassword
				.start(this.password.old, this.password.new1, this.password.new2)
			this.isChanging = false
		} else {
			this.isChanging = !this.isChanging
		}
	})

	onCancel = () => { this.isChanging = false }

}
