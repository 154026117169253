import * as React from 'react'
import { css } from './base'
import { Component } from './Component'

// https://jossef.github.io/material-design-icons-iconfont/

const materialIconMap = {
	error: '\uE000',
	navBack: '\uE317',
	navForward: 'arrow_forward',
	navOk: '\uE5CA',
	ok: 'check',
	doc: 'work',
	query: '\uE8B6',
	item: '\uE3C8',
	image: '\uE410',
	text: 'text_fields',
	url: 'link',
	number: 'looks_one',
	date: 'event',
	time: 'access_time',
	dateTime: 'access_time',
	calendar: 'calendar_today',
	addNew: '\uE39D',
	link: '\uE157',
	expand: '\uE5CF',
	collapse: '\uE5CE',
	delete: '\uE872',
	installation: 'computer',
	todo: 'check_box_outline_blank',
	completed: 'check_box',
	checked: 'check_box',
	unchecked: 'check_box_outline_blank',
	copy: 'content_copy',
	cut: 'content_cut',
	paste: 'content_paste',
	place: 'work',
	location: 'location_on',
	small_preview: 'view_headline',
	compact_preview: 'dehaze',
	normal_preview: 'view_list',
	extended_preview: 'view_stream',
	full_preview: 'view_agenda',
	// small_preview: 'looks_one',
	// compact_preview: 'looks_two',
	// normal_preview: 'looks_3',
	// extended_preview: 'looks_4',
	// full_preview: 'looks_5',
	//accessibility_new: '\ue84e',
	box: 'work',
	box_transient: 'work_outline',
	box_online: 'cloud_queue',
	box_offline: 'cloud_off',
	box_sync: 'sync',
	box_replicate: 'sync_alt',
	box_denied: 'sync_problem',
	box_error: 'error_outline',
	box_cleanup: 'refresh',
	box_remote: 'cloud',
	contact: 'person',
	move: 'import_export',
	account: 'account_circle',
	storage: 'storage',
	system: 'smartphone',
	external: 'launch',
	content: 'segment',
	related: 'dehaze',
	tag: 'local_offer',
	session: 'person',
	question: 'contact_support',
	answered: 'mark_chat_read',
}

export interface IconProps {
	uri: string | Blob
	title?: string
	className?: string
	onClick?: (evn: React.MouseEvent) => void
}

export class Icon extends Component<IconProps> {

	blobUrl: string

	render() {
		const { uri, title, className, onClick } = this.props
		if (this.blobUrl)
			URL.revokeObjectURL(this.blobUrl)
		this.blobUrl = null
		if (typeof uri === 'object' && uri instanceof Blob) {
			this.blobUrl = URL.createObjectURL(uri)
			return <span title={title} className={css('icon', className)}
				onClick={onClick}>
				<img src={this.blobUrl} referrerPolicy="no-referrer" />
			</span>
		}
		if (typeof uri !== 'string')
			return <i className={css('icon', 'text', className)}>•</i>
		const p = uri.indexOf(':')
		const schema = p > 0 ? uri.substring(0, p) : 'material-icon'
		const code = p > 0 ? uri.substring(p + 1) : uri
		const s = code.indexOf('?')
		const key = s > 0 ? code.substring(0, s) : code
		const style = s > 0 ? styleFromUriQuery(code.substring(s + 1)) : null
		return schema === 'material-icon' ?
			<i className={css('icon', 'material-icons', className)}
				title={title} aria-hidden="true" style={style} onClick={onClick}
				data-uri={p < 0 ? 'material-icon:' + uri : uri}>
				{key in materialIconMap ? materialIconMap[key] : key}</i>
			: schema === 'text' ?
				<i className={css('icon', 'text', className)} style={style}
					title={title} aria-hidden="true" onClick={onClick} data-uri={uri}>
					{decodeURIComponent(key)}</i>
				:
				<span title={title} className={css('icon', className)} onClick={onClick}>
					<img src={uri} referrerPolicy="no-referrer" />
				</span>
	}

	componentWillUnmount() {
		if (this.blobUrl)
			setTimeout(url => URL.revokeObjectURL(url), 0, this.blobUrl)
		this.blobUrl = null
	}

}


export function styleToUriQuery(style: React.CSSProperties) {
	const entries = Object.keys(style)
		.map(k => k + '=' + encodeURIComponent(style[k]))
	return entries.join('&')
}

export function styleFromUriQuery(code: string) {
	if (!code || code === '&') return null
	const style = {}
	const entries = code.split('&')
	for (const entry of entries) {
		const p = entry.indexOf('=')
		const k = p > 0 ? entry.substring(0, p) : entry
		const v = p > 0 ? decodeURIComponent(entry.substring(p + 1)) : true
		style[k] = v
	}
	return style
}
