import { IndexedDb } from '../../common/IndexedDb'


export function createDbs(indexedDb = indexedDB) {
	return {
		data: IndexedDb.create('data', [
			['item',
				{
					name: 'box',
					indexes: [
						{
							name: 'items', keyPath: '',
							options: { unique: false, multiEntry: true }
						}
					]
				},
				'history',
				'image', 'icon', 'video', 'pdf', 'file'],
		], indexedDb),
		index: IndexedDb.create('index', [
			['from', 'stem', 'type', 'extension'],
		], indexedDb),
		preview: IndexedDb.create('preview', [
			['image', 'video', 'pdf', 'file'],
		], indexedDb),
	}
}
