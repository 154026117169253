import { observable } from 'mobx'
import * as React from 'react'
import { U } from '../../common'
import { css, observer } from './base'
import { Icon } from './icons'

interface Props {
	month: Date
	onNavigate: (month: Date) => void
	renderDate: (date: Date) => React.ReactNode
	onSelect: (date: Date) => void
	showNavigation?: boolean
}

export class Calendar extends React.Component<Props> {

	render() {
		const { showNavigation, month, onNavigate } = this.props
		const m = getMonth(month)
		const days = [...Array(U.date.daysInMonth(m)).keys()]
			.map(i => new Date(m.getFullYear(),
				m.getMonth(), i + 1))
		return <div className="calendar">
			{showNavigation && <div className="header">
				<YearMonthSelector month={m} onNavigate={onNavigate} />
				<button onClick={this.inc}><Icon uri="chevron_right" /></button>
				<button onClick={this.dec}><Icon uri="chevron_left" /></button>
			</div>}
			<div className="days">
				{weekdays.map(s => <div key={s} className="weekday">{s}</div>)}
				{days.map(d => <Day key={d.getDate()} date={d} {...this.props} />)}
			</div>
		</div>
	}

	inc = () => {
		const d = getMonth(this.props.month)
		d.setMonth(d.getMonth() + 1)
		this.props.onNavigate(d)
	}

	dec = () => {
		const d = getMonth(this.props.month)
		d.setMonth(d.getMonth() - 1)
		this.props.onNavigate(d)
	}

}

interface SelectorProps {
	month: Date
	onNavigate: (month: Date) => void
}

@observer
export class YearMonthSelector extends React.Component<SelectorProps> {

	@observable show = false
	@observable year = this.props.month.getFullYear()

	render() {
		const { month: d } = this.props
		const month = d.getMonth()
		const year = d.getFullYear()
		return <div className="selector" onClick={this.toggle}>
			<div>{`${months[month]} ${year}`}</div>
			{this.show && <div onClick={this.select}>
				<div>
					<button onClick={this.dec}><Icon uri="chevron_left" /></button>
					<span>{this.year}</span>
					<button onClick={this.inc}><Icon uri="chevron_right" /></button>
				</div>
				{months.map((m, i) =>
					<span key={m}
						className={i === month && year === this.year ? 'selected' : ''}>
						{m}</span>)}
			</div>}
		</div>
	}

	toggle = () => { this.show = !this.show }

	select = (evn: React.MouseEvent) => {
		const elem = (evn.target as Element)
		if (elem.localName === 'span') {
			const v = [...elem.parentNode.children].indexOf(elem)
			this.props.onNavigate(new Date(this.year, v - 1))
		}
	}

	inc = (evn: React.MouseEvent) => {
		evn.stopPropagation()
		this.year++
	}

	dec = (evn: React.MouseEvent) => {
		evn.stopPropagation()
		this.year--
	}
}

interface DayProps {
	date: Date
	renderDate: (date: Date) => React.ReactNode
	onSelect?: (date: Date) => void
}

@observer
export class Day extends React.Component<DayProps> {

	render() {
		const { date, renderDate } = this.props
		const weekday = U.date.weekday(date)
		return <div onClick={this.navigate}
			className={css('day' + weekday,
				U.date.sameDate(today.get(), date) && 'today')}
			style={date.getDate() === 1 ? { gridColumnStart: weekday } : {}}>
			<h6>{date.getDate()}</h6>
			{renderDate(date)}
		</div>
	}

	navigate = () => {
		this.props.onSelect?.(this.props.date)
	}

}

// TODO: move to model and env

const oneDayMillis = 1000 * 60 * 60 * 24

// observable today
const today = observable.box(getDate())
// TODO: smarter update
setInterval(() => {
	const d = getDate()
	if (!U.date.sameDate(d, today.get()))
		today.set(d)
}, 3000)

const weekdayFormat = new Intl.DateTimeFormat('de-CH', { weekday: 'short' })
const weekdays = [...Array(7).keys()]
	.map(d => weekdayFormat.format((d + 4) * oneDayMillis))

const monthFormat = new Intl.DateTimeFormat('de-CH', { month: 'long' })
const months = [...Array(12).keys()]
	.map(m => monthFormat.format(m * oneDayMillis * 31))

/** Get date only (year, month, day) */
function getDate(date?: Date) {
	if (!date)
		date = new Date(Date.now())
	if (date instanceof Date)
		return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

/** Get the first date of the month (year, month) */
function getMonth(date?: Date) {
	if (!date)
		date = new Date(Date.now())
	if (date instanceof Date)
		return new Date(date.getFullYear(), date.getMonth(), 1)
}
