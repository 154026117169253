import { signal } from './common'
import { Item } from './Item'


export class SelectDateAction {
	static readonly key = 'selectDate'
	constructor(public item: Item) { }
	start = signal<(date: Date) => (void | Promise<void>)>()
	end = signal<(id: string) => void>()
}

export class ToggleAction {
	static readonly key = 'toggle'

	constructor(public item: Item) { }

	static getActionProp(item: Item) {
		return item.props.findByAction('toggle')?.value?.toggle ?? null
	}

	static getPropToToggle(item: Item) {
		const p = item.props.findByType('boolean')
		return p ? p : item.props.findByTypeInTmpl('boolean')
	}

	toggle = () => {
		const p = ToggleAction.getPropToToggle(this.item)
		if (p)
			this.item.props.set(p.name, !p.value, p.type)
	}
}

export const propertyActionValues = {
	[ToggleAction.key]: ToggleAction,
	[SelectDateAction.key]: SelectDateAction,
}
