import { U } from '../../common'
import * as mdl from '../../model'
import { BoxStorage } from './common'

// TODO: build management GUI

const batchSize = 100

interface Transformer {
	match: (item: mdl.Item) => boolean
	transform: (item: mdl.Item, items: mdl.ItemManager) => void | Promise<void>
}

const transformers = {
	descriptionAsText: {
		match: item => item.props.has('description'),
		transform: async (item, items) => {
			const description = item.props.get('description').value
			if (description !== '')
				item.content.add(await items.createNewItem({
					props: { text: { text: description } },
					tmpls: [items.getItem('text.tmpl')],
				}), -1, 'all')
			item.props.delete('description')
		}
	} as Transformer,
	urlImagesAsContent: {
		match: item => item.props.has('url') && !!item.links.find(
			({ item }) => item.tmpls.has('image.tmpl') && !item.container),
		transform: (item) => {
			item.links
				.filter(({ item }) => item.tmpls.has('image.tmpl') && !item.container)
				.forEach(ln => { item.addAsContent(ln) })
		}
	} as Transformer,
	researchXContest: {
		match: item => item.props.has('url') && item.props.has('description') &&
			item.props.get('url').value?.startsWith('https://www.xcontest.org/'),
		transform: (item) => item.research()
	} as Transformer,
	removeXContestLive: {
		match: item => item.props.has('url') && item.props.has('description') &&
			item.props.get('url').value?.startsWith('https://www.xcontest.org/') &&
			item.props.get('description').value?.includes('live'),
		transform: (item) => {
			item.props.delete('description')
		}
	} as Transformer,
	hideTodoCompleted: {
		match: item => item.tmpls.has('todo.tmpl')
			&& !item.props.get('completed')?.hidden,
		transform: (item) => {
			item.props.hide('completed')
		}
	} as Transformer,
}

export async function transform(boxId: string, storage: BoxStorage,
	items: mdl.ItemManager, statusReporter?: { status: string }) {
	const transformer = transformers.hideTodoCompleted
	if (statusReporter)
		statusReporter.status = 'Loading items...'
	const idRevs = await storage.access.readIds(boxId)
	const stats = { total: idRevs.length, count: 0 }
	if (statusReporter)
		statusReporter.status = `Testing ${stats.total} items...`
	const debugTable = []
	for (let b = 0, batchCount = idRevs.length / batchSize; b < batchCount; ++b) {
		const readItems = await Promise.all(idRevs
			.slice(b * batchSize, b * batchSize + batchSize)
			.map(({ id }) => items.getItem(id).request()))
		const matchedItems = readItems.filter(transformer.match)
		await Promise.all(matchedItems.map(itm => itm.complete()))
		for (const item of matchedItems) {
			debugTable.push(U.obj.filterMembers(item.$debug,
				k => !(k in { tmpls: 1 })))
			await transformer.transform(item, items)
			stats.count++
		}
		if (statusReporter)
			statusReporter.status =
				`Transforming ${stats.count} of ${stats.total} items...`
	}
	console.table(debugTable)
	if (statusReporter)
		statusReporter.status = ''
	return stats
}
