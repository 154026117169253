
export function toObject<T extends string | number, V>(arr: T[])
	: { [k in T]: T }
export function toObject<T extends string | number, V>(arr: T[], val?: V)
	: { [k in T]: V }
export function toObject<T, K extends string | number, V>(arr: T[],
	keyFn: (v: T, idx: number) => K): { [k in K]: T }
export function toObject<T, K extends string | number, V>(arr: T[],
	keyFn: (v: T, idx: number) => K,
	val: V | ((v: T, idx: number) => V)): { [k in K]: V }
export function toObject(arr: any[],
	keyFn?: (v: any, idx: number) => string | number,
	val?: any | ((v: any, idx: number) => any)) {
	const obj = {} as any
	if (!arr)
		return obj
	if (typeof keyFn === 'function') {
		for (let i = 0, len = arr.length; i < len; ++i) {
			const v = arr[i]
			const k = keyFn(v, i)
			obj[k] = typeof val === 'function' ? val(v, i) : val ?? v
		}
	} else {
		for (const v of arr) {
			const k = String(v)
			obj[k] = keyFn ?? v
		}
	}
	return obj
}

/** Concatenate arrays and values.
 * `Array.p(U.array.flatArray, [])`
 */
export function concat<T>(...arraysOrValues: T[] | T[][]): T[] {
	return Array.prototype.concat(...arraysOrValues)
}

/** Flatten one level nested array.
 * `nestedArray.reduce(U.array.flatArray, [])`
 */
export function flatArray<T>(array: T[], element: T[]) {
	return array.concat(element)
}

/** Distinct values. Compares with ===.
 * `doubledArray.filter(U.array.distinct)`
 */
export function distinct<T>(v: T, idx: number, arr: T[]) {
	return arr.indexOf(v) === idx
}

/** Distinct with key extractor.
 * `doubledArray.filter(U.array.distinctKey(e => e.id))`
 * @param keyFn Function to extract key from element.
 */
export function distinctKey<T>(keyFn: (element: T) => string) {
	const matches: Record<string, boolean> = {}
	return (v: T, idx: number, arr: T[]) => {
		const k = keyFn(v)
		if (k in matches)
			return false
		matches[k] = true
		return true
	}
}

/** Side effect for each element of an array.
 * Kind of inline for-each.
 * `array.map(U.array.each(e => { console.log(e.id) }))`
 * Short form for
 * `array.map(e => { console.log(e.id); return e })`
 * TODO: deprecate
 * @param fn Function to call for each element.
 */
export function each<T>(fn: (element: T) => void) {
	return (v: T, idx: number, arr: T[]) => {
		fn(v)
		return v
	}
}

/** Array.findIndex starting at the end of the array. */
export function findLastIndex<T>(arr: T[],
	predicate: (element: T, idx: number, array: T[]) => boolean,
	thisArg: object | null = null) {
	for (let i = arr.length - 1; i >= 0; i--)
		if (predicate.call(thisArg, arr[i], i, arr))
			return i
	return -1
}

/** Array.indexOf and Array.splice(idx, 1). */
export function remove<T>(arr: T[], elem: T) {
	const idx = arr.indexOf(elem)
	const contained = idx >= 0
	if (contained)
		arr.splice(idx, 1)
	return contained
}

export function move<T>(arr: T[], fromIdx: number, toIdx: number) {
	if (fromIdx < 0)
		fromIdx = arr.length + fromIdx
	if (fromIdx >= arr.length)
		fromIdx = arr.length - 1
	if (toIdx < 0)
		toIdx = arr.length + toIdx
	if (toIdx >= arr.length)
		toIdx = arr.length - 1
	const target = arr[fromIdx]
	const inc = fromIdx > toIdx ? -1 : 1
	for (let i = fromIdx; i !== toIdx; i += inc)
		arr[i] = arr[i + inc]
	arr[toIdx] = target
	return arr
}


export function addUnique<V>(values: V[], value: V) {
	if (!values)
		return [value]
	if (values.indexOf(value) < 0)
		values.push(value)
	return values
}

/** Adds a value to an object member array. 
 * If missing the array is created.
 */
export function addTo<T, K extends keyof T>(obj: T, key: K, value: any) {
	if (key in obj)
		(obj[key] as any).push(value)
	else
		obj[key] = [value] as any
}

export function intersect<T extends string | number>(arr1: T[], arr2: T[]): T[] {
	if (arr1.length > arr2.length) {
		const tmp = arr1
		arr1 = arr2
		arr2 = tmp
	}
	const map = toObject(arr1)
	const res: T[] = []
	for (let i = 0, l = arr2.length; i < l; ++i) {
		const v = arr2[i]
		if (v in map)
			res.push(v)
	}
	return res
}

export function difference<T extends string | number>(arr: T[],
	arrSubtractor: T[]): T[] {
	const map = toObject(arrSubtractor)
	const res: T[] = []
	for (let i = 0, l = arr.length; i < l; ++i) {
		const v = arr[i]
		if (!(v in map))
			res.push(v)
	}
	return res
}

export function addMoveFirst<T>(arr: T[], v: T,
	maxLen?: number, removeCb?: (v: T) => void) {
	const idx = arr.indexOf(v)
	if (idx !== 0) {
		if (idx > 0)
			arr.splice(idx, 1)
		arr.unshift(v)
	}
	if (maxLen !== void 0) {
		if (removeCb) {
			for (let i = maxLen, len = arr.length; i < len; ++i) {
				const v = arr.pop()
				if (v !== void 0)
					removeCb(v)
			}
		} else {
			for (let i = maxLen, len = arr.length; i < len; ++i)
				arr.pop()
		}
	}
}

/** Array filled with a range of numbers. Step 1, end exclusive. */
export function range(start: number, end: number) {
	const len = end - start
	const arr = new Array(len)
	for (let i = 0; i < len; ++i) arr[i] = i + start
	return arr
}

