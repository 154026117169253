import { computed, observable, signal } from '../../common'
import { Form, FormValues } from '../../ui/Form'
import { PropertyType } from '../Property'
import { ItemView } from './ItemView'

export class EditItemView extends ItemView {
	readonly key = 'edit'

	@computed get isReady() { return !!this.form }

	@observable form: Form
	enhanceFormValues = signal<(type: PropertyType, vals: FormValues) => void>()

	addTmpl = signal<(id: string) => void>()
	removeTmpl = signal<(id: string) => void>()
}
