import { convert, Form, formValue } from '../../ui/Form'
import { Item } from '../Item'
import { computed, observable, signal, ViewBase } from './common'

export class CreateItemView extends ViewBase {
	readonly key = 'create'
	get isReady() { return !!this.item }
	get label() { return 'Create' }
	@observable recentItems: Item[]
	@computed get linkCandidates() {
		return this.recentItems.filter(itm => !!itm.isReady)
	}
	item: Item
	createItem = signal<(text: string, title: string) => void>()
	get form() {
		const vals = {}
		for (const p of this.item.props)
			vals[p.name] = formValue(p, 'value', convert[p.type])
		return new Form(vals)
	}

}
