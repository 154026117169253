import * as React from 'react'
import * as mdl from '../model'
import { Component, Context, dateTimeFormat, observer } from './common'
import { BoxMark } from './common/BoxMark'

interface Props {
	item: mdl.Item
	context: Context
}

@observer
export class FooterDetails extends Component<Props> {

	render() {
		const { item, context } = this.props
		if (!item.isReady)
			return null
		return <>
			<span onClick={this.onDetails}>
				Item: {item.id}
				{(item.create.date || item.update.date) &&
					`, ${dateTimeFormat.format(item.update.date ?? item.create.date)}`}
				{item.conflicts && ', has conflicts!'}
				{item.isDeleted && ', deleted'}
			</span>
			{', '}
			<span onClick={this.onPlace}>
				{item.boxes.map(b => <BoxMark key={b.id} box={b} />)}
			</span>
		</>
	}

	onDetails = () => { this.props.context.nav.go('details') }
	onPlace = () => { this.props.context.nav.go('place') }

}
