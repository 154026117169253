import * as React from 'react'
import * as mdl from '../model'
import { Component, Context, css, observer } from './common'
import { BoxMarks } from './common/BoxMark'
import { ItemIcon, ItemLink } from './LayoutViews'

interface Props {
	item: mdl.Item
	context: Context
	title?: string
	disabled?: boolean
	showBoxMarks?: boolean
	onNav?: (item: mdl.Item) => void
	level?: mdl.PreviewLevel
	iconOnly?: boolean
}

@observer
export class NavItemLink extends Component<Props> {

	render() {
		const { item, title, disabled, showBoxMarks, context, level = 'normal',
			iconOnly } = this.props
		if (!item.isReady)
			return null
		return <div className={css('link', level, item.layout)}>
			<div className={css('entry', disabled && 'disabled',
				{ deleted: item.isDeleted })}
				onClick={this.onClick} title={title ?? item.labelText} draggable={false}>
				{iconOnly
					? <ItemIcon item={item} context={context} />
					: <ItemLink item={item} level={level} context={context} />}
				{showBoxMarks && <BoxMarks item={item} />}
			</div>
		</div>
	}

	onClick = (evn: React.MouseEvent<HTMLElement>) => {
		if (this.props.disabled) return
		evn.preventDefault()
		const { item, context: { nav }, onNav } = this.props
		if (onNav) onNav(item)
		else nav.go('view', item.id)
	}
}
