
import { setupAll } from '../../../base/src/common/setup'
import { setup as base } from '../../../base/src/data'
import * as mdl from '../model'
import { setup as access } from './access'
import { setup as accounts } from './account'
import { setup as boxesSetup } from './boxes'
import { setup as changes } from './changes'
import { setup as components } from './components'
import { setup as config } from './config'
import { setup as dialogs } from './dialogs'
import { setup as GeoLocation } from './GeoLocation'
import './import/HtmlContentParser'
import {
	createIndexedDbBakStorage, setup as installation
} from './installation'
import { setup as Query } from './Query'
import { setup as recentItems } from './recentItems'
import { setup as Session } from './Session'
import { local_storage as sysStorage } from './Storage'
import { setup as UrlPreview } from './UrlPreview'
import { setup as UserInterface } from './UserInterface'
import { setup as views } from './views'

export const setup = {
	base, dialogs, components, recentItems, UrlPreview, GeoLocation,
	Session, UserInterface, Query, config,

	installation: (props: {
		app: mdl.AppBoot, boxes: mdl.BoxManager, items: mdl.ItemManager,
		inst: mdl.Installation, session: mdl.Session, config: mdl.Config
	}) => {
		setupAll(installation,
			{ ...props, sysStorage, bakStorage: createIndexedDbBakStorage() })
	},

	views: ({ config }: { config: mdl.Config }) => {
		setupAll(views, { config, sysStorage })
	},

	access: (props: {
		app: mdl.AppBoot, boxes: mdl.BoxManager, items: mdl.ItemManager,
		inst: mdl.Installation, config: mdl.Config
	}) => {
		setupAll(access, { ...props, log: props.inst.log })
	},

	accounts: (props: { inst: mdl.Installation, config: mdl.Config }) => {
		setupAll(accounts, { ...props, log: props.inst.log })
	},

	boxes: (props: {
		app: mdl.AppBase, boxes: mdl.BoxManager, changes: mdl.ChangeManager,
		items: mdl.ItemManager, inst: mdl.Installation, session: mdl.Session,
		config: mdl.Config
	}) => {
		setupAll({ boxesSetup, changes },
			{ ...props, log: props.inst.log, auth: props.session.auth, sysStorage })
	},

}

