import * as React from 'react'
import { observable, U } from '../common'
import * as mdl from '../model'
import { Component, Icon, observer } from './common'

interface Props {
	log: mdl.SystemLogger
}

@observer
export class LogView extends Component<Props> {

	@observable showAll = false

	render() {
		const { log: { entries } } = this.props
		return entries.length ?
			<div className="message">
				{entries.slice(0, 1).map(e =>
					<LogEntry key={e.id} entry={e} {...this.props} />)}
				{entries.length > 1 &&
					<div className="more" onClick={this.toggle}>...</div>}
				{this.showAll && entries.slice(1).map(e =>
					<LogEntry key={e.id} entry={e} {...this.props} />)}
			</div>
			: null
	}

	toggle = () => {
		this.showAll = !this.showAll
	}

}

@observer
class LogEntry extends Component<Props & { entry: mdl.LogEntry }> {

	@observable isOpen = false

	render() {
		const { entry } = this.props
		return <div className={mdl.LogLevel[entry.level]}
			onClick={this.toggle}>
			<div className="action"><Icon uri="close" onClick={this.hide} /></div>
			{entry.count > 1 && <span>{entry.count}x</span>}
			{mdl.LogEntry.formatMsg(entry)}
			{this.isOpen && entry.data &&
				<pre>{U.obj.enumerateMembers(entry.data, '  ')}</pre>}
		</div>
	}

	toggle = () => {
		this.isOpen = !this.isOpen
	}

	hide = (evn: React.MouseEvent) => {
		this.props.log.remove(this.props.entry)
		evn.stopPropagation()
	}
}
