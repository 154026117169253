import * as mdl from '..'
import { U } from '../../common'

export function getDateProp(item: mdl.Item) {
	return item.props.findByType('date')
}

export function isDateItem(item: mdl.Item, date: Date) {
	return U.date.sameDate(getDateProp(item)?.value, date) &&
		!item.tmpls.has('calendar.tmpl')
}

export namespace CalendarLayout {

	export function findDateItem(item: mdl.Item, date: Date) {
		if (!item || !date) return null
		for (const itm of item.walkContent()) {
			if (isDateItem(itm, date))
				return itm
		}
		return null
	}

	export function findDateItems(item: mdl.Item, date: Date) {
		if (!item || !date) return null
		const items: mdl.Item[] = []
		for (const itm of item.walkContent()) {
			if (isDateItem(itm, date))
				items.push(itm)
		}
		return items
	}

	export function getItemsForDate(item: mdl.Item, date: Date): mdl.Item[] {
		const items = findDateItems(item, date)
		return items ? items.map(itm => itm.allLinks.map(ln => ln.item)).flat() : []
	}

	export function getContentLinks(item: mdl.Item) {
		// hide content date or year links
		return item.content.available.filter(isMonthCalendar(item)
			? ln => !getDateProp(ln.item) : ln => !ln.item.props.get('year'))
	}

	export function isMonthCalendar(item: mdl.Item) {
		return item.props.has('month')
	}

	export function yearItem(item: mdl.Item, date: Date) {
		return item.content.findByProp('year', date.getFullYear())?.item
	}

	export function monthItem(item: mdl.Item, date: Date) {
		return yearItem(item, date)?.content
			.findByProp('month', date.getMonth() + 1)?.item
	}

}
