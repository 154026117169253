import * as React from 'react'
import { Component, css, observer } from '..'
import * as mdl from '../../../model'
import placeholder from '../../../style/placeholder.svg'

interface Props {
	value: mdl.ImageValue
	objectUrlCache?: string[]
	className?: string
	title?: string
}

@observer
export class Image extends Component<Props> {

	blobUrl: string

	render() {
		const { value, objectUrlCache, className, title } = this.props
		const blob = mdl.ImageValue.blob(value)
		const src = blob ? URL.createObjectURL(blob) :
			mdl.ImageValue.url(value) || placeholder
		if (blob && objectUrlCache) objectUrlCache.push(src)
		if (this.blobUrl)
			URL.revokeObjectURL(this.blobUrl)
		this.blobUrl = blob && !objectUrlCache ? src : null
		return <div
			className={css('img', className, mdl.ImageValue.className(value))}>
			<img src={src} title={title} />
		</ div>
	}

	componentWillUnmount() {
		if (this.blobUrl)
			setTimeout(url => URL.revokeObjectURL(url), 0, this.blobUrl)
		this.blobUrl = null
	}

}


