import * as mdl from '../../../model'
import { ItemDocBase } from './types'

export const query = {
	readData: `
FOR i IN item
RETURN MERGE(i, {
	content: (FOR t, l IN OUTBOUND i content
		RETURN {url: t._key, name: l.name, preview: l.preview }),
	containers: (FOR t IN INBOUND i content RETURN t._key),
	links: (FOR t, l IN OUTBOUND i links
		RETURN {url: t._key, name: l.name, preview: l.preview }),
	tmpls: (FOR t IN OUTBOUND i tmpls RETURN t._key),
	boxes: (FOR t IN OUTBOUND i boxes RETURN t._key),
})
`,
	readItem: (id: string) => `
FOR i IN item
FILTER i._key == ${id}
RETURN MERGE(i, {
	content: (FOR t, l IN OUTBOUND i content
		RETURN {url: t._key, name: l.name, preview: l.preview }),
	containers: (FOR t IN INBOUND i content RETURN t._key),
	links: (FOR t, l IN OUTBOUND i links
		RETURN {url: t._key, name: l.name, preview: l.preview }),
	tmpls: (FOR t IN OUTBOUND i tmpls RETURN t._key),
	boxes: (FOR t IN OUTBOUND i boxes RETURN t._key),
})
`,
	readItems: (ids: string[]) => `
FOR i IN items
FILTER i._key IN ${JSON.stringify(ids)}
RETURN MERGE(i, {
	content: (FOR t, l IN OUTBOUND i content
		RETURN {url: t._key, name: l.name, preview: l.preview }),
	containers: (FOR t IN INBOUND i content RETURN t._key),
	links: (FOR t, l IN OUTBOUND i links
		RETURN {url: t._key, name: l.name, preview: l.preview }),
	tmpls: (FOR t IN OUTBOUND i tmpls RETURN t._key),
	boxes: (FOR t IN OUTBOUND i boxes RETURN t._key),
})
`,
	readIds: 'FOR d IN items RETURN {id: d._key, rev: d.item_rev || 0}',
	upsert: (data: any, col: string) =>
		`INSERT ${JSON.stringify(data)} INTO ${col} OPTIONS {overwrite: true}`,
	upsertEdges: (itemId: string, links: mdl.ItemLinkData[], col: string) => `
FOR to_${col} IN ${JSON.stringify(links)}
	INSERT IS_OBJECT(to_${col}) ? MERGE({ 
			_key: CONCAT('${itemId}-', to_${col}.url),
			_from: 'items/${itemId}', _to: CONCAT('items/', to_${col}.url)
		}, UNSET(to_${col}, 'url')) : 
    { _key: CONCAT('${itemId}-', to_${col}), 
			_from: 'items/${itemId}', _to: CONCAT('items/',to_${col})} 
    INTO ${col} OPTIONS {overwrite: true} 
`,
	remove: (key: string, col: string) => `REMOVE {_key:'${key}'} IN ${col}`,
	removeEdges: (id: string, col: string, excl?: string[]) => `
FOR e_${col} IN ${col}
FILTER e_${col}._from == 'items/${id}' ${excl ?
			`AND e_${col}._to NOT IN ${JSON.stringify(excl.map(k => 'items/' + k))}` :
			`OR e_${col}._to == '${id}'`}
REMOVE e_${col} IN ${col}
`,
	readBoxes: 'FOR b IN items FOR i IN INBOUND b boxes RETURN DISTINCT b',
	readFromLinks: (id: string) =>
		`FOR l IN INBOUND 'items/${id}' links RETURN l._key`,
	readSearchTexts: `
FOR i IN items
RETURN {
	id: i._key,
	rev: CONCAT(i.item_rev || 0, '_',
		i.\`update\`.installationId || i.create.installationId), 
	text: CONCAT_SEPARATOR(' ', 
		SPLIT(CONCAT_SEPARATOR(' ', 
			VALUES(i.props)[* RETURN 
				IS_OBJECT(CURRENT) ? CONCAT(VALUES(CURRENT)) : CURRENT]
		), [' ', '.', ',',';',':'])[* FILTER LENGTH(CURRENT) > 3])
}
`
}
export const edgeCols: (keyof ItemDocBase)[] =
	['links', 'content', 'tmpls', 'boxes']
