import {
	extendObservable, IReactionDisposer, IReactionPublic, isObservableArray
} from 'mobx'
import { O } from './ObjectStatus'

/** Manages disposers per object. */
export class ReactionDisposer<O extends {}> {
	map = new WeakMap<O, IReactionDisposer>()
	dispose = (obj: O) => {
		if (this.map.has(obj)) {
			this.map.get(obj)()
			this.map.delete(obj)
		}
	}
	set(obj: O, val: IReactionDisposer) {
		this.dispose(obj)
		this.map.set(obj, val)
	}

}

const globalDisposer = new WeakMap<Object, IReactionDisposer[]>()

export function disposeWithObject(obj: Object, val: IReactionDisposer) {
	if (globalDisposer.has(obj)) globalDisposer.get(obj).push(val)
	else globalDisposer.set(obj, [val])
}

O.onDispose(Object, obj => {
	if (globalDisposer.has(obj)) {
		for (const d of globalDisposer.get(obj)) d()
		globalDisposer.delete(obj)
	}
})

export function isArray<T>(arr: T[] | T): arr is T[] {
	return Array.isArray(arr) || isObservableArray(arr)
}

export function setComputed<T, K extends keyof T>(target: T, member: K,
	fn: () => T[K]) {
	extendObservable(target, {
		get [member]() { return fn() }
	})
}

/** Work-around the missing reaction debounce.
 * `reaction(() => state, debounceEffect(state => { effect() }, 1000))`
 * https://github.com/mobxjs/mobx/issues/1956
 */
export function debounceEffect<T>(effect: (arg: T, r: IReactionPublic) => void,
	debounceMs: number) {
	let timer: any
	return (arg: T, r: IReactionPublic) => {
		clearTimeout(timer)
		timer = setTimeout(() => { effect(arg, r) }, debounceMs)
	}
}
