import { action, O, when } from '../../common'
import * as mdl from '../../model'

export const setup = {

	completeLinks: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.ViewItemView, async view => {
			await when(() => !!view.item)
			view.item.allAvailableLinks.forEach(mdl.Item.complete)
		})
	},
	placeMode: {
		box: ({ items }: { items: mdl.ItemManager }) => {
			O.onInit(mdl.ViewItemView, async view => {
				await when(() => view.mode === 'place')
				view.box = items.requestItem(view.params.place)
			})
		},
		toggle: ({ log }: { log: mdl.Logger }) => {
			O.onInit(mdl.ViewItemView, async view => {
				view.togglePlace.react(async (item, inclLinks = false) => {
					try {
						const hierarchy = await item.getHierarchy()
						const items = inclLinks
							? [...hierarchy, ...item.links.available.map(mdl.Link.toItem)]
							: hierarchy
						const box = mdl.Box.getBox(view.box)
						if (item.isInBox(box) && (!inclLinks || !item.allAvailableLinks
							.find(ln => !ln.item.isInBox(box)))) {
							action(() => {
								for (const item of items)
									item.removeFromBox(box)
							})()
						} else {
							if (item.containers)
								items.push(...item.containers)
							for (const item of items)
								item.complete().then(item => { item.addToBox(box) })
						}
					} catch (err) {
						log.error(err)
					}
				})
			})
		},
	},
	contentRelated: ({ log }: { log: mdl.SystemLogger }) => {
		O.onInit(mdl.ViewItemView, async view => {
			view.selectedAsContent.react(log.try(action(() => {
				view.item.addAsContent(view.selected)
				view.selected = []
			})))
			view.selectedAsRelated.react(log.try(action(() => {
				view.item.addAsRelated(view.selected, 0)
				view.selected = []
			})))
		})
	},
}
