import { computed, signal, ViewBase } from './common'

export class LoginView extends ViewBase {
	readonly key = 'login'
	@computed get isReady() { return true }
	@computed get label() { return 'Login' }
	login = {
		start: signal<(username: string, password: string) => void>(),
		end: signal()
	}
}
