import { O } from '../../common'
import * as mdl from '../../model'

export const setup = {

	recentImages: ({ items, nav }:
		{ items: mdl.ItemManager, nav: mdl.Navigation }) => {
		O.onInit(mdl.ImageCapture, editor => {
			editor.recentItems = nav.recentIds.map(id => items.requestItem(id))
			for (const item of editor.recentItems)
				item.complete()
		})
	},

}
