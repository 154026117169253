import { comparer, debounceEffect, O, reaction, setComputed, U } from '../../common'
import * as mdl from '../../model'
import { requestRecentItems } from './recentItems'
import { buildIndexes, searchLocal } from './search'

export const setup = {

	recentItems: (props: { items: mdl.ItemManager, nav: mdl.Navigation }) => {
		O.onInit(mdl.Search, search => {
			// search in recent items
			const recentSection = new mdl.SearchResultSection('recent')
			search.resultSections.push(recentSection)
			const recentItems = requestRecentItems(props)
			recentSection.results = recentItems
			reaction(() => search.text, debounceEffect(q => {
				recentSection.results = mdl.searchItems(q, recentItems)
			}, 500))
		})
	},

	shortcutItems: ({ session }: { session: mdl.Session }) => {
		// request shortcut items for the session user
		reaction(() => session.user?.content, content => {
			if (content)
				content.forEach(mdl.Link.request)
		}, { fireImmediately: true, equals: comparer.shallow })
		reaction(() => getShortcuts(session.user), shortcuts => {
			if (shortcuts)
				shortcuts.forEach(mdl.Link.request)
		}, { fireImmediately: true, equals: comparer.shallow })
		// provide shortcut items to the view
		O.onInit(mdl.SearchView, view => {
			setComputed(view, 'shortcutItems',
				() => getShortcuts(session.user)?.map(mdl.Link.toItem) ?? [])
		})
	},

	searchLocal: ({ boxes, items }:
		{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
		O.onInit(mdl.Search, async search => {
			// local search
			const localSection = new mdl.SearchResultSection('local')
			search.resultSections.push(localSection)
			const indexes = await buildIndexes(boxes)
			reaction(() => search.text, debounceEffect(q => {
				localSection.results = searchLocal(q, indexes)
					.map(r => items.getItem(r.itemId))
				localSection.results.forEach(mdl.Item.complete)
			}, 500))
		})
	},

	searchStorages: ({ boxes, items }:
		{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
		O.onInit(mdl.Search, async search => {
			const storages = boxes.availableStorages.filter(s => !!s.access?.search)
			for (const storage of storages) {
				const section = new mdl.SearchResultSection(storage.label)
				search.resultSections.push(section)
				reaction(() => search.text, debounceEffect(async q => {
					section.results =
						(await storage.access.search(q, storage.activeBoxes.map(U.obj.toId)))
							.map(id => items.getItem(id))
					section.results.forEach(mdl.Item.complete)
				}, 1000))
			}
		})
	},

}

function getShortcuts(user: mdl.Item) {
	return user?.content
		.findByProp('title', 'Shortcuts')?.item.links ?? null
}
