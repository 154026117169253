import * as React from 'react'
import { Component } from './input/common'

interface Props {
	onChange?: (evn: React.ChangeEvent<HTMLInputElement>) => void
	children?: React.ReactNode
	accept?: string
	capture?: boolean
	title?: string
}

export class FileUploadButton extends Component<Props> {

	render() {
		const { children, onChange, title, ...r } = this.props
		return <label className="fileUpload button" title={title}>
			{children}
			<input type="file" onChange={onChange}
				onFocus={this.onFocus} onBlur={this.onBlur}	{...r} />
		</label>
	}

	onFocus = (evn: React.FocusEvent<HTMLInputElement>) => {
		evn.currentTarget.parentElement.classList.add('focus')
	}

	onBlur = (evn: React.FocusEvent<HTMLInputElement>) => {
		evn.currentTarget.parentElement.classList.remove('focus')
	}

}

