import { UrlInfo } from './common'

UrlInfo.parser['text/plain'] = textToUrlInfo

function textToUrlInfo(txt: string, ct: string, url: string) {
	const info: UrlInfo = { url }
	info.title = txt.substring(0, 30) + (txt.length > 30 ? '...' : '')
	info.description = txt.substring(0, 120) + (txt.length > 120 ? '...' : '')
	return info
}
