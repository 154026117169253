
// based on https://stackoverflow.com/a/14991797

export function* parseCsv(str: string, separator = ',') {
	var arr: string[] = []
	var quote = false  // 'true' means we're inside a quoted field

	// Iterate over each character, keep track of current row and column (of the returned array)
	for (let col = 0, c = 0, len = str.length; c < len; ++c) {
		const cc = str[c], nc = str[c + 1] // Current character, next character
		arr[col] = arr[col] || '' // Create a new column (start with empty string) if necessary

		// If it's a newline (CRLF), skip the next character
		if (cc === '\r' && nc === '\n')
			++c

		// If the current character is a quotation mark, and we're inside a
		// quoted field, and the next character is also a quotation mark,
		// add a quotation mark to the current column and skip the next character
		if (cc === '"' && quote && nc === '"') {
			arr[col] += cc
			++c
		}
		// If it's just one quotation mark, begin/end quoted field
		else if (cc === '"') {
			quote = !quote
		}
		// If it's a comma and we're not in a quoted field, move on to the next column
		else if (cc === separator && !quote) {
			++col
		}
		// If it's a newline (LF or CR) and we're not in a quoted field,
		// move on to the next row and move to column 0 of that new row
		else if ((cc === '\n' || cc === '\r') && !quote) {
			yield arr
			arr = []
			col = 0
		}
		// Otherwise, append the current character to the current column
		else {
			arr[col] += cc
		}
	}

}
