export * from '../../common'

import { FormValue } from '../base'

export interface InputProps extends React.PropsWithChildren {
	label?: string
	title?: string
	autoFocus?: boolean
}

export interface FormValueProps<T> {
	value: FormValue<T, keyof T>
}

export interface MemberValueProps<T> {
	target: T
	member: keyof T
}

export type ValueProps<T> = FormValueProps<T> | MemberValueProps<T>
