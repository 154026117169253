import { O } from '../../common'
import * as mdl from '../../model'

const url = {
	connections: 'https://people.googleapis.com/v1/people/me/connections',
	extContactUrl: 'https://contacts.google.com/contact/',
	googleIcon: 'https://upload.wikimedia.org/wikipedia/commons/' +
		'5/53/Google_%22G%22_Logo.svg'
}

interface Connection {
	resourceName: string
	etag: string
	names?: Name[]
	photos?: Photo[]
}

interface Name {
	displayName: string
	familyName: string
	givenName: string
	metadata: MetaData
}

interface Photo {
	url: string
	metadata: MetaData
	default: boolean
}

interface MetaData {
	primary: boolean
	source: { type: 'CONTACT' | 'PROFILE', id: string }
}

type Gapi = typeof gapi

function initGoogleContactsApi(conf: mdl.Config['api']['google']) {
	return new Promise<Gapi>((resolve, reject) => {
		gapi.load('client:auth2', {
			callback: () =>
				gapi.client.init({
					apiKey: conf.key,
					clientId: conf.clientId,
					scope: 'profile https://www.googleapis.com/auth/contacts',
				}).then(() => resolve(gapi), reject),
			onerror: reject
		})
	})
}

export const setup = {

	api: ({ items, config, apis }:
		{ items: mdl.ItemManager, config: mdl.Config, apis: mdl.ApiManager }) => {
		O.onInit(mdl.GoogleContactsDialog, async dlg => {
			const gapi = await apis.getGoogleContactsApi(initGoogleContactsApi)
			dlg.isReady = true
			dlg.load.react(async () => {
				const gAuth = gapi['auth2'].getAuthInstance()
				if (!gAuth.isSignedIn.get()) await gAuth.signIn()
				gapi.client.request({
					path: url.connections + '?personFields=names,nicknames,photos' +
						'&sortOrder=FIRST_NAME_ASCENDING' +
						'&pageSize=2000' +
						'&key=' + config.api.google.key,
				}).then(function(resp: { result: { connections: Connection[] } }) {
					// console.log(resp.result)
					dlg.contacts = resp.result.connections
						.filter(c => !!c.names)
						.map(toContact)
				}, function(reason) {
					console.error('Error: ' + reason.result.error.message)
				})
			})
			dlg.select.react(async contact => {
				const content: mdl.ItemSpec[] = [
					...(contact.photos?.map(p => ({
						props: {
							image: { image: p.url.replace(/=s100$/, '=s800') },
							caption: `${p.type} photo`,
						},
						tmpls: [items.getItem('image.tmpl')],
					})) ?? []),
					contact.extId?.contact && {
						props: {
							title: 'Google Contact',
							icon: { icon: url.googleIcon },
							url: { url: url.extContactUrl + contact.extId.contact },
							data: { object: contact, hidden: true },
						},
						tmpls: [items.getItem('url.tmpl')],
					},
				]
				const { item } = dlg
				const tree = item.props.get('title')?.value === contact.name ?
					content : {
						props: { title: contact.name },
						content,
						tmpls: [items.getItem('list.tmpl')],
					} as mdl.ItemSpec
				item.content.add(await items.createNewItem(tree), -1, 'all')
			})
		})
	},

}

function toContact(c: Connection) {
	const n = c.names[0]
	const contact: mdl.GoogleContact = {
		id: c.resourceName, name: n.displayName,
		familyName: n.familyName, givenName: n.givenName,
		extId: {}
	}
	for (const n of c.names)
		contact.extId[n.metadata.source.type.toLowerCase()] = n.metadata.source.id
	if (c.photos) {
		const userPhotos = c.photos.filter(p => !p.default)
		if (userPhotos.length > 0)
			contact.photos = userPhotos.map(p => (
				{ url: p.url, type: p.metadata.source.type.toLowerCase() as any }))
	}
	return contact
}


