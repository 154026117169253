import { observer } from 'mobx-react'
import * as React from 'react'
import { observable } from '../common'
import * as mdl from '../model'
import { Component, Context, css, Icon } from './common'
import { BoxMarks } from './common/BoxMark'
import { ItemLink } from './LayoutViews'

interface Props {
	link: mdl.Link
	previous?: mdl.Link
	context: Context
	view: mdl.ViewItemView
	className?: string
}

@observer
export class ViewItemLink extends Component<Props> {

	@observable expand = false

	render() {
		const { link, previous, view, view: { mode, showBoxMarks }, context, className }
			= this.props
		const isSelected = view.isSelected(link)
		const isNav = mode !== 'move' && !view.hasSelected
		const showName = link.name && (!previous || previous.name !== link.name)
		const placed = mode === 'place' && view.isPlaced(link.item)
		const onlyBox = placed && link.item.boxes.length === 1
		return link.item.isReady && link.item.status !== mdl.ItemStatus.missing ?
			<div onClick={this.onClick}
				className={css({
					selected: isSelected, named: !!link.name, placed,
					deleted: link.item.isDeleted
				}, className)}
				title={`${link.preview} preview on Item ${link.refId
					}.${link.item.isDeleted ? ' Item is deleted!' : ''}`}>
				{showName &&
					<div className="name">{link.name}</div>}
				<div className={css('link', link.preview, link.item?.layout)}>
					{link.item.status === mdl.ItemStatus.error ?
						<div className="entry">
							<div className="missing">
								{link.item.error.message}
								<div className="action" onClick={this.onDelete}>
									<Icon uri={'delete'} /></div>
							</div>
						</div> :
						<div className="entry" draggable={false}
							onClick={isNav ? this.onNav : null}>
							<ItemLink item={link.item} level={link.preview}
								context={context} />
						</div>
					}
					{mode === 'place' &&
						<button className="place" onClick={this.onPlace}
							disabled={onlyBox || view.togglePlace.isActive} title={onlyBox ?
								'This item is placed only in the selected box!' : placed ?
									'Click to remove this item from the selected box!' :
									'Click to add this item to the selected box!'}>
							<Icon uri={placed ? 'check' : 'arrow_left'} />
						</button>}
					{/* {mode === 'view' &&
					<button className="expand" onClick={this.onDetails}>
						<Icon name={this.expand ? 'collapse' : 'expand'} />
					</button>} */}
					{mode === 'move' || view.hasSelected ?
						<div
							className={'action ' + (view.hasSelected ? 'check' : 'reorder')}>
							<Icon uri={view.hasSelected ? isSelected ? 'checked' : 'unchecked'
								: 'reorder'} />
						</div>
						: showBoxMarks && <BoxMarks item={link.item} />}
				</div>
				{mode === 'view' && this.expand ?
					<div className="expand">
						<input type="text" value={link.name || ''}
							onChange={this.onNameChange} />
						<button onClick={this.onLevel}>
							<Icon uri={link.preview + '_preview'} /></button>
						<button onClick={this.onDelete}><Icon uri="delete" /></button>
					</div>
					: null}
			</div>
			: null
	}

	onNav = (evn: React.MouseEvent<HTMLElement>) => {
		if (evn.ctrlKey) return
		if (evn.target['localName'] === 'a')
			return
		evn.preventDefault()
		const { context: { nav }, link, view, view: { box } } = this.props
		// if (view.mode !== 'place' || view.isPlaced(link.item))
		nav.go({ key: 'view', id: link.item.id, params: box ? { place: box.id } : null })
	}

	onClick = (evn: React.MouseEvent<HTMLElement>) => {
		const { link, view, context: { nav } } = this.props
		//		console.log('click', view.hasSelected)
		if (view.mode === 'move' || view.hasSelected) {
			evn.preventDefault()
			view.select(link)
		}
		else if (evn.ctrlKey) {
			evn.preventDefault()
			nav.go('move')
			view.select(link)
		}
	}

	onDetails = (evn: React.MouseEvent<HTMLButtonElement>) => {
		this.expand = !this.expand
	}

	onPlace = () => {
		const { link, view } = this.props
		view.togglePlace(link.item)
	}

	onLevel = () => {
		const { link } = this.props
		link.togglePreview()
	}

	onDelete = () => {
		this.props.view.item.links.remove(this.props.link)
	}

	onNameChange = (evn: React.ChangeEvent<HTMLInputElement>) => {
		const { link } = this.props
		link.name = evn.target.value
	}

}
