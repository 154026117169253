import { U } from '../../common'
import { computed, observable, signal } from '../common'
import { Item } from '../Item'
import { Link } from '../Link'
import { ImageValue } from '../PropertyValue'

export class ImageCapture {
	static readonly imageUrlPattern = /^(https?:\/\/|data:image\/|blob:)./
	static readonly iconUrlPattern = /^(text:|material-icon:)./

	@observable isOpen = false
	@observable isCanceled = false
	open = signal(() => {
		this.isOpen = true
		this.isCanceled = false
	})

	@observable recentItems: Item[]
	@computed get recentIcons(): (string | Blob)[] {
		return this.recentItems?.map(item => item.props.findByType('icon')?.value)
			.filter(U.any.isTrue)
			.filter(U.array.distinct)
	}
	@computed get recentImages(): ImageValue[] {
		const items = this.recentItems
		return items?.map(findImageValue)
			.filter(ImageValue.isValid)
			.concat(...items?.map(
				item => item.allLinks.map(findImageValue).filter(U.any.isTrue)))
			.filter(U.array.distinct)
	}
	@observable fontIcons: { title: string, icons: string[] }[]
	favoritIcons = ['text:👍', 'text:👎', 'text:👉', 'text:💡', 'text:❔', 'text:❕']
}

function findImageValue(item: Item | Link) {
	if ('item' in item)
		item = item.item
	const v = item?.props.findByType('image')?.value
	return ImageValue.isValid(v) ? v : null
}

