import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import * as React from 'react'
import { action, comparer, Component, FormValue, reaction } from '..'

const openStreetMap = {
	urlTemplate: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	options: {
		attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">
			OpenStreetMap</a> contributors`
	}
}

const openTopoMap = {
	urlTemplate: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
	options: {
		maxZoom: 17,
		attribution: `Data: &copy;
	 <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>,
	  <a href="http://viewfinderpanoramas.org">SRTM</a> 
		| Style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> 
		(<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)`
	}
}

const initialZoom = 14

let tilesCache: L.TileLayer

interface Props {
	latitude: FormValue<{ latitude: number }, 'latitude'>
	longitude: FormValue<{ longitude: number }, 'longitude'>
	className?: string
}

export class LocationMapEditor extends Component<Props> {

	ref = React.createRef<HTMLDivElement>()
	map: L.Map
	marker: L.Marker<any>

	render() {
		const { className } = this.props
		return <div className={className} ref={this.ref}></div>
	}

	componentDidMount() {
		this.map = L.map(this.ref.current)
			.addLayer(tilesCache ||
				(tilesCache = L.tileLayer(openTopoMap.urlTemplate,
					openTopoMap.options)))
		// initial position
		this.map.setView([0, 0], 1)
		// interaction
		let doubleClickTimeout: any
		this.map.on('click',
			evn => {
				if (doubleClickTimeout) return
				const p: L.LatLngLiteral = (evn as any).latlng
				doubleClickTimeout = setTimeout(() => {
					doubleClickTimeout = null
					this.setLocation(p)
				}, 200)
			})
			.on('dblclick', evn => {
				clearTimeout(doubleClickTimeout)
				doubleClickTimeout = null
			})
		this.componentDidUpdate()
	}

	componentDidUpdate() {
		// observe position changes
		reaction<L.LatLngTuple>(() =>
			[this.props.latitude.val, this.props.longitude.val],
			p => {
				if (p[0] && p[1]) {
					if (this.map.getZoom() <= 1)
						this.map.setZoom(initialZoom)
					this.moveTo(p)
				}
			}, { fireImmediately: true, equals: comparer.shallow })
	}

	private createMarker(p: L.LatLngExpression): L.Marker<any> {
		return L.marker(p, { draggable: true })
			.addTo(this.map)
			.on('dragend', evn => {
				const latlng = evn.target.getLatLng()
				this.setLocation(latlng)
			})
	}

	componentWillUnmount() {
		this.map.remove()
	}

	moveTo(p: L.LatLngExpression) {
		this.map.panTo(p)
		if (this.marker)
			this.marker.setLatLng(p)
		else
			this.marker = this.createMarker(p)
	}

	setLocation(p: L.LatLngExpression) {
		this.moveTo(p)
		const { latitude, longitude } = this.props
		action(() => {
			latitude.val = 'lat' in p ? p.lat : p[0]
			longitude.val = 'lng' in p ? p.lng : p[1]
		})()

	}

}

// https://github.com/btpschroeder/leaflet-webpack/blob/master/src/index.js
/* This code is needed to properly load the images in the Leaflet CSS */
delete L.Icon.Default.prototype['_getIconUrl']
L.Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
