
export function tryParseJsonObject(str: string) {
	if (!str)
		return null
	str = str.trim()
	if (str.startsWith('{') && str.endsWith('}')) {
		try {
			return JSON.parse(str)
		}
		catch (err) {
			return null
		}
	}
	return null
}
