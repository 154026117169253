import * as mdl from '..'

export namespace TodoLayout {

	export function getContentProps(item: mdl.Item) {
		const toggleProp = mdl.ToggleAction.getPropToToggle(item)
		return item.props.content
			.filter(p => p !== item.props.label && p.type !== 'image' &&
				p !== toggleProp)
	}

	export function getIconUri(item: mdl.Item): string {
		const toggleProp = mdl.ToggleAction.getPropToToggle(item)
		return toggleProp?.value ? 'completed' : 'todo'
	}

}
