export function substringBefore(str: string, match: string) {
	if (typeof str !== 'string')
		return str
	const idx = str.indexOf(match)
	return idx >= 0 ? str.substring(0, idx) : null
}

export function substringBeforeLast(str: string, match: string) {
	if (typeof str !== 'string')
		return str
	const idx = str.lastIndexOf(match)
	return idx >= 0 ? str.substring(0, idx) : null
}

export function substringBetween(str: string,
	prefixMatch: string, postfixMatch: string) {
	if (typeof str !== 'string')
		return str
	const p1 = str.indexOf(prefixMatch)
	const p2 = str.lastIndexOf(postfixMatch)
	return p1 >= 0 && p2 > p1 ? str.substring(p1 + prefixMatch.length, p2)
		: p1 >= 0 ? str.substring(p1 + prefixMatch.length)
			: p1 < 0 && p2 >= 0 ? str.substring(0, p2) : null
}

export function substringAfter(str: string, match: string) {
	if (typeof str !== 'string')
		return str
	const idx = str.indexOf(match)
	return idx >= 0 ? str.substring(idx + match.length) : null
}

export function substringAfterLast(str: string, match: string) {
	if (typeof str !== 'string')
		return str
	const idx = str.lastIndexOf(match)
	return idx >= 0 ? str.substring(idx + match.length) : null
}

export function trim(str: string, ch: string) {
	let start = 0, end = str.length
	while (start < end && str[start] === ch)
		++start
	while (end > start && str[end - 1] === ch)
		--end
	return start > 0 || end < str.length ? str.substring(start, end) : str
}

export function trimStart(str: string, ch: string) {
	let start = 0, end = str.length
	while (start < end && str[start] === ch)
		++start
	return start > 0 ? str.substring(start) : str
}

export function trimEnd(str: string, ch: string) {
	let start = 0, end = str.length
	while (end > start && str[end - 1] === ch)
		--end
	return end < str.length ? str.substring(start, end) : str
}

export function charCount(str: string) {
	let r = 0
	for (const c of [...str]) {
		// https://www.charbase.com/200d-unicode-zero-width-joiner
		if (c === '\u200d')
			r--
		else
			r++
	}
	return r
}

export function compare<T>(selectFn: (e: T) => string) {
	return (a: T, b: T) => selectFn(a).localeCompare(selectFn(b))
}

export function padAbsOne(num: number) {
	const n = Math.floor(Math.abs(num))
	return (n < 10 ? '0' : '') + n
}

export function firstToLowerCase(str: string) {
	return str.charAt(0).toLowerCase() + str.substring(1)
}

export function firstToUpperCase(str: string) {
	return str.charAt(0).toUpperCase() + str.substring(1)
}

export function random() {
	return Math.floor((Math.random() + 1) * Date.now()).toString(36)
}

