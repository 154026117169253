import * as React from 'react'
import * as mdl from '../../model'
import { Block, Button, css, enumerate, Icon, Link, TextInput } from '../common'
import { BoxMark } from '../common/BoxMark'
import { NavItemLink } from '../NavItemLink'
import { PropertyView } from './common'

const permissionLabel = {
	null: ' ... ',
	no: 'no access',
	ro: 'read-only',
	rw: 'read/write'
}

export const box: PropertyView<mdl.Box> = {
	icon: ({ value }) =>
		<span className={css('icon', !value.isActive && 'passive')}
			title={`${value.isActive ? 'Active' : 'Passive'
				} box in ${value.availableStorages.length} storages.`}>
			<Icon uri={value.hasAvailableStorages ? 'box' : 'box_transient'} />
			<BoxMark box={value} />
		</span>,
	content: ({ value }, context) => <div>
		<Block label="Mark">
			<span style={{ display: 'inline-block' }}><BoxMark box={value} /></span>
		</Block>
		<Block label="Permissions">
			<button onClick={value.refreshPermissions}>Refresh</button>
			{' '}{permissionLabel[value.permissions]}
		</Block>
		<Block label="Stored in" if={value.allStorages.length > 0}>
			<div className="links items">
				{value.allStorages.map(storage => <div key={storage.id}>
					<NavItemLink item={storage.item} context={context}
						level="compact" />
				</div>)}
			</div>
		</Block>
		{value.isActive && value.dirtyItemIds.length > 0 &&
			<Block label="Newer Items in other Boxes">
				<button onClick={value.updateItems} disabled={true}>Update</button>
				{' '}
				{enumerate(value.dirtyItemIds.map(id =>
					<Link nav={context.nav} itemId={id} />
				), ', ')}
			</Block>}
		<Block label="Actions">
			<button onClick={value.toggleActive}>
				{value.isActive ? 'Deactivate' : 'Activate'}</button>
			{value.isActive && <>
				{value.availableStorages.length > 1 && <>
					<button onClick={value.updateStorages} title=
						"Explicitly update all available storages with their newest items."
						disabled={!!value.status}>
						Update Storages</button>
				</>}
				{value.isWriteAllowed && <button onClick={value.transform}
					disabled={!!value.status}>Transform Items</button>}
				<Button itemId={value.id} params={{ place: value.id }}
					nav={context.nav}>
					Place Items</Button>
			</>}
			{value.status && <div>{value.status}</div>}
		</Block>
		<Block label="Reports" if={value.reports?.entries.length > 0}>
			<pre>{value.reports.entries.join('\n')}</pre>
			<button onClick={value.reports.clear}>Clear</button>
		</Block>
	</div>,
	editor: ({ name, label }, { values }, ctx, autoFocus) =>
		<Block label={label}>
			<TextInput label="Label" value={values[name]['label']}
				autoFocus={autoFocus} />
			<TextInput label="Mark" value={values[name]['mark']} />
			<TextInput label="Color" value={values[name]['color']} type="color" />
			<TextInput label="Background Color" value={values[name]['backColor']}
				type="color" />
		</Block>,
}
