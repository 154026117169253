import { setup as arango } from './arango'
import { setup as couch } from './couch'
import { setup as gitlab } from './gitlab'
import { setup as google } from './google'
import { setup as indexed } from './indexed'
import { setup as local } from './local'
import { setup as neo4j } from './neo4j'
import { setup as nextcloud } from './nextcloud'
import { setup as wikidata } from './wikidata'

export const setup = {
	couch, local, indexed, arango, gitlab, wikidata, google, nextcloud, neo4j
}
