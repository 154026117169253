import * as obj from './obj'
import * as str from './str'

// cspell:disable
// TODO: extend...
const commonFileExt: Record<string, string> = {
	'text/plain': 'txt',
	'text/calendar': 'ics',
	'text/javascript': 'js',
	'audio/webm': 'weba',
	'image/jpeg': 'jpg',
	'image/vnd.microsoft.icon': 'ico',
	'application/x-7z-compressed': '7z',
	'application/gzip': 'gz',
	'application/octet-stream': 'bin',
	'video/x-msvideo': 'avi',
	'application/msword': 'doc',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		'docx',
	'application/vnd.ms-excel': 'xls',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
	'application/vnd.mozilla.xul+xml': 'xul',
}
const commonFileTypes: Record<string, string> = {
	'jpeg': 'image/jpeg',
	'png': 'image/png',
	'gif': 'image/gif',
	'webp': 'image/webp',
}
// cspell:enable
for (const t of obj.keys(commonFileExt))
	commonFileTypes[commonFileExt[t]] = t

export function typeToFileExt(type: string) {
	if (type in commonFileExt)
		return commonFileExt[type]
	const sub = str.substringAfterLast(type, '/') ?? 'bin'
	// +xml, +zip,...
	const plusPos = sub.indexOf('+')
	if (plusPos > 0)
		return sub.substring(0, plusPos)
	// .rar, +zip,...
	const dotPos = sub.lastIndexOf('.')
	if (dotPos > 0)
		return sub.substring(dotPos + 1)
	// -sh, -php, -tar,...
	const dashPos = sub.lastIndexOf('-')
	if (dashPos > 0)
		return sub.substring(dashPos + 1)
	return sub
}

export function fileExtToType(pathname: string) {
	const ext = str.substringAfterLast(pathname, '.')
	return ext ? commonFileTypes[ext] ?? null : null
}

export function isJpeg(url: string) {
	const n = new URL(url).pathname
	return n.endsWith('.jpg') || n.endsWith('.jpeg')
}

