import { action, computed, observable } from './common'

export type Credentials = PasswordCredentials | TokenCredentials | OAuthCredentials

export interface PasswordCredentials {
	type: 'password'
	id: string
	password: string
}

export interface TokenCredentials {
	type: 'token'
	id: string
	token: string
	expires?: Date
	[ext: string]: any
}

export interface OAuthCredentials {
	type: 'oauth'
	id: string
	accessToken?: string
	refreshToken?: string
	expires?: Date
	[ext: string]: any
}

export interface CredentialsProvider {
	getCredentials(url: string): Credentials
}

export class Auth implements CredentialsProvider {
	@observable.shallow credentials = new Map<string, Credentials>()
	getCredentials(url: string) {
		const cred = this.credentials.get(url)
		return Auth.isExpired(cred) ? null : cred
	}
	@computed get allCredentials() {
		const obj: { [url: string]: Credentials } = {}
		for (const k of this.credentials.keys())
			obj[k] = this.credentials.get(k)
		return obj
	}
	static isExpired(cred: Credentials) {
		return cred && 'expires' in cred && (Date.now() - cred.expires.getTime()) > -5
	}
	setCredentials(url: string, cred: Credentials): this
	setCredentials(credentials: { [url: string]: Credentials }): this
	@action setCredentials(arg0: string | { [url: string]: Credentials },
		cred?: Credentials) {
		if (typeof arg0 === 'string') {
			// TODO: move to data layer
			if ('expires' in cred && typeof cred.expires === 'string')
				cred.expires = new Date(cred.expires)
			if (Auth.isExpired(cred))
				this.removeCredentials(arg0)
			else
				this.credentials.set(arg0, cred)
		} else {
			for (const url of Object.keys(arg0))
				this.setCredentials(url, arg0[url])
		}
		return this
	}
	removeCredentials(url: string): this {
		this.credentials.delete(url)
		return this
	}
}
