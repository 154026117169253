import { httpClient, O, reaction, U } from '../common'
import * as mdl from '../model'

type ApiConfig = {
	services?: {
		proxy?: string
	}
	google: {
		key: string
		clientId: string
		staticMap: string
		embedMap: string
		geocoding: string
		elevation: string
	}
	osm: {
		search: string
		reverse: string
	}
}

// ~11mm precision
export function roundDegrees(v: number) {
	return Math.round(v * 10 ** 7) / 10 ** 7
}
// 1cm precision
export function roundAltitude(v: number) {
	return Math.round(v * 10 ** 2) / 10 ** 2
}

interface LatLng {
	lat: number
	lng: number
}

export const setup = {
	coordinatesForAddress: ({ config }: { config: { api: ApiConfig } }) => {
		O.onInit(mdl.Property, (p: mdl.Property<mdl.LocationValue>) => {
			if (p.type !== 'location') return
			reaction(() => p.value, v => {
				if (v && v.address && !mdl.LocationValue.isValid(v)) {
					getCoordinates(config.api, v.address)
						.then(({ lat, lng }: LatLng) => {
							if (mdl.LocationValue.isValid(lat, lng))
								p.value = {
									...v,
									latitude: roundDegrees(lat),
									longitude: roundDegrees(lng)
								}
						})
				}
			}, { fireImmediately: true })
		})
	},

	altitude: ({ config }: { config: { api: ApiConfig } }) => {
		O.onInit(mdl.Property, (p: mdl.Property<mdl.LocationValue>) => {
			if (p.type !== 'location') return
			reaction(() => p.value, v => {
				if (v && typeof v.altitude !== 'number' &&
					mdl.LocationValue.isValid(v)) {
					getAltitude(config.api, v.latitude + ',' + v.longitude)
						.then(altitude => {
							if (typeof altitude === 'number')
								p.value = { ...v, altitude }
						})
				}
			}, { fireImmediately: true })
		})
	},

}

export async function getCoordinates(api: ApiConfig, address: string) {
	const http = httpClient()
	const results = await http.getJson(api.osm.search +
		'?format=json' + 'v2&q=' + encodeURIComponent(address))
	if (results?.length > 0) {
		const r = results[0]
		return { lat: r.lat, lng: r.lon } as LatLng
	}
}

export async function getAltitude(api: ApiConfig, coordinates: string) {
	const http = httpClient()
	const url = api.google.elevation +
		'?key=' + api.google.key +
		'&locations=' + coordinates
	const { results } = await http.getJson(api.services.proxy + '?url=' +
		encodeURIComponent(url))
	if (results?.length > 0) {
		const r = results[0]
		return roundAltitude(r.elevation)
	}
}

/** Get address for coordinates (latidude,longitude). */
export async function getAddress(api: ApiConfig, coordinates: string) {
	const http = httpClient()
	const { address } = await http.get(api.osm.reverse +
		'?format=json' + 'v2&lat=' + coordinates.replace(',', '&lon='))
	if (address) {
		const road = address.road ?? address.hamlet ?? address.county
		return [
			road ?? road + (address.house_number ? ' ' + address.house_number : ''),
			address.town ?? address.village ?? address.city,
			address.state, address.country,
		].filter(U.any.isTrue).join(', ')
	}
}

export function getMapImageUrl(api: ApiConfig, lat: number, long: number,
	address: string, width: number, height: number) {
	const m = typeof lat !== 'number' ? encodeURIComponent(address)
		: lat + ',' + long
	return api.google.staticMap +
		'?key=' + api.google.key +
		'&size=' + width + 'x' + height +
		'&markers=' + m
}

interface Props {
	address?: string
	title?: string
	id?: string
	latitude?: number
	longitude?: number
}

// While 'Some Address, Some City, 400m) seems to work, '47.27,8.6,400m' does
// not.
const positionAddressPattern = /^[-0-9\.]+,[-0-9\.]+,[-0-9\.]+m/

export function getMapEmbedUrl(api: ApiConfig,
	{ address, title, id, latitude, longitude }: Props) {
	const lat = latitude || 0
	const lng = longitude || 0
	const q = address ? positionAddressPattern.test(address) ?
		address.substring(0, address.lastIndexOf(',')) :
		encodeURIComponent(address) : lat + ',' + lng
	const attr = title ? title : address
	return api.google.embedMap +
		'?key=' + api.google.key +
		'&q=' + q +
		(q === '0,0' ? '&zoom=1' : '') +
		'&maptype=satellite' +
		(attr ? '&attribution_source=' + encodeURIComponent(attr) +
			'&attribution_web_url=' + 'https://test.alls.be/' + id : '')
}
