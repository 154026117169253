
import { setup as CreateItemView } from './CreateItemView'
import { setup as ItemView } from './ItemView'
import { setup as LogView } from './LogView'
import { setup as PlaceItemView } from './PlaceItemView'
import { setup as SearchView } from './SearchView'
import { setup as ViewItemView } from './ViewItemView'

export const setup = {
	CreateItemView, ItemView, PlaceItemView, SearchView,
	LogView, ViewItemView,
}
