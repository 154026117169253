import { O } from '../../common'
import { newItemsFromText } from '../../data/import'
import * as mdl from '../../model'
import { newItemWithTmpl } from '../newItemWithTmpl'

export const setup = {

	addNewItem: ({ items, log }:
		{ items: mdl.ItemManager, log: mdl.SystemLogger }) => {
		O.onInit(mdl.AddItemView, view => {
			view.addNewItem.start.react(async (tmplId, file) => {
				const { item, search } = view
				try {
					if (!tmplId && file)
						tmplId = 'image.tmpl'
					else if (!tmplId && !search.text)
						tmplId = 'list.tmpl'
					if (tmplId) {
						const tmpl = await items.requireItem(tmplId)
						const newItem = newItemWithTmpl(items, tmpl,
							{ file, text: search.text })
						await newItem.initNew()
						newItem.status = mdl.ItemStatus.level1
						await newItem.complete()
						if (tmplId in mdl.contentTmpl)
							item.content.add(newItem, -1, 'all')
						else
							item.links.add(newItem, 0, 'all')
						view.addNewItem.end(newItem.id)
					} else {
						const specs = newItemsFromText(search.text)
						const newItems =
							await items.createNewItem(specs.map(items.resolveItemRefs))
						if (newItems.length === 1 && newItems[0].tmpls.length === 1 &&
							newItems[0].tmpls.get(0).refId in mdl.contentTmpl)
							item.content.add(newItems, -1, 'all')
						else
							item.links.add(newItems, 0, 'all')
						view.addNewItem.end(newItems.length === 1 ? newItems[0].id : null)
					}
				} catch (err) {
					log.error(err)
				}
			})
		})
	},

}
