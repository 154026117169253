import { observer } from 'mobx-react'
import * as React from 'react'
import * as mdl from '../../model'

@observer
export class BoxMark extends React.Component<{ box: mdl.Box }> {

	render() {
		const { box } = this.props
		return <span className="mark"
			style={{ backgroundColor: box.backColor, color: box.color }}>
			{box.mark}</span>
	}

}

interface Props {
	item: mdl.Item
	nav?: mdl.Navigation
}

@observer
export class BoxMarks extends React.Component<Props> {

	render() {
		const { item } = this.props
		return item?.boxes.length > 0 ?
			<div onClick={this.onClick} className="marks">
				{item.boxes.map(box => <BoxMark key={box.id} box={box} />)}
			</div> : null
	}

	onClick = (evn: React.MouseEvent<HTMLDivElement>) => {
		if (evn.button == 0 && !evn.ctrlKey && !evn.metaKey) {
			const { nav, item } = this.props
			if (nav) {
				nav.go('place', item.id)
				evn.preventDefault()
			}
		}
	}
}
