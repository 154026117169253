import { basicAuth, O, readText, request, U } from '../../common'
import * as mdl from '../../model'

export const setup = {

	accountAccess: ({ config, log }:
		{ config: mdl.Config, log: mdl.SystemLogger }) => {
		O.onInit(mdl.Account, account => {
			account.readUser.react(async (credentials: mdl.PasswordCredentials) => {
				// credentials = { type: 'password', id: 'bruno', password: 'bgr3AS,kcd' }
				if (account.provider !== 'nextcloud')
					return
				const url = account.url + '/remote.php/dav/files/' + credentials.id
				const data = await request(U.url.addParams(config.api.services.proxy,
					{ url }), {
					// const data = await request(U.url.addParams('http://localhost:2281/proxy',
					// 	{ url }), {
					// const data = await request(url, {
					headers: {
						Authorization: basicAuth(credentials)
					},
					method: 'PROPFIND'
				}).then(readText)
				if (!data)
					throw new Error(`Nextcloud user is not allowed to access!`)
			})

		})
	},

}
