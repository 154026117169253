import { AccountsView } from './center/AccountsView'
import { Constructor } from './common'
import * as views from './views'

export type View = views.AddItemView | views.ViewItemView |
	views.EditItemView | views.PlaceItemView | views.CreateItemView |
	views.SearchView | views.DetailsView | views.LoginView | AccountsView |
	views.TimelineView

export type ViewKey = View['key'] | 'move'

// map view key to view type
const viewTypeMap: { [k in ViewKey]?: Constructor<View> } =
	{ move: views.ViewItemView }
for (const k of Object.keys(views)) {
	const t = views[k]
	const v = new t()
	if ('key' in v) viewTypeMap[v.key] = t
}

export function getViewType(key: ViewKey) {
	return viewTypeMap[key] ?? views.ViewItemView
}
