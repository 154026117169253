import { ObjectType } from "../common"
import * as any from './any'
import * as array from './array'
import * as date from './date'

export function asEnumerableProperties(obj: any, level = 1,
	transform = (member: any, key: string) => member) {
	if (!obj || typeof obj !== 'object' || level <= 0) return obj
	const res = {} as any
	for (const k of Object.getOwnPropertyNames(obj)) {
		const v = transform(obj[k], k)
		if (v !== void 0)
			res[k] = asEnumerableProperties(v, level - 1, transform)
	}
	return res
}

/** Mask an object with the members of another object (maskObj).
 * Array values of the mask get appended to the values of the objects member. 
 */
export function mask<T, M>(obj: T, maskObj: M): T {
	if (obj === void 0 || obj === null)
		return maskObj as any
	if (maskObj === void 0)
		return obj
	if (Array.isArray(obj))
		return Array.isArray(maskObj) ? [...obj, ...maskObj] : maskObj !== void 0 ?
			[...obj, maskObj] : [...obj] as any
	if (typeof obj === 'object') {
		const res: Record<string, any> = {}
		for (const k of Object.keys(obj)) {
			const v = (obj as any)[k]
			if (k in maskObj) {
				const m = (maskObj as any)[k]
				res[k] = mask(v, m)
			}
			else
				res[k] = v
		}
		for (const k of Object.keys(maskObj)) {
			if (!(k in obj))
				res[k] = (maskObj as any)[k]
		}
		return res as T
	}
	return maskObj as any
}

export function assignDeep<T extends {}>(target: T, src: {}) {
	for (const k of keys(src)) {
		const v = src[k]
		if (k in target && typeof v === 'object')
			assignDeep(target[k], v)
		else
			target[k] = v
	}
	return target
}

/** deprecated! */
export function equalsDeep(objA: any, objB: any) {
	if (!objA || !objB) return !objA == !objB
	if (typeof objA !== 'object') return objA == objB
	for (const k in objA) {
		const a = objA[k]
		if (!(k in objB)) return a === void 0
		if (!equalsDeep(a, objB[k])) return false
	}
	return true
}

/** Compare two values (objects, arrays, primitives) to be deeply equal.
 * Ignore undefined object properties or array elements.
 * Circular references are not allowed!
 */
export function deepEquals<T = any>(a: T, b: T,
	excludes?: (string | number)[] | { [k: string | number]: any; }) {
	if (a === b)
		return true
	if (any.isPrimitive(a))
		return a === b
	if (any.isPrimitive(b))
		return false
	if (excludes && Array.isArray(excludes))
		excludes = array.toObject(excludes)
	const keysA = Object.keys(a)
		.filter(k => !(excludes && k in excludes))
		.filter(k => (a as any)[k] !== void 0)
	const keysB = Object.keys(b)
		.filter(k => !(excludes && k in excludes))
		.filter(k => (b as any)[k] !== void 0)
	if (keysA.length !== keysB.length)
		return false
	for (const k of keysA) {
		if (!(k in b))
			return false
		if (!deepEquals((a as any)[k], (b as any)[k],
			excludes))
			return false
	}
	return true
}

/** Filter object member values into a new object. */
export function filterMembers<T extends object>(obj: T,
	fn: (key: keyof T, v: T[keyof T], obj: T) => boolean) {
	if (!obj) return
	const res = {} as Partial<T>
	for (const k of keys(obj)) {
		const v = obj[k]
		if (fn(k as keyof T, v, obj))
			res[k] = v
	}
	return res
}

export function findMemberValue<T extends {}, K extends keyof T>(src: T,
	fn: (v: T[K], k: K, obj: T) => boolean) {
	for (const k of Object.keys(src) as K[])
		if (fn(src[k], k, src))
			return src[k]
	return null
}

/** Map object member values into a new object. */
export function mapMembers<T extends object, V>(obj: T,
	fn: (v: T[keyof T], key: keyof T, obj: T) => V) {
	if (!obj) return
	const res: { [k in keyof T]: V } = {} as any
	for (const k of keys(obj))
		res[k] = fn(obj[k], k as keyof T, obj)
	return res
}

/** Find object member. */
export function findMember<T extends object>(obj: T,
	fn: (v: T[keyof T], key: keyof T, obj: T) => boolean) {
	if (!obj) return
	for (const k of keys(obj)) {
		if (fn(obj[k], k as keyof T, obj))
			return { key: k, value: obj[k] }
	}
	return null
}

/** Map object member keys into a new object. */
export function mapMemberKeys<T extends object>(obj: T,
	fn: (key: keyof T, v: T[keyof T], obj: T) => string)
	: { [k: string]: T[keyof T] }
export function mapMemberKeys<T extends object>(obj: T,
	fn: (key: keyof T, v: T[keyof T], obj: T) => number)
	: { [k: number]: T[keyof T] }
export function mapMemberKeys<T extends object>(obj: T,
	fn: (key: keyof T, v: T[keyof T], obj: T) => string | number) {
	if (!obj) return
	const res: { [k: string]: T[keyof T] } = {} as any
	for (const k of keys(obj))
		res[fn(k as keyof T, obj[k], obj)] = obj[k]
	return res
}

/** Swap object member keys with their values. Result for duplicate values
 * is not defined. Requires to provide values valid as keys.
 */
export function swapMembersWithValues(obj: {}): any {
	const res = {}
	for (const k of keys(obj)) res[obj[k]] = k
	return res
}

/** Deletes members with undefined values in-place.
 * TODO: implement multilevel filter instead
 */
export function cleanupMembers<T>(obj: T, level = 1): T {
	if (!obj || level < 0)
		return obj
	if (Array.isArray(obj)) {
		for (let i = obj.length - 1; i >= 0; --i) {
			if (obj[i] === void 0) (obj as T[]).splice(i, 1)
			else cleanupMembers(obj[i], level - 1)
		}
	} else if (typeof obj === 'object') {
		for (const k of keys(obj as unknown as object)) {
			if (obj[k] === void 0) delete obj[k]
			else cleanupMembers(obj[k], level - 1)
		}
	}
	return obj
}

const _isWalking = '_isWalking'

// no cyclic references!
export function deepCopyJson(obj: any): any {
	return JSON.parse(JSON.stringify(obj))
}

export function clone(obj: any): any {
	const clone = _clone(obj)
	_cleanupWalk(obj)
	return clone
}

function _clone(obj: any): any {
	if (obj === null || typeof obj !== 'object')
		return obj
	if (obj.constructor === Date || obj.constructor === RegExp ||
		obj.constructor === Function || obj.constructor === String ||
		obj.constructor === Number || obj.constructor === Boolean)
		return new obj.constructor(obj)
	if (_isWalking in obj)
		return obj[_isWalking]
	const clone = new obj.constructor()
	obj[_isWalking] = clone
	for (let k in obj)
		if (k !== _isWalking)
			clone[k] = _clone(obj[k])
	return clone
}

export function diff(obj1: any, obj2: any): any {
	const obj = _diff(obj1, obj2)
	_cleanupWalk(obj1)
	return obj || {}
}

function _diff(obj1: any, obj2: any): any {
	if (obj1 === obj2)
		return
	const t1 = typeof obj1
	const t2 = typeof obj2
	if (t1 === 'function' || t2 === 'function')
		return
	if (t1 === 'undefined')
		return { old: obj2 }
	if (t2 === 'undefined')
		return { new: obj1 }
	if (obj1 === null || t1 === 'string' || t1 === 'number' ||
		t1 === 'boolean' || t1 === 'symbol')
		return { new: obj1, old: obj2 }
	if (obj2 === null || t2 === 'string' || t2 === 'number' ||
		t2 === 'boolean' || t2 === 'symbol')
		return { new: obj1, old: obj2 }
	if (_isWalking in obj1)
		return
	obj1[_isWalking] = true
	const obj: any = {}
	let isEmpty = true
	for (let k in obj1) {
		if (k === _isWalking)
			continue
		const v = _diff(obj1[k], obj2[k])
		if (v !== void 0) {
			obj[k] = v
			isEmpty = false
		}
	}
	for (let k in obj2) {
		if (k in obj1)
			continue
		const v = _diff(obj1[k], obj2[k])
		if (v !== void 0) {
			obj[k] = v
			isEmpty = false
		}
	}
	return isEmpty ? void 0 : obj
}

// Clear a specific member within an object.
// Also removes members with empty objects or undefined values.
export function deepClearMember(obj: any, memberName: string): any {
	obj = clone(obj)
	_deepClearMember(obj, memberName)
	_cleanupWalk(obj)
	return obj
}

function _deepClearMember(obj: any, memberName: string) {
	if (obj === void 0)
		return true
	if (typeof obj !== 'object')
		return false
	// TODO: clean up references to possible empty walked objects
	if (_isWalking in obj)
		return false
	obj[_isWalking] = true
	let isEmpty = true
	for (let k in obj) {
		if (k === _isWalking)
			continue
		if (k === memberName)
			delete obj[k]
		else {
			if (_deepClearMember(obj[k], memberName))
				delete obj[k]
			else
				isEmpty = false
		}
	}
	return isEmpty
}

function _cleanupWalk(obj: any) {
	if (typeof obj === 'object' && _isWalking in obj) {
		delete obj[_isWalking]
		for (let k in obj)
			_cleanupWalk(obj[k])
	}
}

export function isEmptyObject(obj: any): boolean {
	for (let k in obj)
		return false
	return true
}

export function copyValues(source: any, destination: any): any {
	for (var key in source) {
		if (key[0] == '_')
			continue
		var v = source[key]
		if (ObjectType.isValue(v))
			destination[key] = v
	}
	return destination
}

export function copyMembers<T>(source: T, destination: Partial<T>,
	...keys: (keyof T)[]) {
	for (const k of keys)
		if (k in source && source[k] !== void 0)
			destination[k] = source[k]
	return destination
}

export function key2Val<T extends Record<string, string>>(obj: T): T {
	for (let k of Object.keys(obj))
		(obj as any)[k] = k
	return obj
}

export function exchangeKeyVal<T extends Record<string, string>>(obj: T) {
	const res: Record<string, string> = {}
	for (let k of Object.keys(obj))
		res[obj[k]] = k
	return res
}

export function createObj(constructorFn: any, args?: any[]) {
	if (constructorFn === String)
		return args && args.length > 0 && args[0] ? args[0] : ''
	if (constructorFn === Number)
		return args && args.length > 0 && args[0] ? args[0] : 0
	if (constructorFn === Boolean)
		return args && args.length > 0 && args[0] ? args[0] : false
	if (constructorFn === Array)
		return args ? args : []
	if (args)
		return new (constructorFn.bind.apply(constructorFn, [null].concat(args)))
	return new constructorFn()
}

/** Type agnostic Object.keys() */
export const keys = Object.keys as <T extends object>(obj: T) => (keyof T)[]

export function mapKeys<T extends {}>(obj: T,
	fn: (k: keyof T, v: T[keyof T]) => string) {
	const res: Record<string, T[keyof T]> = {}
	for (const k of keys(obj)) {
		const v = obj[k]
		res[fn(k, v)] = v
	}
	return res
}

export function getAllPropertyNames(obj: any) {
	if (!obj || typeof obj !== 'object')
		return []
	const res: Record<string, any> = {}
	while (obj) {
		for (const k of Object.getOwnPropertyNames(obj))
			res[k] = 1
		obj = Object.getPrototypeOf(obj)
	}
	return Object.keys(res)
}

/** Enumerate object members as a comma separated string.
 * Values can be primitives and single member objects/arrays.
 */
export function enumerateMembers(obj: any, indent?: string) {
	return !obj ? '' :
		Object.keys(obj).map(k => k + ': ' + any.stringify(obj[k], indent))
			.join(indent ? ',\n' : ', ')
}

/** Parse a comma separated string into object members.
 * Values can be primitives and single member objects/arrays.
 */
export function parseMembers(str: string, obj: Record<string, any> = {}) {
	for (const s of str.split(',')) {
		if (str) {
			const idx = s.indexOf(':')
			const m = s.substring(0, idx).trim()
			const v = s.substring(idx + 1).trim()
			const l = v.length - 1
			const v0 = v.charAt(0)
			const vL = v.charAt(l)
			obj[m] = v0 === '{' && vL === '}' ?
				parseMembers(v.substring(1, l)) :
				date.isoDatePattern.test(v) ? new Date(v.substring(1, l)) :
					v0 === "'" && vL === "'" ?
						JSON.parse('"' + v.substring(1, l).replace(/"/g, "'") + '"') :
						JSON.parse(v)
		}
	}
	return obj
}

export function excludeMembers<T extends {}, K extends keyof T>(src: T,
	...keys: K[]) {
	const res = {} as Partial<T>
	for (const k of Object.keys(src) as K[]) {
		if (!keys.includes(k))
			res[k] = src[k]
	}
	return res
}

/** Returns a member object of the given object. 
 * If missing an empty one is created. 
 */
export function memberObject<T extends {}, K extends keyof T>(obj: T,
	key: K) {
	if (!(key in obj))
		(obj[key] as any) = {}
	return obj[key]
}

export function filterMembersDeep<T extends {}>(src: T,
	fn: (k: keyof T, v: T[keyof T], obj: T) => boolean) {
	if (typeof src !== 'object')
		return src
	const res = {} as Partial<T>
	for (const k of keys(src)) {
		const v = src[k]
		if (fn(k, v, src))
			res[k] = filterMembersDeep(v as any, fn) as any
	}
	return res
}

/** Deletes specified members in the specified object. */
export function deleteMembers<T extends {}, K extends keyof T>(obj: T, ...keys: K[]) {
	for (const k of keys)
		delete obj[k]
	return obj
}

export function findMemberKey<T extends {}, K extends keyof T>(src: T,
	fn: (v: T[K], k: K, obj: T) => boolean) {
	for (const k of Object.keys(src) as K[])
		if (fn(src[k], k, src))
			return k
	return null
}

export function keyWithMaxValue<T extends object>(obj: T) {
	let max: any, key: keyof T | null = null
	for (const k of keys(obj)) {
		const v = obj[k]
		if (max === void 0 || v > max) {
			max = v
			key = k
		}
	}
	return key
}

export function valueCounts(values: string[]): { [k: string]: number; };
export function valueCounts(values: number[]): { [k: number]: number; };
export function valueCounts(values: any[]) {
	return values.reduce((counts, v) =>
		({ ...counts, [v]: (counts[v] || 0) + 1 }), {})
}

export function valueWithMaxCount<T extends string | number>(values: T[]) {
	return keyWithMaxValue(valueCounts(values as any)) as T
}

/** String key Map to object. Allows to map the values also. */
export function mapToObject<V>(src: Map<string, V>, fn?: (v: V, k: string) => any) {
	const res = {} as { [k: string]: any; }
	for (const k of src.keys())
		res[k] = fn ? fn(src.get(k) as V, k) : src.get(k)
	return res
}

export function toId<T>(obj: { id: T; }) {
	return obj ? obj.id : null
}

export type KeyMap<T, D> = {
	[key in keyof D]?: keyof T;
} & { _?: (key: keyof D) => keyof T };
export type ValMap<D> = {
	[key in keyof D]?: (val: D[key]) => any;
};
export type Keys<T> = (keyof T)[];
export interface Meta<T, D> {
	keyMap?: KeyMap<T, D>
	valMap?: ValMap<D>
	mandatory?: Keys<T>

}

export function assign<T, D = {}>(obj: T, data: D,
	keyMap?: KeyMap<T, D>, valMap?: ValMap<D>) {
	for (const k of Object.keys(data) as (keyof D)[]) {
		const key: keyof T = keyMap && k in keyMap ? keyMap[k] :
			keyMap && "_" in keyMap && keyMap._ ? keyMap._(k) : (k as any);
		obj[key] = valMap && k in valMap && typeof valMap[k] === "function" ?
			(valMap[k] as any)(data[k]) : data[k];
	}
	return obj;
}

export function mandatory<T, K extends keyof T>(obj: T, ...propertyNames: K[]) {
	for (const n of propertyNames) {
		if (!(n in obj))
			throw new Error(`Property ${String(n)} missing in ${JSON.stringify(obj)}`);
	}
}

interface ModelClass<T, D> {
	new(): T
	meta?: Meta<T, D>
}

export function fromJson<T, D>(data: D, cls: ModelClass<T, D>) {
	// merge meta information along the super classes
	const metaChain: Meta<T, D>[] = []
	let s = cls
	while (s) {
		if ('meta' in s && s.meta)
			metaChain.push(s.meta)
		s = Object.getPrototypeOf(s)
	}
	const meta = { keyMap: {}, valMap: {}, mandatory: [] as Keys<T> }
	for (let i = metaChain.length - 1; i >= 0; --i) {
		const m = metaChain[i]
		if ('keyMap' in m)
			meta.keyMap = { ...meta.keyMap, ...m.keyMap }
		if ('valMap' in m)
			meta.valMap = { ...meta.valMap, ...m.valMap }
		if ('mandatory' in m && m.mandatory)
			meta.mandatory = [...meta.mandatory, ...m.mandatory]
	}
	const obj = new cls();
	assign(obj, data, meta.keyMap, meta.valMap);
	if (meta.mandatory)
		mandatory(obj, ...meta.mandatory);
	return obj;
}
