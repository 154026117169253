import { U } from '../common'

export interface ItemData {
	id: string
	rev?: number
	create?: LogData
	update?: LogData
	props?: { [name: string]: any }
	containerId?: string
	content?: ItemLinkData[]
	links?: ItemLinkData[]
	layoutId?: string
	tmpls?: string[]
	tags?: string[]
	boxes?: string[]
	// possible storage conflicts
	conflicts?: any[]
	// storage info
	_info?: any
	// deleted marker
	isDeleted?: boolean
}

export namespace ItemData {
	export function cleanup(data: ItemData) {
		return U.obj.cleanupMembers({
			...data,
			links: ItemLinkData.cleanup(data.links),
			content: ItemLinkData.cleanup(data.content),
			tmpls: ItemLinkData.cleanup(data.tmpls),
		} as ItemData)
	}
}

export interface ItemData3 {
	id: string
	parents?: string[]
	props?: PropertyData[]
	content?: ItemLinkData[]
	links?: ItemLinkData[]
	layoutId?: string
	tmpls?: string[]
}

export interface LogData {
	date?: string
	position?: PositionData | { coords: GeolocationCoordinates }
	userId?: string
	installationId?: string
}

export type PropertyData = {
	name: string
	value: string | number | boolean | Object
	type?: string
	label?: string
}

export type ItemLinkData = string |
{ url: string, name?: string, preview?: string }

export namespace ItemLinkData {

	export function url(d: ItemLinkData) {
		return d && typeof d === 'object' ? d.url : d as string
	}

	export function cleanup(data: ItemLinkData[]) {
		return data?.filter(d => d && (typeof d === 'string' || !!d.url))
	}

	export function toIds(data: ItemLinkData[]) {
		return data?.map(d => !d || typeof d === 'string' ? d : d.url) as string[]
	}
}

export interface PositionData {
	/** Latitude in decimal degrees */
	lat: number
	/** Longitude in decimal degrees */
	long: number
	/** Accuracy in meters */
	accuracy?: number
	/** Altitude in meters above sea level */
	alt?: number
	/** Altitude accuracy in meters */
	altAccuracy?: number
	/** Traveling direction in degrees (0° north, 90° east,
	 * 180° south, 270° west) */
	heading?: number
	/** Traveling speed in m/s */
	speed?: number
}

export function coordsToPosition(coords: GeolocationCoordinates): PositionData {
	return {
		lat: coords.latitude, long: coords.longitude,
		...U.obj.filterMembers({
			accuracy: Math.round(coords.accuracy * 100) / 100,
			alt: Math.round(coords.altitude * 100) / 100,
			altAccuracy: Math.round(coords.altitudeAccuracy * 100) / 100,
			heading: Math.round(coords.heading),
			speed: Math.round(coords.speed)
		}, (k, v) => typeof v === 'number' && !isNaN(v))
	}
}

