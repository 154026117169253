import { action, O } from '../../common'
import * as mdl from '../../model'

let loginWnd: Window
let msgListener: (evn: MessageEvent) => void

function clear() {
	window.removeEventListener('message', msgListener, false)
	msgListener = null
	loginWnd = null
}

export const setup = {

	addAccount: ({ config }: { config: { api: { auth: string } } }) => {
		O.onInit(mdl.AccountsView, view => {
			view.addAccount.react(provider => {
				if (loginWnd && !loginWnd.closed) {
					loginWnd.focus()
				}
				else {
					if (loginWnd) clear()
					loginWnd = window.open(config.api.auth, 'login',
						'width=800,height=600,top=100,left=200,menubar=no,toolbar=no')
					msgListener = evn => {
						// console.log(evn, config.auth)
						if (evn.source !== loginWnd ||
							!config.api.auth.startsWith(evn.origin))
							return
						const { data } = evn
						// TODO: implement
						if (!view.accounts.find(a => a.id === data.sub)) {
							action(() => {
								// const a = O.new(mdl.Account)
								// a.id = data.sub
								// a.label = data.name
								// view.accounts.push(a)
							})()
						}
						const wnd = evn.source
						wnd.close()
						clear()
					}
					window.addEventListener('message', msgListener, false)
				}
			})
		})
	},

}
