import { action } from '../common'
import * as mdl from '../model'

export const setup = {

	close: ({ ui }: { ui: mdl.UserInterface }) => {
		const close = (evn: Event) => {
			if (ui.activeComponent) {
				action('close component', () => { ui.activeComponent = null })()
				// remove focus from buttons and alike
				void (document.activeElement as HTMLElement).blur()
			}
		}
		document.addEventListener('click', evn => {
			if (ui.activeComponent) {
				const previous = ui.activeComponent
				setTimeout(() => {
					if (ui.activeComponent === previous) close(evn)
				})
			}
		})
		window.addEventListener('blur', close)
	},

}
