import { textImporters } from './common'

// example:
// eslint-disable-next-line max-len
// Schänis Walking - Check out my walking activity on Garmin Connect. #beatyesterday https://connect.garmin.com/modern/activity/6715402634?share_unique_id=4

textImporters.push(newItemsForGarminShare)

function newItemsForGarminShare(txt: string) {
	const idx = txt.indexOf('https://connect.garmin.com/')
	if (idx < 1)
		return null
	const q = txt.indexOf('?', idx)
	const url = q > 0 ? txt.substring(idx, q) : txt.substring(idx)
	return [{ props: { url: { url } }, tmpls: [{ ref: 'url.tmpl' }] }]
}
