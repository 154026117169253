import { Auth } from './Auth'
import { computed, observable } from './common'
import { Item } from './Item'

export class Session {
	@observable user: Item
	@observable userBoxItem: Item
	@observable isReady = false
	@computed get needsLogin() {
		return !this.user
	}
	auth = new Auth()
	@observable config = {}
}
