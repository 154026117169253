
/** Service to provide some data for some optional input arguments.
 * Providers can be registered to provide the data. When multiple providers
 * are available the first to return defined data or a Promise (incl. 
 * Promise<void>) wins.
 * Providers may provide data directly or as Promises.
 * If no provider is available or non returns defined data, an optional 
 * default provider is consulted.
 */
type ProviderFn = (...args: any) => Promise<any> | any
type Disposer = () => void
export type Service<F extends ProviderFn> = F & {
	provide(provider: F): Disposer
}

export function service<F extends ProviderFn>(
	defaultProvider?: F) {
	const providers: F[] = []
	const service = <Service<F>>function(...args) {
		for (const provider of providers) {
			const r = provider(...args)
			// the first provider wins
			if (r !== void 0) return r
		}
		if (defaultProvider)
			return defaultProvider(...args)
	}
	service.provide = (provider: F) => {
		providers.push(provider)
		return () => {
			const idx = providers.indexOf(provider)
			if (idx >= 0) providers.splice(idx, 1)
		}
	}
	return service
}

