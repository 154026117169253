import * as React from 'react'
import {
	action, Component, convert, FormValue, formValues, observable,
	reaction, TextInput
} from '.'
import * as mdl from '../../model'

interface Props {
	label?: string
	value: FormValue<mdl.Property<mdl.NumberValue>, 'value'>
	autoFocus?: boolean
}

export class NumberEditor extends Component<Props> {

	vals: { number: FormValue, unit: FormValue }

	render() {
		const { label, value, autoFocus } = this.props
		const model: mdl.NumberValue = observable(typeof value.val === 'number' ?
			{ unit: '', number: value.val } : { unit: '', ...value.val })
		this.vals = formValues(model, { unit: 1, number: convert.number })
		this.vals.number.autoSubmit(true)
		this.vals.unit.autoSubmit(true)
		reaction(() => ({ ...model }),
			data => { value.val = data.unit ? data : data.number })
		reaction(() => value.val, action(v => { Object.assign(model, v) }))
		return <div className="field-group">
			<TextInput label={label || 'Value'} value={this.vals.number} type="number"
				autoFocus={autoFocus} />
			<TextInput label="Unit" value={this.vals.unit} />
		</div>
	}
}
