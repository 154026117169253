import { httpClient } from '../common'
import * as mdl from '../model'

type ApiConfig = mdl.Config['api']

export function getExternalSearchUrl(api: ApiConfig, q: string) {
	return `${api.google.search}?q=${q}&tbm=isch`
}

export function getSearchUrl(api: ApiConfig, q: string) {
	return `${api.google.imageSearch.url}?q=${q
		}&cx=${api.google.imageSearch.id}&searchType=image&key=${api.google.key}`
}

interface SearchData {
	items: {
		link: string
		image: {
			thumbnailLink: string
			width: number
			height: number
		}
	}[]
}

export async function searchImages(config: ApiConfig, q: string) {
	const http = httpClient()
	const data = await http.get<SearchData>(getSearchUrl(config, q))
	return data.items.map(item => ({
		link: item.link, thumbnail: item.image.thumbnailLink,
		width: item.image.width, height: item.image.height,
	}))
}
