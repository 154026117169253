import * as mdl from '..'

export namespace QuestionLayout {

	export function getContentProps(item: mdl.Item) {
		const toggleProp = mdl.ToggleAction.getPropToToggle(item)
		return item.props.content
			.filter(p => p.type !== 'image' && p !== toggleProp)
	}

	export function getFirstComment(item: mdl.Item) {
		return item.content.available
			.find(ln => ln.item.tmpls.has('comment.tmpl'))?.item
			?? item.content.available[0]?.item
	}

	export function getIconUri(item: mdl.Item): string {
		const toggleProp = mdl.ToggleAction.getPropToToggle(item)
		return toggleProp?.value ? 'answered' : 'question'
	}

}
