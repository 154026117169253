import { U } from '../common'
import * as mdl from '../model'
import { propertyToData } from './properties'

export function buildData(item: mdl.Item, inclMeta = true) {
	if (!item) return null
	const data: mdl.ItemData = { id: item.id, props: {} }
	for (const name of item.props.keys()) {
		const p = item.props.get(name)
		if (p.value || p.value === 0 || p.value === false) {
			const d = propertyToData(p)
			data.props[name] = p.hidden ? typeof d === 'object' ?
				{ ...d, hidden: true } : { [p.type]: d, hidden: true } : d
		}
	}
	if (item.layoutId)
		data.layoutId = item.layoutId
	if (item.create.isValid)
		data.create = buildLog(item.create)
	if (item.container)
		data.containerId = item.container.id
	if (!item.content.isEmpty)
		data.content = getLinks(item.content)
	if (!item.links.isEmpty)
		data.links = getLinks(item.links)
	if (!item.tmpls.isEmpty)
		data.tmpls = item.tmpls.map(ln => ln.item?.id ?? ln.url)
	if (inclMeta) {
		addUpdateLog(data, item)
		if (item.rev)
			data.rev = item.rev
	}
	if (item.isDeleted)
		data.isDeleted = true
	return data
}

function getLinks(links: mdl.Links): mdl.ItemLinkData[] {
	return links.map(ln => {
		const itm = ln.item
		const ref = itm ? itm.id : ln.url
		return ref && ln.preview === 'normal' && !ln.name ? ref
			: {
				preview: ln.preview === 'normal' ? void 0 : ln.preview,
				name: ln.name,
				url: ref,
			}
	}).filter(U.any.isTrue)
}

function buildLog(log: mdl.Log) {
	const data: any = {}
	if (U.date.isDate(log.date)) data.date = log.date.toISOString()
	U.obj.copyMembers(log, data, 'position', 'installationId', 'userId')
	return data
}

export function addUpdateLog(data: mdl.ItemData, item: mdl.Item) {
	if (item.update.isValid) data.update = buildLog(item.update)
	return data
}
