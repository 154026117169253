import * as React from 'react'

export function CancelClick({ children }: { children: React.ReactNode }) {
	return <span onClick={cancelClick}>
		{children}
	</span>
}

export function cancelClick(evn: React.MouseEvent<Element>) {
	evn.stopPropagation()
	evn.preventDefault()
}
