import { Item } from '../Item'
import { computed, O, observable, ViewBase } from './common'

export class SearchResultSection {
	@observable results: Item[] = []
	constructor(public label: string) { }
}

export class Search {
	@observable text = ''
	@observable resultSections: SearchResultSection[] = []
	@computed get firstAvailableResultSection(): SearchResultSection {
		return this.resultSections.find(s => s.results.length > 0)
	}
}

export class SearchView extends ViewBase {
	readonly key = 'search'
	@computed get isReady() { return true }
	@computed get label() { return 'Search' }
	search = O.new(Search)
	shortcutItems: Item[]
}

export function searchItems(q: string, items: Item[]) {
	if (!items) return []
	if (!q) return items
	const txt = q.toLowerCase()
	return items
		.filter(itm => itm.searchText.toLowerCase().includes(txt))
}
