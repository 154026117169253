import * as React from 'react'
import * as mdl from '../../model'
import { Component, formValue, observer, TextInput } from '../common'

interface Props {
	dlg: mdl.GoogleContactsDialog
}

@observer
export class GoogleContacts extends Component<Props> {

	render() {
		const { dlg, dlg: { contacts, filteredContacts } } = this.props
		return <div className="full">
			<header>Choose Contact</header>
			{contacts ? <section className="content contacts">
				<TextInput value={formValue(dlg, 'filter').autoSubmit(true)}
					autoComplete="false" placeholder="🔍" />
				{filteredContacts.map(c =>
					<Contact key={c.id} contact={c} dlg={dlg} />
				)}
			</section>
				: <section>-- please load contacts --</section>}
			<footer>
				<button onClick={dlg.load} disabled={!dlg.isReady}>
					Load Google Contacts</button>
				<button onClick={dlg.close}>Close</button>
			</footer>
		</div>
	}

}

interface ContactProps {
	contact: mdl.GoogleContact
	dlg: mdl.GoogleContactsDialog
}

class Contact extends Component<ContactProps> {

	render() {
		const { contact } = this.props
		return <div onClick={this.onSelect}>
			{contact.photos && contact.photos.length > 0 ?
				<img src={contact.photos[0].url} /> :
				<div className="photo">{contact.name.charAt(0).toUpperCase()}</div>}
			{contact.name}
		</div>
	}

	onSelect = () => { this.props.dlg.select(this.props.contact) }

}
