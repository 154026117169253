
export function encode(str: string): string {
	return str ? typeof btoa === 'function' ? btoa(str) :
		Buffer.from(str).toString('base64') : ''
}

export function decode(base64: string): string {
	return base64 ? typeof atob === 'function' ? atob(base64) :
		Buffer.from(base64, 'base64').toString('utf-8') : ''
}

