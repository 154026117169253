import * as React from 'react'
import { ItemContent, ItemIcon } from '../LayoutViews'
import { PropIcon } from '../PropertyViews'
import { Layout } from './common'

export const text: Layout = {
	icon: (item, context) => {
		const prop = item.props.icon ?? item.props.findByType('icon')
		return prop ? <PropIcon prop={prop} context={context} /> :
			null
	},
	links: {
		small: (item, context) => <>
			{item.props.icon && <ItemIcon item={item} context={context} />}
			<div>
				{item.props.title && <h6>{item.props.title.value}</h6>}
				<ItemContent item={item} context={context} />
			</div>
		</>,
	},
}
