import { computed, observable, signal } from '../../common'
import { Box } from '../Box'
import { Item } from '../Item'
import { ItemView } from './ItemView'

export class PlaceItemView extends ItemView {
	readonly key = 'place'
	@observable items: Item[]
	@computed get itemsToPlace() { return this.items ? this.items : [this.item] }
	@computed get isReady() {
		return !!this.item?.isReady && !!this.allBoxes
	}
	allBoxes: Box[]
	itemsInBox(box: Box) {
		return this.itemsToPlace.reduce(
			(s, itm) => s + (itm.isInBox(box) ? 1 : 0), 0)
	}
	toggleBox = signal<(box: Box) => Promise<void>>()
}
