import * as React from 'react'
import * as mdl from '../../model'
import { cancelClick, Icon, observer } from '../common'
import { ItemLabel } from '../LayoutViews'
import { PropLabel } from '../PropertyViews'
import { Layout } from './common'

export const todo: Layout = {
	icon: item =>
		<TodoIcon item={item} />,
	links: {
		small: (item, context) =>
			<div><ItemLabel item={item} context={context} /></div>,
		compact: (item, context) => <>
			<TodoIcon item={item} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
			</div>
		</>,
		normal: (item, context) => <>
			<TodoIcon item={item} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{mdl.TodoLayout.getContentProps(item).slice(0, 1).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
		extended: (item, context) => <>
			<TodoIcon item={item} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{mdl.TodoLayout.getContentProps(item).slice(0, 3).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
		full: (item, context) => <>
			<TodoIcon item={item} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{mdl.TodoLayout.getContentProps(item).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
	},
}

const TodoIcon = observer(({ item }: { item: mdl.Item }) =>
	<Icon uri={mdl.TodoLayout.getIconUri(item)}
		onClick={(evn) => {
			cancelClick(evn)
			mdl.ToggleAction.getActionProp(item)?.()
		}} />
)
