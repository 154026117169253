import * as mdl from '../../../model'

export interface UrlInfo {
	title?: string
	url?: string
	description?: string
	icon?: string | { icon: string, source?: string }
	image?: string | { image: Blob, source?: string }
	type?: string
}

export namespace UrlInfo {
	export const parser: {
		[contentType: string]: (txt: string, ct: string, url: string) => UrlInfo
	} = {}
	export const interpret: ((obj: any) => UrlInfo | null)[] = []
}

export function urlInfoToTree(info: UrlInfo) {
	const tree: mdl.ItemWithRefSpec = {
		props: {
			url: info.url && { url: info.url },
			title: info.title,
			icon: info.icon && { icon: info.icon }
		},
		content: [],
		tmpls: [{ ref: 'url.tmpl' }],
	}
	if (info.description)
		tree.content.push({
			item: {
				props: { text: { text: info.description } },
				tmpls: [{ ref: 'text.tmpl' }],
			},
			preview: 'full'
		})
	if (info.image)
		tree.content.push({
			item: {
				props: { image: { image: info.image } },
				tmpls: [{ ref: 'image.tmpl' }],
			},
			preview: 'full'
		})
	return tree
}
