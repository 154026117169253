import * as str from './str'
import * as urlUtils from './url'

export function isBlob(v: any): v is Blob {
	return typeof Blob === 'undefined' ? false : v instanceof Blob
}

export function isBuffer(v: any): v is Buffer {
	return typeof Buffer === 'undefined' ? false : v instanceof Buffer
}

export function fromBase64(str: string | null, contentType: string | null) {
	if (!str)
		return null
	const byteChars = atob(str)
	const byteCount = byteChars.length
	const byteArray = new Uint8Array(byteCount)
	for (let i = 0; i < byteCount; ++i)
		byteArray[i] = byteChars.charCodeAt(i)
	return new Blob([byteArray], { type: contentType ?? 'text/plain' })
}

export function fromBase64DataUrl(url: string) {
	if (!urlUtils.isDataUrl(url, 'base64'))
		return null
	return fromBase64(str.substringAfter(url, ','),
		str.substringBefore(url.substring(5), ';'))
}

export async function toBase64(blob: Blob) {
	const s = await toDataUrl(blob)
	return s ? s.substring(s.indexOf(';base64,') + 8) : null
}

export function toDataUrl(blob: Blob) {
	return new Promise<string | null>((res, rej) => {
		if (blob) {
			const r = new FileReader()
			r.onerror = () => { rej(r.error) }
			r.onload = () => { res(r.result as string) }
			r.readAsDataURL(blob)
		} else {
			res(null)
		}
	})
}

export function toSha256(blob: Blob) {
	return new Promise<ArrayBuffer | null>((res, rej) => {
		if (blob) {
			const r = new FileReader()
			r.onerror = () => { rej(r.error) }
			r.onloadend = () => {
				crypto.subtle.digest('SHA-256', r.result as ArrayBuffer)
					.then(res)
			}
			r.readAsArrayBuffer(blob)
		} else {
			res(null)
		}
	})
}

