import * as React from 'react'

export class Component<P> extends React.Component<P> {

	componentDidMount() {
		// optionally listen to document key presses
		if ('onDocumentKey' in this || 'onKey' in this)
			document.addEventListener('keyup', this['_onDocKey'])
	}

	componentWillUnmount() {
		// optionally remove document key press listener
		if ('onDocumentKey' in this || 'onKey' in this)
			document.removeEventListener('keyup', this['_onDocKey'])
	}

	private _onDocKey = (evn: KeyboardEvent) => {
		//console.log('doc key', evn.type, evn.key)
		if ('onDocumentKey' in this &&
			evn.target instanceof Element && evn.target.localName === 'body')
			(this as any)['onDocumentKey'](evn)
		if ('onKey' in this)
			(this as any)['onKey'](evn)
	}

}

