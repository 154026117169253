
export function bulk<T = any>(readFn: (ids: string[]) => Promise<T>) {
	let idMap = {}
	let pro: Promise<T>
	return (id: string) => {
		idMap[id] = 1
		if (!pro) {
			pro = new Promise<T>((res, rej) => {
				setTimeout(() => {
					const ids = Object.keys(idMap)
					if (ids.length > 0)
						readFn(ids).then(res, rej)
					idMap = {}
					pro = null
				}, 100)
			})
		}
		return pro
	}
}
