import * as mdl from '../../../model'
import { IndexedDb } from '../../common/IndexedDb'
import { IndexedDbAccess } from './IndexedDbAccess'

export { createDbs } from './createDbs'

export const setup = {

	access: ({ inst }: { inst: { id: string } },
		dbs?: { data: IndexedDb; index: IndexedDb; preview?: IndexedDb }) => {

		let idb: IndexedDbAccess

		// observe browser storage
		// TODO: use localstorage to signal changes
		// addEventListener('storage', async evn => {
		// 	if (!evn.key || !evn.key.startsWith(URL_PREFIX)) return
		// 	const doc = await boxes.getBox(evn.key)
		// 	const data = JSON.parse(evn.newValue)
		// 	const itemData = doc.formatter.interpretData(data)
		// 	boxes.setItemsData(itemData, doc.url)
		// })

		mdl.BoxStorage.protocols['indexed'] = ({ url }): mdl.BoxStorageAccess => {
			if (!inst.id || url !== 'allsbe:' + inst.id)
				return null
			// there is actually only one
			if (!idb)
				idb = new IndexedDbAccess(dbs)
			return idb
		}

	},
}
