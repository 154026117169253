
export const pattern = {
	// complete lines
	line: {
		basic: /^https?:\/\/\S+$/,
		image:
			/^(https?:\/\/[^ #\?]+\.(png|gif|jpg|jpeg)|data:image\/.+)([#\?].*)?$/,
	},
	// within text
	basic: /(https?:\/\/\S+)/,
	image:
		/(https?:\/\/[^ #\?]+\.(png|gif|jpg|jpeg)|data:image\/.+)([#\?].*)?(?:\s|$)/,
}

export function isUrl(val: any) {
	return val && (val instanceof URL ||
		(typeof val === 'string' && pattern.basic.test(val)))
}

/** Test a URL to be a data URL.
 * Optionally include the encoding (eg. base64,...) in the test.
 */
export function isDataUrl(v: any, encoding?: string): v is string {
	return typeof v === 'string' && v.startsWith('data:') &&
		(!encoding || encoding === v.substring(v.indexOf(';') + 1, v.indexOf(',')))
}

export function url(url: string, params: {}) {
	return url + '?' + new URLSearchParams(params)
}

export function addParams(url: string, params?: {}) {
	if (params)
		url += '?' + new URLSearchParams(params)
	return url
}

export function absolute(url: string, base: string) {
	return new URL(url, base).href
}
