import * as React from 'react'
import * as mdl from '../../model'
import { Context, css, f, Icon } from '../common'
import { ItemIcon, ItemLabel, ItemLink } from '../LayoutViews'
import { PropContent } from '../PropertyViews'
import { Layout } from './common'

export const comment: Layout = {
	icon: (item, context) =>
		<CommentIcon item={item} context={context} />,
	content: (item, context) => <div className="comment">
		<h6><CommentLabel item={item} context={context} />
			<span>{time(item)}</span></h6>
		{item.props.content.map(p =>
			<PropContent key={p.name} prop={p} context={context} />)}
	</div>,
	links: {
		small: (item, context) => <div className="comment">
			<h6>{time(item)}</h6>
			<CommentPreview item={item} context={context} level="small" />
		</div>,
		compact: (item, context) => <div className="comment">
			<h6><CommentLabel item={item} context={context} />
				<span>{time(item)}</span></h6>
			<CommentPreview item={item} context={context} level="compact" />
		</div>,
		normal: (item, context) => <>
			<CommentIcon item={item} context={context} />
			<div className={css('comment')}>
				<h6><CommentLabel item={item} context={context} />
					<span>{time(item)}</span></h6>
				<CommentPreview item={item} context={context} level="normal" />
			</div>
		</>,
		extended: (item, context) => <>
			<CommentIcon item={item} context={context} />
			<div className="comment">
				<h6><CommentLabel item={item} context={context} />
					<span>{time(item)}</span></h6>
				{item.content.isEmpty ? item.props.asList.map(p =>
					<PropContent key={p.name} prop={p} context={context} />) :
					item.content.map(ln =>
						<ItemLink key={ln.refId} item={ln.item} context={context}
							level="full" />)}
			</div>
		</>,
	},
}

function CommentLabel({ item, context }: { item: mdl.Item, context: Context }) {
	return item.props.get('author')?.value ??
		<ItemLabel item={item.create.user} context={context} />
}

function CommentIcon({ item, context }: { item: mdl.Item, context: Context }) {
	return item.props.get('author')?.value ? <Icon uri="comment" /> :
		<ItemIcon item={item.create.user} context={context} />
}

function time(item: mdl.Item) {
	return f.dateTime(item.props.get('time')?.value ?? item.create.date)
}

function CommentPreview({ item, context, level }:
	{ item: mdl.Item, context: Context, level: mdl.PreviewLevel }) {
	return item.content.isEmpty ?
		<PropContent prop={getContentProp(item)} context={context} /> :
		<ItemLink item={item.content.get(0)?.item} context={context}
			level={level} />
}

function getContentProp(item: mdl.Item): mdl.Property {
	return item.props.get('comment', 'text', 'title', 'label') ??
		item.props.findByType('text') ?? item.props.findByType('string')
}

