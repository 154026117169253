import * as mdl from '../model'

const storageKey_desktopInstall = 'desktopInstall'

interface BeforeInstallPromptEvent extends Event {
}

export const setup = {

	install: () => {
		// quick&dirty to install desktop PWA
		window.addEventListener('beforeinstallprompt',
			(evn: BeforeInstallPromptEvent) => {
				// Prevent Chrome 67 and earlier from automatically showing the prompt
				evn.preventDefault()
				const desktopInstall = localStorage.getItem(storageKey_desktopInstall)
				if (!desktopInstall || desktopInstall === 'request') {
					const install = document.createElement('div')
					install.className = 'install'
					install.innerHTML = '<div>&nbsp;</div>' +
						'<p>You can install Allsbe on your desktop!</p>' +
						'<button>Install</button><button>Cancel</button>'
					const buttons = install.getElementsByTagName('button')
					buttons[0].onclick = () => {
						evn['prompt']()
							.catch((err: Error) => { console.error(err.message) })
						evn['userChoice'].then((r: 'accepted' | 'dismissed' |
						{ outcome: 'accepted' | 'dismissed' }) => {
							install.remove()
							localStorage.setItem(storageKey_desktopInstall,
								typeof r === 'object' && 'outcome' in r ? r.outcome : r)
						})
					}
					buttons[1].onclick = () => {
						install.remove()
						localStorage.setItem(storageKey_desktopInstall, 'canceled')
					}
					document.body.appendChild(install)
				}
			})
	},

	explicitInstall: ({ inst, app }:
		{ inst: mdl.Installation, app: mdl.App }) => {
		inst.installDesktop.react(() => {
			localStorage.setItem(storageKey_desktopInstall, 'request')
			app.reload()
		})
	},

	initialWindowSize: () => {
		// PWA initial Window Size
		// https://github.com/w3c/manifest/issues/436
		window.addEventListener('resize', async () => {
			if (window.matchMedia('(display-mode: standalone)').matches ||
				window.navigator['standalone'] === true) {
				const v = localStorage.getItem(storageKey_desktopInstall)
				if (v === 'accepted') {
					const h = window.screen.availHeight / 4 * 3
					window.resizeTo(480, h > 600 ? h : 600)
					localStorage.setItem(storageKey_desktopInstall, 'installed')
				}
			}
		})
	}

}
