import { setupApp, startApp } from './app'
import { U } from './common'
import * as mdl from './model'

declare const __DEBUG__: boolean

const app = new mdl.App()
setupApp(app)
startApp(app)

// for direct debugging
window['app'] = app
window['U'] = U
if (__DEBUG__) {
	const info = document.createElement('div')
	info.style.cssText = 'position:fixed;bottom: 2px;right:2px;z-index:10;' +
		'color: white;background-color:darkred;font-size: 8px;padding:2px 4px'
	const key = '__ignore_changes'
	if (localStorage.getItem(key) === 'true') {
		window[key] = true
		document.body.appendChild(info)
		info.innerHTML = key
	}
	document.addEventListener('keyup', evn => {
		if (evn.altKey && evn.ctrlKey && evn.key === 'i') {
			window[key] = !window[key]
			if (window[key]) {
				localStorage.setItem(key, 'true')
				document.body.appendChild(info)
				info.innerHTML = key
			} else {
				localStorage.removeItem(key)
				document.body.removeChild(info)
			}
		}
	})
}
