import { ItemData0, migrate_v0 } from '../../../../../base/src/data/migrate/migrate_v0'
import { ErrorX, U } from '../../../common'
import * as mdl from '../../../model'
import { ItemDocBase } from './types'

export function queryUri(docId: string, view: string) {
	return '_design/' + docId + '/_view/' + view
}
export function boxIdToDbName(boxId: string) {
	return 'box_' +
		(boxId.endsWith('.box') ? boxId.substring(0, boxId.length - 4) : boxId)
}
export function dbNameToBoxId(dbName: string) {
	return dbName.startsWith('box_') ?
		dbName.substring(4) + (dbName.charAt(4) < 'A' ? '' : '.box') : null
}
export function itemToDocId(itemId: string) { return 'i' + itemId }
export function docToItemId(docId: string) { return docId.substring(1) }

export function isItemDoc(doc: { id: string } | { _id: string }) {
	if (doc === null) return false
	return doc['id' in doc ? 'id' : '_id'].startsWith('i')
}

export function toFullRev(doc: ItemDocBase) {
	return mdl.Item.toFullRev(
		{ rev: doc.item_rev, update: doc.update, create: doc.create })
}

export async function docToItemData(doc: ItemDocBase) {
	if (!doc) return null
	try {
		const itemData: mdl.ItemData =
		{
			id: docToItemId(doc._id), props: {},
			_info: { couchdb: { _id: doc._id, _rev: doc._rev } }
		}
		if (doc.item_rev)
			itemData.rev = doc.item_rev
		if (doc.isDeleted)
			itemData.isDeleted = doc.isDeleted
		if (doc.props)
			U.obj.copyMembers(doc, itemData, 'create', 'update',
				'props', 'links', 'content', 'tags', 'layoutId', 'tmpls')
		else if ('type' in doc)
			migrate_v0(doc as any as ItemData0, itemData)
		// convert icon data:image URLs to Blobs
		for (const v of Object.values(itemData.props)) {
			if (v && typeof v === 'object' && 'icon' in v &&
				U.url.isDataUrl(v.icon, 'base64') && v.icon.length > 64)
				v.icon = await mdl.HashedBlob.create(U.blob.fromBase64DataUrl(v.icon))
		}
		if (doc.containerId)
			itemData.containerId = doc.containerId
		if ('_conflicts' in doc) {
			itemData.conflicts = [{ ...doc }, ...doc._conflicts]
			delete itemData.conflicts[0]._conflicts
		}
		if ('_attachments' in doc) {
			if (doc['type'] === 'image') {
				const attKey = 'file' in doc._attachments ? 'file' : 'image'
				const att = doc._attachments[attKey]
				if (!att)
					throw new Error(`Attachment ${attKey} for property ${name
						} of item ${itemData.id} missing!`)
				itemData.props.image.image = att.content_type === 'Buffer' ?
					Buffer.from(att['data'], 'base64') :
					await mdl.HashedBlob.create(
						U.blob.fromBase64(att['data'], att.content_type))
				itemData.props.image.type = att.content_type
				if ('size' in att) itemData.props.image.size = att['size']
			} else {
				for (const name of Object.keys(itemData.props)) {
					const prop = itemData.props[name]
					if (!prop || typeof prop !== 'object')
						continue
					for (const k of Object.keys(prop)) {
						const v = prop[k]
						if (typeof v !== 'string' || !v.startsWith('attachment:'))
							continue
						const attKey = v.substring('attachment:'.length)
						const att = doc._attachments[attKey]
						if (!att)
							throw new Error(`Attachment ${attKey} for property ${name
								} of item ${itemData.id} missing!`)
						prop[k] = 'data' in att ? att.content_type === 'Buffer' ?
							Buffer.from(att.data as any, 'base64') :
							await mdl.HashedBlob.create(
								U.blob.fromBase64(att.data as any, att.content_type)) :
							// attachment stub
							null
						if ('content_type' in att)
							prop.type = att.content_type
						if ('size' in att)
							prop.size = att['size']
					}
				}
			}
		} else {
			// remove attachment refs
			for (const name of Object.keys(itemData.props)) {
				const prop = itemData.props[name]
				if (!prop || typeof prop !== 'object')
					continue
				for (const k of Object.keys(prop)) {
					const v = prop[k]
					if (typeof v === 'string' && v.startsWith('attachment:'))
						prop[k] = null
				}
			}
		}
		U.obj.deleteMembers(itemData as any,
			'_id', '_rev', '_attachments', '_conflicts')
		return mdl.ItemData.cleanup(itemData)
	}
	catch (err) {
		throw new ErrorX(`Failed to convert CouchDB doc ${doc._id}!`, err, doc)
	}
}

export async function itemDataToDoc(data: mdl.ItemData) {
	if (data === null) return null
	// TODO: adapt to itemdata
	const doc: ItemDocBase = {
		...data, props: { ...data.props },
		_id: itemToDocId(data.id), item_rev: data.rev
	}
	delete doc._info
	if (doc.item_rev === 0)
		delete doc.item_rev
	U.obj.deleteMembers(doc, 'id', 'rev')
	// replace blobs with references to attachments
	// only one blob per property!
	for (const name of Object.keys(doc.props)) {
		const prop = doc.props[name]
		if (!prop || typeof prop !== 'object')
			continue
		for (const k of Object.keys(prop)) {
			const v = prop[k]
			const isBlob = mdl.HashedBlob.isInstance(v) || U.blob.isBlob(v)
			if (isBlob || U.blob.isBuffer(v)) {
				if (k === 'icon') {
					// icons as data URLs => they get fetched without attachments
					doc.props[name] = {
						...prop,
						icon: isBlob ? await U.blob.toDataUrl(mdl.HashedBlob.getBlob(v)) :
							'data:buffer;base64,' + v.toString('base64')
					}
				} else {
					// the other blobs as attachments => fetched only on level 2
					if (!('_attachments' in doc)) doc._attachments = {}
					doc._attachments[name] = {
						content_type: isBlob ? mdl.HashedBlob.getBlob(v).type : 'Buffer',
						data: isBlob ? await U.blob.toBase64(mdl.HashedBlob.getBlob(v)) :
							v.toString('base64')
					}
					doc.props[name] = { ...prop, [k]: 'attachment:' + name }
				}
				break
			}
		}
	}
	return doc
}

export function queryToSearchText({ id, key, value }:
	{ id: string, key: string, value: string }) {
	return {
		id: docToItemId(id),
		rev: key,
		text: value
	}
}

export function queryToId({ id }: { id: string, key: string, value: string }) {
	return docToItemId(id)
}

