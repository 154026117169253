import * as React from 'react'
import { getMapEmbedUrl } from '../../data/GeoLocation'
import * as mdl from '../../model'
import { Block, Icon } from '../common'
import { LocationEditor } from '../common/LocationEditor'
import { PropertyView } from './common'

export const location: PropertyView<mdl.LocationValue> = {
	label: ({ value }) => {
		const lat = Math.round(value.latitude * 10E5) / 10E5 || 0
		const lng = Math.round(value.longitude * 10E5) / 10E5 || 0
		return <>{value.address ? `${value.address} (${lat}°, ${lng}°)` :
			`${lat}°, ${lng}°`}</>
	},
	icon: () => <Icon uri="location" />,
	content: ({ value }, { config }) => <>
		<Block label="Address" if={!!value.address}>{value.address}</Block>
		<Block label="Latitude" if={!!value.latitude}
			className="inline">{value.latitude}°</Block>
		<Block label="Longitude" if={!!value.longitude}
			className="inline">{value.longitude}°</Block>
		<Block label="Accuracy" if={!!value.accuracy}
			className="inline">±{value.accuracy}m</Block>
		<Block label="Altitude" if={value.altitude !== void 0}
			className="inline">{value.altitude}</Block>
		<iframe frameBorder={0} src={getMapEmbedUrl(config.api, value)} />
	</>,
	editor: (prop, { values }, { config }, autoFocus) =>
		<LocationEditor values={values[prop.name] as any}
			config={config} autoFocus={autoFocus} />
}


