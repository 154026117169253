import * as React from 'react'
import { Component, InputProps, observer, ValueProps } from './common'

interface EnumInputProps extends InputProps {
	options: { label: string, value: string }[]
}

@observer
export class EnumInput<T> extends Component<EnumInputProps & ValueProps<T>> {

	render() {
		const { label, title, children, options } = this.props
		const value = 'value' in this.props ? this.props.value : null
		const val = 'value' in this.props ? value.value :
			'' + this.props.target[this.props.member]
		return <div className={'field enum' + (value?.hasErrors ? ' error' : '')}
			title={title}>
			{label && <label>{label}</label>}
			<select value={val} onChange={this.onChange}>
				{options.map(opt =>
					<option key={opt.value} value={opt.value}>{opt.label}</option>)}
			</select>
			{value?.hasErrors && <div>{value.errorMessages.join(', ')}</div>}
			{children}
		</div>
	}

	onChange = (evn: React.ChangeEvent<HTMLSelectElement>) => {
		if ('value' in this.props) this.props.value.value = evn.target.value
		else this.props.target[this.props.member] = evn.target.value as any
	}

}
