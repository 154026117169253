import { U } from '../common'

// try to keep in sync with server/src/common/httpClient.ts

export const jsonHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
}

export const xmlHeaders = {
	'Accept': 'text/xml',
	'Content-Type': 'text/xml',
}

export function basicAuth({ id, password }: { id: string, password: string }) {
	return 'Basic ' + U.base64.encode(id + ':' + password)
}

export async function request(url: string, req: RequestInit, params?: {}) {
	try {
		return await fetch(U.url.addParams(url, params), req)
	}
	catch (err: any) {
		throw new ResponseError(`Failed to ${req.method ?? 'GET'} ${url}!`,
			url, -1, null, { error: U.error.toJson(err), params }, err.name)
	}
}

interface HttpHeaders {
	[n: string]: string | number
}

export function httpClient(auth?: { id: string, password: string } | string,
	init?: (req: RequestInit) => void) {
	const req: RequestInit = { headers: { ...jsonHeaders } }
	if (auth)
		(req as any).headers['Authorization'] =
			typeof auth === 'string' ? 'Bearer ' + auth : basicAuth(auth)
	if (init)
		init(req)
	return {
		get: <R = any>(url: string, params?: {}) =>
			request(url, req, params).then(readResponse) as Promise<R>,
		getText: (url: string, params?: {}) =>
			request(url, req, params).then(readText),
		getJson: <R = any>(url: string, params?: {}) =>
			request(url, req, params).then(readJson) as Promise<R>,
		getBlob: (url: string, params?: {}) =>
			request(url, req, params).then(readBlob),
		post: <R = any>(url: string, body: any) =>
			request(url, { ...req, method: 'POST', body: JSON.stringify(body) })
				.then(readResponse) as Promise<R>,
		postForm: <R = any>(url: string, data: {}) =>
			request(url, {
				...req,
				headers: {
					...req.headers, 'Content-Type': 'application/x-www-form-urlencoded'
				},
				method: 'POST', body: new URLSearchParams(data)
			}).then(readResponse) as Promise<R>,
		put: <R = any>(url: string, body: any) =>
			request(url, { ...req, method: 'PUT', body: toBody(body) })
				.then(readResponse) as Promise<R>,
		patch: <R = any>(url: string, body: any) =>
			request(url, { ...req, method: 'PATCH', body: JSON.stringify(body) })
				.then(readResponse) as Promise<R>,
		delete: <R = any>(url: string) => request(url,
			{ ...req, method: 'DELETE' }).then(readResponse) as Promise<R>,
		// TODO: test
		propfind: <R = any>(url: string, body: string, headers: HttpHeaders = {}) =>
			request(url, {
				...req, headers: { ...req.headers, ...xmlHeaders, ...headers },
				method: 'PROPFIND', body
			})
				.then(readResponse) as Promise<R>,
		// TODO: test
		proppatch: <R = any>(url: string, body: string, headers: HttpHeaders = {}) =>
			request(url, {
				...req, headers: { ...req.headers, ...xmlHeaders, ...headers },
				method: 'PROPPATCH', body: body
			})
				.then(readResponse) as Promise<R>,
		mkcol: <R = any>(url: string) => request(url,
			{ ...req, method: 'MKCOL' }).then(readResponse) as Promise<R>,
		// TODO: test
		copy: <R = any>(url: string, dest: string) => request(url, {
			...req, method: 'COPY',
			headers: { ...req.headers, Destination: dest }
		}).then(readResponse) as Promise<R>,
		// TODO: test
		move: <R = any>(url: string, dest: string) => request(url, {
			...req, method: 'MOVE',
			headers: { ...req.headers, Destination: dest }
		}).then(readResponse) as Promise<R>,
	}
}

export type HttpClient = ReturnType<typeof httpClient>

function toBody(body: any): BodyInit {
	// TODO: set content-type header accordingly
	return body instanceof Blob || body instanceof FormData ||
		body instanceof URLSearchParams || typeof body === 'string' ? body as any :
		JSON.stringify(body)
}

export class ResponseError extends Error {

	constructor(msg: string, public url: string, public status: number,
		public headers: Record<string, string> | null, public data?: string | object,
		name = 'ResponseError') {
		super(msg)
		this.name = name
	}

	static create(resp: Response, data?: object | string,
		name?: string) {
		let msg = resp.statusText
		if (!msg)
			msg = U.error.extractMessage(data) ?? 'Unknown Error!'
		return new ResponseError(msg, resp.url, resp.status,
			headersToObject(resp.headers), data, name)
	}

	static notFoundAsNull(err: ResponseError) {
		if (err.status === 404)
			return null
		throw err
	}
}

export function headersToObject(headers: Headers) {
	return headers ? Array.from(headers)
		.reduce((obj, h) => ({ ...obj, [h[0]]: h[1] }), {}) : null
}

export async function readResponse<R = any>(resp: Response,
	expectedContentType?: 'text' | 'json' | 'blob' | string): Promise<R> {
	if (!expectedContentType) {
		const ct = resp.headers?.get('Content-Type')
		expectedContentType = ct?.startsWith(jsonHeaders['Content-Type']) ?
			'json' : 'text'
	}
	if (!resp.ok) {
		let data = null
		try { data = await resp.text() }
		catch (err) { /* just try... */ }
		if (data && expectedContentType?.endsWith('json')) {
			try { data = JSON.parse(data) }
			catch (err) { }
		}
		throw ResponseError.create(resp, data)
	}
	if (['text', 'json', 'xml'].find(t => expectedContentType?.endsWith(t))) {
		// text response
		let txt = null
		try { txt = await resp.text() }
		catch (err: any) { throw ResponseError.create(resp, err.message, err.name) }
		if (expectedContentType?.endsWith('json')) {
			try {
				return JSON.parse(txt)
			}
			catch (err: any) {
				throw ResponseError.create(resp, { error: err.message, data: txt },
					err.name)
			}
		}
		return txt as any
	} else {
		// binary response
		return await resp.blob() as any
	}
}

export function readText(resp: Response) {
	return readResponse<string>(resp, 'text')
}

export function readJson<R = any>(resp: Response) {
	return readResponse<R>(resp, 'json')
}

export async function readBlob(resp: Response) {
	return readResponse<Blob>(resp, 'blob')
}

