
import { IGNORE_ERROR_NULL, isArray, U } from '../common'
import { Box } from './Box'
import { action, computed, observable, searchText, signal, when } from './common'
import { ItemData } from './data'
import { Link } from './Link'
import { Links, LinkSource } from './Links'
import { Log } from './Log'
import { Properties } from './Properties'
import { PropertyType, searchablePropertyTypes } from './Property'

export enum ItemStatus {
	initial, // initial state after construction
	request1, // request to load basic data (min. 'id', 'type')
	level1, // basic data is set (min. 'id', 'type', via create or request1)
	request2, // request to load all available data
	level2, // all available data set
	request3, // request extended data
	level3, // extended data is set
	error, // there has been an error retriving, setting data
	missing, // data has been tried to load, but has not been found
}

interface ItemRevData {
	rev?: number
	update?: { installationId?: string }
	create?: { installationId?: string }
}

// Items with this templates will be linked as content by default.
// TODO: move into some kind of meta info for tag items themselfs
export const contentTmpl = {
	'text.tmpl': 1, 'number.tmpl': 1, 'date.tmpl': 1, 'comment.tmpl': 1,
}

export type LinkDepthSpec = 'content' | 'links' | 'all' | 'none' | boolean

export class Item {

	@observable id: string
	@observable rev = 0
	@observable.ref conflicts: any[]
	@observable.ref _info: any
	@observable layoutId: string
	@computed get layout(): string {
		return this.layoutId ?
			this.layoutId.substring(0, this.layoutId.indexOf('.')) :
			this.tmpls.map(t => t.item.layout).find(U.any.isTrue)
	}
	@computed get revFull() { return Item.toFullRev(this) }
	static toFullRev(data: ItemRevData) {
		const instId = data.update?.installationId ?? data.create?.installationId
		return (data.rev ?? 0) + '_' + (instId ?? 'ukn')
	}
	/** Compare two full revisions. 
	 * 1: rev is newer than ref, 0: equal, -1 older.
	 * A null or undefined makes one older. Both null or undefined => 0. 
	 * An unknown installation ID 'ukn' makes one older also.
	 */
	static compareRev(rev: string | ItemRevData, ref: string | ItemRevData) {
		if (!rev)
			return !ref ? 0 : -1
		if (!ref)
			return 1
		const revA = typeof rev === 'string' ? parseInt(rev) : rev.rev ?? 0
		const revB = typeof ref === 'string' ? parseInt(ref) : ref.rev ?? 0
		if (revA > revB)
			return 1
		else if (revA < revB)
			return -1
		const instA = typeof rev === 'string' ?
			rev.substring(rev.indexOf('_') + 1) :
			rev.update?.installationId ?? rev.create?.installationId ?? 'ukn'
		const instB = typeof ref === 'string' ?
			ref.substring(ref.indexOf('_') + 1) :
			ref.update?.installationId ?? ref.create?.installationId ?? 'ukn'
		return instA === 'ukn'
			? instB === 'ukn' ? 0 : -1
			: instB === 'ukn' ? 1 : instA.localeCompare(instB)
	}

	//#region properties

	props = new Properties(this)

	/** Text representing this item in a text search. */
	@computed get searchText() {
		return searchText(...this.props.asList
			.filter(p => p.type in searchablePropertyTypes)
			.map(p => p.value))
	}

	/** Text representing this item in a sorted label list or the browser 
	 * window title. */
	@computed get labelText() {
		return this.props.label?.stringValue ?? 'Item ' + this.id
	}

	//#endregion

	isGenerated = false
	create = new Log()
	update = new Log()
	recordUpdate = signal()
	@computed get lastModified() {
		return this.update && this.update.date ? this.update.date
			: this.create ? this.create.date : void 0
	}
	@computed get isReadOnly() {
		return this.isGenerated || (this.isReady && this.boxItems.length > 0 &&
			!this.activeBoxes.find(b => b.isWriteAllowed))
	}
	/** Initialize a newly created item. */
	initNew = signal<() => void | Promise<void>>()
	delete = signal(() => { this.isDeleted = true })
	@observable isDeleted = false
	/** Research additional information about this item. */
	research = signal<() => void | Promise<void>>()
	refresh = signal<() => void | Promise<void>>()

	get $debug() {
		const d = { id: this.id, rev: this.rev, status: ItemStatus[this.status] }
		for (const name of this.props.keys())
			d[name] = this.props.get(name).$debug.value
		if (this.container)
			d['containerId'] = this.container.id
		if (!this.content.isEmpty)
			d['content'] = this.content.$debug
		if (!this.links.isEmpty)
			d['links'] = this.links.$debug
		if (!this.tmpls.isEmpty)
			d['tmpls'] = this.tmpls.$debug
		if (this.boxes.length > 0)
			d['boxes'] = this.boxes.reduce((d, b) => ({ ...d, [b.id]: b.label }), {})
		return d
	}


	//#region live-cycle

	@observable status = ItemStatus.initial
	@computed get isReady() {
		return this.status >= ItemStatus.level1 && this.status <= ItemStatus.level3
	}
	@computed get isCompleted() { return this.status == ItemStatus.level3 }
	@computed get isMissing() { return this.status === ItemStatus.missing }
	@computed get isFinished() { return this.status >= ItemStatus.level2 }
	request1 = signal<(item: Item) => (void | Promise<void>)>()
	async request() {
		if (this.status < ItemStatus.request1) {
			this.status = ItemStatus.request1
			await this.request1(this)
			await Promise.all(this.tmpls.map(Item.request))
			if (this.status < ItemStatus.level1)
				this.status = ItemStatus.missing
		} else if (this.status < ItemStatus.level1) {
			await when(() => this.status >= ItemStatus.level1)
		}
		return this
	}
	request2 = signal<(item: Item) => (void | Promise<void>)>()
	observe = signal<(item: Item) => void>()
	request3 = signal<(item: Item) => (void | Promise<void>)>()
	async complete() {
		if (this.status < ItemStatus.level1)
			await this.request()
		if (this.status < ItemStatus.request2) {
			this.status = ItemStatus.request2
			await this.request2(this)
			this.status = ItemStatus.level2
		} else if (this.status < ItemStatus.level2) {
			await when(() => this.status >= ItemStatus.level2)
		}
		// TODO: impl and test idempotency
		if (this.status < ItemStatus.request3) {
			this.observe(this)
			this.status = ItemStatus.request3
			await this.request3(this)
			this.status = ItemStatus.level3
		} else if (this.status < ItemStatus.level3) {
			await when(() => this.status >= ItemStatus.level3)
		}
		return this
	}

	/** collection helpers: items.forEach(Item.request) */
	static request(item: Item | Link) {
		if (item instanceof Link)
			item = item.item
		return item.request().catch(IGNORE_ERROR_NULL)
	}
	static complete(item: Item | Link) {
		if (item instanceof Link)
			item = item.item
		return item.complete().catch(IGNORE_ERROR_NULL)
	}

	/** This item has changed since loaded. */
	@observable hasChanged = false
	@observable error: U.error.ErrorData

	@action setError(err?: string | Error) {
		this.status = ItemStatus.error
		this.error = U.error.toJson(err)
	}

	build = signal<(data: ItemData, force?: boolean) => void>()
	// TODO: redesign
	/** allow to ignore changes for recordUpdate when set from source */
	settingData = false

	//#endregion

	//#region boxes

	@observable.shallow	readonly boxItems: Item[] = []
	@computed get boxes() {
		return this.boxItems.map(Box.getBox).filter(U.any.isTrue)
	}
	@computed get activeBoxes() { return this.boxes.filter(b => b.isActive) }
	isInBox(box: Box | Item) {
		return this.boxItems.includes(box instanceof Box ? box.item : box)
	}

	/** Add this item to an active box. This item should be complete! */
	@action addToBox(box: Box | Item) {
		if (box instanceof Box) box = box.item
		const doAdd = box && !this.isGenerated && !this.boxItems.includes(box)
		if (doAdd)
			this.boxItems.push(box)
		return doAdd
	}

	/** Remove this item from an active box. */
	@action removeFromBox(box: Box | Item) {
		if (this.boxItems.length <= 1)
			// this item must be at least in one box
			return false
		if (box instanceof Item) box = Box.getBox(box)
		if (!box.isActive)
			// cannot remove from inactive box
			return false
		const idx = this.boxItems.indexOf(box.item)
		if (idx >= 0)
			this.boxItems.splice(idx, 1)
		return idx >= 0
	}

	/** Add this item to all the boxes of a given item.
	 * 
	 * Optionally add linked items as well (deep).
	 */
	@action addToBoxesOf(srcItem: Item, deep: LinkDepthSpec = false) {
		let added = false
		for (const boxItem of srcItem.boxItems)
			if (this.addToBox(boxItem))
				added = true
		if (added) {
			// ignore if already added or generated (break circles,...)
			if (deep === true || deep === 'all' || deep === 'content')
				for (const ln of this.content)
					ln.item.addToBoxesOf(srcItem, true)
			if (deep === true || deep === 'all' || deep === 'links')
				for (const ln of this.links)
					ln.item.addToBoxesOf(srcItem, true)
		}
		return added
	}

	//#endregion

	/** Container item if this item is within a hierarchy (content linked). */
	@observable container: Item
	/** Container and container of container,... up to the root. */
	@computed get containers() {
		if (!this.container)
			return null
		const containers = [this.container]
		if (this.container.container)
			containers.push(...this.container.containers)
		return containers
	}
	/** All the linked items to form the content hierarchy with this item as the
	 *  root. */
	async getHierarchy() {
		await this.request()
		const hierarchy: Item[] = [this,
			...(await Promise.all(
				this.content.map(c => c.item.getHierarchy()))).flat()]
		return hierarchy
	}

	content = new Links(this)
	links = new Links(this)
	tmpls = new Links(this)

	@action addAsContent(links: LinkSource | LinkSource[], index = -1) {
		if (this.isReadOnly)
			return null
		if (isArray(links)) {
			for (const link of links) this.addAsContent(link, index)
		} else {
			const item = links instanceof Link ? links.item
				: links instanceof Item ? links : null
			if (item?.container && item.container !== this)
				throw new Error(
					`Item ${item.id} is content of ${item.container.id} already!`)
			this.content.add(links, index)
			if (this.links.has(links))
				this.links.remove(links)
		}
	}
	@action addAsRelated(links: LinkSource | LinkSource[], index = -1) {
		if (this.isReadOnly)
			return null
		if (isArray(links)) {
			for (const link of links) this.addAsRelated(link, index)
		} else {
			this.links.add(links, index)
			if (this.content.has(links))
				this.content.remove(links)
		}
	}

	*walkContent(levels?: number): Generator<Item> {
		if (levels <= 0)
			return
		for (const ln of this.content) {
			yield* ln.item.walkContent(levels ? levels - 1 : void 0)
			yield ln.item
		}
	}

	@computed get allLinks() {
		return [...this.content, ...this.links]
	}

	@computed get allAvailableLinks() {
		return [...this.content.available, ...this.links.available]
	}

	// find related
	// TODO: 2nd level linked items? 3rd,...?
	// TODO: from links?
	// TODO: own props?
	// TODO: find multiple typed props?
	/** Find the first related item with a property of a given type and return
	 * it's value.
	 */
	findRelatedPropertyValue<T = any>(type: PropertyType): T | null {
		const ln = this.links.find(ln => !!ln.item.props.findByType(type))
		return ln?.item.props.findByType(type).value ?? null
	}

	/** Find related items with a property of a given type and return 
	 * their values.
	 */
	findRelatedPropertyValues<T = any>(type: PropertyType): T[] {
		return this.links.map(ln => ln.item.props.findByType(type)?.value)
			.filter(U.any.isTrue)
	}

	selectRev = signal<(rev: string) => void>()
}
