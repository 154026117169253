
import * as mdl from '../../../model'
import { GitlabDataAccess } from './GitlabDataAccess'

export interface Context {
	items: mdl.ItemManager
	boxes: mdl.BoxManager
	log: mdl.Logger
}

export const setup = {

	access: (ctx: Context) => {

		mdl.BoxStorage.protocols['gitlab'] = function(props): mdl.BoxStorageAccess {
			if (!props.url || !props.credentials)
				return null
			const da = new GitlabDataAccess(props, ctx.log)
			return da
		}
	},

}

