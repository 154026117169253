import { U } from '../../../common'
import * as mdl from '../../../model'
import { textImporters } from './common'

textImporters.push(whatsappMessages)

// so far...
// iPhone: [dd.mm.yy, hh:mm:ss]
// android: [dd/mm[/yyyy], hh:mm]
const pattern = /^\[(\d+)[\/\.-](\d+)[\/\.-]?(\d+)?, (\d\d:\d\d:?\d*)\] (\w+ \w+): (.+)/

// TODO: images, videos, URLs

function whatsappMessages(txt: string) {
	const lines = txt.split('\n')
	// test first line to detect
	if (lines.length <= 0 || !pattern.test(lines[0]))
		return null
	const matches = lines.map(ln => ln.match(pattern))
	if (matches.filter(U.any.isTrue).length <= 0)
		return null
	const msgs: mdl.ItemWithRefSpec[] = []
	for (let i = 0, len = matches.length; i < len; ++i) {
		const m = matches[i]
		if (m) {
			msgs.push({
				props: {
					author: { string: m[5].trim(), hidden: true },
					time: { dateTime: buildDate(m), hidden: true },
					text: { text: m[6] }
				},
				tmpls: [{ ref: 'comment.tmpl' }]
			})
		} else {
			msgs[msgs.length - 1].props.text['text'] += '\n' + lines[i]
		}
	}
	return msgs
}

function buildDate([s, d, m, y, t]: RegExpMatchArray) {
	if (!y)
		y = '' + new Date(Date.now()).getFullYear()
	if (y.length === 2)
		// before 2000 there was no Whatsapp ;)
		y = '20' + y
	return new Date(`${y}-${m}-${d}T${t}`)
}
