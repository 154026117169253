
export function bulk<T = any>(readFn: (ids: string[]) => Promise<T>,
	timeMillis = 100, maxSize = -1) {
	let idMap = {}
	let pro: Promise<T>
	let resFn: (value: T | PromiseLike<T>) => void
	let rejFn: (reason?: any) => void
	let count = 0
	let timeout: any

	return (id: string) => {
		idMap[id] = 1
		if (!pro) {
			pro = new Promise<T>((res, rej) => {
				resFn = res
				rejFn = rej
				timeout = setTimeout(read, timeMillis)
			})
		}
		count++
		const p = pro
		if (maxSize >= 0 && count > maxSize) {
			clearTimeout(timeout)
			read()
		}
		return p
	}

	function read() {
		const ids = Object.keys(idMap)
		readFn(ids).then(resFn, rejFn)
		idMap = {}
		pro = null
		count = 0
	}
}
