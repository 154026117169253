import { U } from '../../../common'
import * as type from './types'

export const webDavUrl = '/remote.php/dav'

export const idRootBox = 'root.box'

export function fileIdToId(pfx: string, fileId: string) {
	return pfx + 'files_' + fileId
}

export const itemData = {

	rootBox: (id: string, baseUrl: string) => ({
		id,
		props: {
			title: 'Your NextCloud Files on ' + U.str.substringAfter(baseUrl, '://'),
			box: {
				box: 'nextcloud', mark: 'N',
				color: 'white', backColor: '#0688cf'
			},
		},
		links: [U.str.substringBefore(id, '_') + '_files'],
		tmpls: ['box.tmpl'],
		boxes: [id]
	}),

	rootFolder: (id: string, baseUrl: string, boxId: string) => ({
		id,
		props: {
			title: 'Files',
			view: { url: `${baseUrl}/index.php/apps/files` },
			icon: { icon: 'folder_open' },
		},
		tmpls: ['url.tmpl'],
		boxes: [boxId]
	}),

	folder: (id: string, props: type.FileProps, baseUrl: string, boxId: string) => ({
		id,
		props: {
			title: decodeURIComponent(U.str.substringAfterLast(
				U.str.trimEnd(props.href, '/'), '/')),
			view: { url: `${baseUrl}/index.php/apps/files?fileid=${props.id}` },
			icon: { icon: 'folder_open' },
		},
		update: {
			date: new Date(props.getlastmodified),
		},
		tmpls: ['url.tmpl'],
		boxes: [boxId]
	}),

	file: (id: string, props: type.FileProps, baseUrl: string, boxId: string) => ({
		id,
		props: {
			title: decodeURIComponent(U.str.substringAfterLast(props.href, '/')),
			view: {
				url: `${baseUrl}/index.php/apps/files?dir=/${U.str.substringAfter(
					U.str.substringBetween(props.href, webDavUrl + '/files/', '/'), '/')
					|| ''}&openfile=${props.id}`
			},
			type: props.getcontenttype,
			href: { url: baseUrl + props.href, hidden: true },
			icon: { icon: contentTypeToIcon(props.getcontenttype) },
		},
		update: {
			date: new Date(props.getlastmodified),
		},
		tmpls: ['url.tmpl'],
		boxes: [boxId]
	}),

}


function contentTypeToIcon(type: string) {
	return type.endsWith('pdf') ? 'picture_as_pdf' : type.startsWith('video') ?
		'movie' : type.startsWith('image') ? 'image' :
			type.startsWith('text') ? 'description' : 'insert_drive_file'
}

export const xml = {

	searchById: (fileId: string, user: string) =>
		`<?xml version="1.0" encoding="UTF-8"?>
<searchrequest xmlns="DAV:" xmlns:oc="http://owncloud.org/ns">
	<basicsearch>
		<select>
			<prop>
				<getlastmodified />
				<getcontenttype />
				<oc:permissions />
				<oc:size />
				<getcontentlength />
				<oc:tags />
			</prop>
		</select>
		<from>
			<scope>
				<href>/files/${user}</href>
			</scope>
		</from>
		<where>
			<eq>
				<prop><oc:fileid/></prop>
				<literal>${fileId}</literal>
			</eq>
		</where>
		<orderby/>
	</basicsearch>
</searchrequest>`,

	searchFileIds: (q: string, user: string) =>
		`<?xml version="1.0" encoding="UTF-8"?>
<searchrequest xmlns="DAV:" xmlns:oc="http://owncloud.org/ns">
	<basicsearch>
		<select>
			<prop>
				<oc:fileid />
			</prop>
		</select>
		<from>
			<scope>
				<href>/files/${user}</href>
			</scope>
		</from>
		<where>
			<like caseless="yes">
				<prop><displayname/></prop>
				<literal>%${q}%</literal>
			</like>
		</where>
		<orderby>
			<order>
				<prop><displayname/></prop>
				<ascending/>
			</order>
		</orderby>
	</basicsearch>
</searchrequest>`,

	listFileIds: `<?xml version="1.0" encoding="UTF-8"?>
<propfind xmlns="DAV:" xmlns:oc="http://owncloud.org/ns">
	<prop>
		<oc:fileid/>
	</prop>
</propfind>`,

}

