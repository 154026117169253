import { computed, observable, signal } from '../common'
import { Item } from './Item'

export class Query {
	constructor(public item: Item, data: { query: string }) {
		this.select = data?.query
	}
	@observable select: string
	// only OR-terms so far...
	// TODO: implement query language
	@computed get terms() {
		return this.select ? this.select.split(' ') : []
	}
	@observable results: Item[]
	findResult(id: string): Item {
		return this.results.find(itm => itm.id === id)
	}
	run = signal<() => void>()
}
