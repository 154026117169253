import { observable } from '../common'
import * as mdl from './base'

export class UserInterface {
	nav = new mdl.Navigation()
	@observable dialogs: mdl.Dialog[] = []
	// clipboard
	@observable clipboard: Clipboard
	// config
	@observable longTouchSelect = true
	// the component currently active (context menu, selection box,...)
	@observable activeComponent = null
}

export interface Clipboard {
	content?: (mdl.Link | mdl.Item)[]
	links?: (mdl.Link | mdl.Item)[]
}
