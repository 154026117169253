import { readResponse, request, U } from '../../../common'
import * as mdl from '../../../model'
import { bulk } from '../couch/bulk'
import { buildEntitiesQuery, buildFromLinksQuery, csvToFromLinks, csvToItemData, idPrefix, idRootBox, refToId, specials } from './queries'

/**
 * One-box-only, read-only storage.
 * 
 * Root box (wikidata.org_root.box)
 * 
 * Item IDs like wikidata.org_Q123
 * 
 */

export class WikiDataAccess implements mdl.BoxStorageAccess {

	constructor(private props: mdl.BoxStorageAccessArgs, private log: mdl.Logger) { }

	async readPermissions(fromBoxId: string): Promise<mdl.BoxPermissions> {
		if (!navigator.onLine)
			return null
		return fromBoxId === idRootBox ? 'ro' : null
	}

	async readData(fromBoxId: string, filter?: 'boxes') {
		// TODO: box scope
		return fromBoxId === idRootBox &&
			(filter === 'boxes' || filter === 'admin')
			? [await this.readItem(idRootBox, [idRootBox])]
			// TODO: implement... really needed?
			: []
	}

	async readIds(fromBoxId: string) {
		// TODO: implement... really needed?
		// TODO: box scope
		return []
	}

	private bulkRead = bulk(ids => this.readItems(ids, [idRootBox])
		.then(data => U.array.toObject
			// type interference seems to have problems here
			<mdl.ItemData, string, mdl.ItemData>(data, U.obj.toId)), 100, 5)

	async readItem(id: string, fromBoxIds: string[]) {
		if (!navigator.onLine)
			return null
		if (!id.startsWith(idPrefix))
			return null
		if (!fromBoxIds.includes(idRootBox) && id !== idRootBox)
			return null
		const data = await this.bulkRead(id)
		return data[id] ?? null
	}

	private cache: { [id: string]: mdl.ItemData } = {}
	private cacheTimeout: any

	async readItems(ids: string[], fromBoxIds: string[]) {
		if (!navigator.onLine)
			return []
		if (!fromBoxIds.includes(idRootBox) && !ids.includes(idRootBox))
			return []
		const _ids = ids.filter(id => id.startsWith(idPrefix))
		if (_ids.length <= 0)
			return []
		// generated items
		for (const id of _ids) {
			if (id in this.cache)
				continue
			const [wiki, key] = id.split('_')
			if (key in specials)
				this.cache[id] = specials[key](id)
		}
		// items to read
		const toRead = _ids.filter(id => !(id in this.cache))
		if (toRead.length > 0) {
			const q = buildEntitiesQuery(toRead)
			const csv = await requestSparql(this.props.url, q)
			const data = csvToItemData(csv)
			for (const d of data)
				this.cache[d.id] = d
		}
		// clear cache
		if (this.cacheTimeout)
			clearTimeout(this.cacheTimeout)
		this.cacheTimeout = setTimeout(() => { this.cache = {} }, 5000)
		// return available data
		return ids.map(id => id in this.cache ? this.cache[id] : null)
	}

	async readFromLinks(id: string, fromBoxIds: string[]) {
		if (!navigator.onLine)
			return []
		if (!fromBoxIds.includes(idRootBox))
			return []
		if (!id.startsWith(idPrefix))
			return []
		const q = buildFromLinksQuery(id)
		const csv = await requestSparql(this.props.url, q)
		return csvToFromLinks(csv)
	}

	async search(query: string, fromBoxIds: string[]) {
		const res = await requestSearch(this.props.searchUrl, query)
		return res.search?.map(s => refToId(s.id)) ?? []
	}

	async writeItem(data: mdl.ItemData, intoBoxId: string) {
	}

	async removeItem(id: string, fromBoxId: string) {
	}

	async getBoxes(knownBoxIds: string[]) {
		return await Promise.all([idRootBox]
			.map(async id => ({ id, permissions: await this.readPermissions(id) })))
	}

	async addBox(box: mdl.Box) {
	}

	async removeBox(boxId: string) {
	}

	async open(boxId?: string) {
	}

	async close(boxId?: string) {
	}

}

async function requestSparql(url: string, q: string): Promise<string> {
	const resp = await request(url, {
		headers: {
			'Accept': 'text/csv',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		method: 'POST',
		body: 'query=' + encodeURIComponent(q)
	})
	return readResponse(resp)
}

interface SearchResponse {
	search: { id: string }[]
}

async function requestSearch(url: string, q: string) {
	const resp = await request(url, {
		headers: {
			'Accept': 'application/json',
			'Origin': '*',
		},
	}, {
		action: 'wbsearchentities',
		search: q,
		language: 'en',
		format: 'json',
		origin: '*',
	})
	return readResponse<SearchResponse>(resp)
}
