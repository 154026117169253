import { reaction, U } from '../common'
import * as mdl from '../model'
import { StorageEvent, SyncStorage } from './Storage'

/** Delay for changes to be collected before actually stored. */
export const storageDelay = 500

const STORAGE_KEY_CHANGE_ACTIONS = 'change_actions'

export const setup = {

	changeActions: {
		store: ({ changes, sysStorage }:
			{ changes: mdl.ChangeManager, sysStorage: SyncStorage }) => {
			// TODO: timeline view
			// store(sysStorage, STORAGE_KEY_CHANGE_ACTIONS,
			// 	() => JSON.stringify(changes.actions), action(s => {
			// 		changes.actions = JSON.parse(s)
			// 	}))
		},
		external: ({ changes, sysStorage }:
			{ changes: mdl.ChangeManager, sysStorage: SyncStorage }) => {
			// TODO: implement
			// 	observeStorage(sysStorage, STORAGE_KEY_CHANGE_ACTIONS, str => {
			// const actions = JSON.parse(str)
			// action(() => {
			// })()
			// })
		},
	},

}


function store(storage: SyncStorage, key: string,
	getData: () => string, setData: (str: string) => void) {
	const str = storage.getItem(key)
	if (str) {
		// read
		setData(str)
	} else {
		// create
		const d = getData()
		if (d)
			storage.setItem(key, d)
	}
	// update
	reaction(getData, str => {
		if (str) storage.setItem(key, str)
		else storage.removeItem(key)
	})
}

function storeEntity<T = any>(storage: SyncStorage, key: string, id: string,
	getData: () => {}, setData: (data: T) => void) {
	const str = storage.getItem(key)
	const entities = str ? JSON.parse(str) : {}
	if (id in entities) {
		// read
		setData(entities[id])
	} else {
		// create
		entities[id] = getData()
		storage.setItem(key, JSON.stringify(entities))
	}
	// update
	reaction(getData, data => {
		const str = storage.getItem(key)
		const entities = str ? JSON.parse(str) : {}
		if (id in entities && U.obj.deepEquals(data, entities[id]))
			return
		entities[id] = data
		storage.setItem(key, JSON.stringify(entities))
	})
}

function observeStorage(storage: SyncStorage,
	key: string | ((key: string) => string),
	setData: (str: string, keyReturn: string) => void) {
	const keyFn = typeof key === 'string' ?
		(k: string) => k === key ? k : null : key
	storage.onChange((evn: StorageEvent) => {
		const k = keyFn(evn.key)
		if (k && evn.newValue) setData(evn.newValue, k)
	})
}
