
import { setup as AddItemView } from './AddItemView'
import { setup as EditItemView } from './EditItemView'
import { setup as PlaceItemView } from './PlaceItemView'
import { setup as TimelineView } from './TimelineView'
import { setup as ViewItemView } from './ViewItemView'

export const setup = {
	AddItemView, EditItemView, ViewItemView, PlaceItemView, TimelineView
}
