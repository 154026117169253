import { O, reaction } from '../common'
import * as mdl from '../model'
import { setup as focusComponent } from './activeComponent'
import { setup as AddItemView } from './AddItemView'
import { setup as DataTransfer } from './DataTransfer'
import { setup as ImageIcons } from './ImageIcons'
import { setup as DesktopPwa } from './DesktopPwa'
import { setup as ServiceWorker } from './ServiceWorker'
import { setup as TouchHover } from './TouchHover'

export const setup = {
	AddItemView, DataTransfer, ImageIcons, DesktopPwa, TouchHover,
	ServiceWorker, focusComponent,

	dialog: {
		close: ({ ui }: { ui: mdl.UserInterface }) => {
			O.onInit(mdl.Dialog, dlg => {
				dlg.close.react(() => { ui.dialogs.pop() })
			})
		},
	},

	debugItemStatus: () => {
		O.onInit(mdl.Item, item => {
			let lastStatus = mdl.ItemStatus.initial
			reaction(() => item.status, s => {
				const wasMissing = lastStatus === mdl.ItemStatus.missing
				if (
					(s === mdl.ItemStatus.request1 && !wasMissing &&
						lastStatus !== mdl.ItemStatus.initial) ||
					(s === mdl.ItemStatus.level1 && !wasMissing &&
						lastStatus >= mdl.ItemStatus.level1) ||
					(s === mdl.ItemStatus.level2 && !wasMissing &&
						lastStatus >= mdl.ItemStatus.level2)
				)
					console.error(`Invalid item ${item.id} status! ${mdl.ItemStatus[s]
						} (last: ${mdl.ItemStatus[lastStatus]})`)
				// console.log(`Item ${item.id} status: ${mdl.ItemStatus[s]}`)
				lastStatus = s
			})
		})
	},

}
