import { UrlInfo } from '../../../base/src/data/import'
import { action, O, reaction, readResponse, request, U } from '../common'
import * as mdl from '../model'

interface Config {
	api: {
		services: { preview: string, proxy: string }
		linkPreview: { url: string, key: string }
	}
}

export const setup = {
	capture: ({ config, items, log }:
		{ config: Config, items: mdl.ItemManager, log: mdl.Logger }) => {
		O.onInit(mdl.Item, item => {
			item.request3.reactOnce(() => {
				reaction(() => item.props.findByType('url'), urlProp => {
					if (!urlProp) return
					const titleProp = item.props.get('title')
					if (urlProp.value && !titleProp?.value)
						captureUrlPreview(item, config, items)
							.catch(err => log.error(err, { id: item.id }))
				}, { fireImmediately: true })
			})
		})
	},

	research: ({ config, items, log }:
		{ config: Config, items: mdl.ItemManager, log: mdl.Logger }) => {
		O.onInit(mdl.Item, item => {
			item.research.react(() => captureUrlPreview(item, config, items)
				.catch(log.error))
		})
	},

}

async function captureUrlPreview(item: mdl.Item, config: Config,
	items: mdl.ItemManager) {
	// TODO: check for URL template
	const urlProp: mdl.Property<string> = item.props.findByType('url')
	if (!urlProp?.value) return
	const url = urlProp.value
	let info: UrlInfo = UrlInfo.parser['$special']?.(null, '$special', url) ?? {}
	let baseUrl = info.url ?? url
	if (!info.title || !info.icon) {
		const resp = await request(config.api.services.proxy, {}, { url: baseUrl })
		const txt = await readResponse(resp, 'text')
		const ct = resp.headers.get('Content-Type')
		const t = U.str.substringBefore(ct, ';') ?? ct
		const respUrl = resp.headers.get('X-Response-Url')
		if (respUrl) baseUrl = respUrl
		if (txt && t in UrlInfo.parser) {
			const parsedInfo = UrlInfo.parser[t](txt, t, baseUrl)
			info = Object.assign({}, parsedInfo, info)
		}
	}
	if (!info.icon)
		// try the /favicon.ico
		info.icon = await fetchIcon(
			new URL(baseUrl).origin + '/favicon.ico', config, null)
	if (typeof info.icon === 'string' && !info.icon.startsWith('data:'))
		info.icon = await fetchIcon(info.icon, config, info.icon)
	if (typeof info.image === 'string' && !info.image.startsWith('data:'))
		info.image = await fetchImage(info.image, config, info.image)
	// console.log(info)
	await setUrlInfo(item, info, items, urlProp)
}

async function fetchIcon(url: string, { api: { services } }: Config,
	defaultValue = null) {
	const icon = await request(services.proxy, {}, { url })
		.then(r => r.ok ? r.blob() : null)
		.then(U.blob.toDataUrl)
		.catch<string>(() => null)
	return icon ? { icon, source: url, class: 'small' } : defaultValue
}

async function fetchImage(url: string, { api: { services } }: Config,
	defaultValue = null) {
	const image = await request(services.proxy, {}, { url })
		.then(r => r.ok ? r.blob() : null)
		.catch<Blob>(() => null)
	return image ? { image, source: url } : defaultValue
}

async function setUrlInfo(item: mdl.Item, info: UrlInfo,
	items: mdl.ItemManager, urlProp?: mdl.Property) {
	if (!urlProp)
		urlProp = item.props.findByType('url')
	action(() => {
		if (info.url)
			if (urlProp) urlProp.value = info.url
			else item.props.set('url', info.url, 'url')
		if (info.title) item.props.set('title', info.title)
		if (info.icon) item.props.set('icon', info.icon, 'icon')
		item.tmpls.add(items.getItem('url.tmpl'))
	})()
	if (info.description && !findText(item, info.description)) {
		item.content.add(await items.createLink({
			item: {
				props: { text: { text: info.description } },
				tmpls: [items.getItem('text.tmpl')]
			}, preview: 'full',
		}), -1, 'all')
	}
	if (info.image && !findImage(item, info.image['source'])) {
		item.content.add(await items.createLink({
			item: {
				props: { image: { image: info.image } },
				tmpls: [items.getItem('image.tmpl')]
			}, preview: 'full',
		}), -1, 'all')
	}
}

function findText(item: mdl.Item, text: string) {
	return item.allAvailableLinks
		.map(ln => ln.item.props.findByType<string>('text'))
		.find(p => p?.value === text)
}

function findImage(item: mdl.Item, source: string) {
	return item.allAvailableLinks
		.map(ln => ln.item.props.findByType<mdl.ImageValue>('image'))
		.find(p => p?.value?.['source'] === source)
}
