import { HttpClient, httpClient, IGNORE_ERROR, IGNORE_ERROR_NULL, U } from '../../../common'
import * as mdl from '../../../model'
import { replaceBlobsWithRefs, replaceRefsWithBlobs } from '../Blobs'
import { bulk } from '../couch/bulk'
import { listFileNames, makeFolder, uploadFile } from '../nextcloud/NextCloudDataAccess'
import { itemToStatements, responseToItems, responseToValues, statements } from './queries'
/**
 *  
 */

export class Neo4jDataAccess implements mdl.BoxStorageAccess {

	private http: HttpClient
	private httpBlob: HttpClient
	url: string
	urlBlob: string

	constructor(private props: mdl.BoxStorageAccessArgs, private log: mdl.Logger,
		private config: mdl.Config) {
		const { url, creds } = extractCreds(this.props.url, this.props.credentials)
		this.url = url + '/tx/commit'
		this.http = httpClient(creds)
		// TODO: multiple accounts/credentials or better specialized storages #76
		{
			const { url, creds } = extractCreds(this.props.blobUrl)
			this.urlBlob = url
			this.httpBlob = httpClient(creds)
		}
	}

	async readPermissions(fromBoxId: string): Promise<mdl.BoxPermissions> {
		if (!navigator.onLine)
			return null
		return 'rw'
	}

	async readData(fromBoxId: string, filter?: 'boxes') {
		if (!navigator.onLine)
			return
		// TODO: box scope
		return filter === 'boxes' || filter === 'admin'
			? responseToItems(await this.postStatements(
				statements.getAdminItems))
			// TODO: implement... really needed?
			: []
	}

	async readIds(fromBoxId: string) {
		if (!navigator.onLine)
			return
		return responseToValues(await this.postStatements(
			statements.getItemIds(fromBoxId)))
	}

	private bulkRead = bulk(ids => this.readItems(ids, [])
		.then(data => U.array.toObject
			// type interference seems to have problems here
			<mdl.ItemData, string, mdl.ItemData>(data, U.obj.toId)), 100, 5)

	async readItem(id: string, fromBoxIds: string[]) {
		if (!navigator.onLine)
			return null
		const data = await this.bulkRead(id)
		return data[id] ?? null
	}

	private cache: { [id: string]: mdl.ItemData } = {}
	private cacheTimeout: any

	postStatements(...statements: any[]) {
		return this.http.post(this.url,
			{ statements: statements.flat().filter(U.any.isTrue) })
	}

	async readBlob(hash: string) {
		const fileNames = await this.readFileNames()
		const fileName = fileNames.find(n => n.startsWith('sha256-' + hash))
		if (!fileName)
			return null
		const blob = await this.httpBlob.getBlob(
			U.url.addParams(this.config.api.services.proxy,
				{ url: `${this.urlBlob}/${hash.substring(0, 2)}/${fileName}` }))
			.catch(IGNORE_ERROR_NULL)
		return blob ? { blob, hash } : null
	}

	async readItems(ids: string[], fromBoxIds: string[]) {
		if (!navigator.onLine)
			return []
		// items to read
		const toRead = ids.filter(id => !(id in this.cache))
		if (toRead.length > 0) {
			const items = responseToItems(await this.postStatements(
				statements.getItems(toRead)))
			await Promise.all(items.map(d => replaceRefsWithBlobs(d, this)))
			items.forEach(d => { this.cache[d.id] = d })
		}
		// clear cache
		if (this.cacheTimeout)
			clearTimeout(this.cacheTimeout)
		this.cacheTimeout = setTimeout(() => { this.cache = {} }, 5000)
		// return available data
		return ids.map(id => id in this.cache ? this.cache[id] : null)
	}

	async readFromLinks(id: string, fromBoxIds: string[]) {
		if (!navigator.onLine)
			return []
	}

	async search(query: string, fromBoxIds: string[]) {
		if (!navigator.onLine)
			return []
		return []
	}

	private filenameCache: string[] = null
	private filenameCacheTimeout = null

	async readFileNames() {
		if (!this.filenameCache)
			this.filenameCache =
				await listFileNames(this.httpBlob, this.config, this.urlBlob, 2)
		clearTimeout(this.filenameCacheTimeout)
		this.filenameCacheTimeout = setTimeout(
			() => { this.filenameCache = null }, 5 * 60 * 1000)
		return this.filenameCache
	}

	async writeItem(data: mdl.ItemData, intoBoxId: string) {
		if (!navigator.onLine)
			return
		this.cache[data.id] = data
		// find all BLOBs and replace them with an ID
		const { doc, blobs } = await replaceBlobsWithRefs(data)
		// store BLOBs as files
		const hashes = Object.keys(blobs)
		if (hashes.length > 0) {
			const fileNames = await this.readFileNames()
			const storedHashes = U.array.toObject(fileNames
				.filter(n => n.startsWith('sha256-'))
				.map(n => n.substring(7, n.indexOf('.'))))
			// make sure all folders are available
			const storedFolders = Object.keys(storedHashes)
				.map(n => n.substring(0, 2))
			const folders = hashes.map(hash => hash.substring(0, 2))
			const newFolders = U.array.difference(folders, storedFolders)
				.filter(U.array.distinct)
			await Promise.all(newFolders.map(async folderName => {
				// try to create the folder for the ID and ignore error, if it
				// already exists
				const folderUrl = `${this.urlBlob}/${folderName}`
				await makeFolder(this.httpBlob, this.config, folderUrl)
					.catch(IGNORE_ERROR)
			}))
			// store the missing blobs
			await Promise.all(hashes.map(async hash => {
				if (hash in storedHashes)
					// blob already stored
					return
				// put the blob
				const blob = blobs[hash]
				// the nextcloud GUI likes to have file extensions
				const fileName = `sha256-${hash}.${U.file.typeToFileExt(blob.type)}`
				const folderUrl = `${this.urlBlob}/${hash.substring(0, 2)}`
				await uploadFile(this.httpBlob, this.config,
					`${folderUrl}/${fileName}`, blob)
				this.filenameCache?.push(fileName)
			}))
		}
		// store the actual data
		const statements = itemToStatements(doc, intoBoxId)
		const resp = await this.postStatements(statements)
		if (resp.errors?.length > 0)
			for (const e of resp.errors)
				this.log.error(e)
	}

	async removeItem(id: string, fromBoxId: string) {
		if (!navigator.onLine)
			return
		await this.postStatements(statements.deleteBoxLink(id, fromBoxId))
		delete this.cache[id]
	}

	async getBoxes(knownBoxIds: string[]) {
		if (!navigator.onLine)
			return
		return await Promise.all(responseToItems(
			await this.postStatements(statements.getBoxItems))
			.map(async ({ id }) => ({ id, permissions: await this.readPermissions(id) })))
	}

	async addBox(box: mdl.Box) {
		if (!navigator.onLine)
			return
		// new empty box stored only within this storage
		if (box.allStorages.length === 1 && box.allStorages[0].access === this)
			// TODO: build box item data and write it 
			return
		// TODO: manage boxes and check more efficiently
		const idRevs = await this.readIds(box.id)
		if (idRevs.find(idRev => idRev.id === box.id))
			// box already stored
			return
		// read all items from the box and store them in this storage
		const src = box.availableStorages.find(s => s.access !== this)
		if (src) {
			const ids = (await src.access.readIds(box.id)).map(idRev => idRev.id)
			const batchSize = 100
			for (let b = 0, bCount = ids.length / batchSize; b < bCount; ++b) {
				const data = await src.access.readItems(
					ids.slice(b * batchSize, b * batchSize + batchSize), [box.id], true)
				await Promise.all(data.map(d => this.writeItem(d, box.id)))
			}
		}
	}

	async removeBox(boxId: string) {
		if (!navigator.onLine)
			return
		await this.postStatements(statements.deleteBox(boxId))
	}

	async open(boxId?: string) {
	}

	async close(boxId?: string) {
	}

}


function extractCreds(url: string, defaultCreds = null) {
	const m = url.match(/https?:\/\/(\w+):([^@\/]+)@\w/)
	const creds = m.length >= 3 ? { id: m[1], password: m[2] } : null
	return creds
		? { url: url.replace(creds.id + ':' + creds.password + '@', ''), creds }
		: { url, creds: defaultCreds }
}
