
import * as mdl from '../../../model'
import { WikiDataAccess } from './WikiDataAccess'

export interface Context {
	items: mdl.ItemManager
	boxes: mdl.BoxManager
	log: mdl.Logger
}

export const setup = {

	access: (ctx: Context) => {

		mdl.BoxStorage.protocols['wikidata'] = function(props): mdl.BoxStorageAccess {
			if (!props.url)
				return null
			const da = new WikiDataAccess(props, ctx.log)
			return da
		}
	},

}

