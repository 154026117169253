import { setup as boxes } from './boxes'
import { setup as cache } from './cache'
import { setup as components } from './components'
import { setup as read } from './read'
import { setup as update } from './update'
import { setup as views } from './views'

export const setup = { read, views, components, boxes, cache, update }


