import { U } from '../../../common'
import { UrlInfo } from './common'

UrlInfo.parser['application/json'] = jsonToUrlInfo

function jsonToUrlInfo(txt: string, ct: string, url: string) {
	const obj = JSON.parse(txt)
	for (const interpret of UrlInfo.interpret) {
		const info = interpret(obj)
		if (info)
			return info
	}
	// no specific interpretation found => extract info from general JSON
	const info: UrlInfo = { url }
	if ('title' in obj) {
		info.title = obj.title
		info.description = obj.description
	} else {
		info.title = U.obj.findMemberValue(obj,
			v => typeof v === 'string' && v.length > 2)
		if (info.title?.length > 30) {
			info.description = info.title.substring(0, 120) +
				(info.title.length > 120 ? '...' : '')
			info.title = info.title.substring(0, 30) + '...'
		}
	}
	if (!info.title) {
		// still no title found => use the text itself
		info.title = txt.substring(0, 30) + (txt.length > 30 ? '...' : '')
		info.description = txt.substring(0, 120) +
			(txt.length > 120 ? '...' : '')
	}
	return info
}

/** interpret error message */
function errorToInfo(obj: any) {
	const errorMsg = U.error.extractMessage(obj)
	if (!errorMsg)
		return null
	const info: UrlInfo = {}
	info.title = errorMsg.substring(0, 30) +
		(errorMsg.length > 30 ? '...' : '')
	info.description = errorMsg
	return info
}

UrlInfo.interpret.push(errorToInfo)
