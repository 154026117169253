import { httpClient } from '../../../common'
import * as mdl from '../../../model'
import { idPrefix, idRootBox, lists, Query, singles } from './queries'
import { Issue } from './types'

/**
 * One-box-only storage.
 * Read-only so far...
 * 
 * Root box (gitlab.com_root.box), box per group (gitlab.com_groups_12.box) or 
 * per project (gitlab.com_projects_13.box)
 * 
 * Item IDs like gitlab.com_projects_13, gitlab.com_groups_12,
 * gitlab.com_projects_13_commits_6104942438c14ec7bd21c6cd5bd995272b3faff6,
 * gitlab.com_projects_13_issues_14, gitlab.com_users_10, gitlab.com_todos_15,
 * 
 */

export class GitlabDataAccess implements mdl.BoxStorageAccess {

	private http: ReturnType<typeof httpClient>
	url: string
	searchUrl: string

	private cache = {}
	private cacheTimeout: any

	constructor(private props: mdl.BoxStorageAccessArgs, private log: mdl.Logger) {
		this.url = this.props.url.endsWith('/api/v4')
			? this.props.url : this.props.url + '/api/v4'
		this.searchUrl = this.props.searchUrl || this.url
		this.http = httpClient('token' in this.props.credentials ?
			this.props.credentials.token : this.props.credentials['accessToken'])
	}

	async readPermissions(fromBoxId: string): Promise<mdl.BoxPermissions> {
		if (!navigator.onLine)
			return null
		let p: mdl.BoxPermissions = 'ro'
		try {
			// TODO: impl. write permission per project and group
		}
		catch (err) {
			if (err.status !== 403)
				throw err
		}
		return p
	}

	async readData(fromBoxId: string, filter?: 'boxes') {
		// TODO: box scope
		return filter === 'boxes' || filter === 'admin'
			? [await this.readItem(idRootBox, [idRootBox], true)]
			// TODO: implement... really needed?
			: []
	}

	async readIds(fromBoxId: string) {
		// TODO: implement... really needed?
		// TODO: box scope
		return []
	}

	async readItem(id: string, fromBoxIds: string[], completely: boolean) {
		if (!navigator.onLine)
			return null
		if (!fromBoxIds.includes(idRootBox) && id !== idRootBox)
			return null
		if (!id.startsWith(idPrefix + '_'))
			return null
		const parts = id.split('_')
		// get query for ID
		const lastPart = parts[parts.length - 1]
		const secondLastPart = parts[parts.length - 2]
		const query: Query = lastPart in lists ? lists[lastPart] :
			secondLastPart in singles ? singles[secondLastPart] : null
		if (!query)
			throw new Error(`Unable to determine query from ${id}!`)
		// get data for ID
		let data: any
		if (id in this.cache) {
			data = this.cache[id]
		} else {
			// get data for ID
			const path = typeof query.toPath === 'function'
				? query.toPath(parts.slice(1))
				: parts.slice(1).join('/')
			if (path === null) {
				// no data needed
				data = null
			} else {
				data = await this.http.get(this.url + '/' + path)
				if (Array.isArray(data)) {
					if ('toId' in query)
						data.forEach(d => { this.cache[query.toId(d, id, parts)] = d })
				} else {
					this.cache[id] = data
				}
				// clear cache after 1min
				if (!this.cacheTimeout)
					clearTimeout(this.cacheTimeout)
				this.cacheTimeout = setTimeout(() => {
					this.cacheTimeout = null
					this.cache = {}
				}, 60 * 1000)
			}
		}
		// tranform data into itemData
		return query.transform(id, data, parts)
	}

	async readItems(ids: string[], fromBoxIds: string[], completely: boolean) {
		if (!navigator.onLine)
			return []
		if (!fromBoxIds.includes(idRootBox) && !ids.includes(idRootBox))
			return []
		return await Promise.all(
			ids.map(id => this.readItem(id, fromBoxIds, completely)))
	}

	async readFromLinks(id: string, fromBoxIds: string[]) {
		// TODO: implement...
		return []
	}

	async search(query: string, fromBoxIds: string[]) {
		const resp = await this.http.get<Issue[]>(this.searchUrl + '/search', {
			scope: 'issues', search: query
		})
		return resp.map(i =>
			lists.issues.toId(i, lists.projects.toId({ id: i.project_id }) + '_issues'))
	}

	async writeItem(data: mdl.ItemData, intoBoxId: string) {
	}

	async removeItem(id: string, fromBoxId: string) {
	}

	async getBoxes(knownBoxIds: string[]) {
		return await Promise.all([idRootBox]
			.map(async id => ({ id, permissions: await this.readPermissions(id) })))
	}

	async addBox(box: mdl.Box) {
	}

	async removeBox(boxId: string) {
	}

	async open(boxId?: string) {
	}

	async close(boxId?: string) {
	}

}
