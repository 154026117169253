import { O, setComputed } from '../../common'
import * as mdl from '../../model'

export const setup = {

	init: ({ changes }: { changes: mdl.ChangeManager }) => {
		O.onInit(mdl.TimelineView, view => {
			setComputed(view, 'actions', () => changes.actions)
		})
	}
}
