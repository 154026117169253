import { parseCsv } from '../../../../../common/src/common/parse/csv'
import { U } from '../../../common'
import * as mdl from '../../../model'

export const idPrefix = 'wikidata.org_'
export const idRootBox = idPrefix + 'root.box'
export const idUrlPrefix = idPrefix + 'url_'
export const idImgPrefix = idPrefix + 'img_'

const urlPrefixWikiCommons = 'http://commons.wikimedia.org/wiki/Special:FilePath/'
const iconWikipedia = 'https://en.wikipedia.org/static/favicon/wikipedia.ico'

export function idToRef(id: string) {
	return id.startsWith(idUrlPrefix) ? `<${idToUrl(id)}>` :
		id.startsWith(idImgPrefix) ? `<${idToImgUrl(id)}>` :
			id.replace(idPrefix, 'wd:')
}

export function idToUrl(id: string) {
	return id.startsWith(idUrlPrefix) ?
		U.base64.decode(id.substring(idUrlPrefix.length)) : null
}

export function idToImgUrl(id: string) {
	return id.startsWith(idImgPrefix) ?
		toImageUrl(U.base64.decode(id.substring(idImgPrefix.length))) : null
}

export function refToId(ref: string) {
	return idPrefix + ref
}

function minimizeQuery(q: string) {
	return q.split('\n').map(ln => ln.trim())
		.map(ln => ln.startsWith('#') ? '' : ln).join(' ')
}

export function buildEntitiesQuery(ids: string[]) {
	return minimizeQuery(`
	SELECT DISTINCT (substr(str(?item), 32) as ?id) ?relLabel ?type ?unit ?objLabel
	WHERE {
		hint:Query hint:optimizer 'None' .
		VALUES ?item { ${ids.map(idToRef).join(' ')} }
		{
			# wikidata strings
			VALUES ?relate { 
				# given name, family name, official name, nickname, sex or gender
				wdt:P735 wdt:P734 wdt:P1448 wdt:P1449 wdt:P21
				# native label, title, main subject, genre, occupation
				wdt:P1705 wdt:P1476 wdt:P921 wdt:P136 wdt:P106
				# use, chemical formula, E number
				wdt:P366 wdt:P274 wdt:P628
			}
			?item ?relate ?obj.
			?rel wikibase:directClaim ?relate.
			BIND("str" AS ?type).
			BIND(lang(?obj) AS ?unit).
			BIND("2" AS ?order).
		}
		UNION {
			# label
			?item rdfs:label ?obj.
			FILTER (lang(?obj) IN ('en'))
			BIND("label" AS ?relLabel).
			BIND("lbl" AS ?type).
			BIND(lang(?obj) AS ?unit).
			BIND("1" AS ?order).
		}
		UNION {
			BIND("area" AS ?relLabel).
			?item p:P2046/psv:P2046 ?v.
			?v wikibase:quantityAmount ?obj.
			OPTIONAL {
				?v wikibase:quantityUnit/wdt:P5061 ?unit.
				FILTER(lang(?unit) IN ("en"))
			}
			BIND("num" AS ?type).
			BIND("3" AS ?order).
		}
		UNION {
			# dates
			VALUES ?relate { 
				# date of birth, date of death, inception, publication date
				wdt:P569 wdt:P570 wdt:P571 wdt:P577
			}
			?item ?relate ?obj.
			?rel wikibase:directClaim ?relate.
			BIND("dat" AS ?type).
			BIND("4" AS ?order).
		}
		UNION {
			# images
			VALUES ?relate { 
				# image, locator map image, flag image, logo image
				wdt:P18 wdt:P242 wdt:P41 wdt:P154
				# chemical structure
				wdt:P117
			}
			?item ?relate ?obj.
			?rel wikibase:directClaim ?relate.
			BIND("img" AS ?type).
			BIND("5" AS ?order).
		}
		UNION {
			# urls
			VALUES ?relate { 
				# official website, Open Food Facts food additive ID, Microsoft Academic ID
				wdt:P856 wdt:P1820 wdt:P6366
			}
			?item ?relate ?obj.
			?rel wikibase:directClaim ?relate.
			OPTIONAL { ?rel wdt:P1630 ?unit }
			BIND("url" AS ?type).
			BIND("6" AS ?order).
		}
		UNION {
			# wikipedia page
			?obj schema:about ?item;
					schema:isPartOf <https://en.wikipedia.org/>.
			BIND("page" AS ?relLabel).
			BIND("url" AS ?type).
			BIND("6" AS ?order).
		}
		UNION {
			VALUES ?class {
				# human, country, business, continent, city,
				# administrative territorial entity
				wd:Q5 wd:Q6256 wd:Q4830453 wd:Q5107 wd:Q515 wd:Q56061
				# literary work, fictional entity, film
				wd:Q7725634 wd:Q14897293 wd:Q11424
				# chemical compound
				wd:Q11173 wd:Q2221906
			}
			?item ?relate ?obj.
			# exclude diplomatic relation, twinned administration body
			FILTER (?relate NOT IN (wdt:P530, wdt:P190)).
			?obj wdt:P31/wdt:P279* ?class.
			?rel wikibase:directClaim ?relate.
			BIND(substr(str(?obj), 32) AS ?objLabel).
			OPTIONAL { 
        ?obj rdfs:label ?unit
    		FILTER (lang(?unit) IN ('en'))
      }
			BIND("rel" AS ?type).
			BIND("9" AS ?order).
		}
		SERVICE wikibase:label { bd:serviceParam wikibase:language "en" }
	}
	ORDER BY ?item ?order ?relLabel ?unit
	LIMIT 10000
	`)
}

export function csvToItemData(csv: string) {
	const items: mdl.ItemData[] = []
	let item: mdl.ItemData
	for (const parts of parseCsv(csv)) {
		if (parts.length < 5 || parts[0] === 'id')
			continue
		if (parts.length > 5)
			parts[4] = parts.slice(4).join(',')
		const [id, rel, type, unit, val] = parts
		if (!item || id !== item.id) {
			item = {
				id, props: {}, links: [],
				tmpls: ['list.tmpl'],
				boxes: [idRootBox]
			}
			items.push(item)
		}
		// TODO: validate URLs,...
		switch (type) {
			case 'str':
			case 'lbl':
				item.props[rel] = rel in item.props ? item.props[rel] + ', ' + val : val
				break
			case 'num':
				item.props[rel] = unit ? { number: Number(val), unit } : Number(val)
				break
			case 'dat':
				item.props[rel] = { date: new Date(val) }
				break
			case 'rel':
				item.links.push({ name: rel, url: refToId(val) })
				break
			case 'img':
				item.links.push({
					name: rel === 'image' ? void 0 : rel, preview: 'full',
					url: idImgPrefix + U.base64.encode(toWikiCommonsImageName(val))
				})
				break
			case 'url':
				item.links.push(idUrlPrefix + U.base64.encode(
					unit ? unit.replace('$1', val) : val))
				break
		}
	}
	for (const item of items) {
		item.id = refToId(item.id)
		if (!('icon' in item.props)) {
			const img = item.links
				.find(ln => typeof ln === 'object' && ln.url.startsWith(idImgPrefix))
			item.props.icon = {
				icon: img ? toImageUrl(
					U.base64.decode(img['url'].substring(idImgPrefix.length)), 64) :
					'text:' + item.props.label?.substring(0, 1) ?? '?'
			}
		}
	}
	return items
}

export function buildFromLinksQuery(id: string) {
	return minimizeQuery(`
	SELECT DISTINCT (substr(str(?obj), 32) as ?fromId) ?objLabel
	WHERE {
		VALUES ?item { ${idToRef(id)} }
		VALUES ?class {
			wd:Q5 wd:Q6256 wd:Q4830453 wd:Q5107 wd:Q515 wd:Q56061
			wd:Q7725634 wd:Q14897293 wd:Q11424 wd:Q11173
		}
		VALUES ?relate {
			# basin country, part of, has part, owned by,
			# located in or next to body of water
			wdt:P205 wdt:P361 wdt:P527 wdt:P127 wdt:P206
			# inspired by, based on, present in work
			wdt:P941 wdt:P144 wdt:P1441
			# official website, Open Food Facts food additive ID, Microsoft Academic ID
			wdt:P856 wdt:P1820 wdt:P6366
			# image, locator map image, flag image, logo image
			wdt:P18 wdt:P242 wdt:P41 wdt:P154
			# chemical structure
			wdt:P117
	}
		?obj ?relate ?item.
		FILTER (!EXISTS {
			?item ?toRel ?obj.
		})
		?obj wdt:P31/wdt:P279* ?class.
		?rel wikibase:directClaim ?relate.
		SERVICE wikibase:label { bd:serviceParam wikibase:language "en" }
	}
	ORDER BY ?objLabel
	LIMIT 1000
	`)
}

export function csvToFromLinks(csv: string) {
	const ids: string[] = []
	const lines = csv.split('\n')
	for (const ln of lines) {
		const idx = ln.indexOf(',')
		if (idx < 2)
			continue
		const fromRef = ln.substring(0, idx).trim()
		if (fromRef === 'fromId')
			continue
		ids.push(refToId(fromRef))
	}
	return ids
}

export const specials: {
	[key: string]: (id: string) => mdl.ItemData
} = {
	'root.box': id => ({
		id,
		props: {
			title: 'WikiData Box',
			box: {
				box: 'wikidata', mark: 'W',
				color: 'darkred', backColor: 'white'
			}
		},
		links: [
			'Q68261', 'Q208460', 'Q15624215', 'Q83495', 'Q132037', 'Q42'
		].map(refToId),
		tmpls: ['box.tmpl'],
		boxes: [idRootBox]
	}),
	'url': id => (url => ({
		id,
		props: {
			url: { url },
		},
		tmpls: ['url.tmpl'],
		boxes: [idRootBox]
	}))(idToUrl(id)),
	'img': id => (url => ({
		id,
		props: {
			image: { image: toImageUrl(url, 640) },
			icon: { icon: toImageUrl(url, 64) },
			caption: decodeURIComponent(url),
		},
		tmpls: ['image.tmpl'],
		boxes: [idRootBox]
	}))(U.base64.decode(id.substring(idImgPrefix.length))),
}

function toWikiCommonsImageName(url: string) {
	return url.replace(urlPrefixWikiCommons, '')
}

function toImageUrl(url: string, width?: number) {
	return /http:\/\//.test(url) ? url : width ?
		`${urlPrefixWikiCommons}${url}?width=${width}` :
		urlPrefixWikiCommons + url
}
