import { O } from '../../common'
import * as mdl from '../../model'

export const setup = {

	completeItem: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.ItemView, async (view, id: string) => {
			const idx = id.indexOf(',')
			if (idx > 0) id = id.substring(0, idx)
			view.item = await items.getItem(id).complete()
		})
	},

}
