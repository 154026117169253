import { Env } from "../common"

export function delayAsyncFn<F extends (...args: any[]) => Promise<any>>(
	fn: F, millis: number, thisArg: any = null): F {
	return function() {
		return new Promise<any>((res, rej) => {
			setTimeout(() => {
				fn.apply(thisArg, arguments as any).then(res, rej)
			}, millis)
		})
	} as F
}

export function wrapForNextTick(
	fn: (...args: any[]) => any, context?: any): (...any: any[]) => any {
	return function(...args) {
		window.setTimeout(() => {
			fn.apply(context || null, args)
		})
	}
}

export function runWithNextTick(fn: (...args: any[]) => any): void
export function runWithNextTick(fn: (...args: any[]) => any,
	context: any, ...args: any[]): void
export function runWithNextTick(fn: (...args: any[]) => any,
	context?: any, ...args: any[]) {
	if (context)
		window.setTimeout(() => { fn.apply(context, args) })
	else
		window.setTimeout(fn)
}

export function wait<T>(time: number, returnValue?: T) {
	return new Promise<T>(r => { setTimeout(r, time, returnValue) })
}

/** Run fn after the promise (promise.then(fn)) or directly fn(promise),
	 *  if not a promise. */
export function after<T = void, R = void>(promise: T | Promise<T>,
	fn: (v: T) => R) {
	if (promise instanceof Promise)
		return promise.then(fn)
	else
		return fn(promise)
}

// nextTick
const nextTickFns: (() => void)[] | null = !Env.isNodeJs ? [] : null
function runNextTickFns() {
	if (!nextTickFns)
		return
	const fns = ([] as (() => void)[]).concat(nextTickFns)
	nextTickFns.length = 0
	for (let fn of fns) fn()
}

declare const setImmediate: typeof window['setImmediate']

export function nextTick(fn: () => void) {
	if (typeof process !== 'undefined' && typeof process.nextTick === 'function')
		process.nextTick(fn)
	else if (nextTickFns) {
		nextTickFns.push(fn)
		if (typeof setImmediate === 'function') setImmediate(runNextTickFns)
		else setTimeout(runNextTickFns, 0)
	} else if (typeof setImmediate === 'function') setImmediate(fn)
	else setTimeout(fn, 0)
}

export function delay(milliseconds: number) {
	return new Promise<void>((resolve, reject) => {
		function fn() { try { resolve() } catch (err) { reject(err) } }
		if (milliseconds > 0) setTimeout(fn, milliseconds)
		else nextTick(fn)
	})
}

export function isPromise<T>(obj: T | Promise<T>): obj is Promise<T> {
	return obj instanceof Promise
}

export function isPromiseLike(o: any): o is Promise<any> {
	return typeof o === 'object' && 'then' in o && typeof o.then === 'function'
}

