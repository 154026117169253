import { tryParseJsonObject } from '../../../../../common/src/common/parse/json'
import { textImporters } from '../text'
import { UrlInfo, urlInfoToTree } from './common'

type Link = HTMLLinkElement
type Meta = HTMLMetaElement & { property: string, itemprop: string }
type Img = HTMLImageElement & { itemprop: string }

interface PageInfo {
	url: string
	title: string
	type: string
	images: Img[]
	meta: Meta[]
	link: Link[]
	description: HTMLElement
}

// deprecated!
// similar to web/src/data/import/HtmlContentParser.ts

const queries = {
	title: [
		{ meta: (e: Meta) => e.property === 'og:title' && e.content },
		{ meta: (e: Meta) => e.property === 'twitter:title' && e.content },
		{ meta: (e: Meta) => e.name === 'title' && e.content },
		{ title: (e: string) => e },
	],
	url: [
		{ link: (e: Link) => e.rel === 'canonical' && e.href },
		{ meta: (e: Meta) => e.property === 'og:url' && e.content },
		{ meta: (e: Meta) => e.property === 'twitter:url' && e.content },
		{ url: (e: string) => e },
	],
	description: [
		{ meta: (e: Meta) => e.property === 'og:description' && e.content },
		{ meta: (e: Meta) => e.property === 'twitter:description' && e.content },
		{ meta: (e: Meta) => e.name === 'description' && e.content },
		{ description: (e: HTMLElement) => e.innerHTML },
	],
	icon: [
		{ meta: (e: Meta) => e.property === 'og:logo' && e.content },
		{ meta: (e: Meta) => e.itemprop === 'logo' && e.content },
		{ link: (e: Link) => e.rel === 'icon' && !!e.type && e.href },
		{ link: (e: Link) => e.rel?.includes('icon') && e.href },
		{ images: (e: Img) => e.itemprop === 'logo' && e.src },
	],
	image: [
		{ meta: (e: Meta) => e.property === 'og:image:secure_url' && e.content },
		{ meta: (e: Meta) => e.property === 'og:image:url' && e.content },
		{ meta: (e: Meta) => e.property === 'og:image' && e.content },
		{ meta: (e: Meta) => e.property === 'twitter:image' && e.content },
		{ meta: (e: Meta) => e.name === 'twitter:image:src' && e.content },
		{ meta: (e: Meta) => e.name === 'twitter:image' && e.content },
		// amazon.com
		// { images: (e: Img) => e.id === 'main-image-container' && e.src },
	]
}

function first(queries: { [k: string]: (e: any) => string }[], data: {}) {
	for (const q of queries) {
		const k = Object.keys(q)[0]
		if (k in data) {
			for (const d of data[k]) {
				const v = q[k](d)
				if (v)
					return v
			}
		}
	}
}

UrlInfo.interpret.push(pageToUrlInfo)

export function pageToUrlInfo(page: PageInfo) {
	if (!page || page.type === 'PageInfo')
		return null
	const info: UrlInfo = {
		url: first(queries.url, page),
		title: first(queries.title, page),
		description: first(queries.description, page),
		icon: first(queries.icon, page),
		image: first(queries.image, page),
	}
	return info
}

textImporters.push(newItemsForPageInfo)

function newItemsForPageInfo(txt: string) {
	if (!txt.startsWith('{') || !txt.endsWith('}'))
		return null
	const obj = tryParseJsonObject(txt)
	if (!obj || obj.type !== 'PageInfo' || !obj.url)
		return null
	const info = pageToUrlInfo(obj)
	return [urlInfoToTree(info)]
}

