import { ItemData0, migrate_v0 } from '../../../../base/src/data/migrate/migrate_v0'
import { U } from '../../common'
import * as mdl from '../../model'
import { LocalStorage, SyncStorage } from '../Storage'

const URL_PREFIX = 'box_'

interface Data {
	[id: string]: mdl.ItemData
}

export const setup = {

	access: ({ boxes }: { boxes: mdl.BoxManager },
		storage = new LocalStorage()) => {

		// observe browser storage
		addEventListener('storage', async evn => {
			if (!evn.key || !evn.key.startsWith(URL_PREFIX) ||
				evn.key === 'box_states' || evn.key.startsWith('box_reports_')) return
			const boxItem = boxes.getBoxItem(evn.key.substring(URL_PREFIX.length))
			const data: Data = JSON.parse(evn.newValue)
			if (!data)
				return
			for (const d of Object.values(data)) {
				const item = boxes.items.getItem(d.id, true)
				if (item) {
					item.build(interpretData(d.id, d))
					item.addToBox(boxItem)
				}
			}
		})

		// register protocol
		mdl.BoxStorage.protocols['local'] = boxStorageProtocolLocal(storage)
	},

}

function interpretData(id: string, data: ItemData0 | mdl.ItemData)
	: mdl.ItemData {
	if (!data) return null
	data.id = id
	return data.props ? data as mdl.ItemData :
		migrate_v0(data as ItemData0, { id: data.id, rev: data.rev, props: {} })
}

function boxStorageProtocolLocal(storage: SyncStorage) {
	return function(): mdl.BoxStorageAccess {
		return {
			readData: async (fromBoxId, filter) => {
				const data: Data = JSON.parse(storage.getItem(URL_PREFIX + fromBoxId))
				if (!data)
					return []
				for (const id of Object.keys(data))
					data[id] = interpretData(id, data[id])
				if (filter) {
					// TODO: handle other formats
					// Since the type filter should optimize this read, for
					// localstorage it does not make much sense.
				}
				return Object.values(data)
			},
			readIds: async (fromBoxId) => {
				const data: Data = JSON.parse(storage.getItem(URL_PREFIX + fromBoxId))
				if (!data)
					return []
				for (const id of Object.keys(data))
					data[id] = interpretData(id, data[id])
				return Object.values(data)
					.map(d => ({ id: d.id, rev: mdl.Item.toFullRev(d) }))
			},
			readSearchTexts: async () => null,
			readFromLinks: async id => null,
			readItem: async (id, fromBoxIds, completely) => {
				// TODO: read from all boxes => most recent rev, fill itemData.boxes
				for (const boxId of fromBoxIds) {
					// TODO: implement completely
					const items: Data = JSON.parse(storage.getItem(URL_PREFIX + boxId))
					if (items) {
						const d = interpretData(id, items[id])
						if (d)
							d.boxes = [boxId]
						return d
					}
				}
				return null
			},
			readItems: async (ids, fromBoxIds, completely) => {
				// TODO: read from all boxes => most recent rev, fill itemData.boxes
				for (const boxId of fromBoxIds) {
					// TODO: implement completely
					const items: Data = JSON.parse(storage.getItem(URL_PREFIX + boxId))
					if (items) {
						return ids.map(id => (
							{ ...interpretData(id, items[id]), boxes: [boxId] }))
					}
				}
				return null
			},
			writeItem: async (itemData, intoBoxId) => {
				const key = URL_PREFIX + intoBoxId
				const boxData: Data = JSON.parse(storage.getItem(key)) ?? {}
				// TODO: implement blob storage!
				// itemData = { ...itemData }
				// const blobs = extractBlobs(itemData)
				boxData[itemData.id] = { ...itemData }
				// optimize space
				delete boxData[itemData.id].id
				storage.setItem(key, JSON.stringify(boxData))
				// const db = await allsbeDb()
				// await db.putVal('local', itemData.id, blobs)
			},
			removeItem: async (id, fromBoxId) => {
				const key = URL_PREFIX + fromBoxId
				const data: Data = JSON.parse(storage.getItem(key))
				if (data) {
					delete data[id]
					storage.setItem(key, JSON.stringify(data))
				}
				// const db = await allsbeDb()
				// await db.delVal('local', id)
			},
			getBoxes: async (knownBoxIds: string[]) => {
				const excludeBoxIds = U.array.toObject(knownBoxIds)
				return storage.getKeys().filter(k => k.startsWith(URL_PREFIX))
					.map(k => k.substring(URL_PREFIX.length))
					.filter(id => !(id in excludeBoxIds))
					.map(id => ({ id, permissions: 'rw' as mdl.BoxPermissions }))
			},
			addBox: async (box: mdl.Box) => {
				const key = URL_PREFIX + box.id
				if (!storage.getItem(key)) {
					const data = await box.readData()
					const boxData = {}
					for (const d of data)
						boxData[d.id] = d
					storage.setItem(key, JSON.stringify(boxData))
				}
			},
			removeBox: async (boxId: string) => {
				const key = URL_PREFIX + boxId
				storage.removeItem(key)
			},
			open: () => Promise.resolve(),
			close: () => Promise.resolve(),
		}
	}
}
