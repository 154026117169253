import { action } from 'mobx'
import { O, U } from '../common'
import * as mdl from '../model'

export const setup = {

	initNew: ({ inst, items }:
		{ inst: mdl.Installation, items: mdl.ItemFactory }) => {
		O.onInit(mdl.Item, item => {
			item.initNew.reactOnce(async () => {
				if (inst.isMissing) await createInstallationItem(inst, items)
				action(() => {
					item.id = encodeId(inst.id, inst.idGen++)
				})()
			})
		})
	},

}

async function createInstallationItem(inst: mdl.Installation,
	factory: mdl.ItemFactory) {
	if (inst.creatingNew) {
		await inst.creatingNew
		inst.creatingNew = null
	} else {
		inst.creatingNew = (async () => {
			const item = factory.createItem()
			await inst.initNew(item)
			inst.setItem(item)
			item.status = mdl.ItemStatus.level2
			await item.complete()
		})()
		await inst.creatingNew
	}
}

export function encodeId(installationId: string, idNum: number) {
	return installationId + U.base32.encode(idNum)
}

export function decodeId(installationId: string, id: string) {
	return U.base32.decode(id.substring(installationId.length))
}

export function isLocalId(id: string, installationId: string) {
	return id.startsWith(installationId)
}
