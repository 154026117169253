import { O, reaction } from '../../common'
import * as mdl from '../../model'
import { SyncStorage } from '../Storage'

const STORAGE_KEY_HIDETAGS = 'hideTags'
const STORAGE_KEY_SHOWBOXMARKS = 'showBoxMarks'
export const setup = {

	showTags: ({ sysStorage }: { sysStorage: SyncStorage }) => {
		O.onInit(mdl.ViewItemView, view => {
			if (sysStorage.getItem(STORAGE_KEY_HIDETAGS) === '1')
				view.showTags = false
			reaction(() => view.showTags, v => {
				if (v)
					sysStorage.removeItem(STORAGE_KEY_HIDETAGS)
				else
					sysStorage.setItem(STORAGE_KEY_HIDETAGS, '1')
			})
		})
	},

	showBoxMarks: ({ sysStorage }: { sysStorage: SyncStorage }) => {
		O.onInit(mdl.ViewItemView, view => {
			if (sysStorage.getItem(STORAGE_KEY_SHOWBOXMARKS) === '1')
				view.showBoxMarks = true
			reaction(() => view.showBoxMarks, v => {
				if (v)
					sysStorage.setItem(STORAGE_KEY_SHOWBOXMARKS, '1')
				else
					sysStorage.removeItem(STORAGE_KEY_SHOWBOXMARKS)
			})
		})
	},

}
