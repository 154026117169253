import * as React from 'react'
import * as mdl from '../model'
import * as logo from '../style/logo.svg'
import {
	Component, css, dateTimeFormat, Icon, Link, Menu, observable, observer
} from './common'
import { BoxMark } from './common/BoxMark'
import { ContainerHeader } from './ContainerHeader'
import { ItemTags } from './ItemTags'
import { ItemContent, ItemIcon, ItemLabel } from './LayoutViews'
import { ViewItemLinks } from './ViewItemLinks'
import { FooterDetails } from './FooterDetails'

interface Props {
	view: mdl.ViewItemView
	context: {
		config: mdl.Config
		nav: mdl.Navigation
		ui: mdl.UserInterface
		session: mdl.Session
	}
}

@observer
export class ViewItem extends Component<Props> {

	@observable showHeaderComplete = false

	render() {
		const { view, view: { item, mode, selectedAsContent, selectedAsRelated,
			isTagsVisible, tags, allTags, params }, context,
			context: { nav, ui, ui: { clipboard }, session } } = this.props
		const placed = mode === 'place' && view.isPlaced(item)
		return <div
			className={css('view', item.container && 'content', mode, { placed })}>
			{mode === 'place' && <div className="place-header">
				Place Items into <ItemLabel item={view.box} context={context} />
				<button onClick={this.onClosePlace} title="Close place-items mode.">
					<Icon uri="close" /></button>
			</div>}
			<header>
				{nav.hasHistory ?
					<button onClick={this.onBack} onDoubleClick={this.onSearch}
						onContextMenu={this.onSearch}>
						<Icon uri={view.hasSelected ?
							view.allSelected ? 'checked' : 'unchecked' : 'navBack'} />
					</button> :
					<img className="logo icon" src={logo} />}
				{view.hasSelected ? <>
					<div><span>{view.selected.length} selected</span></div>
					{!item.isReadOnly && <button onClick={this.onRename}>
						<Icon uri="label" title="Rename selected links." />
					</button>}
					<button onClick={this.onCopy}>
						<Icon uri="copy" title="Copy selected links to clipboard." />
					</button>
					{!item.isReadOnly && <button onClick={this.onCut}>
						<Icon uri="cut" title="Move selected links into clipboard." />
					</button>}
					{!item.isReadOnly && <Menu ui={ui}>
						{mdl.previewLevels.map(l =>
							<span key={l} onClick={this['on_' + l]}
								title={`Show selected links in a ${l} preview.`}>
								<Icon uri={l + '_preview'} />
							</span>
						)}
						<div onClick={selectedAsContent}
							title="Link selected items as content of this item.">
							<Icon uri="content" /> link as content</div>
						<div onClick={selectedAsRelated}
							title="Link selected items as related to this item.">
							<Icon uri="related" /> link as related</div>
						<div onClick={this.onPlace}
							title="Place selected items into boxes.">
							<Icon uri="place" /> place</div>
						<div onClick={this.onDelete}
							title={'Remove selected links from this item.' +
								' The items themselves will not be deleted.'}>
							<Icon uri="delete" /> delete</div>
					</Menu>}
				</> : mode === 'move' ? <>
					<Icon uri="import_export" /><span>reorder...</span>
				</> : <>
					<div>
						<ContainerHeader item={item} context={context} params={params} />
						<div onDoubleClick={this.onEdit} onClick={this.onToggleHeader}
							className={this.showHeaderComplete ? 'complete' : null}>
							<ItemIcon item={item} context={context} />
							<span><ItemLabel item={item} context={context} /></span>
						</div>
					</div>
					{!item.isReadOnly && clipboard && mode === 'view' &&
						<button onClick={this.onPaste} className="pinned">
							<Icon uri="paste" title="Link to all items from clipboard." />
						</button>
					}
					<button onClick={this.onSearch} className="pinned">
						<Icon uri="search" title="Search item with text." />
					</button>
					{mode === 'place'
						? <>
							<button className="place"
								onClick={this.onPlaceThis} disabled={view.togglePlace.isActive}>
								<Icon uri={placed ? 'check' : 'arrow_left'} />
							</button>
							<button className="place"
								onClick={this.onPlaceAll} disabled={view.togglePlace.isActive}>
								<Icon uri={view.isPlaced(item, true) ? 'remove_done' : 'done_all'} />
							</button>
						</>
						: <Menu ui={ui}>
							<div onClick={this.onHome}
								title="Navigate to the home item.">
								<Icon uri="home" /> home</div>
							<div onClick={this.onSearch} title="Search item.">
								<Icon uri="search" />search</div>
							{session.needsLogin && <div onClick={this.onLogin}
								title="Login to access your user box.">
								<Icon uri="login" />login</div>}
							{!item.isReadOnly && <div onClick={this.onLink}>
								<Icon uri="add" />add/link</div>}
							{!item.isReadOnly && <div onClick={this.onTags}>
								<Icon uri="tag" />{isTagsVisible ? 'hide' : 'show'} tags</div>}
							<div onClick={this.onBoxMarks}
								title="Toggle Box marks next to links.">
								<Icon uri="box" /> box marks</div>
							<div onClick={this.onDetails}>
								<Icon uri="info" />details</div>
							{!item.isReadOnly && <div onClick={this.onEdit}>
								<Icon uri="edit" /> edit</div>}
							<div onClick={this.onCopy}
								title="Copy current item to be linked to.">
								<Icon uri="copy" /> copy</div>
							{!item.isReadOnly && <div onClick={this.onMove}
								title="Move or select links.">
								<Icon uri="move" /> move, select</div>}
							{!item.isReadOnly && <div onClick={this.onPlace}>
								<Icon uri="place" /> place</div>}
							{!item.isReadOnly && <div onClick={item.research}>
								<Icon uri="biotech" /> research</div>}
							{!item.isGenerated && <div onClick={item.refresh}>
								<Icon uri="refresh" /> refresh</div>}
							{!item.isReadOnly && <div onClick={this.onDelete}>
								<Icon uri="delete" /> delete</div>}
						</Menu>}
				</>
				}
			</header>
			<section>
				{item.isMissing ?
					<div className="missing">
						<div>
							Cannot find this item in any of your active <Link
								itemId="boxes.sys" nav={nav}>boxes</Link>!
						</div>
						<div>
							<button onClick={this.showBoxes}>Show Boxes</button>
							<button onClick={this.showStorages}>Show Storages</button>
							<button onClick={this.onBack}>Back</button>
						</div>
					</div> : <>
						{isTagsVisible &&
							<ItemTags item={item} tags={tags} allTags={allTags}
								context={context} />}
						<div className="content">
							<ItemContent item={item} context={context} params={params} />
						</div>
						<ViewItemLinks {...this.props} />
					</>}
			</section>
			<Footer {...this.props} />
		</div>
	}

	onToggleHeader = () => { this.showHeaderComplete = !this.showHeaderComplete }
	showBoxes = () => { this.props.context.nav.go('view', 'boxes.sys') }
	showStorages = () => { this.props.context.nav.go('view', 'storages.sys') }
	onHome = () => { this.props.context.nav.go('view', 'home') }
	onBack = () => {
		const { view, context: { nav } } = this.props
		if (!view.hasSelected)
			nav.back()
		view.selectAll()
	}
	onDetails = () => { this.props.context.nav.go('details') }
	onBoxMarks = () => { this.props.view.showBoxMarks = !this.props.view.showBoxMarks }
	onEdit = (evn?: React.MouseEvent) => {
		evn?.preventDefault()
		if (!this.props.view.item.isGenerated && this.props.view.mode === 'view')
			this.props.context.nav.go('edit')
	}
	onPlace = () => {
		const { view, context: { nav } } = this.props
		nav.go('place',
			view.hasSelected ? view.selected.map(l => l.item.id).join(',') : void 0)
	}
	onPlaceThis = () => {
		const { view } = this.props
		view.togglePlace(view.item)
	}
	onPlaceAll = () => {
		const { view } = this.props
		view.togglePlace(view.item, true)
	}
	onClosePlace = () => {
		const { view, context: { nav } } = this.props
		nav.go({ key: 'view', id: view.item.id, params: null })
	}
	onTags = () => {
		const { view, context: { ui } } = this.props
		if (view.tags.length === 0)
			setTimeout(() => { ui.activeComponent = 'test' })
		view.toggleTags()
	}
	onLink = () => {
		// delay for the click to be handled and a possible later autoFocus works
		setTimeout(() => { this.props.context.nav.go('add') })
	}
	onSearch = (evn: React.MouseEvent) => {
		evn.preventDefault()
		// delay for the click to be handled and a possible later autoFocus works
		setTimeout(() => { this.props.context.nav.go('search') })
	}
	onLogin = (evn: React.MouseEvent) => {
		evn.preventDefault()
		// delay for the click to be handled and a possible later autoFocus works
		setTimeout(() => { this.props.context.nav.go('login') })
	}
	onDelete = () => {
		const { view, context: { nav } } = this.props
		if (view.hasSelected) {
			view.item.links.remove(view.selected)
			this.reset()
		}
		else {
			view.item.delete()
			nav.back()
		}
	}
	onMove = () => {
		this.props.context.nav.go(this.props.view.mode === 'move' ? 'view' : 'move')
	}
	onRename = () => {
		const { view } = this.props
		const name = prompt('Rename', view.selected[0].name)
		if (name === null) return
		for (const ln of view.selected) ln.name = name
		this.reset()
	}
	onCopy = () => {
		const { context: { ui }, view } = this.props
		ui.clipboard = { links: view.hasSelected ? view.selected : [view.item] }
		this.reset()
	}
	onCut = () => {
		const { context: { ui }, view: { item, selected } } = this.props
		if (selected.length > 0) {
			const content = item.content.filter(ln => selected.includes(ln))
			const links = item.links.filter(ln => selected.includes(ln))
			ui.clipboard = { content, links }
			item.content.remove(content)
			item.links.remove(links)
		}
		this.reset()
	}
	onPaste = () => {
		const { context: { ui }, view: { item } } = this.props
		if ('content' in ui.clipboard)
			item.content.add(ui.clipboard.content, 0)
		if ('links' in ui.clipboard)
			item.links.add(ui.clipboard.links, 0)
		ui.clipboard = null
	}
	on_small = () => { preview(this.props.view.selected, 'small') }
	on_compact = () => { preview(this.props.view.selected, 'compact') }
	on_normal = () => { preview(this.props.view.selected, 'normal') }
	on_extended = () => { preview(this.props.view.selected, 'extended') }
	on_full = () => { preview(this.props.view.selected, 'full') }
	onDocumentKey = (evn: React.KeyboardEvent<HTMLBodyElement>) => {
		if (evn.ctrlKey && evn.key === 'i')
			navigator.clipboard.writeText(this.props.view.item.id)
		if (evn.ctrlKey || evn.altKey)
			return
		const { context: { nav } } = this.props
		switch (evn.key) {
			case 'h': this.onHome(); break
			case '+': nav.go('add'); break
			case 'a': nav.go('add'); break
			case 's': nav.go('search', '/'); break
			case 'e': this.onEdit(); break
			case 'p': this.onPlace(); break
			case 'm': this.onMove(); break
			case 'b': this.onBoxMarks(); break
			case 'r': this.props.view.item.research(); break
			case 'd': this.onDetails(); break
			case 'Delete': if (confirm('really?')) this.onDelete(); break
		}
	}


	private reset() {
		const { view, context: { nav } } = this.props
		if (view.mode !== 'view') nav.back()
		view.selected = []
	}
}

function preview(links: mdl.Link[], level: mdl.PreviewLevel) {
	for (const ln of links) ln.preview = level
}

@observer
class Footer extends Component<Props> {

	render() {
		const { view: { item, mode }, context } = this.props
		return <footer>
			<FooterDetails item={item} context={context} />
			{mode === 'view' && !item.isReadOnly &&
				<div className="buttons">
					<button className="plus" onClick={this.onLink}>+</button>
				</div>
			}
		</footer>
	}

	onLink = () => {
		// delay for the click to be handled and a possible later autoFocus works
		setTimeout(() => { this.props.context.nav.go('add') })
	}
}
