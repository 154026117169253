import { httpClient, U } from '../../../common'
import * as mdl from '../../../model'
import { idPeopleBox, idPrefix, lists, Query, search, singles } from './queries'

/**
 * One-box-only storage.
 * Read-only so far...
 * 
 * People box (google.com_people.box)
 * 
 * Item IDs like google.com_people_13, google.com_contactGroups,
 * google.com_contactGroups_14, google.com_calendars_21,
 * google.com_calendars_21_events_33,
 * 
 */

export class GoogleDataAccess implements mdl.BoxStorageAccess {

	private http: ReturnType<typeof httpClient>
	url: string
	searchUrl: string

	private cache = {}
	private cacheTimeout: any

	constructor(private props: mdl.BoxStorageAccessArgs, private log: mdl.Logger) {
		this.url = this.props.url
		this.searchUrl = this.props.searchUrl || this.url
		this.http = httpClient(this.props.credentials['accessToken'])
	}

	async readPermissions(fromBoxId: string): Promise<mdl.BoxPermissions> {
		if (!navigator.onLine)
			return null
		let p: mdl.BoxPermissions = 'ro'
		try {
			// TODO: impl. write permission per project and group
		}
		catch (err) {
			if (err.status !== 403)
				throw err
		}
		return p
	}

	async readData(fromBoxId: string, filter?: 'boxes') {
		// TODO: box scope
		return filter === 'boxes' || filter === 'admin'
			? [await this.readItem(idPeopleBox, [idPeopleBox], true)]
			// TODO: implement... really needed?
			: []
	}

	async readIds(fromBoxId: string) {
		// TODO: implement... really needed?
		// TODO: box scope
		return []
	}

	async readItem(id: string, fromBoxIds: string[], completely: boolean) {
		if (!navigator.onLine)
			return null
		if (!fromBoxIds.includes(idPeopleBox) && id !== idPeopleBox)
			return null
		if (!id.startsWith(idPrefix))
			return null
		const parts = id.split('_')
		// get query for ID
		const lastPart = parts[parts.length - 1]
		const secondLastPart = parts[parts.length - 2]
		const query: Query = lastPart in lists ? lists[lastPart] :
			secondLastPart in singles ? singles[secondLastPart] : null
		if (!query)
			throw new Error(`Unable to determine query from ${id}!`)
		const idParts = parts.splice(1)
		// get data for ID
		let data: any
		if (id in this.cache) {
			data = this.cache[id]
		} else {
			// get data for ID
			const url = query.toUrl(idParts)
			if (url === null) {
				// no data needed
				data = null
			} else {
				data = await this.http.get(url)
				this.cache[id] = data
				// clear cache after 1min
				if (!this.cacheTimeout)
					clearTimeout(this.cacheTimeout)
				this.cacheTimeout = setTimeout(() => {
					this.cacheTimeout = null
					this.cache = {}
				}, 60 * 1000)
			}
		}
		// tranform data into itemData
		return query.transform(id, data, idParts)
	}

	async readItems(ids: string[], fromBoxIds: string[], completely: boolean) {
		if (!navigator.onLine)
			return []
		if (!fromBoxIds.includes(idPeopleBox) && !ids.includes(idPeopleBox))
			return []
		return await Promise.all(
			ids.map(id => this.readItem(id, fromBoxIds, completely)))
	}

	async readFromLinks(id: string, fromBoxIds: string[]) {
		// TODO: implement...
		return []
	}

	async search(query: string, fromBoxIds: string[]) {
		const ids = await Promise.all([
			search.people, search.calendar, search.gmail, search.drive]
			.map(s => this.http.get(s.toUrl(query))
				.then(s.toIds)))
		return ids.flat().filter(U.any.isTrue)
	}

	async writeItem(data: mdl.ItemData, intoBoxId: string) {
	}

	async removeItem(id: string, fromBoxId: string) {
	}

	async getBoxes(knownBoxIds: string[]) {
		return await Promise.all([idPeopleBox]
			.map(async id => ({ id, permissions: await this.readPermissions(id) })))
	}

	async addBox(box: mdl.Box) {
	}

	async removeBox(boxId: string) {
	}

	async open(boxId?: string) {
	}

	async close(boxId?: string) {
	}

}
