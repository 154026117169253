import { action, O } from '../common'
import * as mdl from '../model'

// TODO: use icon items and allow to configure, link one per month, per ?year?
// TODO: public box with templates like these
const iconTmpl = 'data:image/svg+xml,' + `
<svg viewBox="-10 -10 84 84" xmlns="http://www.w3.org/2000/svg">
	<g style="stroke: black">
		<rect style="fill: #e0e0e0" width="60" height="54" rx="6" ry="6" x="2"
			y="8" />
		<text style="font-family: cursive; font-size: 20px" x="32" y="46"
			text-anchor="middle">2021</text>
		<path style="fill: #700000"
			d="M 8 8 H 56 A 6 6 0 0 1 62 14 V 19 H 2 V 14 A 6 6 0 0 1 8 8 Z" />
		<rect style="fill: silver" x="12" y="2" width="4" height="12" rx="2"
			ry="2" />
		<rect style="fill: silver" x="48" y="2" width="4" height="12" rx="2"
			ry="2" />
	</g>
</svg>
`.trim().replace(/\s+/g, ' ').replace(/"/g, "'")
	.replace(/[%#<>{}?]/g, encodeURIComponent)

// TODO: allow to configure month-names locale
const monthFormat = new Intl.DateTimeFormat('en-US', { month: 'long' })
const monthShortFormat = new Intl.DateTimeFormat('en-US', { month: 'short' })
// TODO: allow to configure season colors
const monthColors = ['#4080f0', '#4080f0', '#40a060', '#40a060', '#40a060',
	'#e0d000', '#e0d000', '#e0d000', '#d78179', '#d78179', '#d78179', '#4080f0']

export const setup = {

	selectDate: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.SelectDateAction, act => {
			act.start.react(async (date: Date) => {
				const item = mdl.CalendarLayout.findDateItem(act.item, date)
				if (item) {
					act.end(item.id)
				} else {
					const isMonthCalendar = mdl.CalendarLayout.isMonthCalendar(act.item)
					const yearItem = isMonthCalendar ? null :
						mdl.CalendarLayout.yearItem(act.item, date)
					const monthItem = isMonthCalendar ? null :
						mdl.CalendarLayout.monthItem(act.item, date)
					const tree = isMonthCalendar || monthItem ? dateTree(date) :
						yearItem ? monthTree(date) : yearTree(date)
					const newItem =
						await items.createNewItem(items.resolveItemRefs(tree))
					const container = monthItem ?? yearItem ?? act.item
					await container.complete()
					action(() => {
						container.content.add(newItem, -1, 'all')
						container.content.sort()
					})()
					const dateItem = mdl.CalendarLayout.findDateItem(act.item, date)
					act.end(dateItem.id)
				}
			})
		})
	}
}

function dateTree(date: Date): mdl.ItemWithRefSpec {
	return {
		props: { date: { date } },
		tmpls: [{ ref: 'date.tmpl' }],
	}
}

function monthTree(date: Date): mdl.ItemWithRefSpec {
	const icon = iconTmpl.replace('2021', monthShortFormat.format(date))
		.replace('700000', monthColors[date.getMonth()].substring(1))
	return {
		props: {
			title: monthFormat.format(date),
			month: { number: date.getMonth() + 1, hidden: true },
			date: { date: new Date(date.getFullYear(), date.getMonth(), 1) },
			icon: { icon }
		},
		content: [dateTree(date)],
		tmpls: [{ ref: 'calendar.tmpl' }],
	}
}

function yearTree(date: Date): mdl.ItemWithRefSpec {
	const year = '' + date.getFullYear()
	return {
		props: {
			title: year,
			year: { number: date.getFullYear(), hidden: true },
			icon: { icon: iconTmpl.replace('2021', year) }
		},
		content: [monthTree(date)],
		tmpls: [{ ref: 'list.tmpl' }],
	}
}

