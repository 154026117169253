import { O, signal } from './common'
import { ItemView } from './ItemView'
import { Search } from './SearchView'

export class AddItemView extends ItemView {
	readonly key = 'add'
	search = O.new(Search)
	onPaste = signal()
	addContact = signal()
	addNewItem = {
		start: signal<
			(templateId?: string, file?: File) => (void | Promise<void>)>(),
		end: signal<(newItemId: string) => void>()
	}
}
