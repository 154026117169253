import { O } from './common'
import { Item, ItemStatus } from './Item'
import { Link, PreviewLevel } from './Link'
import { PropertiesSpec } from './Properties'

export class ItemFactory {

	createItem(props?: PropertiesSpec): Item {
		const item = O.new(Item)
		if (props)
			item.props.set(props)
		return item
	}

	async createLink(spec: ItemSpec | Item | LinkSpec) {
		if (!spec)
			return null
		const item = spec instanceof Item ? spec : 'item' in spec ?
			spec.item instanceof Item ? spec.item :
				await this.createNewItem(spec.item) :
			await this.createNewItem(spec)
		const link = O.new(Link, item.id, item)
		if ('item' in spec) {
			if (spec.name) link.name = spec.name
			if (spec.preview) link.preview = spec.preview
		}
		return link
	}

	async createNewItem(spec: ItemSpec): Promise<Item>
	async createNewItem(specs: ItemSpec[]): Promise<Item[]>
	async createNewItem(spec: ItemSpec | ItemSpec[]): Promise<Item | Item[]>
	async createNewItem(spec: ItemSpec | ItemSpec[]): Promise<Item | Item[]> {
		if (Array.isArray(spec))
			return Promise.all(spec.map(sp => this.createNewItem(sp)))
		const newItem = this.createItem(spec.props)
		await newItem.initNew()
		newItem.status = ItemStatus.level1
		const linkPromises = []
		if (spec.content)
			linkPromises.push(Promise.all(spec.content.map(sp => this.createLink(sp)))
				.then(links => { newItem.content.add(links) }))
		if (spec.links)
			linkPromises.push(Promise.all(spec.links.map(sp => this.createLink(sp)))
				.then(links => { newItem.links.add(links) }))
		if (spec.tmpls) {
			for (const tmpl of spec.tmpls)
				newItem.tmpls.add(tmpl, 0)
		}
		await Promise.all(linkPromises)
		await newItem.complete()
		return newItem
	}

}

export interface LinkSpec {
	item: ItemSpec | Item
	preview?: PreviewLevel
	name?: string
}

export interface ItemSpec {
	props: PropertiesSpec
	content?: (ItemSpec | Item | LinkSpec)[]
	links?: (ItemSpec | Item | LinkSpec)[]
	tmpls?: (Item)[]
}

export interface ItemRefSpec {
	ref: string
}

export interface LinkWithRefSpec {
	item: ItemWithRefSpec | ItemRefSpec | Item
	preview?: PreviewLevel
	name?: string
}

export interface ItemWithRefSpec {
	props: PropertiesSpec
	content?: (ItemWithRefSpec | ItemRefSpec | Item | LinkWithRefSpec)[]
	links?: (ItemWithRefSpec | ItemRefSpec | Item | LinkWithRefSpec)[]
	tmpls?: (Item | ItemRefSpec)[]
}
