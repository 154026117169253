import { observable } from 'mobx'
import { O, U } from '../common'
import { action, computed } from './common'
import { Item } from './Item'
import {
	Property, PropertyType, propertyTypes, stringablePropertyTypes
} from './Property'
import { propertyActionValues } from './PropertyActionValue'

export type PropertySpec = string | number | boolean |
	{ [type in PropertyType]?: any } & { hidden?: boolean } |
	((newItem: Item) => any)
export type PropertiesSpec = { [name: string]: PropertySpec }

const labelPropTypes = { string: 1, text: 1, url: 1 }

export class Properties {

	@observable.shallow private map = new Map<string, Property>()

	constructor(public item: Item) { }

	@computed get asList() { return [...this.map.values()] }
	@computed get asObject() { return U.obj.mapToObject(this.map) }
	@computed get visible() {
		return [...this.map.values()].filter(p => !p.hidden)
	}

	*[Symbol.iterator]() {
		yield* this.map.values()
	}

	get length() { return this.map.size }
	get isEmpty() { return this.map.size <= 0 }

	keys() { return this.map.keys() }
	values() { return this.map.values() }
	get(...names: string[]) {
		for (const n of names) {
			if (this.map.has(n))
				return this.map.get(n)
		}
		return null
	}
	has(name: string) { return this.map.has(name) }

	@action hide(...names: string[]) {
		for (const n of names) {
			const p = this.map.get(n)
			if (p)
				this.set(p.name, p.value, p.type, true)
		}
	}

	find<T = any>(fn: (p: Property) => boolean): Property<T> {
		for (const p of this.map.values())
			if (fn(p))
				return p
		return null
	}

	findByType<T = any>(...types: PropertyType[]): Property<T> {
		for (const t of types) {
			for (const p of this.map.values())
				if (p.type === t)
					return p
		}
		return null
	}

	findByTypeInTmpl(...types: PropertyType[]) {
		for (const tmpl of this.item.tmpls) {
			for (const t of types) {
				for (const p of tmpl.item.props.values())
					if (p.type === t)
						return p
			}
		}
		return null
	}

	findByAction(action: string) {
		const a = propertyActionValues[action]
		for (const p of this.map.values()) {
			if (p.type === 'action' && p.value?.constructor === a)
				return p
		}
		return null
	}

	@computed get icon() {
		return this.get('icon') ?? this.findByType('icon')
	}

	@computed get title() {
		return this.get('label') ?? this.get('title')
	}

	@computed get label() {
		return this.title ?? this.find(p => !p.hidden && p.type in labelPropTypes) ??
			this.find(p => !p.hidden && p.type in stringablePropertyTypes)
	}

	@computed get content() {
		if (this.isEmpty)
			return []
		const l = this.label
		const inclLabel = !l || l.type in { 'text': 1, 'url': 1 } ||
			l.stringValue.length > 72 || !(l.type in stringablePropertyTypes)
		return this.asList.filter(p => (inclLabel || p !== this.label) &&
			p !== this.icon && p.type !== 'action' && !p.hidden)
	}

	@computed get editable() {
		const list = this.label && this.label.name !== 'caption' ?
			[this.label, ...this.asList.filter(p => p !== this.label)] :
			[...this.map.values()]
		const additions = {}
		for (const t of this.item.tmpls) {
			for (const p of t.item.props) {
				if (!this.map.has(p.name) && !(p.name in additions)) {
					const v = p.type === 'action' || p.type === 'account' ?
						O.new(p.value.constructor, this.item) :
						p.type in { color: 1, icon: 1, location: 1 } ? p.value : null
					list.push(O.new(Property, this.item, p.name, v, p.type))
					additions[p.name] = p
				}
			}
		}
		return list
	}

	set<T>(prop: Property<T>): void
	set(props: PropertiesSpec): void
	set<T>(name: string, value: T, type?: PropertyType, hidden?: boolean): void
	@action set(name: string | Property | PropertySpec,
		value?: any, type?: PropertyType, hidden?: boolean) {
		if (name instanceof Property) {
			if (name.value !== void 0)
				this.map.set(name.name, name)
		} else if (name && typeof name === 'object') {
			for (const n of Object.keys(name)) {
				const spec = name[n]
				if (typeof spec === 'object' && spec.constructor === Object) {
					const keys = Object.keys(spec)
					const type = keys.find(k => k in propertyTypes)
					if (type)
						this.set(n, spec[type], type as PropertyType, spec.hidden)
				} else if (typeof spec === 'function') {
					this.set(n, spec(this.item))
				} else {
					this.set(n, spec)
				}
			}
		} else if (typeof name === 'string') {
			if (value === void 0)
				return

			// DEBUG: test for invalid values
			if (value && typeof value === 'object' && 'item' in value &&
				value.item !== this.item && this.item.id !== 'installation.sys')
				console.warn(`Setting property ${name} to item ${this.item.id
					} with value pointing to item ${value.item.id}!`, value)

			const p = O.new(Property, this.item, name, value, type)
			if (hidden === true)
				p.hidden = true
			this.map.set(name, p)
		}
	}

	@action delete(name: string) { return this.map.delete(name) }

}
