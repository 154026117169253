import { action, computed, IGNORE_ERROR_NULL, observable } from './common'
import { Item } from './Item'


export type PreviewLevel = 'small' | 'compact' | 'normal' | 'extended' | 'full'
export const previewLevels: PreviewLevel[] =
	['small', 'compact', 'normal', 'extended', 'full']

const nextLevel: { [k in PreviewLevel]: PreviewLevel } = {
	small: 'compact', compact: 'normal', normal: 'extended',
	extended: 'full', full: 'small',
}

export class Link {

	@observable item: Item

	@observable name: string

	@observable url: string
	@computed get refId() { return Link.parseItemId(this.url) }

	constructor(url: string, item?: Item) {
		this.url = url
		this.item = item
	}

	static parseItemId(url: string) {
		if (!url)
			return null
		const s = url.indexOf('#')
		if (s < 0)
			return url
		const id = url.substr(s + 1)
		return id || null
	}

	static toItem(link: Link | Item) {
		return link instanceof Item ? link : link?.item
	}

	/** collection helpers: links.forEach(Link.request) */
	static request(link: Link) {
		return link.item.request().catch(IGNORE_ERROR_NULL)
	}

	@observable _preview?: PreviewLevel
	@computed get preview() { return this._preview ?? 'normal' }
	set preview(v: PreviewLevel) { this._preview = v }

	@action togglePreview() {
		this.preview = nextLevel[this.preview]
	}
}
