
// to put into couchdb views
// - older, basics-only JavaScript (for couchdb 2.x)
// - try to be simple and efficient

declare function emit(a: any, b: any): void

export const views = {
	boxes: function(doc: any) {
		if (doc.type === 'box' || (doc.props && doc.props.box && doc.props.box.box))
			emit(doc._id, doc.item_rev)
	},
	admin: function(doc: any) {
		if (doc._id.indexOf('.user', doc._id.length - 5) > 0 ||
			doc.type === 'box' || doc.type === 'user')
			emit(doc._id, doc.item_rev)
		if (doc.tmpls && doc.tmpls.indexOf('tag.tmpl') >= 0)
			emit(doc._id, doc.item_rev)
		if (doc.props) {
			for (var n in doc.props) {
				var v = doc.props[n]
				if (typeof v === 'object' && ('storage' in v || 'box' in v ||
					'account' in v || 'installation' in v)) {
					emit(doc._id, doc.item_rev)
					break
				}
			}
		}
	},
	fromLinks: function(doc: any) {
		if (doc.links && !doc.isDeleted) {
			for (let ln of doc.links) {
				ln = ln && typeof ln === 'object' ? ln.url : ln
				if (ln) emit(ln, 0)
			}
		}
	},
	containers: function(doc: any) {
		if (doc.content && !doc.isDeleted) {
			for (let ln of doc.content) {
				ln = ln && typeof ln === 'object' ? ln.url : ln
				if (ln) emit(ln, 1)
			}
		}
	},
	referrers: function(doc: any) {
		if (doc.links && !doc.isDeleted) {
			for (let ln of doc.links) {
				ln = ln && typeof ln === 'object' ? ln.url : ln
				if (ln) emit(ln, 0)
			}
		}
		if (doc.content && !doc.isDeleted) {
			for (let ln of doc.content) {
				ln = ln && typeof ln === 'object' ? ln.url : ln
				if (ln) emit(ln, 1)
			}
		}
	},
	idRevs: function(doc: any) {
		if (doc._id.charAt(0) === 'i') {
			var rev = (doc.item_rev || 0) + '_' + (
				doc.update && doc.update.installationId ? doc.update.installationId :
					doc.create && doc.create.installationId ? doc.create.installationId :
						'ukn')
			emit(doc._id, rev)
		}
	},
	// implement corresponding to web/src/data/access/indexed.ts getSearchTextVals
	searchText: function(doc: any) {
		if (doc.isDeleted)
			return
		var vals = []
		if (doc.props) {
			for (var n in doc.props) {
				var v = doc.props[n]
				if (!v) continue
				var t = typeof v
				if (t === 'string') {
					if (v.indexOf(' ') > 0 || v.indexOf(':') < 4)
						vals.push(v)
				} else if (t === 'object') {
					for (var n1 in v) {
						var v1 = v[n1]
						if (v1 && typeof v1 === 'string' &&
							(v1.indexOf(' ') > 0 || v1.indexOf(':') < 4)) {
							vals.push(v1)
							break
						}
					}
				}
			}
		} else {
			if (doc.title) vals.push(doc.title)
			if (doc.description) vals.push(doc.description)
			if (doc.text) vals.push(doc.text)
			if (doc.caption) vals.push(doc.caption)
			if (doc.name) vals.push(doc.name)
		}
		if (vals.length > 0) {
			var rev = (doc.item_rev || 0) + '_' + (
				doc.update && doc.update.installationId ? doc.update.installationId :
					doc.create && doc.create.installationId ? doc.create.installationId :
						'ukn')
			emit(rev, vals.join(' '))
		}
	}
}

interface UserCtx {
	roles: string[]
	name: string
}

export const validateDocUpdate = {
	auth: function(newDoc: any, oldDoc: any, userCtx: UserCtx, secObj: any) {
		if (userCtx.roles.indexOf('_admin') < 0 && userCtx.name !== 'bruno')
			throw ({ forbidden: 'Read-only access!' })
	}
}

export const couchInitDocs = [
	{
		_id: '_design/filter', language: 'javascript',
		views: {
			boxes: { map: views.boxes.toString() },
			admin: { map: views.admin.toString() },
		}
	},
	{
		_id: '_design/index', language: 'javascript',
		views: {
			fromLinks: { map: views.fromLinks.toString() },
			containers: { map: views.containers.toString() },
			referrer: { map: views.referrers.toString() },
		}
	},
	{
		_id: '_design/map', language: 'javascript',
		views: {
			searchText: { map: views.searchText.toString() },
			idRevs: { map: views.idRevs.toString() },
		}
	},
]

