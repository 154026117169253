import { computed } from 'mobx'
import { observable, signal } from '../common'
import { Item } from '../Item'
import { Dialog } from './Dialog'

export interface GoogleContact {
	id: string
	name: string
	familyName: string
	givenName: string
	photos?: { url: string, type: 'contact' | 'profile' }[]
	extId: { contact?: string, profile?: string }
}

export class GoogleContactsDialog extends Dialog {
	readonly component = 'GoogleContacts'
	constructor(public item: Item) { super() }
	@observable contacts: GoogleContact[]
	@observable filter: string
	@computed get filteredContacts() {
		if (this.contacts && this.filter) {
			const f = this.filter.toLowerCase()
			return this.contacts.filter(c => c.name.toLowerCase().includes(f))
		}
		return this.contacts
	}
	@observable isReady = false
	load = signal()
	select = signal<(contact: GoogleContact) => void>()
}
