import * as blob from './blob'
import * as obj from './obj'

export const maxErrorMessageLength = 1000

function formatErrorMessage(msg: string) {
	return msg.length > maxErrorMessageLength ?
		msg.substring(0, maxErrorMessageLength) + '...' : msg
}

export interface ErrorData {
	name: string
	message?: string
	stack?: string[]
	error?: ErrorData
}

export function toJson(err: Error | string, inclStack = true): ErrorData {
	return !err ? null : typeof err === 'string'
		? {
			message: formatErrorMessage(err),
		}
		: {
			...obj.asEnumerableProperties(err, 6,
				v => blob.isBuffer(v)
					? formatErrorMessage(v.toString())
					: typeof v === 'string'
						? formatErrorMessage(v)
						: typeof v === 'object' && v instanceof Error
							? toJson(v, inclStack)
							: typeof v === 'bigint'
								? v + 'n'
								: v === null
									? void 0
									: v),
			stack: inclStack ? err.stack && err.stack.split
				? err.stack.split('\n')
					.map(v => formatErrorMessage(v.trim())) : err.stack : void 0,
		}
}

export function extractMessage(data?: Partial<ErrorData> | string): string | null {
	return data ?
		typeof data === 'string' ?
			data.substring(0, 120) + (data.length > 120 ? '...' : '') :
			data.error ? extractMessage(data.error) :
				data.message ? data.message :
					data.stack && data.stack.length > 0 ? data.stack[0] :
						data.name ? data.name :
							obj.findMemberValue(data as any,
								v => typeof v === 'string' && v.length > 2) ??
								data ? JSON.stringify(data) : null : null
}

export function getStackTrace(levelUp = 0): string[] {
	//try { throw new Error() } catch (err) { 
	//return err.stack.split('\n').slice(levelUp + 2) }
	return (<any>new Error()).stack.split('\n').slice(levelUp + 2)
}

