
const encode_hex =
	[...Array(256).keys()].map(n => n.toString(16).padStart(2, '0'))

export function encodeHex(buf: ArrayBuffer): string {
	const arr = new Uint8Array(buf)
	let str = ''
	for (let i = 0, len = arr.length; i < len; ++i)
		str += encode_hex[arr[i]]
	return str
}

