import { createBrowserHistory, createHashHistory, History } from 'history'
import { reaction } from '../common'
import * as mdl from '../model'

let ignoreHistory = false

const replacePatterns = ['/add/', '/move/', '/create', '/search', '/login']

export function setupBrowserHistory(nav: mdl.Navigation, config: mdl.Config,
	app: mdl.App,
	navigate: (loc: { pathname: string, search?: string }) => void,
	format: (loc: mdl.NavData) => string | null) {
	// handle navigation commands
	nav.back.react(async steps => {
		//console.log('back')
		// remove focus from buttons and alike
		void (<HTMLElement>document.activeElement).blur()
		// we might be leaving the page
		// TODO: move change management into a service worker
		await app.changes.signalSave()
		const oldHref = location.href
		const history = getHistory(config.nav.type)
		history.go(typeof steps === 'number' ? -steps : -1)
		setTimeout(() => {
			if (location.href === oldHref) {
				// no history => show home item
				nav.go('view', 'home')
				nav.hasHistory = false
			}
		}, 400)
	})
	nav.forward.react(steps => {
		//console.log('forward')
		// remove focus from buttons and alike
		void (<HTMLElement>document.activeElement).blur()
		const history = getHistory(config.nav.type)
		history.go(typeof steps === 'number' ? steps : 1)
	})
	nav.reset.react(steps => {
		const history = getHistory(config.nav.type)
		//console.log('reset')
		if (typeof steps === 'number' && steps !== 0) history.go(steps)
		// try to reset history to current point (clear forwards)
		// ugly, but working
		const path = history.location.pathname
		ignoreHistory = true
		history.go(-1)
		setTimeout(() => {
			ignoreHistory = false
			history.push(path)
		}, 200)
	})
	app.init.reactOnce(() => {
		// project browser location into navigation
		const history = getHistory(config.nav.type)
		navigate(history.location)
		history.listen(({ location }) => {
			if (ignoreHistory) return
			// console.log('listen', location)
			navigate(location)
		})
	})
	// represent navigation in browser
	reaction(() => format(nav.location), loc => {
		if (!loc) return
		const history = getHistory(config.nav.type)
		const p = history.location.pathname + history.location.search
		if (loc !== p) {
			//console.log('push ' + loc)
			// TODO: nav.replace() or something alike...
			// console.log('push', loc)
			if (p === '/' || replacePatterns.find(t => p.includes(t)))
				history.replace(loc)
			else
				history.push(loc.includes('?') ? loc : { pathname: loc, search: '' })
		}
	})
}

let _history: History
function getHistory(type: string) {
	if (!_history) {
		const baseElem = document.getElementsByTagName('base')[0]
		const baseUrl = baseElem ? baseElem.getAttribute('href') || '' : ''
		_history = type === 'html5' ? createBrowserHistory() :
			createHashHistory()
	}
	return _history
}

