import { computed, ViewBase } from './common'

interface Action {
	itemId: string
	itemLabel?: string
	boxId: string
	boxLabel?: string
	type: 'create' | 'update' | 'delete'
	start: Date
	end?: Date
}

export class TimelineView extends ViewBase {
	readonly key = 'timeline'
	@computed get isReady() { return true }
	@computed get label() { return 'Timeline' }
	actions: Action[]
}

