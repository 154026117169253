import { action, httpClient, O, U } from '../../common'
import * as mdl from '../../model'

const scopes = [
	'openid', 'profile', 'email',
	'https://www.googleapis.com/auth/contacts.readonly',
	'https://www.googleapis.com/auth/calendar.readonly',
	'https://www.googleapis.com/auth/gmail.readonly',
	'https://www.googleapis.com/auth/drive.readonly',
]

export const setup = {

	accountAccess: ({ config: { api: { google } }, log }:
		{ config: mdl.Config, log: mdl.SystemLogger }) => {
		let popupWnd: Window
		O.onInit(mdl.Account, account => {
			account.readUser.react(async (credentials: mdl.OAuthCredentials) => {
				// TODO: separate login from read-user
				// TODO: implement AccountProviders (see #65)
				if (account.provider !== 'google-oauth-implicit')
					return
				if (popupWnd)
					throw new Error('Google login already in progress!')
				// after 30s allow another login
				setTimeout(() => { popupWnd = null }, 30000)
				// login popup
				popupWnd = window.open(U.url.addParams(google.auth, {
					client_id: google.clientId,
					redirect_uri: document.baseURI + '_serv/cb',
					response_type: 'token',
					scope: scopes.join(' '),
					state: account.id,
					login_hint: account.url || void 0,
					// prompt: 'select_account',
				}), 'login', 'width=600,height=600,left=100,top=100,' +
				'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no')
				// response after redirect
				const props = await new Promise<URLSearchParams>((res, rej) => {
					window['__on-response__'] = (respUrl: string) => {
						delete window['__on-response__']
						if (popupWnd)
							popupWnd.close()
						popupWnd = null
						// console.log(respUrl)
						const hash = respUrl.substring(respUrl.indexOf('#') + 1)
						const props = new URLSearchParams(hash)
						// console.log([...props.entries()])
						res(props)
					}
				})
				if (props.get('state') !== account.id)
					throw new Error(`Invalid state (${props.get('state')
						}) in Google login response for account ${account.id}`)
				// set credentials
				credentials.accessToken = props.get('access_token')
				credentials.expires = new Date(
					Date.now() + parseInt(props.get('expires_in')) * 1000)
				// read user
				const http = httpClient(credentials.accessToken)
				const data = await http.get<Person>(
					U.url.addParams(google.people, {
						key: google.key,
						personFields: 'names,nicknames,photos,emailAddresses,externalIds',
					}))
				if (!data)
					throw new Error(`Google profile access failed!`)
				// console.log(data)
				// assume the first element is metadata.primary == true
				const email = data.emailAddresses?.[0]?.value
				credentials.id = email
				action(() => {
					account.item.props.set('email', email)
					setOptional(data.names?.[0], 'displayName', account.item, 'username')
					setOptional(data.photos?.[0], 'url', account.item, 'avatar', 'icon')
					if (data.resourceName?.startsWith('people/'))
						account.item.props.set('userId',
							data.resourceName.substring('people/'.length))
				})()
			})

		})
	},

}

interface Metadata {
	primary: boolean
}

interface Person {
	resourceName: string
	names: {
		displayName: string
		metadata: Metadata
	}[]
	emailAddresses: {
		value: string
		metadata: Metadata
	}[]
	photos: {
		url: string
		metadata: Metadata
	}[]
}

function setOptional(data: any, member: string,
	item: mdl.Item, propName: string, propType?: mdl.PropertyType) {
	if (data && member in data)
		item.props.set(propName, data[member], propType)
}
