import { O } from '../../common'
import * as mdl from '../../model'
import { newItemsFromText } from '../import'
import { requestRecentItems } from './recentItems'

export const setup = {

	createItem: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.CreateItemView, view => {
			view.createItem.react(async (text, title) => {
				const specs = newItemsFromText(text)
				if (title && specs.length === 1 && !specs[0].props.title)
					specs[0].props.title = title
				const newItem =
					await items.createNewItem(items.resolveItemRefs(
						specs.length === 1
							? specs[0]
							: { props: { title: title ?? 'text' }, content: specs }))
				view.item = newItem
			})
		})
	},

	recentItems: (props: { items: mdl.ItemManager, nav: mdl.Navigation }) => {
		O.onInit(mdl.CreateItemView, view => {
			view.recentItems = requestRecentItems(props)
		})
	},

}


