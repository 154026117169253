import * as React from 'react'
import * as mdl from '../../model'
import { Block, f, Icon, isMobile } from '../common'
import { PropertyView } from './common'

export const installation: PropertyView<mdl.Installation> = {
	label: ({ value }) => <>Installation {value?.id}</>,
	icon: () => <Icon uri="installation" />,
	content: ({ value: installation }) => installation && <>
		<Block label="Version">{installation.version}</Block>
		<Block label="Commit Date">{f.dateTime(installation.commitDate)}</Block>
		<Block label="Tools">
			<button onClick={installation.timeline}>Timeline</button>
			<button onClick={installation.reIndex}>Rebuild Indexes</button>
			{!installation.localStorage &&
				<button onClick={installation.createLocalStorage.start}>
					Create Local Storage</button>}
			{installation.localStorage?.isActive &&
				<button onClick={installation.network.toggleOnline}
					disabled={installation.network.toggleOnline.isActive}>
					Go {installation.network.isOnline ? 'Offline' : 'Online'}</button>}
			{!isMobile() &&
				<button onClick={installation.installDesktop}>
					Install on Desktop</button>}
		</Block>
		{installation.app && <Block label="App">
			<div>{installation.app.state}</div>
			<button onClick={installation.app.update}>Update</button>
			<button onClick={installation.app.skipWaiting}>Skip Waiting</button>
			<button onClick={installation.app.reload}>Reload</button>
		</Block>}
		{installation.log && <Block label="System Log">
			<pre>
				{installation.log.archive && installation.log.archive.join('\n')}
			</pre>
			<button onClick={installation.log.requestArchive}>Refresh</button>
			<button onClick={installation.log.clear}>Clear</button>
		</Block>}
	</>,
	editor: () => null,
}
