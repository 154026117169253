
export function parseInt(str: string, defaultValue?: number): number | undefined {
	const v: number | undefined = parseInt(str)
	return v !== void 0 && isNaN(v) ? defaultValue : v
}

export function parseNumbers(str: string) {
	return str ? str.split(',').map(v => parseInt(v))
		.filter(v => v !== void 0 && !isNaN(v)) : []
}

