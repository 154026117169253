import { action, ErrorX, O, reaction, U } from '../common'
import * as mdl from '../model'
import { StorageEvent, SyncStorage } from './Storage'

/** Delay for changes to be collected before actually stored. */
export const storageDelay = 500

const STORAGE_KEY_BOX_STATES = 'box_states'
const STORAGE_KEY_STORAGE_STATES = 'storage_states'
const STORAGE_KEY_BOX_REPORTS = 'box_reports_'
const STORAGE_KEY_STORAGE_REPORTS = 'storage_reports_'

export const setup = {

	boxStates: {
		store: ({ sysStorage }: { sysStorage: SyncStorage }) => {
			O.onInit(mdl.Box, box => {
				storeEntity(sysStorage, STORAGE_KEY_BOX_STATES, box.id, () => ({
					active: box.isActive, permissions: box.permissions || void 0,
					label: box.label
				}), action(b => {
					box.isActive = b.active
					box.permissions = b.permissions ?? null
				}))
			})
		},
		external: ({ sysStorage, boxes }:
			{ sysStorage: SyncStorage, boxes: mdl.BoxManager }) => {
			observeStorage(sysStorage, STORAGE_KEY_BOX_STATES, str => {
				const boxStates = JSON.parse(str)
				action(() => {
					for (const box of boxes.allBoxes)
						if (box?.id in boxStates) {
							box.isActive = boxStates[box.id].active
							box.permissions = boxStates[box.id].permissions ?? null
						}
				})()
			})
		},
	},

	storageStates: {
		store: ({ sysStorage }: { sysStorage: SyncStorage }) => {
			O.onInit(mdl.BoxStorage, storage => {
				storeEntity(sysStorage, STORAGE_KEY_STORAGE_STATES, storage.id,
					() => ({ active: storage.isActive, url: storage.url }),
					s => { storage.isActive = s.active })
			})
		},
		external: ({ sysStorage, boxes }:
			{ sysStorage: SyncStorage, boxes: mdl.BoxManager }) => {
			observeStorage(sysStorage, STORAGE_KEY_STORAGE_STATES,
				str => {
					const storages = JSON.parse(str)
					action(() => {
						for (const storage of boxes.allStorages)
							if (storage?.id in storages)
								storage.isActive = storages[storage.id].active
					})()
				})
		},
	},

	boxReports: {
		store: ({ sysStorage }: { sysStorage: SyncStorage }) => {
			O.onInit(mdl.Box, box => {
				store(sysStorage, STORAGE_KEY_BOX_REPORTS + box.id,
					() => box.reports.entries.join('\n'),
					str => { box.reports.entries = str.split('\n') })
			})
		},
		external: ({ sysStorage, boxes }:
			{ sysStorage: SyncStorage, boxes: mdl.BoxManager }) => {
			observeStorage(sysStorage,
				key => U.str.substringAfter(key, STORAGE_KEY_BOX_REPORTS),
				(str, id) => {
					const b = boxes.getBox(id)
					if (b) b.reports.entries = str.split('\n')
				})
		},
	},

	storageReports: {
		store: ({ sysStorage }: { sysStorage: SyncStorage }) => {
			O.onInit(mdl.BoxStorage, storage => {
				store(sysStorage, STORAGE_KEY_STORAGE_REPORTS + storage.id,
					() => storage.reports.entries.join('\n'),
					str => { storage.reports.entries = str.split('\n') })
			})
		},
		external: ({ sysStorage, boxes }:
			{ sysStorage: SyncStorage, boxes: mdl.BoxManager }) => {
			observeStorage(sysStorage,
				key => U.str.substringAfter(key, STORAGE_KEY_STORAGE_REPORTS),
				(str, id) => {
					const s = boxes.getStorage(id)
					if (s) s.reports.entries = str.split('\n')
				})
		},
	},

	storageDelay: ({ changes }: { changes: mdl.ChangeManager }) => {
		let idleHdl: any
		let saving = false
		let nextSave = false
		const save = () => {
			if (nextSave)
				changes.signalSave().finally(save)
			saving = nextSave
			nextSave = false
		}
		changes.signalChanges.react(() => {
			if (idleHdl) clearTimeout(idleHdl)
			idleHdl = setTimeout(() => {
				nextSave = true
				if (!saving)
					save()
			}, storageDelay)
		})
	},

	buildAll: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.Box, box => {
			box.buildAll.react(async filter => {
				const itemsData = await box.readData(filter)
				if (itemsData) {
					for (const d of itemsData.values()) {
						const item = items.getItem(d.id)
						item.build(d)
						item.addToBox(box)
					}
				}
			})
		})
	},

	addBox: ({ boxes, items, auth }:
		{ boxes: mdl.BoxManager, items: mdl.ItemManager, auth: mdl.Auth }) => {
		// explicitly adding a box reads the data of all it's items.
		boxes.readBox.react(async (storageUrls, boxId) => {
			// URL already known
			const box = boxes.getBox(boxId)
			if (box) {
				// reread the box to contain new boxes
				await box.buildAll('boxes')
			} else {
				await buildBox(items, boxes, storageUrls, boxId, auth)
			}
		})
		boxes.addBox.react(async boxUrl => {
			// no URL, nothing to add
			if (!boxUrl) return
			const urlObj = new URL(boxUrl)
			const { storageUrl, boxId } = interpretUrl(urlObj)
			// maybe it contains credentials
			if (urlObj.username) {
				auth.setCredentials(storageUrl,
					{ id: urlObj.username, type: 'password', password: urlObj.password })
			}
			await boxes.readBox([storageUrl], boxId)
		})
	},

	refreshBox: () => {
		O.onInit(mdl.Box, box => {
			box.refreshAll.react(async () => {
				await box.buildAll()
			})
		})
	},

	removeBox: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.Item, boxItem => {
			boxItem.delete.react(() => { boxes.removeBox(boxItem.id) })
		})
	},

	selectRev: () => {
		O.onInit(mdl.Item, item => {
			item.selectRev.react(async rev => {
				// TODO: implement...
			})
		})
	},

	reIndex: ({ boxes, inst }:
		{ boxes: mdl.BoxManager, inst: mdl.Installation }) => {
		inst.reIndex.react(async () => {
			for (const box of boxes.activeBoxes)
				await box.reIndex()
		})
	},

}

function interpretUrl(url: URL) {
	const idx = url.pathname.lastIndexOf('/')
	const res = {
		storageUrl: url.protocol + '//' + url.host +
			(idx >= 0 ? url.pathname.substring(0, idx) : ''),
		boxId: idx >= 0 ? url.pathname.substring(idx + 1) : url.pathname,
	}
	return res
}

function getProtocol(url: string) {
	// TODO: 'smarter' protocol detection
	if (url.startsWith('http'))
		return 'couch'
	if (url.startsWith('allsbe:'))
		return 'indexed'
	for (const p of Object.keys(mdl.BoxStorage.protocols))
		if (url.startsWith(p))
			return p
	throw new Error(`Cannot determine the box storage protocol for ${url}!`)
}

async function buildBox(items: mdl.ItemManager, boxes: mdl.BoxManager,
	storageUrls: string[], boxId: string, auth: mdl.Auth) {
	let data: mdl.ItemData[], storage: mdl.BoxStorage, storageUrl: string
	const errors = []
	for (storageUrl of storageUrls) {
		let access: mdl.BoxStorageAccess
		try {
			// try to access storage
			storage = boxes.getStorageByUrl(storageUrl)
			access = storage?.access ? storage.access :
				mdl.BoxStorage.protocols[getProtocol(storageUrl)](
					{ url: storageUrl, credentials: auth.getCredentials(storageUrl) })
			data = await access.readData(boxId, 'admin')
			if (data?.length > 0)
				break
		}
		catch (err) {
			errors.push(err)
			if (access)
				access.close()
		}
	}
	if (data?.length) {
		const boxData = data.find(d => d.id === boxId)
		if (!boxData)
			throw new Error(
				`Box item missing in ${storageUrl}/${boxId} : ${JSON.stringify(data)}`)
		const boxItem = items.getItem(boxData.id)
		boxItem.build(boxData)
		const box: mdl.Box = mdl.Box.getBox(boxItem)
		boxItem.settingData = true
		boxItem.addToBox(box)
		boxItem.settingData = false
		// build all read
		for (const d of data) {
			if (d.id === boxItem.id)
				continue
			const item = items.getItem(d.id)
			item.build(d)
			item.settingData = true
			item.addToBox(box)
			item.settingData = false
		}
		if (!storage) {
			// the storage should have been build if available in the data
			storage = boxes.getStorageByUrl(storageUrl)
			if (!storage)
				throw new Error(
					`Storage item missing in ${storageUrl}/${boxId} : ${JSON.stringify(data)}`)
		}
		box.addStorage(storage)
		// make sure possible new storages are complete
		await Promise.all(boxes.allStorages.map(st => st.item.complete()))
		return box
	}
	else {
		throw new ErrorX(`No data available to read box ${storageUrl}/${boxId}!`,
			errors.length === 1 ? errors[0] : errors)
	}
}

function store(storage: SyncStorage, key: string,
	getData: () => string, setData: (str: string) => void) {
	const str = storage.getItem(key)
	if (str) {
		// read
		setData(str)
	} else {
		// create
		const d = getData()
		if (d)
			storage.setItem(key, d)
	}
	// update
	reaction(getData, str => {
		if (str) storage.setItem(key, str)
		else storage.removeItem(key)
	})
}

function storeEntity<T = any>(storage: SyncStorage, key: string, id: string,
	getData: () => {}, setData: (data: T) => void) {
	const str = storage.getItem(key)
	const entities = str ? JSON.parse(str) : {}
	if (id in entities) {
		// read
		setData(entities[id])
	} else {
		// create
		entities[id] = getData()
		storage.setItem(key, JSON.stringify(entities))
	}
	// update
	reaction(getData, data => {
		const str = storage.getItem(key)
		const entities = str ? JSON.parse(str) : {}
		if (id in entities && U.obj.deepEquals(data, entities[id]))
			return
		entities[id] = data
		storage.setItem(key, JSON.stringify(entities))
	})
}

function observeStorage(storage: SyncStorage,
	key: string | ((key: string) => string),
	setData: (str: string, keyReturn: string) => void) {
	const keyFn = typeof key === 'string' ?
		(k: string) => k === key ? k : null : key
	storage.onChange((evn: StorageEvent) => {
		const k = keyFn(evn.key)
		if (k && evn.newValue) setData(evn.newValue, k)
	})
}
