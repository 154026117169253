import { observer } from 'mobx-react'
import * as React from 'react'
import { css, FormValue } from './base'

export interface BlockProps {
	label?: string
	if?: any
	children?: React.ReactNode
	value?: string | number | FormValue<any, any>
	className?: string
}

export const Block = observer((p: BlockProps) => {
	if (p.if !== void 0 && !p.if) return null
	const c = p.value ? typeof p.value === 'object' ?
		p.value.value : p.value : p.children
	if (!c) return null
	return <div className={css('block', p.className)}>
		{p.label && <label>{p.label}:</label>}
		<span>{c}</span>
	</div>
})

