import { Observer, observer } from 'mobx-react'
import * as React from 'react'
import { observable } from '../common'
import { getItem, getLink } from '../env/views/ViewItemLinks'
import * as mdl from '../model'
import { Component, css, Icon } from './common'
import { Sortable } from './common/Sortable'
import { getContentLinks, getLinks } from './LayoutViews'
import { NavItemLink } from './NavItemLink'
import { ViewItemLink } from './ViewItemLink'

interface Props {
	view: mdl.ViewItemView
	context: {
		config: mdl.Config
		nav: mdl.Navigation
		ui: mdl.UserInterface
	}
}

@observer
export class ViewItemLinks extends Component<Props> {

	ref = React.createRef<HTMLDivElement>()
	sortable: Sortable
	@observable sorting = false
	@observable showLinks = true
	@observable showFrom = false

	render() {
		const { view, view: { item, mode, showBoxMarks }, context } = this.props
		// TODO: build Sortable component
		if (this.sortable)
			this.sortable.disable(view.hasSelected || item.isReadOnly)
		const selecting = view.hasSelected && mode === 'move'
		return <div ref={this.ref}
			className={css('links items', { sorting: this.sorting, selecting })}>
			{getContentLinks(item).map((lnk, idx) =>
				<Observer key={lnk.name + lnk.item.id}>{() => lnk.item.isReady ?
					<ViewItemLink link={lnk} previous={item.links.available[idx - 1]}
						view={view} context={context} className="content-link" /> :
					<div>
						<div className="loading">loading...</div>
					</div>
				}</Observer>
			)}
			{!item.content.isEmpty && !item.links.isEmpty &&
				<div className="section"
					onClick={this.toggleLinks}>
					<Icon uri={this.showLinks ? 'expand' : 'chevron_right'}
					/> links...</div>
			}
			<Observer>{() => (this.showLinks || item.content.isEmpty) && <>
				{getLinks(item).map((lnk, idx) =>
					<Observer key={lnk.name + lnk.item.id}>{() => lnk.item.isReady ?
						<ViewItemLink link={lnk} previous={item.links.available[idx - 1]}
							view={view} context={context} /> :
						<div>
							<div className="loading">loading...</div>
						</div>
					}</Observer>
				)}
			</>
			}</Observer>
			{view.fromLinks && <Observer>{() => <>
				<div className="section" onClick={this.toggleFrom}>
					<Icon uri={this.showFrom ? 'expand' : 'chevron_right'}
					/> linked from...</div>
				{this.showFrom && view.fromLinks.map(item => item &&
					<NavItemLink item={item} context={context} key={item.id}
						disabled={view.hasSelected} showBoxMarks={showBoxMarks} />
				)}
			</>}</Observer>}
		</div>
	}

	toggleLinks = () => { this.showLinks = !this.showLinks }
	toggleFrom = () => { this.showFrom = !this.showFrom }

	componentDidMount() {
		this.sortable = new Sortable(this.ref.current, {
			setData: (data, idx) => {
				const { view: { item }, view } = this.props
				const itm = getItem(idx, item.content.available, item.links.available,
					view.fromLinks)
				if (itm) {
					data.setData('text/plain', `Link in item ${item.id} to ${itm.id}.`)
					data.setData('allsbe/link', JSON.stringify(
						{ itemId: item.id, linkItemId: itm.id }))
				}
			},
			onStart: () => {
				this.sorting = true
			},
			onEnd: (moved, startIdx, endIdx) => {
				const { view: { item }, view } = this.props
				if (moved) {
					view.moveLink(startIdx, endIdx)
				} else {
					const ln = getLink(startIdx, item.content.available,
						item.links.available)
					if (ln)
						view.select(ln)
				}
				this.sorting = false
			},
			disabled: this.props.view.hasSelected || this.props.view.item.isReadOnly
		})
	}

	componentWillUnmount() {
		if (this.sortable) {
			this.sortable.dispose()
			this.sortable = null
		}
	}
}

