import { basicAuth, HttpClient, readText, request, U } from '../../../common'
import * as mdl from '../../../model'
import { fileIdToId, idRootBox, itemData, webDavUrl, xml } from './data'
import { FileProps } from './types'

/**
 * One-box-only storage.
 * Read-only so far...
 * 
 * 'domain_[path_]' ID prefix. For the following simply 'nextcloud.com_'.
 * 
 * Root box (nextcloud.com_root.box)
 * 
 * Item IDs like nextcloud.com_files_10
 * 
 */

export class NextCloudDataAccess implements mdl.BoxStorageAccess {

	url: string
	baseUrl: string
	idPrefix: string
	idRootBox: string

	private cache = {}
	private cacheTimeout: any

	constructor(private props: mdl.BoxStorageAccessArgs, private log: mdl.Logger,
		private config: mdl.Config) {
		this.url = this.props.url.endsWith(webDavUrl)
			? this.props.url : this.props.url + webDavUrl
		this.baseUrl = U.str.substringBefore(this.url, webDavUrl)
		this.idPrefix = U.str.substringAfter(this.baseUrl, '://')
			.replaceAll('/', '_') + '_'
		this.idRootBox = this.idPrefix + idRootBox
	}

	async readPermissions(fromBoxId: string): Promise<mdl.BoxPermissions> {
		if (!navigator.onLine)
			return null
		let p: mdl.BoxPermissions = 'ro'
		try {
			// TODO: impl. write permission per project and group
		}
		catch (err) {
			if (err.status !== 403)
				throw err
		}
		return p
	}

	async readData(fromBoxId: string, filter?: 'boxes') {
		// TODO: box scope
		return filter === 'boxes' || filter === 'admin'
			? [await this.readItem(this.idRootBox, [this.idRootBox], true)]
			// TODO: implement... really needed?
			: []
	}

	async readIds(fromBoxId: string) {
		// TODO: implement... really needed?
		// TODO: box scope
		return []
	}

	async readItem(id: string, fromBoxIds: string[], completely: boolean) {
		if (!navigator.onLine)
			return null
		if (!fromBoxIds.includes(this.idRootBox) && id !== this.idRootBox)
			return null
		if (!id.startsWith(this.idPrefix))
			return null
		if (id === this.idRootBox)
			return itemData.rootBox(this.idRootBox, this.baseUrl)
		const parts = id.split('_')
		// get data for ID
		let data: any
		if (id in this.cache) {
			data = this.cache[id]
		} else {
			// get data for ID
			if (id === this.idPrefix + 'files') {
				data = itemData.rootFolder(id, this.baseUrl, this.idRootBox)
				data.links = (await listFileIds(this.url, this.props.credentials,
					`${webDavUrl}/files/${this.props.credentials.id}/`, this.config))
					.map(id => this.idPrefix + 'files_' + id)
			} else {
				const props = await searchById(this.url, this.props.credentials,
					parts[2], this.config)
				if (!props)
					return null
				const isFolder = props.href.endsWith('/')
				data = itemData[isFolder ? 'folder' : 'file'](id, props,
					this.baseUrl, this.idRootBox)
				if (isFolder)
					data.links = (await listFileIds(this.url, this.props.credentials,
						props.href, this.config)).map(id => fileIdToId(this.idPrefix, id))
			}
			this.cache[id] = data
			// clear cache after 1min
			if (!this.cacheTimeout)
				clearTimeout(this.cacheTimeout)
			this.cacheTimeout = setTimeout(() => {
				this.cacheTimeout = null
				this.cache = {}
			}, 60 * 1000)
		}
		// tranform data into itemData
		return data
	}

	async readItems(ids: string[], fromBoxIds: string[], completely: boolean) {
		if (!navigator.onLine)
			return []
		if (!fromBoxIds.includes(this.idRootBox) && !ids.includes(this.idRootBox))
			return []
		return await Promise.all(
			ids.map(id => this.readItem(id, fromBoxIds, completely)))
	}

	async readFromLinks(id: string, fromBoxIds: string[]) {
		// TODO: implement...
		return []
	}

	async search(query: string, fromBoxIds: string[]) {
		const fileIds = await searchFileIds(this.url, this.props.credentials,
			query, this.config)
		return fileIds.map(id => fileIdToId(this.idPrefix, id))
	}

	async writeItem(data: mdl.ItemData, intoBoxId: string) {
	}

	async removeItem(id: string, fromBoxId: string) {
	}

	async getBoxes(knownBoxIds: string[]) {
		return await Promise.all([this.idRootBox]
			.map(async id => ({ id, permissions: await this.readPermissions(id) })))
	}

	async addBox(box: mdl.Box) {
	}

	async removeBox(boxId: string) {
	}

	async open(boxId?: string) {
	}

	async close(boxId?: string) {
	}

}


async function searchById(url: string, credentials: mdl.Credentials,
	fileId: string, config: mdl.Config) {
	const data = await request(U.url.addParams(config.api.services.proxy,
		{ url }), {
		headers: {
			'Authorization': basicAuth(credentials as mdl.PasswordCredentials),
			'Content-Type': 'text/xml',
		},
		method: 'SEARCH',
		body: xml.searchById(fileId, credentials.id)
	}).then(readText)
	if (data) {
		const dom = new DOMParser().parseFromString(data, 'text/xml')
		return ['href', 'getcontenttype', 'getlastmodified', 'size']
			.reduce((o, m) => (
				{ ...o, [m]: dom.querySelector(m)?.textContent }
			), { id: fileId }) as FileProps
	}
}

async function searchFileIds(url: string, credentials: mdl.Credentials,
	query: string, config: mdl.Config) {
	const data = await request(U.url.addParams(config.api.services.proxy,
		{ url }), {
		headers: {
			'Authorization': basicAuth(credentials as mdl.PasswordCredentials),
			'Content-Type': 'text/xml',
		},
		method: 'SEARCH',
		body: xml.searchFileIds(query, credentials.id)
	}).then(readText)
	if (data) {
		const dom = new DOMParser().parseFromString(data, 'text/xml')
		return [...dom.querySelectorAll('fileid')]
			.map(e => e?.textContent)
			.filter(U.any.isTrue)
	}
}

async function listFileIds(url: string, credentials: mdl.Credentials,
	href: string, config: mdl.Config) {
	const data = await request(U.url.addParams(config.api.services.proxy,
		{ url: url + U.str.substringAfter(href, webDavUrl) }), {
		headers: {
			'Authorization': basicAuth(credentials as mdl.PasswordCredentials),
			'Content-Type': 'text/xml',
		},
		method: 'PROPFIND',
		body: xml.listFileIds,
	}).then(readText)
	if (data) {
		const dom = new DOMParser().parseFromString(data, 'text/xml')
		return [...dom.querySelectorAll('response')].slice(1)
			.map(e => ({
				id: e.querySelector('fileid')?.textContent,
				href: e.querySelector('href')?.textContent,
			}))
			.map(e => ({ ...e, isFolder: e.href.endsWith('/') }))
			// folders before files
			.sort((a, b) => a.isFolder && !b.isFolder ? -1 :
				!a.isFolder && b.isFolder ? 1 : a.href.localeCompare(b.href))
			.map(e => e.id)
			.filter(U.any.isTrue)
	}
}

export async function makeFolder(http: HttpClient, config: mdl.Config,
	url: string) {
	await http.mkcol(U.url.addParams(config.api.services.proxy, { url }))
}

export async function uploadFile(http: HttpClient, config: mdl.Config,
	url: string, blob: Blob) {
	await http.put(U.url.addParams(config.api.services.proxy, { url }), blob)
}

export async function listFileNames(http: HttpClient, config: mdl.Config,
	url: string, depth = 1) {
	const data = await http.propfind(
		U.url.addParams(config.api.services.proxy, { url }),
		xml.listFileIds, { Depth: '' + depth })
	if (data) {
		const dom = new DOMParser().parseFromString(data, 'text/xml')
		return [...dom.querySelectorAll('response')].slice(1)
			.map(e => e.querySelector('href')?.textContent)
			.filter(href => !href.endsWith('/'))
			.map(href => U.str.substringAfterLast(href, '/'))
			.filter(U.any.isTrue)
	}
}

