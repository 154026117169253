import * as React from 'react'
import { U } from '../../common'
import { Block, Icon, TextInput } from '../common'
import { PropertyView } from './common'

const dateOptions = {
	date: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
	time: { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false },
	dateTime: {
		year: 'numeric', month: 'long', day: 'numeric', weekday: 'long',
		hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
	},
} as { [type: string]: Intl.DateTimeFormatOptions }

function dateToString(value: any, opts: Intl.DateTimeFormatOptions) {
	return value ? U.date.isDate(value) ?
		value.toLocaleString('default', opts) : '' + value : '?'
}

export const date: PropertyView = {
	label: ({ value }) => <>{dateToString(value, dateOptions.date)}</>,
	content: ({ value, label }) => <Block label={label}>
		{dateToString(value, dateOptions.date)}</Block>,
	icon: () => <Icon uri="date?color=#2f4f4f" />,
	editor: (p, form, ctx, autoFocus) =>
		<TextInput label={p.label} value={form.values[p.name]} type="date"
			autoFocus={autoFocus} />
}

export const time: PropertyView = {
	label: ({ value }) => <>{dateToString(value, dateOptions.time)}</>,
	content: ({ value, label }) => <Block label={label}>
		{dateToString(value, dateOptions.time)}</Block>,
	icon: () => <Icon uri="time" />,
	editor: (p, form, ctx, autoFocus) =>
		<TextInput label={p.label} value={form.values[p.name]} type="time"
			autoFocus={autoFocus} />
}

export const dateTime: PropertyView = {
	label: ({ value }) => <>{dateToString(value, dateOptions.dateTime)}</>,
	content: ({ value, label }) => <Block label={label}>
		{dateToString(value, dateOptions.dateTime)}</Block>,
	icon: () => <Icon uri="dateTime" />,
	editor: (p, form, ctx, autoFocus) =>
		<TextInput label={p.label} value={form.values[p.name]} type="datetime-local"
			autoFocus={autoFocus} step={1} />
}

