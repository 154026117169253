import * as React from 'react'
import { Component, FormValue, Icon, observable, observer } from '..'
import * as mdl from '../../../model'
import placeholder from '../../../style/placeholder.svg'
import { ImageCapture } from './ImageCapture'
import { PixelEditor } from './PixelEditor'

interface Props {
	label?: string
	value: FormValue
	query: FormValue | string
	width?: number
	height?: number
	asIcon?: boolean
	config: mdl.Config
}

@observer
export class ImageEditor extends Component<Props> {

	get url() {
		return mdl.ImageValue.url(this.props.value.val)
	}

	get isImage() {
		return !this.url || this.url === placeholder ||
			mdl.ImageCapture.imageUrlPattern.test(this.url)
	}

	@observable show: 'none' | 'capture' | 'edit' = 'none'

	render() {
		const { label, value, width, height, asIcon, config, query } = this.props
		return <div className="field">
			{label && <label>{label}:</label>}
			{value.val && (this.isImage ?
				<PixelEditor value={value} width={width} height={height} asIcon={asIcon}
					active={this.show === 'edit'} onClose={this.onClose} /> :
				<div className={'image'}>
					<Icon uri={this.url} />
				</div>)}
			{this.show !== 'edit' && <div className="actions">
				<button onClick={this.onCapture} disabled={this.show === 'capture'}>
					<Icon uri={'insert_photo'} /></button>
				{value.val && this.isImage &&
					<button onClick={this.onEdit} disabled={this.show === 'capture'}>
						<Icon uri={'create'} /></button>}
			</div>}
			{this.show === 'capture' &&
				<ImageCapture label={`Find or select ${label}`} value={value}
					asIcon={asIcon} query={query}
					config={config} onClose={this.onClose} />}
		</div>
	}

	onCapture = () => {
		this.show = 'capture'
	}

	onEdit = () => {
		this.show = 'edit'
	}

	onClose = () => {
		this.show = 'none'
	}

}

export function IconEditor({ url, ...p }:
	{ label: string, url: FormValue, query: FormValue, config: mdl.Config }) {
	return <ImageEditor asIcon={true} width={64} height={64} value={url} {...p} />
}
