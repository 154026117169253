import { U } from '../../../common'
import { textImporters } from './common'

textImporters.push(newItemsForUrl)

const pattern = U.url.pattern.line.basic

function newItemsForUrl(txt: string) {
	const lines = txt.split('\n')
	// test first line to detect
	if (lines.length <= 0 || !pattern.test(lines[0]))
		return null
	const matches = lines.map(ln => ln.match(pattern)).filter(U.any.isTrue)
	if (matches.length <= 0)
		return null
	return matches.map(ln => U.url.pattern.line.image.test(txt)
		? { props: { image: { image: txt } }, tmpls: [{ ref: 'image.tmpl' }] }
		: { props: { url: { url: txt } }, tmpls: [{ ref: 'url.tmpl' }] }
	)
}
