
export type SetupFn<P> = (props: { [k in keyof P]: P[k] }) => void
export type SetupHierarchy<P> =
	{ [n: string]: SetupFn<P> | SetupHierarchy<P> } | SetupFn<P>

// DEBUG: check to be called only once
const calledFunctions = new Set()

export function setupAll<P>(setupFunctions: SetupHierarchy<P>, props: P) {
	for (const k of Object.keys(setupFunctions)) {
		const f = setupFunctions[k]
		if (typeof f === 'function') {
			if (calledFunctions.has(f))
				console.error(`Setup function ${f.name} has been called already!`, f)
			else
				f(props)
			calledFunctions.add(f)
		} else {
			setupAll(f, props)
		}
	}
}
