import { O, reaction, when } from '../common'
import * as mdl from '../model'
import { setupBrowserHistory } from './BrowserHistory'
import { format, parse } from './BrowserUrl'

export const setup = {

	homeItem: ({ nav, session }:
		{ nav: mdl.Navigation, session: mdl.Session }) => {
		reaction(() => session.userBoxItem?.links
			.find(ln => !ln.item.props.findByType('storage'))?.item.id,
			id => { if (id) nav.homeItemId = id },
			{ fireImmediately: true })
	},

	navigate: ({ app, nav, boxes }:
		{ app: mdl.App, nav: mdl.Navigation, boxes: mdl.BoxManager }) => {
		setupBrowserHistory(nav, app.config, app,
			loc => { go(nav, boxes, app.session, parse(loc)) }, format)
	},

	reload: ({ app }: { app: mdl.App }) => {
		app.reload.react(() => {
			location.reload()
		})
	},

	timeline: ({ nav, inst }: { nav: mdl.Navigation, inst: mdl.Installation }) => {
		inst.timeline.react(() => {
			nav.go('timeline')
		})
	},

	afterLogin: ({ app, nav, boxes }:
		{ app: mdl.App, nav: mdl.Navigation, boxes: mdl.BoxManager }) => {
		// after login navigate to the originally requested location
		O.onInit(mdl.LoginView, view => {
			view.login.end.react(() => {
				go(nav, boxes, app.session, nav.goNext ||
					{ id: nav.recentIds.length > 0 ? nav.recentIds[0] : nav.homeItemId })
			})
		})
	},

	placeItemsFromBox: ({ nav }: { nav: mdl.Navigation }) => {
		// place all items contained in a box
		O.onInit(mdl.Box, box => {
			box.placeAll.react(() => {
				// TODO: explicitly read all item IDs for a box 
				// if (box.items.length < 1) throw new Error('Please load items first!')
				// nav.go('place', box.items
				// 	.filter(item => !mdl.Box.getBox(item))
				// 	.map(item => item.id)
				// 	.join(','))
			})
		})
	},

	addItemView: ({ nav }: { nav: mdl.Navigation }) => {
		O.onInit(mdl.AddItemView, view => {
			view.addNewItem.end.react(newItemId => {
				if (newItemId)
					nav.addRecentId(newItemId)
				if (view.search.text || !newItemId)
					nav.back()
				else
					nav.go('edit', newItemId)
			})
		})
	},

	createLocalStorage: ({ nav, inst }:
		{ nav: mdl.Navigation, inst: mdl.Installation }) => {
		inst.createLocalStorage.end.react(newItemId => {
			nav.addRecentId(newItemId)
			nav.go('edit', newItemId)
		})
	},

	createAccountForStorage: ({ nav }: { nav: mdl.Navigation }) => {
		O.onInit(mdl.BoxStorage, storage => {
			storage.createAccount.end.react(newItemId => {
				nav.addRecentId(newItemId)
				nav.go('edit', newItemId)
			})
		})
	},

	documentTitle: ({ nav }: { nav: mdl.Navigation }) => {
		reaction(() => nav.view?.isReady ? nav.view?.label : null, label => {
			document.title = label || 'Allsbe'
		})
	},

	createItemView: ({ nav }: { nav: mdl.Navigation }) => {
		O.onInit(mdl.CreateItemView, async view => {
			await when(() => nav.view === view)
			const { params } = nav.location
			view.createItem(params?.['text'], params?.['title'])
		})
	},

	calendarDate: ({ nav }: { nav: mdl.Navigation }) => {
		O.onInit(mdl.SelectDateAction, act => {
			act.end.react(itemId => {
				nav.go('view', itemId)
			})
		})
	},

}


async function go(nav: mdl.Navigation, boxes: mdl.BoxManager,
	session: mdl.Session, dest: mdl.NavData) {
	await when(() => session.isReady)
	delete nav.goNext
	// if (session.needsLogin && dest.id && dest.id !== fixIds.intro.allsbe) {
	// 	nav.go('login')
	// 	if (dest.key !== 'login') nav.goNext = dest
	// } else {
	const { params } = dest
	const wait = params && params.box && boxes.addBox(params.box)
	if (wait) wait.then(() => nav.go(dest))
	else nav.go(dest)
	// }
}
