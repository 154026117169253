import { U } from '../../../common'
import * as mdl from '../../../model'
import { ItemDocBase } from './types'

export function queryUri(docId: string, view: string) {
	return '_design/' + docId + '/_view/' + view
}
export function boxIdToDbName(boxId: string) { return 'box_' + boxId }
export function dbNameToBoxId(dbName: string) {
	return dbName.startsWith('box_') ? dbName.substring(4) : null
}
export function itemToDocId(itemId: string) { return 'i' + itemId }
export function docToItemId(docId: string) { return docId.substring(1) }

export function isItemDoc(doc: { id: string } | { _id: string }) {
	if (doc === null) return false
	return doc['id' in doc ? 'id' : '_id'].startsWith('i')
}

export function toFullRev(doc: ItemDocBase) {
	return mdl.Item.toFullRev(
		{ rev: doc.item_rev, update: doc.update, create: doc.create })
}

export function docToItemData(doc: ItemDocBase) {
	if (!doc) return null
	const data: mdl.ItemData =
	{
		...doc, props: { ...doc.props }, id: doc._key, rev: doc.item_rev,
		_info: { arangodb: { _id: doc._id, _rev: doc._rev } }
	}
	U.obj.deleteMembers(data as any,
		'_id', '_rev', '_key', 'item_rev')
	for (const name of Object.keys(data.props)) {
		const p = data.props[name]
		if (!p || typeof p !== 'object')
			continue
		const prop = data.props[name] = { ...p }
		for (const k of Object.keys(prop)) {
			const v = prop[k]
			if ('content_type' in v && 'data' in v) {
				prop[k] = v.content_type === 'Buffer' ? Buffer.from(v.data, 'base64') :
					U.blob.fromBase64(v.data, v.content_type)
				prop.type = v.content_type
				if ('size' in v) prop.size = v['size']
				// only one blob per property
				break
			}
		}
	}
	return mdl.ItemData.cleanup(data)
}

export async function itemDataToDoc(data: mdl.ItemData) {
	if (data === null) return null
	const doc: ItemDocBase = {
		...data, props: { ...data.props },
		_key: data.id, item_rev: data.rev
	}
	delete doc._info
	if (doc.item_rev === 0)
		delete doc.item_rev
	U.obj.deleteMembers(doc, 'id', 'rev', 'containerId', 'conflicts', '_info')
	// replace blobs with references to attachments
	// only one blob per property!
	for (const name of Object.keys(doc.props)) {
		const p = doc.props[name]
		if (!p || typeof p !== 'object')
			continue
		const prop = doc.props[name] = { ...p }
		for (const k of Object.keys(prop)) {
			const v = prop[k]
			if (U.blob.isBlob(v) || U.blob.isBuffer(v)) {
				prop[k] = {
					content_type: U.blob.isBlob(v) ? v.type : 'Buffer',
					data: U.blob.isBlob(v) ? await U.blob.toBase64(v) :
						v.toString('base64')
				}
				// only one blob per property
				break
			}
		}
	}
	return doc
}

export function queryToSearchText({ id, key, value }:
	{ id: string, key: string, value: string }) {
	return {
		id: docToItemId(id),
		rev: key,
		text: value
	}
}

export function queryToId({ id }: { id: string, key: string, value: string }) {
	return docToItemId(id)
}

