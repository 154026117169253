import * as React from 'react'
import { autoGrow, Component, InputProps, observer, ValueProps } from './common'

interface TextInputProps extends InputProps {
	autoComplete?: string
	autoFocus?: boolean
	disabled?: boolean
	maxLength?: number
	minLength?: number
	name?: string
	placeholder?: string
	readOnly?: boolean
	required?: boolean
	spellCheck?: boolean
	wrap?: string
	onKeyUp?: (evn: React.KeyboardEvent) => void
}

@observer
export class TextareaInput<T>
	extends Component<TextInputProps & ValueProps<T>> {

	private static idGen = 1
	id = 't' + (TextareaInput.idGen++)
	txtRef = React.createRef<HTMLTextAreaElement>()

	render() {
		const { label, title, children, ...rest } = this.props
		void ['value', 'key', 'member'].forEach(k => { delete rest[k] })
		const props: Omit<typeof rest, 'value' | 'key' | 'member'> = rest
		const value = 'value' in this.props ? this.props.value : null
		const val = 'value' in this.props ? value.value :
			'' + this.props.target[this.props.member]
		return (
			<div className={'field' + (value?.hasErrors ? ' error' : '')}
				title={title}>
				{label && <label htmlFor={this.id}>{label}</label>}
				<textarea id={this.id} ref={this.txtRef} value={val}
					onChange={this.onChange} {...props} />
				{value?.hasErrors && <div>{value.errorMessages.join(', ')}</div>}
				{children}
			</div>
		)
	}

	componentDidMount() {
		autoGrow(this.txtRef.current, 2)
	}

	onChange = (evn: React.ChangeEvent<HTMLTextAreaElement>) => {
		if ('value' in this.props) this.props.value.value = evn.target.value
		else this.props.target[this.props.member] = evn.target.value as any
		autoGrow(this.txtRef.current, 2)
	}
}
