import { httpClient, U } from '../../../common'
import * as mdl from '../../../model'
import { bulk } from './bulk'
import { docToItemData, itemDataToDoc } from './data'
import { edgeCols, query } from './query'
import { ItemDocBase, QueryResponse } from './types'

// TODO: transactions
// TODO: allow multiple boxes
// TODO: user permissions

export class ArangoDataAccess implements mdl.BoxStorageAccess {

	private http: ReturnType<typeof httpClient>
	url: string
	private bulkReads = {
		plain: {} as { [boxId: string]: (id: string) => Promise<any>; },
		completely: {} as { [boxId: string]: (id: string) => Promise<any>; },
	}
	private idRevs: {
		[boxId: string]:
		{ req: Promise<void>, idRevs?: { [id: string]: string } }
	} = {}

	constructor(private props: mdl.BoxStorageAccessArgs,
		private log: mdl.Logger) {
		this.url = this.props.url + '/_db/test/_api'
		this.http = httpClient(this.props.credentials as mdl.PasswordCredentials)
	}

	async readPermissions(fromBoxId: string) {
		if (!navigator.onLine)
			return null
		let p: mdl.BoxPermissions = 'rw'
		try {
			// TODO: impl.
		}
		catch (err) {
			if (err.status !== 403)
				throw err
		}
		return p
	}

	private async aql<T = any>(query: string | string[]) {
		if (!navigator.onLine)
			return []
		// work-around to put multiple statements in one query
		if (Array.isArray(query))
			query = query.map((q, idx) => `LET r${idx} = (${q})\n`).join('') +
				'RETURN 1'
		const res = await this.http.post<QueryResponse>(this.url + '/cursor',
			{ query })
		return res.result as T[]
	}

	private async readAll(boxId: string, keys: string[], completely: boolean) {
		// TODO: box scope
		return await this.aql<ItemDocBase>(query.readItems(keys))
	}

	async readData(fromBoxId: string, filter?: 'boxes') {
		// TODO: filter and box scope
		const res = await this.aql<ItemDocBase>(query.readData)
		return res.map(docToItemData)
	}

	async readIds(fromBoxId: string) {
		// TODO: box scope
		return await this.aql<{ id: string, rev: string }>(query.readIds)
	}

	async isItemInBox(itemId: string, boxId: string) {
		if (!(boxId in this.idRevs)) {
			const req = this.readIds(boxId)
			this.idRevs[boxId] = {
				req: req.then(idRevs => {
					this.idRevs[boxId].idRevs =
						U.array.toObject(idRevs, v => v.id, v => v.rev)
					this.idRevs[boxId].req = null
					setTimeout(() => { delete this.idRevs[boxId] }, 5000)
				}, err => {
					delete this.idRevs[boxId]
					throw err
				})
			}
		}
		if (this.idRevs[boxId].req)
			await this.idRevs[boxId].req
		return itemId in this.idRevs[boxId].idRevs
	}

	async readItem(id: string, fromBoxIds: string[], completely: boolean) {
		if (!navigator.onLine)
			return null
		// OPT: explicitly check for revision and only read newer ones.
		let itemData: mdl.ItemData = null
		await Promise.all(fromBoxIds.map(async (boxId) => {
			if (!await this.isItemInBox(id, boxId))
				return
			const bulkReads = this.bulkReads[completely ? 'completely' : 'plain']
			if (!(boxId in bulkReads))
				bulkReads[boxId] = bulk(keys => this.readAll(boxId, keys, completely)
					.then(docs => U.array.toObject(docs, d => d._key)))
			const res = await bulkReads[boxId](id)
			if (res && id in res) {
				const d = docToItemData(res[id].doc)
				if (d) {
					if (!itemData || itemData.rev < d.rev)
						itemData = d
					if (itemData.boxes)
						itemData.boxes.push(boxId)

					else
						itemData.boxes = [boxId]
				}
			}
		}))
		return itemData
	}

	async readItems(ids: string[], fromBoxIds: string[], completely: boolean) {
		if (!navigator.onLine)
			return []
		const itemsData: { [id: string]: mdl.ItemData; } = {}
		for (const boxId of fromBoxIds) {
			const res = await this.readAll(boxId, ids, completely)
			if (res?.length) {
				for (const r of res) {
					const inData = docToItemData(r)
					let itemData = itemsData[inData.id]
					if (!itemData || itemData.rev < inData.rev)
						itemData = itemsData[inData.id] = inData
					if (itemData.boxes)
						itemData.boxes.push(boxId)

					else
						itemData.boxes = [boxId]
				}
			}
		}
		return Object.values(itemsData)
	}

	async readFromLinks(id: string, fromBoxIds: string[]) {
		// TODO: box scope
		return await this.aql<string>(query.readFromLinks(id))
	}

	async readSearchTexts(fromBoxIds: string[]) {
		// TODO: box scope
		return await this.aql<{ id: string, rev: string, text: string }>(
			query.readSearchTexts)
	}

	async writeItem(data: mdl.ItemData, intoBoxId: string) {
		if (!navigator.onLine)
			return
		if (await this.isItemInBox(data.id, intoBoxId)) {
			const oldRev = '0' // TODO: get rev
			if (mdl.Item.compareRev(data, oldRev) <= 0)
				// only write newer data
				return
		}
		const d = await itemDataToDoc(data)
		// TODO: transaction
		// deleted edges
		const removes = []
		for (const col of edgeCols.filter(c => c !== 'boxes' && c in d))
			removes.push(query.removeEdges(data.id, col,
				mdl.ItemLinkData.toIds(d[col])))
		await this.aql(removes)
		// insert new data
		const itm = U.obj.deleteMembers({ ...d }, ...edgeCols)
		const inserts = [query.upsert(itm, 'items')]
		for (const col of edgeCols.filter(c => c !== 'boxes' && c in d))
			inserts.push(query.upsertEdges(data.id, d[col], col))
		inserts.push(query.upsert(linkToEdge(data.id, intoBoxId), 'boxes'))
		await this.aql(inserts)
		// update cache
		if (intoBoxId in this.idRevs) {
			const e = this.idRevs[intoBoxId]
			if (e.req)
				await e.req
			e.idRevs[data.id] = mdl.Item.toFullRev(data)
		}
	}

	async removeItem(id: string, fromBoxId: string) {
		if (!navigator.onLine)
			return
		if (!await this.isItemInBox(id, fromBoxId))
			return
		// TODO: box scope
		const removes = [query.remove(id, 'items')]
		for (const col of edgeCols)
			removes.push(query.remove('items/' + id, col))
		await this.aql(removes)
		if (fromBoxId in this.idRevs) {
			const e = this.idRevs[fromBoxId]
			if (e.req)
				await e.req
			delete e.idRevs[id]
		}
	}

	async getBoxes(knownBoxIds: string[]) {
		const excludeBoxIds = U.array.toObject(knownBoxIds)
		const bDocs = await this.aql<ItemDocBase>(query.readBoxes)
		return await Promise.all(bDocs.map(d => d._key)
			.filter(id => id && !(id in excludeBoxIds))
			.map(async boxId => (
				{ id: boxId, permissions: await this.readPermissions(boxId) })))
	}

	async addBox(box: mdl.Box) {
		if (!navigator.onLine)
			return
		const bDocs = await this.aql<ItemDocBase>(query.readBoxes)
		if (!bDocs.find(d => d._key === box.id)) {
			const data = await box.readData()
			const docs = await Promise.all(data.map(itemDataToDoc))
			const itemDocs = docs.map(d => U.obj.deleteMembers({ ...d }, ...edgeCols))
			await this.http.post(this.url + '/document/items', itemDocs)
			for (const col of edgeCols.filter(c => c !== 'boxes')) {
				const edgeDocs = docs
					.filter(d => col in d)
					.map(d => d[col].map((ln: mdl.ItemLinkData) => linkToEdge(d.id, ln)))
					.flat()
				await this.http.post(this.url + '/document/' + col, edgeDocs)
			}
			const boxDocs = docs.map(d => linkToEdge(d.id, box.id))
			await this.http.post(this.url + '/document/boxes', boxDocs)
		}
	}

	async removeBox(boxId: string) {
		if (!navigator.onLine)
			return
		// don't actually delete remote, potentially shared boxes
	}

	async open(boxId?: string) {
	}

	async close(boxId?: string) {
	}

}

function linkToEdge(itemId: string, ln: mdl.ItemLinkData)
	: { _key: string; _from: string; _to: string, [n: string]: any } {
	const to = typeof ln === 'string' ? ln : ln.url
	const edge =
		{ _key: itemId + '-' + to, _from: 'items/' + itemId, _to: 'items/' + to }
	return typeof ln === 'string' ? edge :
		{ ...U.obj.deleteMembers({ ...ln }, 'url'), ...edge }
}

