import { observer } from 'mobx-react'
import * as React from 'react'
import { U } from '../common'
import * as mdl from '../model'
import { Context, enumerate, Link } from './common'
import { ItemLabel } from './LayoutViews'


interface Props {
	item: mdl.Item
	params?: { [k: string]: string }
	context: Context
}

export const ContainerHeader = observer(
	({ item: { container }, params, context }: Props) =>
		container ? <div>
			{enumerate(
				[container.container?.container, container.container, container]
					.filter(U.any.isTrue).map(itm =>
						<Link itemId={itm.id} params={params} nav={context.nav}>
							<ItemLabel item={itm} context={context} />
						</Link>
					), '\u00A0 - \u00A0')}
		</div> : null
)
