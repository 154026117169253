import { fixIds } from '../../../base/src/model/common'
import { action, httpClient, O, reaction } from '../common'
import * as mdl from '../model'

const STORAGE_KEY_SESSION = 'session'

interface UserData {
	userId: string
	userBoxStorageUrls: string[]
	userBoxId: string
}

interface SessionData extends UserData {
	config: { loadPublicBox: boolean, }
}

export const setup = {

	login: ({ config, boxes, session, inst }:
		{
			config: { api: { auth: string } }, inst: mdl.Installation,
			boxes: mdl.BoxManager, session: mdl.Session
		}) => {
		O.onInit(mdl.LoginView, view => {
			view.login.start.react(async (loginName, password) => {
				loginName = loginName.toLowerCase()
				const cred: mdl.PasswordCredentials =
					{ id: loginName, type: 'password', password }
				const http = httpClient(cred)
				const data: UserData = await http.get(
					config.api.auth + '/_users/org.couchdb.user:' + loginName)
				session.auth.setCredentials(config.api.auth, cred)
				if (data.userBoxStorageUrls)
					for (const url of data.userBoxStorageUrls)
						// TODO: smarter need-credential detection
						if (url.startsWith('http'))
							session.auth.setCredentials(url, cred)
				await login(boxes, session, data.userId,
					data.userBoxStorageUrls, data.userBoxId)
				session.isReady = true
				await inst.reIndex()
					.then(() => {
						view.login.end()
					}, (err) => {
						console.log(err)
						view.login.end()
					})
			})
		})
	},

	// TODO: logout feature
	// TODO: login only needed for online boxes

	userSession: {

		read: ({ session }: { session: mdl.Session }) => {
			// TODO: more secure credential storage
			const data = JSON.parse(localStorage.getItem(STORAGE_KEY_SESSION))
			if (data?.credentials) {
				session.auth.setCredentials(data.credentials)
			}
		},

		setup: async ({ boxes, session, app }:
			{ boxes: mdl.BoxManager, session: mdl.Session, app: mdl.AppBase }) => {
			app.init.reactOnce(async () => {
				const data: SessionData =
					JSON.parse(localStorage.getItem(STORAGE_KEY_SESSION))
				// read public box
				// TODO: allow to configure
				if (data?.config?.loadPublicBox ?? true)
					await readActiveBox(boxes, ['https://public.alls.be'],
						fixIds.box.public, 'ro')
				if (data?.config)
					session.config = data.config
				// try auto-login
				if (data?.userId && data.userBoxId && data.userBoxStorageUrls?.length) {
					await login(boxes, session, data.userId,
						data.userBoxStorageUrls, data.userBoxId)
				}
				session.isReady = true
			})
		},

		update: ({ session }: { session: mdl.Session }) => {
			reaction(() => session.user &&
				mdl.Box.getBox(session.userBoxItem)?.availableStorages[0]?.url ?
				{
					userId: session.user.id, userBoxId: session.userBoxItem.id,
					userBoxStorageUrls: mdl.Box.getBox(session.userBoxItem)
						.availableStorages.map(s => s.url),
					credentials: session.auth.allCredentials,
					config: session.config,
				} : null,
				data => {
					if (data?.userId)
						localStorage.setItem(STORAGE_KEY_SESSION, JSON.stringify(data))
				})
		},

	},

	accountLogin: ({ log, session }:
		{ log: mdl.Logger, session: mdl.Session }) => {
		O.onInit(mdl.Account, account => {
			account.login.react(async (id, password) => {
				// TODO: separate oauth authentication
				const cred: mdl.Credentials = account.isOAuth ? { id: '', type: 'oauth' } :
					account.isToken ? { type: 'token', id, token: password } :
						{ type: 'password', id, password }
				try {
					await account.readUser(cred)
					session.auth.setCredentials(account.credentialsKey, cred)
				} catch (err) {
					log.error('Login failed!', { err })
				}
			})
			account.logout.react(async () => {
				session.auth.removeCredentials(account.credentialsKey)
			})
			account.changePassword.end.react(async newPw => {
				const cred = session.auth.getCredentials(account.credentialsKey)
				if (cred.type === 'password') {
					cred['password'] = newPw
					session.auth.setCredentials(account.credentialsKey, { ...cred })
				}
			})
		})
	},

}

async function login(boxes: mdl.BoxManager, session: mdl.Session,
	userId: string, userBoxStorageUrls: string[], userBoxId: string) {
	const box = await readActiveBox(boxes, userBoxStorageUrls, userBoxId)
	action(() => {
		session.user = boxes.items.getItem(userId)
		session.userBoxItem = box.item
		boxes.userBoxItem = box.item
	})()
	await session.user.request()
}

async function readActiveBox(boxes: mdl.BoxManager, storageUrls: string[],
	boxId: string, defaultPermission?: mdl.BoxPermissions) {
	await boxes.readBox(storageUrls, boxId)
	const boxItem = boxes.getBoxItem(boxId)
	const box = mdl.Box.getBox(boxItem)
	if (!box)
		throw new Error(`Item ${boxItem.id} does not contain a box!`)
	const storage = box.findStorageByUrl(...storageUrls)
	if (!storage)
		throw new Error(`Box ${boxItem.id} does not contain a storage item!`)
	action(() => {
		if (!box.permissions)
			box.permissions = defaultPermission
		// activate box
		box.isActive = true
		// activate storage
		storage.isActive = true
	})()
	return box
}
