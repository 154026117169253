import { U } from '../../../common'
import { UrlInfo } from './common'

type UrlSpec = (
	{ prefix: string } |
	{ match: RegExp, transform?: (s: string) => string }
) & {
	params?: string
	title?: string
	icon?: string
	url?: string

}

export const specialUrls: UrlSpec[] = [
	{
		prefix: 'https://www.google.com/search?',
		icon: 'https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg',
		title: 'search "{q}"',
		url: 'keep params: q',
	},
	{
		match: /https:\/\/mail.google.com\/mail\/mu\/mp\/943\/#tl\/search\//,
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/7e/Gmail_icon_%282020%29.svg',
		title: 'Gmail search "{$1}"',
	},
	// gets redirected to .../mu/... on mobile and looses search hash parameter
	// => convert all URLs to .../mu/... also on desktop
	// TODO: URL replacer per installation
	{
		match: /https:\/\/mail.google.com\/mail\/[^#]*#search\/(.+)/,
		icon: 'https://upload.wikimedia.org/wikipedia/commons/7/7e/Gmail_icon_%282020%29.svg',
		title: 'Gmail search "{$1}"',
		url: 'https://mail.google.com/mail/mu/mp/943/#tl/search/{$1}',
		transform: s => s.replace(/\+/g, '%20'),
	},
	{
		prefix: 'https://www.google.com/maps/search/',
		url: 'trim',
	},
	{
		prefix: 'https://www.google.com/maps/place/',
		url: 'trim',
	},
	{
		prefix: 'https://www.bing.com/search?',
		icon: 'https://www.bing.com/sa/simg/favicon-2x.ico',
		title: 'search "{q}"',
		url: 'keep params: q',
	},
	{
		prefix: 'https://search.yahoo.com/search?',
		icon: 'https://search.yahoo.com/favicon.ico',
		title: 'search "{p}"',
		url: 'keep params: p'
	},
	{
		prefix: 'https://www.wolframalpha.com/input',
		icon:
			'https://www.wolframalpha.com/_next/static/images/favicon_fzx75d1e.ico',
		title: 'lookup "{i}"',
		url: 'keep params: i',
	},
	{
		prefix: 'https://en.wikipedia.org/w/index.php?search=',
		icon: 'https://en.wikipedia.org/static/favicon/wikipedia.ico',
		title: 'search "{search}"',
		url: 'keep params: search',
	},
	{
		prefix: 'https://www.search.ch/?',
		icon: 'https://lib.search.ch/favicon.ico',
		url: 'keep params: q',
		title: 'suche "{q}"',
	},
	{
		prefix: 'https://tel.search.ch',
		params: 'was, wo',
		icon: 'https://lib.search.ch/favicon.ico',
		url: 'keep params: was, wo',
		title: '📞 von "{was}" in "{wo}"',
	},
	{
		prefix: 'https://tel.search.ch',
		params: 'was',
		icon: 'https://lib.search.ch/favicon.ico',
		url: 'keep params: was, wo',
		title: '📞 von "{was}"',
	},
	{
		prefix: 'https://tel.search.ch',
		params: 'wo',
		icon: 'https://lib.search.ch/favicon.ico',
		url: 'keep params: was, wo',
		title: '📞 in "{wo}"',
	},
	{
		prefix: 'https://tel.search.ch',
		icon: 'https://lib.search.ch/favicon.ico',
		url: 'trim',
		title: 'Telefonbuch',
	},
	{
		prefix: 'https://a.sbbmobile.ch/',
		icon: 'https://cdn.app.sbb.ch/favicons/sbb/v2/apple-touch-icon-72x72.png',
	},
	{
		prefix: 'https://connect.garmin.com/modern/activity/',
		url: 'trim',
	},
	{
		prefix: 'https://www.xcontest.org/',
		url: 'trim',
	},
	{
		prefix: 'https://www.linkedin.com/in/',
		url: 'trim',
		title: 'LinkedIn Profile',
		icon: 'https://static-exp1.licdn.com/sc/h/2if24wp7oqlodqdlgei1n1520',
	},
]

UrlInfo.parser['$special'] = (txt, ct, url) => interpretUrl(url)

export function interpretUrl(url: string, rules = specialUrls) {
	const urlObj = new URL(url)
	const params = urlObj.searchParams
	for (const sUrl of rules) {
		if (sUrl.params && !hasAllParams(params, sUrl.params.split(',')))
			continue
		const info: UrlInfo = {}
		if (sUrl.icon)
			info.icon = sUrl.icon
		if ('match' in sUrl) {
			const m = url.match(sUrl.match)
			if (m) {
				const matches = sUrl.transform ? m.map(sUrl.transform) : m
				if (sUrl.title)
					info.title = convertStr(sUrl.title, params,
						matches.map(decodeURIComponent))
				if (sUrl.url)
					info.url = convertUrl(sUrl.url, urlObj) ??
						convertStr(sUrl.url, params, matches)
				return info
			}
		} else if (url.startsWith(sUrl.prefix)) {
			if (sUrl.title)
				info.title = convertStr(sUrl.title, params)
			if (sUrl.url)
				info.url = convertUrl(sUrl.url, urlObj) ?? sUrl.url
			return info
		}
	}
}

function hasAllParams(params: URLSearchParams, names: string[]) {
	for (const s of names) {
		const n = s.trim()
		if (n && !params.has(n))
			return false
	}
	return true
}

function convertStr(str: string, params: URLSearchParams, matches?: string[]) {
	return str.replace(/{([^}]*)}/g, (m, p) => matches &&
		p.startsWith('$') ? matches[parseInt(p.substring(1))] : params.get(p))
}

function convertUrl(url: string, urlObj: URL): string {
	const params = urlObj.searchParams
	const trimmedUrl = urlObj.origin + urlObj.pathname
	if (url === 'trim') {
		return trimmedUrl
	} else if (url.startsWith('keep params:')) {
		const newParams = {}
		for (const s of url.substring(12).split(',')) {
			const n = s.trim()
			if (n && params.has(n))
				newParams[n] = params.get(n)
		}
		return U.url.addParams(trimmedUrl, newParams)
	}
}

