import * as React from 'react'
import * as mdl from '../model'
import { Component, Context, Icon, observable, observer } from './common'
import { ItemLabel } from './LayoutViews'

interface Props {
	title: string
	item: mdl.Item
	context: Context
	onAddTmpl: (id: string) => void
	onRemoveTmpl: (id: string) => void
}

// TODO: TemplateSelect dialog
const tmpls = [
	'list.tmpl', 'text.tmpl', 'url.tmpl', 'location.tmpl', 'query.tmpl',
	'todo.tmpl', 'comment.tmpl', 'number.tmpl', 'date.tmpl', 'calendar.tmpl',
	'image.tmpl', 'box.tmpl', 'installation.tmpl', 'account.tmpl', 'storage.tmpl',
	'question.tmpl'
]

@observer
export class TemplatesList extends Component<Props> {

	@observable show = false
	@observable showOptions = false
	selectedTmplId = tmpls[0]

	render() {
		const { title, item, context, onRemoveTmpl } = this.props
		return <div className="templates field">
			<label onClick={this.toggle}>
				<Icon uri={this.show ? 'expand_more' : 'chevron_right'} />
				{title}
			</label>
			{this.show && <>
				<div className="searchResults" onClick={this.onSelect}>
					{item.tmpls.map(({ item }) =>
						<Template key={item.id} item={item} onRemoveTmpl={onRemoveTmpl}
							context={context} />
					)}
				</div>
				<div>
					<button onClick={this.onAdd}>+</button>
					{this.showOptions && <select onChange={this.onTmplSel}>
						{tmpls.map(id => <option key={id} value={id}>{id}</option>)}
					</select>}
				</div>
			</>}
		</div>
	}

	toggle = () => { this.show = !this.show }

	onAdd = () => {
		if (this.showOptions)
			this.props.onAddTmpl(this.selectedTmplId)
		this.showOptions = !this.showOptions
	}

	onSelect = (evn: React.MouseEvent<HTMLElement>) => {
		const trg = evn.target as HTMLElement
	}

	onTmplSel = (evn: React.ChangeEvent<HTMLSelectElement>) => {
		this.selectedTmplId = evn.target.value
	}

}

interface TmplProps {
	item: mdl.Item
	onRemoveTmpl: (id: string) => void
	context: Context
}

class Template extends Component<TmplProps> {

	render() {
		const { item, context } = this.props
		return <div className="template">
			<ItemLabel item={item} context={context} />
			<button onClick={this.onRemove}><Icon uri="clear" /></button>
		</div>
	}

	onRemove = () => { this.props.onRemoveTmpl(this.props.item.id) }

}

