import * as mdl from '../../model'

export interface Index {
	build(item: mdl.Item): void
	remove(item: mdl.Item): void
	run(terms: string[], resultIds: Set<string>,
		items: mdl.ItemManager): Promise<void>
}

export function updateIndex(indexKey, fn: (index) => string) {
	const index = fn(localStorage.getItem(indexKey))
	if (index) localStorage.setItem(indexKey, index)
}

export function inverseMap(
	map: { [k: string]: string }): { [k: string]: string | string[] } {
	const res = {}
	for (let k of Object.keys(map)) {
		const v = map[k]
		if (v in res) {
			const r = res[v]
			if (Array.isArray(r)) r.push(k)
			else res[v] = [r]
		}
		else res[v] = k
	}
	return res
}

export function addAll(set: Set<any>, values: any[]) {
	values.forEach(set.add, set)
}
