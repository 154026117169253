
export function add<T>(arr: T[], e: T, index?: number) {
	if (!arr)
		return [e]
	const copy = arr.slice()
	if (typeof index !== 'number' || index === -1)
		copy.push(e)
	else
		copy.splice(index, 0, e)
	return copy
}

export function replace<T>(arr: T[], element: T, withElement: T) {
	return arr.map(e => e === element ? withElement : e)
}

export function remove<T>(arr: T[], idx: number) {
	const newArr = arr.slice()
	newArr.splice(idx, 1)
	return newArr
}

export function move<T>(arr: T[], fromIdx: number, toIdx: number) {
	if (fromIdx < 0)
		fromIdx = arr.length + fromIdx
	if (toIdx < 0)
		toIdx = arr.length + toIdx
	const res = arr.slice()
	const target = arr[fromIdx]
	const inc = fromIdx > toIdx ? -1 : 1
	for (let i = fromIdx; i !== toIdx; i += inc)
		res[i] = arr[i + inc]
	res[toIdx] = target
	return res
}

export function exclude<T extends {}, K extends keyof T>(src: T, ...keys: K[]) {
	const res = {} as Partial<T>
	for (const k of Object.keys(src) as K[]) {
		if (!keys.includes(k))
			res[k] = src[k]
	}
	return res
}

