import { O, reaction, when } from '../../common'
import * as mdl from '../../model'
import { convert, Form, formValue, FormValues } from '../../ui'

const convertExt = {
	...convert,
	date: {
		in: (v: Date) => v ? v.toISOString().substring(0, 10) : '',
		out: (v: string) => v ? new Date(v) : null
	},
	time: {
		in: (v: Date) => v ? v.toISOString().substring(11, 19) : '',
		out: (v: string) => v ? new Date(v) : null
	},
	dateTime: {
		in: (v: Date) => v ? v.toISOString() : '',
		out: (v: string) => v ? new Date(v) : null
	},
	action: (item: mdl.Item) => ({
		in: (v: object) => v.constructor['key'],
		out: (v: string) => v in mdl.propertyActionValues ?
			O.new(mdl.propertyActionValues[v], item) : null
	}),
}

const complexFormValues = {
	box: (v: mdl.Box): mdl.BoxValue.FormValues => ({
		label: formValue(v, 'label'),
		mark: formValue(v, 'mark'),
		color: formValue(v, 'color'),
		backColor: formValue(v, 'backColor'),
	}),
	storage: (v: mdl.BoxStorage): mdl.BoxStorageValue.FormValues => ({
		protocol: formValue(v, 'protocol'),
		url: formValue(v, 'url'),
		searchUrl: formValue(v, 'searchUrl'),
		blobUrl: formValue(v, 'blobUrl'),
	}),
	account: (v: mdl.Account): mdl.AccountValue.FormValues => ({
		provider: formValue(v, 'provider'),
		url: formValue(v, 'url'),
	}),
	location: (v: mdl.LocationValue): mdl.LocationValue.FormValues => ({
		address: formValue(v, 'address'),
		latitude: formValue(v, 'latitude', convert.number),
		longitude: formValue(v, 'longitude', convert.number),
		altitude: formValue(v, 'altitude', convert.number),
	}),
}

export const setup = {

	createForm: () => {
		O.onInit(mdl.EditItemView, async view => {
			await when(() => view.item?.isReady)
			reaction(() => view.item.props.editable, props => {
				const vals: FormValues = {}
				for (const p of props) {
					if (p.type in complexFormValues) {
						const propVals = complexFormValues[p.type](p.value)
						view.enhanceFormValues(p.type, propVals)
						vals[p.name] = propVals
					} else {
						const convert = convertExt[p.type]
						vals[p.name] = formValue(p, 'value',
							typeof convert === 'function' ? convert(view.item) : convert)
					}
				}
				view.form = new Form(vals)
				view.form.submit.react(() => {
					for (const p of props) {
						if (!view.item.props.has(p.name) &&
							(p.value || p.value === 0 || p.value === false))
							view.item.props.set(p)
						else if (p.type in complexFormValues)
							view.item.props.set(p)
					}
				})
			}, { fireImmediately: true })
		})
	},

	addTmpl: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.EditItemView, view => {
			view.addTmpl.react(async (id) => {
				const tmpl = items.requestItem(id)
				view.item.tmpls.add(tmpl)
			})
			view.removeTmpl.react(async (id) => {
				view.item.tmpls.remove(id)
			})
		})
	},

}
