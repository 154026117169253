import * as mdl from '../model'
import { previewLevels } from '../model'
import { Context, Form, observer } from './common'
import * as layouts from './layouts'
import { Layout } from './layouts/common'

/** Fallback layout. Needs to implement all parts! */
const defaultLayout = layouts.list

function getLayout(item: mdl.Item): Layout {
	return item.layout && item.layout in layouts
		? layouts[item.layout] : defaultLayout
}

export const ItemLabel = observer(({ item, context }:
	{ item: mdl.Item; context: Context; }) => {
	if (!item)
		return null
	const layout = getLayout(item)
	return ('label' in layout ? layout : defaultLayout).label(item, context)
})

export const ItemIcon = observer(({ item, context }:
	{ item: mdl.Item; context: Context; }) => {
	if (!item)
		return null
	const layout = getLayout(item)
	return ('icon' in layout ? layout : defaultLayout).icon(item, context)
})

export const ItemContent = observer(({ item, context, params }:
	{ item: mdl.Item; context: Context; params?: { [k: string]: string } }) => {
	if (!item)
		return null
	const layout = getLayout(item)
	return ('content' in layout ? layout : defaultLayout)
		.content(item, context, params)
})

export function getContentLinks(item: mdl.Item) {
	const layout = getLayout(item)
	return 'getContentLinks' in layout
		? layout.getContentLinks(item) : item.content.available
}

export function getLinks(item: mdl.Item) {
	const layout = getLayout(item)
	return 'getLinks' in layout ? layout.getLinks(item) : item.links.available
}

function getLinkLayout(linkLayouts: Layout['links'], level: mdl.PreviewLevel) {
	if (level in linkLayouts)
		return linkLayouts[level]
	for (let i = previewLevels.indexOf(level); i >= 0; --i) {
		const l = previewLevels[i]
		if (l in linkLayouts)
			return linkLayouts[l]
	}
	return linkLayouts['small']
}

export const ItemLink = observer(({ item, level, context }:
	{ item: mdl.Item; level: mdl.PreviewLevel; context: Context; }) => {
	if (!item)
		return null
	const layout = getLayout(item)
	const linkLayouts = 'links' in layout ? layout.links : layouts.list.links
	return getLinkLayout(linkLayouts, level)(item, context)
})

export const ItemEditor = ({ item, context, form }:
	{ item: mdl.Item; context: Context; form: Form }) => {
	if (!item)
		return null
	const layout = getLayout(item)
	return ('editor' in layout ? layout : defaultLayout)
		.editor(item, context, form)
}

