import { comparer, O, reaction, U, when } from '../common'
import * as mdl from '../model'

export const setup = {

	credentialsProvider: ({ auth }: { auth: mdl.Auth }) => {
		O.onInit(mdl.Account, account => {
			account.credentialsProvider = auth
		})
	},

	loadItem: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.Item, item => {
			item.request1.react(item => boxes.loadItem(item, false))
			item.request2.react(item => boxes.loadItem(item, true))
		})
	},

	storeBox: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.BoxStorage, async storage => {
			await when(() => storage.item.isReady)
			reaction(() => storage.access ? storage.allBoxes.map(U.obj.toId) : null,
				boxIds => {
					if (boxIds) {
						const idMap = U.array.toObject(boxIds)
						for (const box of boxes.allBoxes) {
							if (box.id in idMap)
								storage.access.addBox(box)
							else
								storage.access.removeBox(box.id)
						}
					}
				})
		})
	},

	completeBoxStorageLinks: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.BoxStorage, async storage => {
			await when(() => storage.item.isReady)
			reaction(() => storage.item.allLinks,
				links => {
					links.forEach(mdl.Item.complete)
				}, { fireImmediately: true, equals: comparer.shallow })
		})
	},

	boxStorages: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.BoxStorage, async storage => {
			await when(() => storage.item.isReady)
			reaction(() => storage.allBoxes.map(U.obj.toId), boxIds => {
				const idMap = U.array.toObject(boxIds)
				for (const box of boxes.allBoxes) {
					if (box.id in idMap)
						box.addStorage(storage)
					else
						box.removeStorage(storage)
				}
			}, { fireImmediately: true })
		})
	},

	hideMarkProps: () => {
		O.onInit(mdl.Box, box => {
			box.item.request2.react(() => {
				box.item.props.hide('mark', 'color', 'backColor')
			})
		})
	},

	userBox: {

		installation: ({ boxes }: { boxes: mdl.BoxManager }) => {
			// put an installation also into the user box
			O.onInit(mdl.Item, item => {
				item.request3.react(() => {
					if (boxes.userBoxItem && !item.isGenerated &&
						!!item.props.findByType('installation'))
						item.addToBox(boxes.userBoxItem)
				})
			})
		},

	},

	userInBox: ({ boxes }: { boxes: mdl.BoxManager }) => {
		// put a user into the user box
		O.onInit(mdl.Item, item => {
			item.request3.react(() => {
				// TODO: maybe better a user property value or something...
				if (boxes.userBoxItem && item.id.endsWith('.user'))
					item.addToBox(boxes.userBoxItem)
			})
		})
	},

	boxInOwnBox: () => {
		// always put a box item in it's own box
		O.onInit(mdl.Box, box => {
			const { item } = box
			item.request3.react(() => {
				if (!item.isGenerated)
					item.addToBox(box)
			})
		})
	},

	loadMissingItems: ({ items }: { items: mdl.ItemManager }) => {
		// after activating a box, there might be some missing items available
		O.onInit(mdl.Box, box => {
			reaction(() => box.isActive, active => {
				if (active) {
					setTimeout(() => {
						for (const item of items.missingItems)
							box.readItem(item.id).then(d => { item.build(d) })
					})
				}
			}, { fireImmediately: true })
		})
	},

	permissions: () => {
		O.onInit(mdl.Box, box => {
			reaction(() =>
				box.isActive && box.permissions === null && [...box.availableStorages],
				storages => {
					if (!storages)
						return
					for (const s of storages) {
						if ('readPermissions' in s.access) {
							s.access.readPermissions(box.id)
								.then(p => { box.permissions = p })
							return
						}
					}
				})
		})
	},

	createAccountForStorage: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.BoxStorage, storage => {
			storage.createAccount.start.react(async () => {
				const newItem = await items.createNewItem({
					props: {
						title: storage.item.labelText + ' Account',
						account: itm =>
							O.new(mdl.Account, itm, { account: 'couch', url: storage.url }),
					},
					tmpls: [items.getItem('account.tmpl')],
				})
				storage.item.links.add(newItem, -1, 'all')
				storage.createAccount.end(newItem.id)
			})
		})
	},

	addBoxesToStorage: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.BoxStorage, storage => {
			storage.addBoxes.react(async () => {
				const boxInfos = await storage.access.getBoxes(boxes.allStorages
					.map(U.obj.toId))
				for (const boxInfo of boxInfos) {
					if (!boxInfo.permissions || boxInfo.permissions === 'no')
						continue
					await boxes.readBox([storage.url], boxInfo.id)
					const box = boxes.getBox(boxInfo.id)
					box.permissions = boxInfo.permissions
					storage.item.links.add(box.item, 0)
				}
			})
		})
	},

}
