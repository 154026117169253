import * as React from 'react'
import * as mdl from '../model'
import logo from '../style/logo.svg'
import {
	Component, Form, formValues, Icon, observable, observer,
	Observer, TextInput
} from './common'

interface Props {
	view: mdl.LoginView
}

@observer
export class Login extends Component<Props> {

	@observable login = ''
	@observable password = ''
	form = new Form(formValues(this, { login: 1, password: 1 }))

	constructor(props: Readonly<Props>) {
		super(props)
		this.form.values.login.validate((s, p) =>
			!s && 'The login might not be empty!')
		this.form.values.password.validate((s, p) =>
			!s && 'The password might not be empty!')
	}

	render() {
		const { form } = this
		return <div className="view">
			<header>
				<Observer>{() =>
					<button onClick={this.onOk} disabled={form.hasErrors}
						className={form.hasErrors ? '' : 'active'}>
						<Icon uri={'navOk'} />
					</button>
				}</Observer>
				<div>
					<img className="icon" src={logo} />
					<span>Allsbe Login</span>
				</div>
			</header>
			<section>
				<div className="content">
					<TextInput label="Login Name" value={form.values.login}
						autoFocus={true} autoComplete="username" />
					<TextInput label="Password" value={form.values.password}
						type="password" autoComplete="current-password" />
				</div>
			</section>
			<footer>
				<div className="buttons">
					<button onClick={this.onOk} disabled={form.hasErrors}
						className={form.hasErrors ? '' : 'active'}>
						<Icon uri={'navOk'} />
					</button>
				</div>
			</footer>
		</div>
	}

	onOk = () => {
		if (this.form.hasErrors) return
		this.form.submit()
		this.props.view.login.start(this.login, this.password)
	}

	onKey = (evn: KeyboardEvent) => {
		if (evn.key === 'Enter')
			this.onOk()
	}

}
