
export const setup = {

	removeHover: () => {
		// Try to remove hover styles on touch usages.
		let touching = false
		document.addEventListener('mousemove', () => {
			if (touching) return
			document.body.classList.remove('touch')
			//console.log('no touch')
		}, true)
		document.addEventListener('touchstart', () => {
			document.body.classList.add('touch')
			touching = true
			//console.log('touch start')
		}, true)
		document.addEventListener('touchend', () => {
			setTimeout(() => { touching = false }, 500)
			//console.log('touch end')
		}, true)
	},

}
