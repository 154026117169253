import { action, computed, observable, signal, U } from '../common'

export enum LogLevel { error, warn, log, info }

export interface Logger {
	error(msg: string | Error, data?: any): void
	warn(msg: string | Error, data?: any): void
	log(msg: string | Error, data?: any): void
	info(msg: string | Error, data?: any): void
}

export class SystemLogger implements Logger {

	@observable.shallow entries: LogEntry[] = []
	@observable archive: string[]
	private idGen = 0
	consoleLevel = LogLevel.info
	consoleSys = { ...console }

	@computed get errors() {
		return this.entries.filter(e => e.level === LogLevel.error)
	}

	@action error = (msg: string | Error, data?: any) => {
		this.add(LogLevel.error, msg, data)
	}

	@action warn = (msg: string | Error, data?: any) => {
		this.add(LogLevel.warn, msg, data)
	}

	@action log = (msg: string | Error, data?: any) => {
		this.add(LogLevel.log, msg, data)
	}

	@action info = (msg: string | Error, data?: any) => {
		this.add(LogLevel.info, msg, data)
	}

	@action add = (level: LogLevel, msgOrError: string | Error, data?: any) => {
		const msg = typeof msgOrError === 'string' ? msgOrError : msgOrError.message
		const entryData = errorsToJson(typeof msgOrError !== 'string' ?
			data ? { error: msgOrError, ...data } : msgOrError : data)
		if (this.entries.length > 0) {
			const lastEntry = this.entries[this.entries.length - 1]
			if (level === lastEntry.level && msg === lastEntry.message &&
				U.obj.deepEquals(entryData, lastEntry.data)) {
				lastEntry.count++
				return
			}
		}
		const entry = new LogEntry(this.idGen++, level, msg, entryData)
		if (this.consoleLevel !== null && level <= this.consoleLevel) {
			const l = level === LogLevel.info ? 'log' : LogLevel[level]
			const msg = LogEntry.formatMsg(entry)
			if (entryData === void 0)
				this.consoleSys[l](msg)
			else if (msg === 'Console' && Array.isArray(data))
				this.consoleSys[l](...entryData)
			else if (msg === 'Console')
				this.consoleSys[l](entryData)
			else if (msgOrError instanceof Error)
				this.consoleSys[l](msgOrError, { ...entryData })
			else
				this.consoleSys[l](msg, entryData)
		}
		this.entries.push(entry)
		if (this.archive) this.archive.push(LogEntry.format(entry))
	}

	@action console = (level: LogLevel, args: any[]) => {
		const msg = args[0]
		if (typeof msg === 'string') {
			args = args.slice(1)
			// TODO: string interpolation other than %s
			// eslint-disable-next-line max-len
			// https://developer.mozilla.org/en-US/docs/Web/API/Console#outputting_text_to_the_console
			if (msg.includes('%s'))
				this.add(level, msg.replace(/%s/g, () => args.shift()))
			else
				this.add(level, msg, args.length > 0 ? args : void 0)
		} else {
			this.add(level, 'Console', args.length === 1 ? args[0] : args)
		}
	}

	@action clear = () => {
		this.entries.length = 0
		if (this.archive) this.archive.length = 0
	}

	@action remove(entry: LogEntry) {
		const idx = this.entries.indexOf(entry)
		if (idx >= 0) this.entries.splice(idx, 1)
	}

	requestArchive = signal()

	try<F extends Function>(fn: F): F {
		return ((...args: any[]) => {
			try {
				return fn(...args)
			} catch (err) {
				this.error(err)
			}
		}) as any
	}
}

function errorsToJson(data: any) {
	if (!data)
		return data
	else if (data instanceof Error)
		data = U.error.toJson(data, true)
	else if (typeof data === 'object' &&
		'error' in data && data.error instanceof Error)
		data.error = U.error.toJson(data.error, true)
	else if (Array.isArray(data))
		for (let i = 0, len = data.length; i < len; ++i)
			data[i] = errorsToJson(data[i])
	return data
}

const msgPattern = /\{([^\}]*)\}/

export class LogEntry {

	@observable count = 1
	date = new Date(Date.now())

	constructor(public id: number, public level: LogLevel, public message: string,
		public data: any) { }

	static formatMsg(entry: LogEntry) {
		if (!entry.data) return entry.message
		return entry.message.replace(msgPattern, (m, g1) => entry.data[g1])
	}

	static format(entry: LogEntry) {
		return entry.date.toISOString() + ' ' + LogLevel[entry.level] + ' ' +
			LogEntry.formatMsg(entry) +
			(entry.data ? ' ' + U.any.stringify(entry.data).trim() : '')
	}
}
