import { comparer, doDebug, IObservableArray, O, observe, reaction, U } from '../common'
import * as mdl from '../model'
import { addUpdateLog, buildData } from './build'

declare const __DEBUG__: boolean

export const setup = {

	observe: ({ changes }: { changes: mdl.ChangeManager }) => {
		O.onInit(mdl.Item, item => {
			item.observe.react(() => {
				if (item.isGenerated) return
				let prev = __DEBUG__ && doDebug() ? buildData(item, false) : void 0
				reaction(
					() => buildData(item, false),
					data => {
						// TODO: redesign
						if (item.settingData)
							return
						if (__DEBUG__ && doDebug()) {
							console.log(item.id, U.obj.diff(data, prev))
							prev = data
						}
						// prepare data
						data = { ...data }
						data.rev = item.rev + 1
						item.recordUpdate()
						addUpdateLog(data, item)
						// process change
						item.hasChanged = true
						changes.setChange(data, item.boxItems
							.filter(mdl.Box.hasActiveStorage).map(U.obj.toId))
					},
					{ name: `update ${item.id}`, equals: comparer.structural })
				observe(item.boxItems as IObservableArray<mdl.Item>, c => {
					// TODO: redesign
					if (item.settingData)
						return
					if ('added' in c) {
						for (const boxItem of c.added)
							if (mdl.Box.hasActiveStorage(boxItem))
								changes.setAdd(buildData(item), boxItem.id)
						for (const boxItem of c.removed)
							if (mdl.Box.hasActiveStorage(boxItem))
								changes.setRemove(item.id, boxItem.id)
					}
				})
			})
		})
	},

}
