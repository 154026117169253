import { action, httpClient, O } from '../../common'
import * as mdl from '../../model'

export const setup = {

	accountAccess: ({ log }: { log: mdl.SystemLogger }) => {
		O.onInit(mdl.Account, account => {
			account.readUser.react(async (credentials: mdl.TokenCredentials) => {
				if (account.provider !== 'gitlab-token')
					return
				const http = httpClient(credentials.token)
				const url = account.url + '/api/v4/user'
				const data = await http.get(url)
				if (!data)
					throw new Error(`Gitlab user not found!`)
				action(() => {
					setOptional(data, 'username', account.item, 'username')
					setOptional(data, 'avatar_url', account.item, 'avatar', 'icon')
					setOptional(data, 'email', account.item, 'email')
					setOptional(data, 'commit_email', account.item, 'email')
				})()
			})

		})
	},

}

function setOptional(data: any, member: string,
	item: mdl.Item, propName: string, propType?: mdl.PropertyType) {
	if (member in data)
		item.props.set(propName, data[member], propType)
}
