import { FormValue } from '../ui/Form'
import { U } from './common'

export type NumberValue = number | { number: number; unit?: string; }

export interface HashedBlob {
	blob: Blob
	hash: string
}
export namespace HashedBlob {

	export function isInstance(v: any): v is HashedBlob {
		return v && typeof v === 'object' && 'hash' in v && v.blob instanceof Blob
	}

	export async function create(blob: Blob) {
		return blob ?
			{ blob, hash: U.base32.encode(await U.blob.toSha256(blob)) } :
			null
	}

	export function getBlob(blob: Blob | HashedBlob) {
		return 'blob' in blob ? blob.blob : blob
	}
}

export interface ImageValueBase {
	width?: number
	height?: number
	class?: string
	info?: any
	source?: string
}

export interface ImageValueObject extends ImageValueBase {
	image: Blob | HashedBlob | string
}

export interface IconValueObject extends ImageValueBase {
	icon: Blob | HashedBlob | string
}

export type ImageValue = string | Blob | ImageValueObject | IconValueObject
export namespace ImageValue {
	export function url(v: ImageValue): string {
		return typeof v === 'string' ? v :
			v && typeof v['image'] === 'string' ? v['image'] :
				v && typeof v['icon'] === 'string' ? v['icon'] : null
	}
	export function blob(v: ImageValue): Blob {
		return U.blob.isBlob(v) ? v : HashedBlob.isInstance(v) ? v.blob :
			v && HashedBlob.isInstance(v['image']) ? v['image'].blob :
				v && U.blob.isBlob(v['image']) ? v['image'] :
					v && HashedBlob.isInstance(v['icon']) ? v['icon'].blob :
						v && U.blob.isBlob(v['icon']) ? v['icon'] : null
	}
	export function urlOrBlob(v: ImageValue): string | Blob {
		return ImageValue.url(v) ?? ImageValue.blob(v)
	}
	export function className(v: ImageValue): string {
		return v && typeof v['class'] === 'string' ? v['class'] : null
	}
	export function isValid(v: ImageValue) {
		return v && (!!ImageValue.url(v) || !!ImageValue.blob(v))
	}
}

export interface LocationValue {
	address?: string
	latitude: number
	longitude: number
	accuracy?: number
	altitude?: number
	altitudeAccuracy?: number
	heading?: number
	speed?: number
}
export namespace LocationValue {
	export function isValid(lat: number, lng: number): boolean
	export function isValid(loc: LocationValue): boolean
	export function isValid(lat: any, lng?: number) {
		const t = typeof lat
		return t === 'object' ?
			typeof lat.latitude === 'number' && typeof lat.longitude === 'number' :
			t === 'number' && typeof lng === 'number'
	}
	export interface FormValues {
		address: FormValue<{ address?: string }, 'address'>
		latitude: FormValue<{ latitude: number }, 'latitude'>
		longitude: FormValue<{ longitude: number }, 'longitude'>
		altitude: FormValue<{ altitude?: number }, 'altitude'>
	}
}


export interface BoxValue {
	box: string
	mark?: string
	color?: string
	backColor?: string
}
export namespace BoxValue {
	export interface FormValues {
		label: FormValue<{ label: string }, 'label'>
		mark: FormValue<{ mark: string }, 'mark'>
		color: FormValue<{ color: string }, 'color'>
		backColor: FormValue<{ backColor: string }, 'backColor'>
	}
}


export interface BoxStorageValue {
	storage: string
	url?: string
	searchUrl?: string
	blobUrl?: string
}
export namespace BoxStorageValue {
	export interface FormValues {
		protocol: FormValue<{ protocol: string }, 'protocol'>
		url: FormValue<{ url: string }, 'url'>
		searchUrl: FormValue<{ searchUrl: string }, 'searchUrl'>
		blobUrl: FormValue<{ blobUrl: string }, 'blobUrl'>
	}
}


export interface AccountValue {
	account: string
	url: string
}
export namespace AccountValue {
	export interface FormValues {
		provider: FormValue<{ provider: string }, 'provider'>
		url: FormValue<{ url: string }, 'url'>
	}
}

