import { IGNORE_ERROR_NULL, U } from '../../../common'
import * as mdl from '../../../model'
import { IndexedDb } from '../../common/IndexedDb'
import { replaceBlobsWithRefs, replaceRefsWithBlobs } from '../Blobs'
import { createDbs } from './createDbs'

export class IndexedDbAccess implements mdl.BoxStorageAccess {

	constructor(public dbs:
		{ data: IndexedDb; index: IndexedDb; preview?: IndexedDb; }) {
		if (!dbs)
			this.dbs = createDbs()
	}

	async readBlob(hash: string) {
		const blob = await this.dbs.data.getVal<Blob>('image', hash)
			.catch(IGNORE_ERROR_NULL)
		return blob ? { blob, hash } : null
	}

	async readData(fromBoxId: string): Promise<mdl.ItemData[]> {
		const data: mdl.ItemData[] = await this.dbs.data.bulk(
			{ stores: ['box', 'item'], mode: 'readonly' },
			{
				primaryKeysFor: fromBoxId, store: 'box', index: 'items',
				next: (itemIds: string[]) => itemIds.length ?
					{ get: itemIds, store: 'item' } : null
			}) ?? []
		return await Promise.all(data.map(mdl.ItemData.cleanup)
			.map(d => replaceRefsWithBlobs(d, this)))
	}

	async readIds(fromBoxId: string) {
		const data = await this.readData(fromBoxId)
		return data.map(d => ({ id: d.id, rev: mdl.Item.toFullRev(d) }))
	}

	async readItem(id: string, fromBoxIds: string[]) {
		const keys = U.array.toObject(fromBoxIds)
		const boxIdVals = await this.dbs.data.getVal<string[]>('box', id)
		const boxIds = boxIdVals?.filter(id => id in keys)
		if (boxIds?.length > 0) {
			const d = await this.dbs.data.getVal<mdl.ItemData>('item', id)
			if (d)
				d.boxes = boxIds
			await replaceRefsWithBlobs(d, this)
			return mdl.ItemData.cleanup(d)
		}
		return null
	}

	async readItems(ids: string[], fromBoxIds: string[]) {
		return Promise.all(ids.map(id => this.readItem(id, fromBoxIds)))
	}

	async readFromLinks(id: string) {
		return await this.dbs.index.getVal('from', id) ?? []
	}

	async readSearchTexts() {
		return await this.dbs.index.getAll('stem') ?? []
	}

	async writeItem(data: mdl.ItemData, intoBoxId: string): Promise<void> {
		const { id } = data
		const { doc, blobs } = await replaceBlobsWithRefs(data)
		const orig = await this.dbs.data.getVal('item', id)
		await this.dbs.data.bulk(
			// item data
			{ put: id, store: 'item', val: doc },
			// blobs
			...Object.keys(blobs)
				.map(h => ({ put: h, store: 'image', val: blobs[h] })),
			// box assignment
			{
				get: id, store: 'box', next: (boxKeys: string[]) => ({
					put: id, store: 'box',
					val: U.array.addUnique(boxKeys, intoBoxId)
				})
			})
		// from links index
		if (data.links?.length > 0 || orig?.links?.length > 0) {
			const fromIndexes: { [id: string]: string[] } = {}
			if (!data.isDeleted)
				setLinkIds(data, fromIndexes)
			const newIndexes = data.isDeleted ? {} : { ...fromIndexes }
			setLinkIds(orig, fromIndexes)
			await this.dbs.index.bulk({
				get: fromIndexes, store: 'from',
				next: (kv: { [x: string]: any; }) =>
					updateFromIndex(id, kv, newIndexes)
			})
		}
		// text search index
		if (data.isDeleted) {
			await this.dbs.index.delVal('stem', id)
		} else {
			// TODO: word stem based and adapt data access interface
			const searchText = getSearchTextVals(data).join(' ')
			if (searchText)
				await this.dbs.index.putVal('stem', id,
					{ id, rev: mdl.Item.toFullRev(data), text: searchText })
		}
	}

	async removeItem(id: string, fromBoxId: string) {
		const itemData: mdl.ItemData =
			await this.dbs.data.bulk({ stores: ['box', 'item'] }, {
				get: id, store: 'box', next: (boxKeys: string[]) => {
					const idx = boxKeys ? boxKeys.indexOf(fromBoxId) : -1
					if (idx >= 0) {
						boxKeys.splice(idx, 1)
						return boxKeys.length > 0 ?
							{ put: id, store: 'box', val: boxKeys } : [
								{ del: id, store: 'box' },
								{ get: id, store: 'item' },
								{ del: id, store: 'item' }
							]
					}
				}
			})
		if (itemData) {
			// There might be duplicates! => keep the blobs
			// TODO: some kind of garbage collection instead
			// for (const v of Object.values(itemData.props).filter(U.any.isTrue)) {
			// 	if (typeof v.icon === 'string' && v.icon.startsWith('blob:'))
			// 		await this.dbs.data.delVal('image', v.icon.substring(5))
			// 	if (typeof v.image === 'string' && v.image.startsWith('blob:'))
			// 		await this.dbs.data.delVal('image', v.image.substring(5))
			// }
			if (itemData.links?.length > 0) {
				const fromIndexes: { [id: string]: string[]; } = {}
				setLinkIds(itemData, fromIndexes)
				await this.dbs.index.bulk({
					get: fromIndexes, store: 'from',
					next: (kv: { [x: string]: string[]; }) => updateFromIndex(id, kv)
				})
			}
			await this.dbs.index.delVal('stem', id)
		}
	}

	async getBoxes(knownBoxIds: string[]) {
		const excludeBoxIds = U.array.toObject(knownBoxIds)
		const boxIds = await this.dbs.data.getIndexKeys('box', 'items')
		return boxIds.filter(id => !(id in excludeBoxIds))
			.map(id => ({ id, permissions: 'rw' as mdl.BoxPermissions }))
	}

	async addBox(box: mdl.Box) {
		// OPT: cache boxIds
		const boxIds = await this.dbs.data.getIndexKeys('box', 'items')
		if (!boxIds.includes(box.id)) {
			const storage = box.availableStorages.find(s => s.access !== this)
			if (!storage)
				return
			const batchSize = 100
			// OPT: only read items not already in this storage
			const ids = (await storage.access.readIds(box.id)).map(U.obj.toId)
			for (let b = 0, len = ids.length / batchSize; b < len; ++b) {
				const data = await storage.access.readItems(
					ids.slice(b * batchSize, b * batchSize + batchSize), [box.id], true)
				await Promise.all(data.map(d => this.writeItem(d, box.id)))
			}
		}
	}

	async removeBox(boxId: string) {
		const itemIds = await this.dbs.data.getIndexPrimaryKeys('box', 'items',
			boxId)
		await Promise.all(itemIds.map(id => this.removeItem(id, boxId)))
	}

	async open(boxId?: string) {
		if (!this.dbs)
			this.dbs = createDbs()
	}

	async cleanup() {
		if (!this.dbs)
			return

	}

	async close(boxId?: string) {
		if (!this.dbs)
			return
		if (!boxId) {
			await Promise.all(Object.values(this.dbs).map(db => db.close()))
			this.dbs = null
		}
	}
}

export function getSearchTextVals(itemData: mdl.ItemData) {
	// implement corresponding to center/src/couch/upsertBox.ts views.searchText
	const vals = []
	for (const n in itemData.props) {
		const v = itemData.props[n]
		if (!v) continue
		const t = typeof v
		if (t === 'string') {
			if (v.indexOf(' ') > 0 || v.indexOf(':') < 4)
				vals.push(v)
		} else if (t === 'object') {
			for (const n1 in v) {
				const v1 = v[n1]
				if (v1 && typeof v1 === 'string' &&
					(v1.indexOf(' ') > 0 || v1.indexOf(':') < 4)) {
					vals.push(v1)
					break
				}
			}
		}
	}
	return vals
}

/** Update an item in existing from-indexes to new from-indexes. */
export function updateFromIndex(itemId: string,
	indexes: { [x: string]: string[] },
	newIndexes?: { [x: string]: string[] }) {
	return Object.keys(indexes).map(k => {
		const v = indexes[k]
		const idx = v?.indexOf(itemId)
		if (idx >= 0) {
			if (newIndexes && k in newIndexes) {
				return null
			} else {
				v.splice(idx, 1)
				return v.length > 0 ? { put: k, store: 'from', val: v } :
					{ del: k, store: 'from' }
			}
		} else if (newIndexes && k in newIndexes) {
			return { put: k, store: 'from', val: v ? [...v, itemId] : [itemId] }
		} else {
			return null
		}
	})
}

export function setLinkIds(itemData: mdl.ItemData,
	fromIndexes: { [id: string]: string[] }) {
	if (itemData?.links?.length > 0)
		for (const ln of itemData.links)
			if (ln)
				fromIndexes[mdl.ItemLinkData.url(ln)] = null
}


