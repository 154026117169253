import { observable, U } from './common'

/** Signal or event to be observed. */
type HandlerFn = (...args: any) => void | Promise<void>
type Disposer = () => void

export type Signal<F extends HandlerFn = () => void> = F & {
	/** React to this signal until disposed. */
	react(handler: F): Disposer
	/** React to a signal once and dispose listener afterwards. */
	reactOnce(handler: F): Disposer
	/** This signal has been called count times so far. */
	count: number
	/** This signal is active in a call. */
	isActive: boolean
	/** Reset this signal and remove all reactions. */
	reset(): void
}

export function signal<F extends HandlerFn = () => void>(handler?: F) {
	const handlers: F[] = []
	let onceHandlers: F[]
	if (typeof handler === 'function') handlers.push(handler)
	const isActive = observable.box(false)
	const sig = <Signal<F>>function(...args) {
		sig.count++
		isActive.set(true)
		const promises = handlers.map(h => h(...args)).filter(U.async.isPromiseLike)
		if (onceHandlers)
			promises.push(...onceHandlers.map(h => h(...args))
				.filter(U.async.isPromiseLike))
		if (onceHandlers) onceHandlers.length = 0
		const p = promises.length > 0 ? Promise.all(promises).then(() => { })
			.finally(() => { isActive.set(false) }) : void 0
		if (!p)
			isActive.set(false)
		return p
	}
	sig.react = (handler: F) => {
		handlers.push(handler)
		return () => {
			const idx = handlers.indexOf(handler)
			if (idx >= 0) handlers.splice(idx, 1)
		}
	}
	sig.reactOnce = (handler: F) => {
		if (!onceHandlers) onceHandlers = []
		onceHandlers.push(handler)
		return () => {
			const idx = onceHandlers.indexOf(handler)
			if (idx >= 0) onceHandlers.splice(idx, 1)
		}
	}
	sig.reset = () => {
		handlers.length = 0
		onceHandlers = null
		sig.count = 0
	}
	sig.count = 0
	Object.defineProperty(sig, 'isActive', {
		get: function() { return isActive.get() }
	})
	return sig
}

export function firstSignal(signal: Signal) {
	return signal.count > 0 ? Promise.resolve() :
		new Promise<void>(resolve => {
			signal.reactOnce(() => {
				resolve()
			})
		})
}
