import { action, O, reaction } from '../../common'
import { getAddress, getAltitude, getCoordinates } from '../../data/GeoLocation'
import * as mdl from '../../model'

const enhanceFormValues = {
	storage: (vals: mdl.BoxStorageValue.FormValues) => {
		reaction(() => vals.protocol.val,
			protocol => {
				if (protocol === 'indexed' || protocol === 'local')
					vals.url.val = protocol
			})
	},
	location: (vals: mdl.LocationValue.FormValues, config: mdl.Config) => {
		if (vals.latitude === void 0)
			getCurrentPosition(vals)
		reaction(() => vals.latitude.val + ',' + vals.longitude.val,
			coordinates => {
				if (coordinates === 'NaN,NaN') return
				getAltitude(config.api, coordinates)
					.then(action((alt: number) => { vals.altitude.val = alt }))
			})
		reaction(() => vals.address.isDirty,
			isDirty => {
				if (isDirty) return
				getCoordinates(config.api, vals.address.val).then(latlng => {
					action(() => {
						vals.latitude.val = latlng.lat
						vals.longitude.val = latlng.lng
					})()
				})
			})
		reaction(() => vals.latitude.val + ',' + vals.longitude.val,
			coordinates => {
				if (coordinates === 'NaN,NaN') return
				getAddress(config.api, coordinates).then(action(addr => {
					vals.address.val = addr
					vals.address.submit()
				}))
			})
	}
}

export const setup = {

	enhanceLocationValues: ({ config }: { config: mdl.Config }) => {
		O.onInit(mdl.EditItemView, view => {
			view.enhanceFormValues.react((type, vals) => {
				if (type in enhanceFormValues)
					enhanceFormValues[type](vals, config)
			})
		})
	},

}

export function getCurrentPosition(vals: mdl.LocationValue.FormValues) {
	navigator.geolocation.getCurrentPosition(p => {
		const { coords } = p
		action(() => {
			vals.latitude.val = coords.latitude
			vals.longitude.val = coords.longitude
			if (typeof coords.altitude === 'number')
				vals.altitude.val = Math.round(coords.altitude * 100) / 100
		})()
	})
}
