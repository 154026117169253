import { ApiManager } from './ApiManager'
import * as mdl from './base'
import { defaultConfig } from './Config'
import { UserInterface } from './UserInterface'

export class App extends mdl.AppBase {
	ui = new UserInterface()
	installation = new mdl.Installation(this)
	items = new mdl.ItemManager()
	boxes = new mdl.BoxManager(this.items, this.installation.log)
	changes = new mdl.ChangeManager(this.boxes, this.items, this.installation.log)
	session = new mdl.Session()
	config = defaultConfig
	apis = new ApiManager(this.config.api)
}
