import { action, O, U } from '../../common'
import * as mdl from '../../model'

export const setup = {

	toggle: ({ log }: { log: mdl.SystemLogger }) => {
		O.onInit(mdl.PlaceItemView, async view => {
			view.toggleBox.react(async box => {
				if (!box.isActive || !box.hasAvailableStorages) return
				try {
					const selectedItems = view.itemsToPlace
					const items = (await Promise.all(selectedItems
						.map(item => item.getHierarchy()))).flat()
					if (view.itemsInBox(box) === selectedItems.length) {
						action(() => {
							for (const item of items)
								item.removeFromBox(box)
						})()
					} else {
						items.push(...selectedItems.map(item => item.containers)
							.filter(U.any.isTrue).flat())
						for (const item of items)
							item.complete().then(item => { item.addToBox(box) })
					}
				} catch (err) {
					log.error(err)
				}
			})
		})
	}
}
