import * as React from 'react'
import { U } from '../../common'
import { Block, BooleanInput, Icon, TextareaInput } from '../common'
import { NumberEditor } from '../common/NumberEditor'
import { PropertyView } from './common'

export const string: PropertyView = {
	content: ({ value, label }) => <Block label={label}>
		{'' + value}</Block>,
}

function FormatedText({ text }: { text: string }) {
	const lines = text.split('\n')
		.filter((ln, idx, arr) => !!ln.trim() || (idx > 0 && arr[idx - 1].trim()))
	return <>
		{lines.map((v, i) => <React.Fragment key={i}>
			{i > 0 && <br />}
			{v.split(U.url.pattern.basic).map((s, j) => <React.Fragment key={j}>
				{U.url.pattern.basic.test(s) ? <a href={s} target="_blank">{s}</a> : s}
			</React.Fragment>)}
		</React.Fragment>)}
	</>
}

export const text: PropertyView = {
	content: ({ value }) => '' + value ? <p><FormatedText text={'' + value} /></p> : null,
	editor: (prop, form, ctx, autoFocus) =>
		<TextareaInput label={prop.label} value={form.values[prop.name]}
			autoFocus={autoFocus} />
}


function numberToString(value: any) {
	return typeof value === 'object' ?
		value.number + (value.unit ? value.unit : '') : '' + value
}

export const number: PropertyView = {
	label: ({ value }) => <>{numberToString(value)}</>,
	icon: ({ value }) =>
		<Icon uri={'text:' + numberToString(value).substring(0, 1)} />,
	content: ({ value, label }) => <Block label={label}>
		{numberToString(value)}</Block>,
	editor: (p, form, ctx, autoFocus) =>
		<NumberEditor label={p.label} value={form.values[p.name]}
			autoFocus={autoFocus} />
}

export const boolean: PropertyView = {
	content: ({ value, label }) => <Block label={label}>
		{'' + value}</Block>,
	editor: (p, form, ctx, autoFocus) => <BooleanInput label={p.label}
		value={form.values[p.name]} autoFocus={autoFocus} />
}
