import * as React from 'react'
import * as mdl from '../../model'
import { Block, EnumInput, Icon } from '../common'
import { PropertyView } from './common'

const actionOptions: { label: string, value: string }[] =
	Object.keys(mdl.propertyActionValues).map(k => ({ label: k, value: k }))

export const action: PropertyView = {
	label: ({ value }) => <>{value.constructor.name}</>,
	icon: () => <Icon uri="star" />,
	content: ({ value, label }) => <Block label={label}>
		{value.constructor.name}
	</Block>,
	editor: ({ name, label }, form, ctx, autoFocus) =>
		<EnumInput label={label} value={form.values[name]} autoFocus={autoFocus}
			options={actionOptions} />
}
