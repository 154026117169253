
export const ITEM_SEPARATOR = '|'
export const ENTRY_SEPARATOR = '\n'

/** Get all entries of an index. */
export function getEntries(index: string): { [k: string]: string }
export function getEntries(index: string, multiItems: false)
	: { [k: string]: string }
export function getEntries(index: string, multiItems: true)
	: { [k: string]: string[] }
export function getEntries(index: string, multiItems: boolean)
	: { [k: string]: string | string[] }
export function getEntries(index: string, multiItems = false) {
	if (!index) return {}
	const map: { [k: string]: string | string[] } = {}
	const entries = cleanupIndex(index).split(ENTRY_SEPARATOR)
	for (let entry of entries) {
		const p = entry.indexOf(ITEM_SEPARATOR)
		if (p > 0) {
			const v = entry.substring(p + 1)
			map[entry.substring(0, p)] = multiItems ? v.split(ITEM_SEPARATOR) : v
		}
	}
	return map
}

export function setEntries(values: { [k: string]: string[] }) {
	return Object.keys(values)
		.map(k => k + ITEM_SEPARATOR + values[k].join(ITEM_SEPARATOR))
		.join(ENTRY_SEPARATOR)
}

/** Get items of an index entry. */
export function getItems(index: string, key: string) {
	if (!index) return []
	index = ENTRY_SEPARATOR + cleanupIndex(index) + ENTRY_SEPARATOR
	const s = index.indexOf(ENTRY_SEPARATOR + key + ITEM_SEPARATOR)
	if (s >= 0) {
		const s2 = s + key.length + 2
		const e = index.indexOf(ENTRY_SEPARATOR, s2)
		const entry = index.substring(s2, e)
		return entry.split(ITEM_SEPARATOR)
	}
	return []
}

/** Add item to an index entry. */
export function addItem(index: string, key: string, item: string) {
	if (!index) return key + ITEM_SEPARATOR + item
	index = ENTRY_SEPARATOR + cleanupIndex(index) + ENTRY_SEPARATOR
	const s = index.indexOf(ENTRY_SEPARATOR + key + ITEM_SEPARATOR)
	if (s >= 0) {
		const s2 = s + ENTRY_SEPARATOR.length + key.length
		const e = index.indexOf(ENTRY_SEPARATOR, s2)
		const entry = index.substring(s2, e) + ITEM_SEPARATOR
		const p = entry.indexOf(ITEM_SEPARATOR + item + ITEM_SEPARATOR)
		if (p >= 0)
			// no change
			return
		index = index.substring(0, e) + ITEM_SEPARATOR + item + index.substring(e)
	}
	else {
		index += key + ITEM_SEPARATOR + item
	}
	return trim(index, ENTRY_SEPARATOR)
}

/** Remove item from an index entry. */
export function removeItem(index: string, key: string, item: string) {
	if (!index) return index
	index = ENTRY_SEPARATOR + cleanupIndex(index) + ENTRY_SEPARATOR
	const s = index.indexOf(ENTRY_SEPARATOR + key + ITEM_SEPARATOR)
	if (s >= 0) {
		const s2 = s + ENTRY_SEPARATOR.length + key.length
		const e = index.indexOf(ENTRY_SEPARATOR, s2)
		const entry = index.substring(s2, e) + ITEM_SEPARATOR
		const p = entry.indexOf(ITEM_SEPARATOR + item + ITEM_SEPARATOR)
		if (p >= 0) {
			const e1 = p + ITEM_SEPARATOR.length + item.length
			const e2 = entry.length - ITEM_SEPARATOR.length
			index = index.substring(0, s2) +
				entry.substring(0, p) + entry.substring(e1, e2) + index.substring(e)
		}
	}
	return trim(index, ENTRY_SEPARATOR)
}

/** Cleanup the string index a little. */
export function cleanupIndex(index: string) {
	index = index.replace(ENTRY_SEPARATOR + ENTRY_SEPARATOR, ENTRY_SEPARATOR)
	return trim(index, ENTRY_SEPARATOR)
}

/** Replace a hole index entry. */
export function replaceEntry(index: string, key: string, item: string) {
	if (!index) return key + ITEM_SEPARATOR + item
	index = ENTRY_SEPARATOR + cleanupIndex(index) + ENTRY_SEPARATOR
	const s = index.indexOf(ENTRY_SEPARATOR + key + ITEM_SEPARATOR)
	if (s >= 0) {
		const s2 = s + ENTRY_SEPARATOR.length + key.length + ITEM_SEPARATOR.length
		const e = index.indexOf(ENTRY_SEPARATOR, s2)
		const entry = index.substring(s2, e)
		if (entry === item)
			// no change 
			return
		index = index.substring(0, s2) + item + index.substring(e)
	}
	else {
		index += key + ITEM_SEPARATOR + item
	}
	return trim(index, ENTRY_SEPARATOR)
}

/** Remove a hole index entry. */
export function removeEntry(index: string, key: string) {
	if (!index) return index
	index = ENTRY_SEPARATOR + cleanupIndex(index) + ENTRY_SEPARATOR
	const s = index.indexOf(ENTRY_SEPARATOR + key + ITEM_SEPARATOR)
	if (s >= 0) {
		const e = index.indexOf(ENTRY_SEPARATOR, s + ENTRY_SEPARATOR.length)
		index = index.substring(0, s) + index.substring(e)
	}
	return trim(index, ENTRY_SEPARATOR)
}

function trim(str: string, char: string) {
	let s = 0, e = str.length - 1
	while (s < e && str[s] === char)
		++s
	while (e >= 0 && str[e] === char)
		--e
	return str.substring(s, e + 1)
}
