import { comparer, IGNORE_ERROR, O, reaction, U, when } from '../../common'
import * as mdl from '../../model'

export const setup = {

	/** request further items to show the available data */
	request: {
		/** labels, partial content,... shown in the link preview */
		links: () => {
			O.onInit(mdl.ViewItemView, async view => {
				await when(() => view.item?.isReady)
				// request the directly linked items
				reaction(() => view.item.allLinks, requestLinks,
					{ fireImmediately: true, equals: comparer.shallow })
				// ...and the indirectly linked also (they might provide an icon)
				reaction(() => view.item.allLinks.map(ln => ln.item.allLinks).flat(),
					requestLinks,
					{ fireImmediately: true, equals: comparer.shallow })
			})
		},
		/** complete URLs shown in the link preview */
		urlLinks: () => {
			O.onInit(mdl.ViewItemView, async view => {
				await when(() => view.item?.isReady)
				view.item.allLinks.forEach(async ({ item }) => {
					await when(() => item.isReady)
					if (item.tmpls.has('url.tmpl'))
						item.complete().catch(IGNORE_ERROR)
				})
			})
		},
		/** container ancestor axis shown with the item */
		containers: () => {
			O.onInit(mdl.ViewItemView, async view => {
				await when(() => view.item?.isReady)
				// request the whole container axis up to the root of the hierarchy
				reaction(() => view.item.containers, items => {
					if (items)
						items.forEach(mdl.Item.request)
				}, { fireImmediately: true })
			})
		},
		linksContainer: () => {
			O.onInit(mdl.ViewItemView, async view => {
				await when(() => view.item?.isReady)
				// request the containers of the directly linked items
				reaction(() => view.item.links
					.map(ln => ln.item.containers).flat(),
					items => { items.filter(U.any.isTrue).forEach(mdl.Item.request) },
					{ fireImmediately: true, equals: comparer.shallow })
			})
		},
		/** some layouts need additional data */
		layout: () => {
			O.onInit(mdl.ViewItemView, async view => {
				await when(() => view.item?.isCompleted)
				const { layout } = view.item
				if (layout in requestPerLayout)
					requestPerLayout[layout](view.item, view.params)
			})
		},
	},
	/** read from-links to show in the corresponding section */
	fromLinks: ({ boxes, items }:
		{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
		O.onInit(mdl.ViewItemView, async view => {
			await when(() => view.item?.isReady)
			const boxIdMap = U.array.toObject(boxes.activeBoxes, U.obj.toId)
			for (const storage of boxes.availableStorages) {
				storage.access.readFromLinks(view.item.id,
					storage.allBoxes.filter(b => b.id in boxIdMap).map(U.obj.toId))
					.then(ids => {
						if (ids)
							view.addFromLinks(ids.map(id => items.requestItem(id)))
					}, boxes.log.error)
			}
		})
	},

	tags: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.ViewItemView, view => {
			view.allTags = items.tagItems
		})
	},

	tagChanges: () => {
		O.onInit(mdl.ViewItemView, async view => {
			await when(() => view.item?.isReady)
			// TODO: needs redesign
			reaction(() => view.allTags.map(t => t.links.has(view.item)),
				tagStates => {
					for (let i = 0, len = tagStates.length; i < len; ++i) {
						const tag = view.allTags[i]
						if (tagStates[i])
							view.addFromLink(tag)
						else
							view.removeFromLink(tag)
					}
				})
		})
	},

}

const requestPerLayout:
	{ [layout: string]: (item: mdl.Item, params: mdl.Params) => void } = {

	calendar: (item, params) => {
		if (mdl.CalendarLayout.isMonthCalendar(item)) {
			// linked from content date items
			reaction(() => item.content.map(ln => ln.item.allLinks).flat(),
				requestLinks,
				{ fireImmediately: true, equals: comparer.shallow })
		} else {
			// request 3 content levels for calendar items (./year/month/date)
			const date = new Date(params?.['month'] ?? Date.now())
			// linked from date items
			reaction(() => mdl.CalendarLayout.monthItem(item, date)?.content
				.map(ln => ln.item.allLinks).flat(),
				requestLinks,
				{ fireImmediately: true, equals: comparer.shallow })
			// month and date items
			reaction(() => (mdl.CalendarLayout.monthItem(item, date) ??
				mdl.CalendarLayout.yearItem(item, date))?.content,
				requestLinks,
				{ fireImmediately: true, equals: comparer.shallow })
		}
	},

}

const requestLinkPerLayout:
	{ [layout: string]: (item: mdl.Item) => void } = {

	image: (item) => {
		// the image itself might show in link preview (as icon or fully)
		item.complete().catch(IGNORE_ERROR)
	},

	comment: (item) => {
		// create log details might serve as author to show
		item.complete().catch(IGNORE_ERROR)
	},

}

function requestLink(link: mdl.Link) {
	link.item.request().then(item => {
		if (item.layout in requestLinkPerLayout)
			requestLinkPerLayout[item.layout](item)
	}).catch(IGNORE_ERROR)
}

function requestLinks(links?: mdl.Link[] | mdl.Links) {
	links?.forEach(requestLink)
}
