import { action, O, U } from '../common'
import * as mdl from '../model'
import { propertyEqualsData, propertySetValue } from './properties'

export const setup = {

	buildItem: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.Item, item => {
			item.build.react((data, force) => {
				buildItem(items, data, item, force)
			})
		})
	},

}

export function buildItem(items: mdl.ItemManager,
	data: mdl.ItemData, item: mdl.Item, force = false) {
	if (!data || !item) return
	item.settingData = true
	action(() => {
		if (item.isReady) {
			if (force)
				readItem(item, data, items)
			else
				updateItem(items, item, data)
		} else {
			readItem(item, data, items)
			item.status = mdl.ItemStatus.level1
		}
		// fill boxes even if with same or older revisions
		getBoxes(item, data, items)
	})()
	item.settingData = false
}

function updateItem(items: mdl.ItemManager, item: mdl.Item,
	data: mdl.ItemData) {
	const rev = data.rev || 0
	if (item.rev < rev) {
		// newer revision => update rev for pending change or update
		if (item.hasChanged) {
			item.rev = data.rev
		} else {
			readItem(item, data, items)
		}
	} else if (item.rev === rev && !item.hasChanged) {
		// equal revisions, no pending change => update or conflict
		// TODO: handle conflicts
		if (mdl.Item.compareRev(data, item) >= 0)
			readItem(item, data, items)
		else if (mdl.Item.compareRev(data, item) < 0)
			throw new Error(`Conflict updating item ${item.id
				}-${item.revFull} with rev ${mdl.Item.toFullRev(data)}!`)
	}
}

/** Get the boxes an item is in. */
function getBoxes(item: mdl.Item, itemData: mdl.ItemData,
	items: mdl.ItemManager) {
	if (itemData?.boxes) {
		for (const boxId of itemData.boxes)
			item.addToBox(items.getItem(boxId))
	}
	return item
}

export function readItem(item: mdl.Item, data: mdl.ItemData,
	items: mdl.ItemManager) {
	item.id = data.id
	item.rev = data.rev ? data.rev : 0
	item.conflicts = data.conflicts ? data.conflicts : null
	item.layoutId = data.layoutId
	if (data._info)
		item._info = { ...item._info, ...data._info }
	if (data.props) {
		for (const name of item.props.keys())
			if (!(name in data.props))
				item.props.delete(name)
		for (const name of Object.keys(data.props)) {
			const d = data.props[name]
			if (d === void 0)
				continue
			const prop = item.props.get(name)
			if (!prop || !propertyEqualsData(prop, d)) {
				const p = O.new(mdl.Property, item, name)
				propertySetValue(p, d)
				item.props.set(p)
			}
		}
	}
	// TODO: fix impl for create, replace update with history
	getLog(item.create, data.create)
	getLog(item.update, data.update)
	if (data.isDeleted)
		item.isDeleted = data.isDeleted
	getLinks(item, data, 'content', items)
	if (data.containerId)
		item.container = items.getItem(data.containerId)
	getLinks(item, data, 'links', items)
	getLinks(item, data, 'tmpls', items)
	return item
}

function getLog(log: mdl.Log, data: mdl.LogData) {
	if (!data)
		return
	if (data.date)
		log.date = new Date(data.date)
	if (data.position)
		log.position = 'coords' in data.position ?
			mdl.coordsToPosition(data.position.coords) : data.position
	U.obj.copyMembers(data, log as any, 'userId', 'installationId')
}

/** Get the links and their items of the given item. For the related items 
 * links and details might not yet be available.
 */
function getLinks(item: mdl.Item, itemData: mdl.ItemData,
	collection: 'links' | 'content' | 'tmpls' | 'parents',
	items: mdl.ItemManager) {
	if (itemData?.[collection]) {
		const links = itemData[collection].filter(mdl.ItemLinkData.url)
		const linkUrls = links.map(mdl.ItemLinkData.url)
		// collect external URLs
		//	collectItemUrls(linkUrls, boxes.itemUrls)
		// add/insert/remove item links
		item[collection].update(linkUrls)
		// update/set link data
		for (let i = 0; i < links.length; ++i) {
			const d = links[i]
			const ln = item[collection].get(i)
			if (typeof d === 'object') {
				// link data object
				if (d.preview) ln.preview = d.preview as mdl.PreviewLevel
				ln.name = d.name
			}
			const id = ln.refId
			//				if (boxes.isItemKnown(id))
			ln.item = items.getItem(id)
		}
	}
	return item
}

/** Item is newer (1), older (-1) or the same (0) than the data. */
export function compareItem(item: mdl.Item, data: mdl.ItemData) {
	const itemDate = item.lastModified || new Date(0)
	const dataDate = new Date(data.update && data.update.date ? data.update.date
		: data.create && data.create.date ? data.create.date : 0)
	return itemDate > dataDate ? 1 : itemDate < dataDate ? -1 : 0
}
