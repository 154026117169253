import { O, when } from '../../common'
import * as mdl from '../../model'

export const setup = {

	requestArchive: ({ inst }: { inst: mdl.Installation }) => {
		O.onInit(mdl.ViewItemView, async view => {
			await when(() => !!view.item)
			if (view.item === inst.item || view.item.id === 'installation.sys')
				inst.log.requestArchive()
		})
	},

}

