import * as React from 'react'
import { Component, InputProps, observer, ValueProps } from './common'

export type TextInputType = 'text' | 'color' | 'date' |
	'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'range' |
	'tel' | 'time' | 'url' | 'week'

// TODO: split per type
// eslint-disable-next-line max-len
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
interface TextInputProps extends InputProps {
	type?: TextInputType
	alt?: string
	autoComplete?: string
	disabled?: boolean
	max?: number | string
	maxLength?: number
	min?: number | string
	minLength?: number
	name?: string
	pattern?: string
	placeholder?: string
	readOnly?: boolean
	required?: boolean
	size?: number
	step?: number | string
}

const valueFormatter = {
	date: (v: string) => v && v.substring(0, 10),
	time: (v: string) => v && v.substring(11, 19),
}

@observer
export class TextInput<T> extends Component<TextInputProps & ValueProps<T>> {

	private static idGen = 1
	id = 't' + (TextInput.idGen++)

	render() {
		const { type, label, title, children, ...rest } = this.props
		void ['value', 'key', 'target', 'member'].forEach(k => { delete rest[k] })
		const props: Omit<typeof rest, 'value' | 'key' | 'target' | 'member'> = rest
		const value = 'value' in this.props ? this.props.value : null
		const val = 'value' in this.props ? value.value :
			this.props.target[this.props.member]
		const v = type in valueFormatter ? valueFormatter[type](val) : val
		return (
			<div className={'field' + (value?.hasErrors ? ' error' : '')}
				title={title}>
				{label && <label htmlFor={this.id}>{label}</label>}
				<input id={this.id} type={type || 'text'} value={v}
					onChange={this.onChange} {...props} />
				{value?.hasErrors && <div>{value.errorMessages.join(', ')}</div>}
				{children}
			</div>
		)
	}

	onChange = (evn: React.ChangeEvent<HTMLInputElement>) => {
		if ('value' in this.props) this.props.value.value = evn.target.value
		else this.props.target[this.props.member] = evn.target.value as any
	}
}
