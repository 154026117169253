import { action, IGNORE_ERROR, O, setComputed } from '../../common'
import * as mdl from '../../model'

export const setup = {

	queries: ({ boxes, items }:
		{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
		O.onInit(mdl.PlaceItemView, async (view, ids: string) => {
			const itemsToPlace = ids.includes(',') ?
				ids.split(',').map(id => items.requestItem(id)) : null
			action(() => {
				setComputed(view, 'allBoxes', () => boxes.allBoxes)
				view.items = itemsToPlace
			})()
			if (view.items)
				await Promise.all(view.items.map(r => r.request().catch(IGNORE_ERROR)))
		})
	},

}
