import * as React from 'react'
import { getCurrentPosition } from '../../env/views/EditItemView'
import * as mdl from '../../model'
import { observer } from './base'
import { Component } from './Component'
import { Icon } from './icons'
import { LocationMapEditor } from './input/LocationMapEditor'
import { TextInput } from './input/Text'

interface Props {
	values: mdl.LocationValue.FormValues
	config: mdl.Config
	autoFocus?: boolean
}

export class LocationEditor extends Component<Props> {

	render() {
		const { values, config, autoFocus } = this.props
		return <>
			<div className="field-group" onKeyDown={this.onAddressKey}>
				<TextInput label="Address" value={values.address}
					autoFocus={autoFocus} />
				<SearchButton vals={values} config={config} />
				<CurrentLocationButton vals={values} />
			</div>
			<LocationMapEditor className="map"
				latitude={values.latitude} longitude={values.longitude} />
			<TextInput label="Latitude" value={values.latitude} />
			<TextInput label="Longitude" value={values.longitude} />
			<TextInput label="Altitude" value={values.altitude} />
		</>
	}

	onAddressKey = (evn: React.KeyboardEvent) => {
		if (evn.key === 'Enter')
			this.props.values.address.submit()
	}
}

interface ButtonProps {
	vals: mdl.LocationValue.FormValues
}

@observer
class SearchButton extends Component<ButtonProps & { config: mdl.Config }> {

	render() {
		const { vals: { address } } = this.props
		return <button onClick={this.onClick} disabled={!address.isDirty}>
			<Icon uri="query" /></button>
	}

	onClick = () => { this.props.vals.address.submit() }

}

class CurrentLocationButton extends Component<ButtonProps> {

	render() {
		return <button onClick={this.onClick}><Icon uri="my_location" /></button>
	}

	onClick = () => { getCurrentPosition(this.props.vals) }

}
