
import * as mdl from '../../../model'
import { NextCloudDataAccess } from './NextCloudDataAccess'

export interface Context {
	items: mdl.ItemManager
	boxes: mdl.BoxManager
	log: mdl.Logger
	config: mdl.Config
}

export const setup = {

	access: (ctx: Context) => {

		mdl.BoxStorage.protocols['nextcloud'] = function(props): mdl.BoxStorageAccess {
			if (!props.url || !props.credentials)
				return null
			const da = new NextCloudDataAccess(props, ctx.log, ctx.config)
			return da
		}
	},

}

