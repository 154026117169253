export * from 'mobx'
export * from './common'
export * from './mobx'
export * from './ObjectStatus'
export * from './Service'
export * from './Signal'

import { IObservableValue, observable } from 'mobx'

export const ref = observable.shallowBox
export type Ref<T> = IObservableValue<T>
