
import { action, O, reaction } from '../common'
import * as mdl from '../model'
import * as stringIndex from './stringIndex'

const LOCALSTORAGE_KEY_RECENT_ITEMS = 'recentItems'

export const setup = {

	store: ({ nav, app }: { nav: mdl.Navigation, app: mdl.AppBase }) => {
		app.init.react(() => {
			const data = localStorage.getItem(LOCALSTORAGE_KEY_RECENT_ITEMS)
			if (data) {
				const ids = data.split(stringIndex.ENTRY_SEPARATOR)
				action(() => { nav.recentIds = ids })()
			}
		})
		reaction(() => {
			return nav.recentIds.join(stringIndex.ENTRY_SEPARATOR)
		}, data => {
			localStorage.setItem(LOCALSTORAGE_KEY_RECENT_ITEMS, data)
		})
	},

	delete: ({ nav }: { nav: mdl.Navigation }) => {
		// remove deleted item from recent list
		O.onInit(mdl.Item, item => {
			item.delete.react(() => {
				nav.removeRecentId(item.id)
			})
		})
	},

	missing: ({ nav }: { nav: mdl.Navigation }) => {
		// remove missing items from recent list
		O.onInit(mdl.Item, item => {
			reaction(() => item.isMissing, isMissing => {
				if (isMissing)
					nav.removeRecentId(item.id)
			})
		})
	},

	reIndex: ({ nav, items, inst }:
		{ nav: mdl.Navigation, items: mdl.ItemManager, inst: mdl.Installation }
	) => {
		inst.reIndex.react(async () => {
			const removes: string[] = []
			await Promise.all(nav.recentIds.map(id =>
				items.getItem(id).request().catch(err => { removes.push(id) })))
			action(() => {
				for (const id of removes) {
					const i = nav.recentIds.indexOf(id)
					if (i >= 0) nav.recentIds.splice(i, 1)
				}
			})()
		})
	},

}
