import * as React from 'react'
import * as mdl from '../../model'
import { Component, Icon, observer } from '../common'
import { ItemIcon, ItemLabel } from '../LayoutViews'
import { PropLabel } from '../PropertyViews'
import { Layout } from './common'
import { getItemWithIcon, getLinkContentProps } from './List'

export const url: Layout = {
	links: {
		small: (item, context) => <>
			<div><ItemLabel item={item} context={context} /></div>
			<NavExtern item={item} />
		</>,
		compact: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
			</div>
			<NavExtern item={item} />
		</>,
		normal: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{getLinkContentProps(item).slice(0, 1).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
			<NavExtern item={item} />
		</>,
		extended: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{getLinkContentProps(item).slice(0, 3).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
			<NavExtern item={item} />
		</>,
		full: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{getLinkContentProps(item).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
			<NavExtern item={item} />
		</>,
	},
}

@observer
class NavExtern extends Component<{ item: mdl.Item }> {

	render() {
		return <Icon uri="external" className="external" onClick={this.navigate} />
	}

	navigate = (evn: React.MouseEvent) => {
		const urlProp = this.props.item.props.findByType('url')
		if (urlProp) {
			window.open(urlProp.value)
			evn.stopPropagation()
			evn.preventDefault()
		}
	}
}
