import * as React from 'react'
import { ItemIcon, ItemLabel } from '../LayoutViews'
import { PropContent, PropLabel } from '../PropertyViews'
import { Layout } from './common'
import { getItemWithIcon, getLinkContentProps } from './List'

export const image: Layout = {
	links: {
		small: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div><ItemLabel item={item} context={context} /></div>
		</>,
		compact: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
			</div>
		</>,
		normal: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{getLinkContentProps(item).slice(0, 1).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
		extended: (item, context) => <>
			<ItemIcon item={getItemWithIcon(item)} context={context} />
			<div>
				<h6><ItemLabel item={item} context={context} /></h6>
				{getLinkContentProps(item).slice(0, 3).map(p =>
					<PropLabel key={p.name} prop={p} context={context} />)}
			</div>
		</>,
		full: (item, context) => {
			const img = item.props.findByType('image')
			return img ? <PropContent prop={img} context={context} /> : <>
				<ItemIcon item={getItemWithIcon(item)} context={context} />
				<div>
					<h6><ItemLabel item={item} context={context} /></h6>
					{getLinkContentProps(item).map(p =>
						<PropLabel key={p.name} prop={p} context={context} />)}
				</div>
			</>
		},
	},
}
