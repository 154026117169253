import { action, comparer, O, reaction } from '../common'
import * as mdl from '../model'

export const setup = {

	complete: {

		logDetails: ({ items }: { items: mdl.ItemManager }) => {
			O.onInit(mdl.Item, item => {
				item.request2.react(() => {
					requestLogDetails(item.create, items)
					requestLogDetails(item.update, items)
				})
			})
		}

	},

	deleteLinks: ({ boxes, items }:
		{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
		// delete from links with the item itself
		O.onInit(mdl.Item, item => {
			item.delete.react(async () => {
				if (item.id) {
					if (item.container) {
						const container = item.container
						container.complete()
							.then(() => { container.content.remove(item.id) })
					}
					for (const box of boxes.activeBoxes) {
						box.readFromLinks(item.id)
							.then(ids => {
								if (ids) {
									for (const id of ids) {
										const itm = items.requestItem(id)
										itm.complete()
											.then(() => { itm.links.remove(item.id) })
									}
								}
							})
					}
					// TODO: also remove from pending fromLinks (see #42)
				}
			})
		})
	},

	refresh: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.Item, item => {
			item.refresh.react(() => boxes.loadItem(item, true))
		})
	},

	itemContainer: ({ log }: { log: mdl.SystemLogger }) => {
		O.onInit(mdl.Item, item => {
			let oldItems: mdl.Item[] = []
			reaction(() => item.content.map(mdl.Link.toItem), action((items) => {
				for (const itm of items)
					if (itm.container && itm.container !== item)
						log.error(`Not allowed to change container for item ${itm.id
							} from ${itm.container.id} to ${item.id}!`)
				for (const itm of oldItems)
					itm.container = null
				oldItems = items
				for (const itm of items)
					itm.container = item
			}), { equals: comparer.shallow })
		})
	},

}


function requestLogDetails(log: mdl.Log, items: mdl.ItemManager) {
	if (log.userId && !log.user)
		log.user = items.requestItem(log.userId)
	if (log.installationId && !log.installation)
		log.installation =
			items.requestItem(log.installationId)
}

