import { signal } from '../../common'
import { Box } from '../Box'
import { Item } from '../Item'
import { Link } from '../Link'
import { NavData } from '../Navigation'
import { action, computed, observable, U } from './common'
import { ItemView } from './ItemView'

export type Params = { [k: string]: string }

export class ViewItemView extends ItemView {
	readonly key = 'view'
	// mode
	@observable mode: 'view' | 'move' | 'place' = 'view'
	// parameters for layout,...
	@observable params: Params = null
	@action configure(loc: NavData) {
		this.mode = loc.key === 'move' ? 'move'
			: loc.params && 'place' in loc.params ? 'place' : 'view'
		this.params = loc.params
	}
	// tags
	@observable showTags = true
	@observable showEmptyTags = false
	@computed get isTagsVisible() {
		return this.showTags && this.item.isReady &&
			(this.tags.length > 0 || this.showEmptyTags)
	}
	toggleTags = () => {
		if (this.item.isReady && this.tags.length === 0 && !this.showEmptyTags) {
			this.showEmptyTags = true
			this.showTags = true
		} else {
			this.showTags = !this.showTags
		}
	}
	@computed get tags() {
		return this.allFromLinks?.filter(itm => itm.tmpls.has('tag.tmpl')) ?? []
	}
	@observable allTags: Item[] = []
	// boxMarks
	@observable showBoxMarks = false
	// selection
	@observable selected: Link[] = []
	@computed get hasSelected() { return this.selected.length > 0 }
	@computed get allSelected() {
		return this.selected.length === this.item.links.available.length +
			this.item.content.available.length
	}
	/** Is item link currently selected? */
	isSelected(link: Link) { return this.selected.indexOf(link) >= 0 }
	/** Select/unselect item link. */
	@action select(link: Link) {
		const idx = this.selected.indexOf(link)
		if (idx < 0) this.selected.push(link)
		else this.selected.splice(idx, 1)
	}
	@action selectAll() {
		this.selected = this.hasSelected && !this.allSelected ?
			this.item.allAvailableLinks : []
	}

	selectedAsContent = signal()
	selectedAsRelated = signal()

	@observable allFromLinks: Item[]
	@computed get fromLinks() {
		const ids = this.item ?
			U.array.toObject(this.item.allLinks, l => l.refId) : {}
		const items = this.allFromLinks && this.allFromLinks
			.filter(r => !(r.id in ids))
		return items && items.length > 0 ? items : null
	}

	@action addFromLink(item: Item) {
		if (this.allFromLinks?.find(ln => ln.id === item.id))
			return
		if (this.allFromLinks) this.allFromLinks.push(item)
		else this.allFromLinks = [item]
	}

	@action addFromLinks(items: Item[]) {
		for (const item of items)
			this.addFromLink(item)
	}

	@action removeFromLink(item: Item) {
		const idx = this.allFromLinks?.findIndex(ln => ln.id === item.id) ?? -1
		if (idx >= 0)
			this.allFromLinks.splice(idx, 1)
	}

	moveLink = signal<(startIdx: number, endIdx: number) => void>()

	// place mode
	@observable box: Item
	togglePlace = signal<(item: Item, inclLinks?: boolean) => void>()
	isPlaced(item: Item, inclLinks = false) {
		const box = Box.getBox(this.box)
		return item.boxes.includes(box) && (!inclLinks ||
			!item.allAvailableLinks.find(ln => !ln.item.boxes.includes(box)))
	}

}
