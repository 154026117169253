import * as React from 'react'

export function enumerate(arr: React.ReactNode[], separator: React.ReactNode) {
	return <>
		{arr.map((v, i) => <React.Fragment key={i}>
			{i > 0 && separator}
			{v}
		</React.Fragment>)}
	</>
}
