import { action, autorun, O, reaction, when } from '../common'
import * as mdl from '../model'
import * as indexes from './indexes'
import { Index } from './indexes/common'

interface Indexes {
	[k: string]: Index
}

export const setup = {

	index: {
		build: () => {
			O.onInit(mdl.Item, item => {
				item.request3.reactOnce(() => {
					autorun(() => {
						for (let k of Object.keys(indexes as any as Indexes))
							indexes[k].build(item)
					})
				})
			})
		},

		delete: () => {
			O.onInit(mdl.Item, item => {
				item.delete.react(() => {
					for (let k of Object.keys(indexes as any as Indexes))
						indexes[k].remove(item)
				})
			})
		},

	},

	search: () => {
		O.onInit(mdl.Query, query => {
			// run a query on search terms changed
			reaction(() => query.terms, () => {
				if (query.item.isReady)
					query.run()
			})
		})
	},

	show: () => {
		// run a query when a query item is shown
		O.onInit(mdl.ViewItemView, view => {
			when(() => view.isReady, () => {
				const query: mdl.Property<mdl.Query> =
					view.item.props.findByType('query')
				if (query) query.value.run()
			})
		})
	},

	run: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.Query, query => {
			query.run.react(async () => {
				const resultIds = new Set<string>()
				await Promise.all(Object.keys(indexes).map(
					k => indexes[k].run(query.terms, resultIds, items)))
				action(() => {
					query.results = [...resultIds].map(id => items.requestItem(id))
				})()
			})
		})
	},

}
