import { Observer, observer } from 'mobx-react'
import * as React from 'react'
import * as mdl from '../model'
import { Component, Context, css, Icon } from './common'
import { ItemEditor } from './LayoutViews'
import { NavItemLink } from './NavItemLink'

interface Props {
	view: mdl.CreateItemView
	context: Context
}

@observer
export class CreateItem extends Component<Props> {

	render() {
		const { context, view, view: { item, form } } = this.props
		return <div className="view">
			<header>
				<button onClick={this.onOk} disabled={form.hasErrors}>
					<Icon uri="navOk" /></button>
				<div><span>Create Allsbe Item</span></div>
			</header>
			<section>
				<div className="content">
					<ItemEditor item={item} form={form} context={context} /></div>
				<h5>Link from...</h5>
				<Observer>{() =>
					<div className={css('items', 'linkTo', { disabled: form.hasErrors })}>
						{view.linkCandidates.map(itm =>
							<LinkFrom key={itm.id} from={itm} newItem={item}
								context={context} />)}
						{view.linkCandidates.length <= 0 && '-'}
					</div>
				}</Observer>
			</section>
			<footer>
				<div className="buttons">
					<Observer>{() =>
						<button onClick={this.onOk} disabled={form.hasErrors}>
							<Icon uri="ok" /></button>
					}</Observer>
					<button onClick={this.onCancel}><Icon uri="cancel" /></button>
				</div>
			</footer>
		</div>
	}

	onOk = (evn: React.MouseEvent<HTMLButtonElement>) => {
		this.submit()
	}

	onCancel = () => { this.props.context.nav.go('view', 'home') }

	onKey = (evn: React.KeyboardEvent<HTMLDivElement>) => {
		if (evn.ctrlKey && evn.key === 'Enter')
			this.submit()
		else if (evn.key === 'Escape')
			this.props.context.nav.back()
	}

	async submit() {
		const { view: { form, item }, context: { nav } } = this.props
		if (form.hasErrors) return
		form.submit()
		await item.initNew()
		await item.complete()
		nav.go('place', item.id)
	}
}


interface LinkFromProps {
	from: mdl.Item
	newItem: mdl.Item
	context: Context
}

@observer
class LinkFrom extends Component<LinkFromProps> {

	render() {
		const { from, context } = this.props
		return <NavItemLink item={from} context={context} onNav={this.onLink}
			title={`Link from item ${from.id}...`} />
	}

	onLink = async () => {
		const { newItem, from, context: { nav } } = this.props
		await from.complete()
		from.links.add(newItem, 0, 'all')
		nav.go('view', from.id)
	}
}

