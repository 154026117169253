import { action, computed, observable } from './common'
import { PositionData } from './data'
import { Item } from './Item'

// Who, when, where.

export class Log {
	private _userId: string
	@computed get userId() { return this._userId || this.user?.id }
	set userId(v: string) { this._userId = v }
	@observable user: Item
	@observable date: Date
	private _installationId: string
	@computed get installationId() {
		return this._installationId || this.installation?.id
	}
	set installationId(v: string) { this._installationId = v }
	@observable installation: Item
	@observable.ref position: PositionData
	@computed get isValid() {
		return !!this.date
	}
}

export class StatusReports {
	@observable entries: string[] = []
	@action add(rec: string) {
		this.entries.push(new Date(Date.now()).toISOString() + ': ' + rec)
	}
	@action clear = () => { this.entries.length = 0 }
}
