import * as mdl from "../../model"
import { U } from "../../model"

export async function replaceBlobsWithRefs(data: mdl.ItemData) {
	const doc = { ...data, props: { ...data.props } }
	const blobs: { [id: string]: Blob } = {}
	for (const name of Object.keys(doc.props)) {
		const v = doc.props[name]
		if (!v || typeof v !== 'object')
			continue
		const key = Object.keys(v)
			.find(k => mdl.HashedBlob.isInstance(v[k]) || U.blob.isBlob(v[k]))
		if (key) {
			const b = v[key]
			const hash = U.blob.isBlob(b) ? (await mdl.HashedBlob.create(b)).hash :
				b.hash
			blobs[hash] = U.blob.isBlob(b) ? b : b.blob
			doc.props[name] = { ...v, [key]: 'blob:' + hash }
		}
	}
	return { doc, blobs }
}

export async function replaceRefsWithBlobs(d: mdl.ItemData,
	blobProvider: { readBlob(hash: string): Promise<mdl.HashedBlob> }) {
	for (const v of Object.values(d.props).filter(U.any.isTrue)) {
		const key = Object.keys(v)
			.find(k => typeof v[k] === 'string' && v[k].startsWith('blob:'))
		if (key) {
			const hash = v[key].substring(5)
			v[key] = await blobProvider.readBlob(hash)
		}
	}
	return d
}


