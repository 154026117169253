import { Item } from '../Item'
import { computed, observable, ViewBase } from './common'

export class ItemView extends ViewBase {
	key = 'item'
	@observable item: Item
	@computed get isReady() { return !!this.item?.isFinished }
	@computed get label() {
		return this.item ? this.item.labelText : this.key
	}
}

