import { U } from '../../common'
import * as mdl from '../../model'

export interface ItemData0 {
	id: string
	rev?: number
	type: string
	links?: mdl.ItemLinkData[]
	// possible storage conflicts
	conflicts?: any[]
	// storage info
	_info?: any
	[k: string]: any
}

const transformByType:
	{ [type: string]: (d: ItemData0, itm: mdl.ItemData) => void } = {
	image: (d, { props }) => {
		props.image = U.obj.copyMembers(d as any, {}, 'width', 'height', 'info')
		if ('url' in d) props.image.image = d.url
		props.caption = d.caption ?? { string: null }
	},
	box: (d, { props }) => {
		props.box = { box: d.url, protocol: d.protocol, format: d.format }
		U.obj.copyMembers(d, props, 'indexId', 'mark')
		if (d.color) props.color = { color: d.color }
		if (d.backColor) props.backColor = { color: d.backColor }
	},
	query: (d, { props }) => {
		props.query = { query: d.select }
	},
	installation: (d, { props }) => {
		props.installation = { installation: d.id }
		props.idGen = d.idGen
		if (d.info) props.info = { object: d.info }
	},
	location: (d, { props }) => {
		props.location = U.obj.copyMembers<mdl.LocationValue>(d as any, {},
			'address', 'latitude', 'longitude', 'accuracy',
			'altitude', 'altitudeAccuracy', 'heading', 'speed')
	},
	date: (d, { props }) => {
		if (d.format === 'time')
			props.time = { time: d.date.substring(10) }
		else if (d.format === 'dateTime')
			props.date = { dateTime: d.date }
		else if (d.date)
			props.date = { date: d.date.substring(0, 10) }
	},
	calendar: (d, itm) => {
		itm.layoutId = 'calendar.layout'
		itm.props.startDate = { date: d.startDate }
		itm.props.selectDate = { action: 'selectDate' }
	},
	todo: (d, itm) => {
		itm.layoutId = 'todo.layout'
		itm.props.completed = d.completed ?? { boolean: null }
		itm.props.toggle = { action: 'toggle' }
	},
	text: (d, itm) => {
		itm.layoutId = 'text.layout'
	},
	comment: (d, itm) => {
		itm.layoutId = 'comment.layout'
	},
	number: (d, itm) => {
		itm.props.number = d.unit ? { number: d.value, unit: d.unit } : d.value
	},
	url: (d, itm) => {
		itm.props.url = { url: d.url }
	},
}

export function migrate_v0(data: ItemData0, itemData: mdl.ItemData) {
	const type = data.type
	itemData.tmpls = [type + '.tmpl']
	U.obj.copyMembers(data, itemData, 'links', 'create', 'update', 'conflicts',
		'_info')
	// simple props
	U.obj.copyMembers(data, itemData.props, 'title', 'name')
	// special props
	if (type in transformByType)
		transformByType[type](data, itemData)
	// typed props
	for (const n of ['icon', 'text'])
		if (n in data)
			itemData.props[n] = { [n]: data[n] }
	// text props
	for (const n of ['comment', 'description'])
		if (n in data)
			itemData.props[n] = { text: data[n] }
	return itemData
}

