import * as React from 'react'
import * as mdl from '../model'
import {
	action, Component, Context, Icon, observer, Observer, Section, TextInput
} from './common'
import { NavItemLink } from './NavItemLink'

interface Props {
	view: mdl.SearchView
	context: Context
}

@observer
export class Search extends Component<Props> {

	render() {
		const { context, context: { nav }, view: { search, shortcutItems },
		} = this.props
		return <div className="view">
			<header>
				<button onClick={nav.back}><Icon uri="navBack" /></button>
				<div><span><i>Search</i></span></div>
			</header>
			<section>
				<div className="items shortcuts">
					{shortcutItems.map(item => item.isReady
						? <NavItemLink key={item.id} context={context} item={item}
							iconOnly={true} />
						: <div key={item.id} className="loading">?</div>)}
				</div>
				<div className="content">
					<div className="field">
						<TextInput target={search} member="text"
							placeholder="Search text..."
							autoFocus autoComplete="off" />
					</div>
				</div>
				<SearchResults search={search} context={context} />
			</section>
			<footer />
		</div>
	}

	@action onEntry = (evn: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.props.view.search.text = evn.target.value
	}

	onDocumentKey = (evn: React.KeyboardEvent<HTMLBodyElement>) => {
		if (evn.ctrlKey || evn.altKey)
			return
		const { context: { nav } } = this.props
		switch (evn.key) {
			case 'Escape': nav.back(); break
		}
		// only outside of text inputs
		if (evn.target === document.body)
			switch (evn.key) {
				case 'h': nav.go('view', 'home'); break
			}
	}
}

export const SearchResults = observer(({ search, context, onNav }:
	{ search: mdl.Search, context: Context, onNav?: (item: mdl.Item) => void }) =>
	<div className="items results">
		{search.resultSections.map(s => <Section label={`${s.label} (${s.results.length})`} key={s.label}
			openByDefault={s === search.firstAvailableResultSection}
			if={s.results.length > 0}>
			<Observer>{() => <>
				{s.results.map(item => item.isReady ?
					<NavItemLink key={item.id} context={context} item={item}
						showBoxMarks={true} onNav={onNav} title={`Item ${item.id}`} /> :
					<div key={item.id} className="loading"
						title={`Item ${item.id}`}>loading...</div>)}
			</>}</Observer>
		</Section>
		)}
		{search.resultSections.length <= 0 && '-'}
	</div>
)

