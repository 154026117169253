import { O } from '../../common'
import * as mdl from '../../model'
import { transform } from './transform'
import { updateBoxInStorages } from './updateStorages'

export const setup = {

	updateStorages: ({ log }: { log: mdl.SystemLogger }) => {
		O.onInit(mdl.Box, box => {
			box.updateStorages.react(async () => {
				try {
					const stats = await updateBoxInStorages(box.id, box.availableStorages,
						box)
					box.reports.add(`Storages updated (${box.availableStorages
						.map((s, i) => stats[i] + ' in ' + s.url).join(', ')})`)
				}
				catch (err) {
					if (Array.isArray(err))
						err.forEach(err => { log.error(err) })
					else
						log.error(err)
				}
			})
		})
	},

	transform: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.Box, box => {
			box.transform.react(async () => {
				const stats = await transform(box.id, box.availableStorages[0],
					items, box)
				box.reports.add(`${stats.count} of ${stats.total} items transformed`)
			})
		})
	},
}
