import * as mdl from '../model'

export const setup = {

	system: ({ boxes, items }:
		{ boxes: mdl.BoxManager, items: mdl.ItemManager }) => {
		// TODO: remove setup-order dependency! 
		// generatedItemsFactory.register() or something similar
		const orig = boxes.generatedItemsFactory['system.sys']
		boxes.generatedItemsFactory['system.sys'] =
			async (item: mdl.Item) => {
				if (orig)
					orig(item)
				item.links.add(items.getItem('bookmarklets.url'))
				return item
			}
	},

	bookmarklets: ({ boxes }: { boxes: mdl.BoxManager }) => {
		boxes.generatedItemsFactory['bookmarklets.url'] =
			async (item: mdl.Item) => {
				item.props.set('title', 'Bookmarklets for your Browser')
				item.props.set('url', 'bookmarklets.html', 'url')
				item.props.set('icon', 'bookmarks', 'icon')
				item.layoutId = 'url.layout'
				item.isGenerated = true
				return item
			}
	},

}
