import * as mdl from '../model'

declare const __DEBUG__: boolean

export const setup = {
	install: ({ app }: { app: mdl.App }) => {
		if ('serviceWorker' in navigator && !__DEBUG__) {
			window.addEventListener('load', async () => {
				const workers = new ServiceWorkers(app)
				try {
					const reg = await navigator.serviceWorker
						.register('/service-worker.js')
					workers.add(reg.installing)
					workers.add(reg.waiting)
					workers.add(reg.active)
					app.skipWaiting.react(() => { workers.skipWating() })
					app.update.react(() => { reg.update() })
					reg.addEventListener('updatefound',
						() => { workers.add(reg.installing) })
				}
				catch (err) {
					app.installation.log.error('Service Worker failed to register!',
						err)
				}
			})
		}
	}
}

class ServiceWorkers {

	workers: ServiceWorker[] = []

	constructor(public app: mdl.App) { }

	setState() {
		this.app.state = this.workers.map(sw => sw.state).join(', ')
	}

	add(sw: ServiceWorker) {
		if (!sw)
			return
		this.workers.push(sw)
		this.setState()
		sw.addEventListener('statechange', () => {
			this.setState()
			if (sw.state === 'redundant')
				// auto cleanup
				setTimeout(() => { this.remove(sw) }, 5000)
			if (sw.state === 'activated')
				// reload after a new version is activated
				this.app.reload()
		})
	}

	remove(sw: ServiceWorker) {
		if (!sw)
			return
		const idx = this.workers.indexOf(sw)
		if (idx >= 0) {
			this.workers.splice(idx, 1)
			this.setState()
		}
	}

	skipWating() {
		for (const sw of this.workers) {
			if (sw.state === 'installed')
				sw.postMessage({ type: 'SKIP_WAITING' })
		}
	}

}
