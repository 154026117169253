import { observer } from 'mobx-react'
import * as React from 'react'
import * as mdl from '../../model'
import { format } from '../../navigation/BrowserUrl'

interface Props extends React.PropsWithChildren {
	itemId: string
	viewKey?: mdl.ViewKey
	params?: { [k: string]: string }
	nav: mdl.Navigation
}

@observer
export class Link extends React.Component<Props> {

	render() {
		const { itemId, viewKey, params, children } = this.props
		return <a href={format({ id: itemId, key: viewKey || 'view', params })}
			onClick={this.onClick}>
			{children || itemId}</a>
	}

	onClick = (evn: React.MouseEvent<HTMLElement>) => {
		if (evn.button == 0 && !evn.ctrlKey && !evn.metaKey) {
			evn.preventDefault()
			const { nav, itemId, viewKey, params } = this.props
			nav.go({ key: viewKey || 'view', id: itemId, params })
		}
	}
}

@observer
export class Button extends React.Component<Props> {

	render() {
		const { itemId, viewKey, params, children } = this.props
		return <button onClick={this.onClick}>
			{children || itemId}</button>
	}

	onClick = (evn: React.MouseEvent<HTMLElement>) => {
		const { nav, itemId, viewKey, params } = this.props
		nav.go({ key: viewKey || 'view', id: itemId, params })
	}
}
