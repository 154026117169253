import { isArray, O, observable, setComputed, U } from '../common'
import { action, computed } from './common'
import { Item, ItemStatus, LinkDepthSpec } from './Item'
import { Link } from './Link'

export type LinkSource = Link | Item

export class Links {

	@observable.shallow private list: Link[] = []

	constructor(public item: Item) { }

	get(idx: number) { return this.list[idx] }

	*[Symbol.iterator]() {
		yield* this.list
	}

	slice(start: number, end: number) {
		return this.list.slice(start, end)
	}

	map<T = any>(fn: (ln: Link, idx: number, list: Link[]) => T, thisArg?: any) {
		return this.list.map(fn, thisArg)
	}

	forEach(fn: (ln: Link, idx: number, list: Link[]) => void, thisArg?: any) {
		return this.list.forEach(fn, thisArg)
	}

	filter(fn: (ln: Link, idx: number, list: Link[]) => boolean, thisArg?: any) {
		return this.list.filter(fn, thisArg)
	}

	find(fn: (ln: Link, idx: number, list: Link[]) => boolean, thisArg?: any) {
		return this.list.find(fn, thisArg)
	}

	findByProp(name: string, value: any | ((val: any) => boolean)) {
		const fn = typeof value === 'function' ? value : (v: any) => v === value
		return this.list.find(ln => fn(ln.item?.props.get(name)?.value))
	}

	has(ln: Link | Item | string) {
		const id = typeof ln === 'string' ? ln :
			ln instanceof Item ? ln.id : ln.refId
		return !!this.list.find(ln => ln.refId === id)
	}

	indexOf(ln: Link | Item | string) {
		const id = typeof ln === 'string' ? ln :
			ln instanceof Item ? ln.id : ln.refId
		return this.list.findIndex(ln => ln.refId === id)
	}

	@computed get length() { return this.list.length }
	@computed get isEmpty() { return this.list.length <= 0 }

	@computed.struct get available() {
		return this.list?.filter(ln => ln.item.status !== ItemStatus.missing) ?? []
	}

	@action add(links: LinkSource | LinkSource[], index = -1,
		addBoxes: LinkDepthSpec = false) {
		if (this.item.isReadOnly)
			return null
		if (isArray(links)) {
			for (const link of links) this.add(link, index, addBoxes)
		} else {
			const item = links instanceof Link ? links.item : links
			if (!item)
				return null
			const url = item.id
			if (this.list) {
				const ln = this.list.find(l => l.url === url)
				if (ln) return ln
			}
			const link = O.new(Link, url, item)
			if (links)
				link.preview = this.isEmpty ? 'normal' :
					U.obj.valueWithMaxCount(this.list.map(ln => ln.preview))
			// TODO: move somehow to image layout instead
			if (item.props.findByType('image'))
				link.preview = 'full'
			if (this.list && index < 0) this.list.push(link)
			else if (this.list) this.list.splice(index, 0, link)
			else this.list = [link]
			if (addBoxes !== false && addBoxes !== 'none')
				link.item.addToBoxesOf(this.item, addBoxes)
			return link
		}
	}

	@action remove(links: Link | Item | string | (Link | Item | string)[]) {
		if (this.item.isReadOnly)
			return false
		if (isArray(links)) {
			let someSuccess = false
			for (const link of links)
				if (this.remove(link))
					someSuccess = true
			return someSuccess
		} else {
			if (this.list) {
				const idx = this.indexOf(links)
				if (idx >= 0) {
					this.list.splice(idx, 1)
					return true
				}
			}
			return false
		}
	}

	@action move(fromIdx: number, toIdx: number) {
		if (this.item.isReadOnly)
			return null
		const f = this.list.indexOf(this.available[fromIdx])
		const t = this.list.indexOf(this.available[toIdx])
		if (f < 0 || t < 0)
			throw new Error(`Invalid indexes (${fromIdx} -> ${toIdx
				}) to move links in item ${this.item.id}!`)
		this.list.splice(t, 0, this.list.splice(f, 1)[0])
	}

	@action sort(compareFn: (a: Link, b: Link) => number =
		(a, b) => a.item.labelText.localeCompare(b.item.labelText)) {
		if (this.item.isReadOnly)
			return null
		this.list = this.list.sort(compareFn)
	}

	@action update(linkUrls: string[]) {
		if (this.item.isReadOnly)
			return null
		if (this.list.length <= 0) {
			this.list = linkUrls.map(u => O.new(Link, u))
		}
		else if (!linkUrls || linkUrls.length <= 0) {
			this.list = []
		}
		else {
			for (let i = 0; i < linkUrls.length; ++i) {
				const u = linkUrls[i]
				if (i >= this.list.length) {
					this.list.push(O.new(Link, u))
				}
				else {
					const ln = this.list[i]
					const id = Link.parseItemId(u)
					if (ln.refId !== id) {
						if (i + 1 < this.list.length &&
							this.list[i + 1].refId === id)
							this.list.splice(i, 1)
						else
							this.list.splice(i, 0, O.new(Link, u))
					}
				}
			}
			if (linkUrls.length < this.list.length)
				this.list.splice(linkUrls.length)
		}
	}

	/** Set this readonly list of links to be computed. */
	setComputed(fn: () => Link[]) {
		setComputed(this, 'list' as any, fn)
	}

	get $debug() {
		return this.list.map(ln => {
			const { props } = ln.item
			const r = { id: ln.item.id }
			for (const name of props.keys())
				r[name] = props.get(name).$debug.value
			return r
		})
	}

}
