import { action, fixIds, O, observable, signal, U } from './common'
import { getViewType, View, ViewKey } from './View'

// TODO: config
export const maxRecentItemCount = 100

export interface NavData {
	key?: ViewKey
	id?: string
	params?: {
		boxUrl?: string
		[k: string]: string
	}
}

const plainItemIdPattern = /^\w+$/
const idAliases = ['installation', 'home']
export const nonItemViewKeys =
	['create', 'search', 'login', 'accounts', 'timeline']

export class Navigation {
	location: NavData = observable({ id: null, key: null, params: null })
	@observable view: View
	/** Navigate n steps back. Negative step values navigate forward. */
	back = signal<(steps?: any) => void>()
	/** Navigate n steps forward. Negative step values navigate back. */
	forward = signal<(steps?: any) => void>()
	reset = signal<(steps?: any) => void>()

	@observable homeItemId: string

	@observable hasHistory = true

	@observable recentIds: string[] = []
	@action addRecentId(id: string) {
		if (id && plainItemIdPattern.test(id) && idAliases.indexOf(id) < 0)
			U.array.addMoveFirst(this.recentIds, id, maxRecentItemCount)
	}
	@action removeRecentId(id: string) {
		const idx = this.recentIds.indexOf(id)
		if (idx >= 0) this.recentIds.splice(idx, 1)
	}

	goNext: NavData

	go(key: ViewKey, id?: string): void
	go(loc: NavData): void
	go(arg0: ViewKey | NavData, idArg?: string): void {
		const d = this.getNavData(arg0, idArg)
		if (U.obj.deepEquals(d, this.location))
			return
		// reuse ViewItem view for move key, create new ones for others
		const view = this.isMoveWithSameView(d) ?
			this.view : O.new(getViewType(d.key), d.id)
		// TODO: after replaces (add, move, create (see BrowserHistory.ts)), check
		// the previous entry and signal back instead of go, when identical.
		action(`${d.key} ${d.id}`, () => {
			Object.assign(this.location, d)
			this.view = view
			if ('configure' in view) view.configure(this.location)
			// remember recent item IDs
			this.addRecentId(d.id)
			this.hasHistory = true
		})()
	}

	private isMoveWithSameView(d: NavData) {
		return d.key === 'move' &&
			this.view && this.view.key === 'view' &&
			U.obj.deepEquals(this.location, { ...d, key: 'view' })
	}

	private getNavData(arg0: ViewKey | NavData, id: string) {
		let key: ViewKey = typeof arg0 === 'object' ? arg0.key : arg0
		if (!key)
			key = 'view'
		if (!id && typeof arg0 === 'object')
			id = arg0.id
		if (!id)
			id = this.location.id
		if (!id && this.recentIds.length > 0)
			id = this.recentIds[0]
		if (!id || id === 'home')
			id = this.homeItemId || fixIds.intro.allsbe
		if (nonItemViewKeys.indexOf(key) >= 0)
			id = null
		if (!id && key === 'view')
			key = 'search'
		const params = typeof arg0 === 'object' ? arg0.params : null
		return { key, params, id } as NavData
	}
}
