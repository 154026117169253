import { observer } from 'mobx-react'
import * as React from 'react'
import { computed } from '../../common'
import * as mdl from '../../model'
import { css } from './base'
import { Component } from './Component'

interface Props extends React.PropsWithChildren {
	ui: mdl.UserInterface
}

@observer
export class Menu extends Component<Props> {

	@computed get isOpen() { return this.props.ui.activeComponent === this }

	render() {
		const { children } = this.props
		return (
			<button className={css('menu', this.isOpen && ' open')}
				onClick={this.onOpen}>
				<div>{children}</div>
			</button>
		)
	}

	onOpen = (evn: React.MouseEvent<HTMLButtonElement>) => {
		if (!this.isOpen) {
			this.props.ui.activeComponent = this
			evn.stopPropagation()
		}
	}
}

