import * as React from 'react'
import * as mdl from '../../model'
import { Calendar, Context } from '../common'
import { ItemLabel } from '../LayoutViews'
import { Layout } from './common'

export const calendar: Layout = {
	content: (item, context, params) =>
		<NavCalendar item={item} context={context} month={params?.month} />,
	getContentLinks: mdl.CalendarLayout.getContentLinks,
}

class NavCalendar extends React.Component
	<{ item: mdl.Item, context: Context, month: string }> {

	render() {
		const { item, context, month } = this.props
		const m = month ? new Date(month) : item.props.findByType('date')?.value
		return <Calendar month={m} onNavigate={this.nav}
			showNavigation={!mdl.CalendarLayout.isMonthCalendar(item)}
			renderDate={date =>
				mdl.CalendarLayout.getItemsForDate(item, date).map(item =>
					<div key={item.id}><ItemLabel item={item} context={context} /></div>)}
			onSelect={item.props.findByAction('selectDate')?.value?.start} />
	}

	nav = (m: Date) => {
		this.props.context.nav.go({
			id: this.props.item.id,
			params: { month: m.getFullYear() + '-' + (m.getMonth() + 1) }
		})
	}

}
