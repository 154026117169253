export * from '../common'

export const fixIds = <const>{
	sys: {
		installation: 'installation.sys',
		boxes: 'boxes.sys',
		storages: 'storages.sys',
		tags: 'tags.sys',
		session: 'session.sys',
		system: 'system.sys',
	},
	intro: {
		allsbe: 'allsbe.intro',
	},
	box: {
		public: 'public.box',
	},
	storage: {
		public: 'public.storage',
	},
}

export function searchText(...args: any[]) {
	return args.filter(a => !!a).join(' ')
}

