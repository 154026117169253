import { action, O } from '../common'
import * as mdl from '../model'

export const setup = {

	create: () => {
		O.onInit(mdl.Item, item => {
			item.initNew.reactOnce(() => { setLog(item.create) })
		})
	},

	update: () => {
		O.onInit(mdl.Item, item => {
			item.recordUpdate.react(() => { setLog(item.update) })
		})
	},

}

function setLog(log: mdl.Log) {
	navigator.geolocation.getCurrentPosition(p => {
		action(() => {
			log.position = mdl.coordsToPosition(p.coords)
		})()
	})
}
