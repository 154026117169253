import { O, reaction, U, when } from '../common'
import * as mdl from '../model'

export const setup = {

	boxes: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.Box, box => {
			boxes.setBox(box.item)
		})
	},

	storages: ({ boxes }: { boxes: mdl.BoxManager }) => {
		O.onInit(mdl.BoxStorage, storage => {
			boxes.setStorage(storage)
		})
	},

	newItems: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.Item, item => {
			item.initNew.reactOnce(() => {
				when(() => !!item.id, () => { items.items.set(item.id, item) })
			})
		})
	},

	disposedItems: ({ items, boxes }:
		{ items: mdl.ItemManager, boxes: mdl.BoxManager }) => {
		O.onDispose(mdl.Item, item => {
			items.removeItem(item.id)
			boxes.removeBox(item.id)
			boxes.removeStorage(item.id)
		})
	},

	tagItems: ({ items }: { items: mdl.ItemManager }) => {
		O.onInit(mdl.Item, item => {
			reaction(() => item.tmpls.has('tag.tmpl'), isTmpl => {
				if (isTmpl)
					items.tagItems.push(item)
				else
					U.array.remove(items.tagItems, item)
			})
		})
	},

	limit: ({ items, boxes, nav }:
		{ items: mdl.ItemManager, boxes: mdl.BoxManager, nav: mdl.Navigation }) => {
		// limit cached items count (expect an average of 10 links per item)
		// recent items, boxes, storages, tags
		const maxCount = mdl.maxRecentItemCount + 10 + 10 + 20
		reaction(() => items.items.size > maxCount * 10,
			cleanup => {
				if (!cleanup) return
				// determine the item IDs to keep
				const excludes = U.array.toObject(boxes.allBoxItems, U.obj.toId, 1)
				for (const item of boxes.storageItems) {
					excludes[item.id] = 1
					addLinkedItems(item, excludes)
				}
				for (const item of items.tagItems) {
					excludes[item.id] = 1
					addLinkedItems(item, excludes)
				}
				for (const id of nav.recentIds) {
					excludes[id] = 1
					const item = items.getItem(id, true)
					if (item)
						addLinkedItems(item, excludes)
				}
				// remove not so recent items from cache
				const ids = []
				for (const id of items.items.keys())
					if (!(id in excludes)) ids.push(id)
				for (const id of ids) items.items.delete(id)
			})
	},

	installation: ({ items, inst }:
		{ items: mdl.ItemManager, inst: mdl.Installation }) => {
		// add installation item to items cache
		when(() => !!inst.item, () => {
			items.items.set('installation', inst.item)
		})
	},

}

function addLinkedItems(item: mdl.Item, excludes: { [x: string]: unknown }) {
	for (const ln of item.allAvailableLinks)
		if (ln.item)
			excludes[ln.item.id] = 1
	if (item.container)
		excludes[item.container.id] = 1
	for (const ln of item.tmpls)
		if (ln.item)
			excludes[ln.item.id] = 1
}

