import { observable, signal, Signal } from './common'

export interface AppBoot {
	// allow asynchronous initialization
	init: Signal<() => void | Promise<void>>
}

export class AppBase implements AppBoot {
	init = signal<() => void | Promise<void>>()
	@observable state: string = 'unknown'
	update = signal()
	skipWaiting = signal()
	reload = signal()
}

