import { U } from '../../../common'
import * as mdl from '../../../model'

export const textImporters:
	((txt: string) => mdl.ItemWithRefSpec[])[] = []

export function newItemsFromText(txt: string, type?: string):
	mdl.ItemWithRefSpec[] {
	txt = txt.trim()
	if (!txt)
		return null
	for (const importer of textImporters) {
		const newItems = importer(txt)
		if (newItems)
			return newItems
	}
	// default text/list item(s)
	// TODO: per-line types (url, list, comment,...)
	const lines = txt.split('\n').map(ln => ln.trim()).filter(U.any.isTrue)
	const asText = lines.find(ln => ln.length > 32)
	return lines.map(ln => asText ?
		{ props: { text: { text: ln } }, tmpls: [{ ref: 'text.tmpl' }] } :
		{ props: { title: ln }, tmpls: [{ ref: 'list.tmpl' }] })
}
