import { IGNORE_ERROR } from '../common'
import { O, observable, when } from './common'
import { Item, ItemStatus } from './Item'
import { ItemFactory, ItemRefSpec, ItemSpec, ItemWithRefSpec, LinkSpec, LinkWithRefSpec } from './ItemFactory'

export class ItemManager extends ItemFactory {

	/** 
	 * items cache 
	 * (directly accessed only in data/cache.ts)
	*/
	items = new Map<string, Item>()

	get missingItems() {
		return [...this.items.values()].filter(i => i.status === ItemStatus.missing)
	}

	/** observable cache for tag items */
	@observable.shallow tagItems: Item[] = []

	/** item ID aliases */
	itemIdAliases: { [alias: string]: () => string } = {}

	/** get an item */
	getItem(id: string, cacheOnly = false): Item {
		if (id in this.itemIdAliases)
			id = this.itemIdAliases[id]()
		if (!id) {
			return null
		} else if (this.items.has(id)) {
			return this.items.get(id) as Item
		} else if (cacheOnly) {
			return null
		} else {
			const item = O.new(Item)
			item.id = id
			this.items.set(id, item)
			return item as Item
		}
	}

	/** resolve item references */
	resolveItemRefs = (spec: ItemWithRefSpec) => {
		const res: ItemSpec = { props: spec.props }
		if (spec.content)
			res.content = spec.content.map(this.resolveLinkRefs)
		if (spec.links)
			res.links = spec.links.map(this.resolveLinkRefs)
		if (spec.tmpls)
			res.tmpls = spec.tmpls.map(s => 'ref' in s ? this.getItem(s.ref) : s)
		return res
	}
	resolveLinkRefs =
		(s: ItemWithRefSpec | ItemRefSpec | Item | LinkWithRefSpec) => {
			return s instanceof Item
				? s
				: 'ref' in s
					? this.getItem(s.ref)
					: 'item' in s
						? {
							...s,
							item: s.item instanceof Item
								? s.item
								: 'ref' in s.item
									? this.getItem(s.item.ref)
									: this.resolveItemRefs(s.item)
						} as LinkSpec
						: this.resolveItemRefs(s)
		}

	/** request an item */
	requestItem(id: string): Item {
		const item = this.getItem(id)
		item?.request().catch(IGNORE_ERROR)
		return item
	}

	/** require a ready item */
	async requireItem(id: string): Promise<Item> {
		const item = this.requestItem(id)
		if (item)
			await when(() => item.isReady || item.isMissing)
		if (!item || item.isMissing)
			throw new Error(`Item ${id} not found!`)
		return item
	}

	/** remove item from cache */
	removeItem(id: string) {
		this.items.delete(id)
		const idx = this.tagItems.findIndex(itm => itm.id === id)
		if (idx >= 0)
			this.tagItems.splice(idx, 1)
	}

	/** remove items from cache */
	cleanUp(maxCount: number, excludes: { [id: string]: any }) {
		if (this.items.size < maxCount)
			return
		const ids = []
		for (const id of this.items.keys())
			if (!(id in excludes))
				ids.push(id)
		for (let i = 0, len = ids.length - maxCount; i < len; ++i)
			this.items.delete(ids[i])
	}

}
