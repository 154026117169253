import * as date from './date'
import * as error from './error'

/** Returns true if the given value v is true. Reusable v => !!v */
export function isTrue<T>(v: T): boolean { return !!v }

/** Returns true if the given value v is true. Reusable v => !v */
export function isFalse<T>(v: T): boolean { return !v }

export function isPrimitive(v: any): v is string | number | boolean {
	return v !== Object(v)
}

export function stringify(val: any,
	indent?: string, maxLevels = 100, stringQuote = "'") {
	return _stringify(val, 0, indent ?? null, maxLevels, stringQuote)
}

function _stringify(val: any, level: number, indent: string | null,
	maxLevels: number, quote: string): string {
	if (level > maxLevels)
		return '-- stringify: maximal level depth exceeded! --'
	const t = typeof val
	if (t === 'function')
		return ''
	if (t === 'string')
		return quote + val + quote
	if (t !== 'object')
		return '' + val
	if (val === null)
		return ''
	if (date.isDate(val))
		return quote + val.toISOString() + quote
	if (val instanceof Error)
		return _stringify(error.toJson(val), level, indent, maxLevels, quote)
	if (Object.prototype.toString.call(val) === '[object Array]' &&
		'map' in val)
		return '[' + val
			.map((v: any) => _stringify(v, level + 1, indent, maxLevels, quote))
			.join(indent ? ', ' : ',') + ']'
	const keys = []
	for (const k in val)
		keys.push(k)
	if (keys.length <= 0)
		return '{}'
	keys.sort()
	const members = []
	for (const k of keys) {
		const s = _stringify(val[k], level + 1, indent, maxLevels, quote)
		if (s)
			members.push(k + ':' + (indent ? ' ' : '') + s)
	}
	const nl = indent ? '\n' + indent.repeat(level + 1) : ''
	return `{${nl}${members.join(',' + nl)}${indent ?
		'\n' + indent.repeat(level) : ''}}`
}
